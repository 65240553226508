import { render, staticRenderFns } from "./What is Debt-to-Income Ratio and Why It’s Important.vue?vue&type=template&id=5dd68fe6&scoped=true&"
import script from "./What is Debt-to-Income Ratio and Why It’s Important.vue?vue&type=script&lang=js&"
export * from "./What is Debt-to-Income Ratio and Why It’s Important.vue?vue&type=script&lang=js&"
import style0 from "./What is Debt-to-Income Ratio and Why It’s Important.vue?vue&type=style&index=0&id=5dd68fe6&lang=scss&scoped=true&"
import style1 from "./What is Debt-to-Income Ratio and Why It’s Important.vue?vue&type=style&index=1&id=5dd68fe6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dd68fe6",
  null
  
)

export default component.exports