import { render, staticRenderFns } from "./faqs.vue?vue&type=template&id=aa708962&scoped=true&"
import script from "./faqs.vue?vue&type=script&lang=js&"
export * from "./faqs.vue?vue&type=script&lang=js&"
import style0 from "./faqs.vue?vue&type=style&index=0&id=aa708962&lang=scss&scoped=true&"
import style1 from "./faqs.vue?vue&type=style&index=1&id=aa708962&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa708962",
  null
  
)

export default component.exports