<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>Choosing the Right Life Insurance Company For You</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Sarah Pritzker</div>
                                <div>Last update: 21 April 2020</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/Choosing the Right Life Insurance Company For Youblog_1.jpg" alt="">
                            <p>Choosing any kind of insurance, whether it's health insurance, car insurance, life insurance, or any other type, really, can be a challenger to navigate if you've never done it before!</p>
                            <p>There's plenty to consider, like what determines the rates for policies, coverage options, finding the right company, and so on that can make the process seem more involved than it is. And while there are some nuances to the various policies you'll find, the basic purpose is always the same: life insurance is designed to provide your beneficiaries with a cash payout if you die.</p>
                            <p>Choosing the right life insurance company for you is actually pretty straightforward once you're familiar with some of the terminology involved, so let's dive in.</p>
                            <h2>Factors to Consider When Choosing Life Insurance</h2>
                            <p>Before embarking on your quest for the perfect life insurance policy, there are a few factors to consider to make your search easier.</p>
                            <h3>Decide how long you want your coverage to be</h3>
                            <p>Policies can be 10, 15, 20, 30 years long, or even for life. If you think you'll only need insurance for the length of your mortgage or while your kids are growing up, for example, a temporary term policy is a good option. If you want the security of having it your whole life, permanent coverage is a solution.</p>
                            <h3>Calculate the payout amount you need</h3>
                            <p>When getting a life insurance quote, you'll have the option of selecting the coverage amount, which can be $250,000, $400,000, $500,000, or more.</p>
                            <p>None</p>
                            <p>So how do you know how much you'll need?</p>
                            <p>Use the DIME method—which stands for:</p>
                            <ul class="text1"><li>Debt (like car loans, mortgage, credit cards, etc)</li>
                            <li>Income replacement (consider who depends on your income and how long you'd want to replace your income)</li>
                            <li>Mortality (funeral and burial costs)</li>
                            <li>Education (Do you want the payout to cover education costs, like college, for your beneficiaries? Do you have kids who will need daycare covered, and do you want your policy to cover it?)</li></ul>
                            <h3>Think about other goals</h3>
                            <p>There are permanent life insurance policies that have a cash value that increases over time (depending on the market and interest). Some people use these types of policies as an investment vehicle when they've already maxed out their other retirement accounts, like 401(k)s and Roth IRAs.</p>
                            <h3>Choose your beneficiary</h3>
                            <p>Who do you want the proceeds of your policy to go to when you die? This person is your beneficiary. When choosing, it's best to avoid naming a minor since there can be some legal complications, like a child not being able to receive the funds directly.</p>
                            <h3>Speak with a trusted advisor</h3>
                            <p>While it's not necessary, speaking with a trusted advisor—someone who has your best interests in mind—is a great way to get through the process if you're still unsure about something or have any questions.</p>
                            <h2>Types of Life Insurance</h2>
                            <p>Once you've considered points from the section above, like how long you want the coverage to last and the amount needed, it's time to think about what type of insurance is best for you.</p>
                            <ul class="text1"><li>Term life insurance: This is the most common type and easiest to understand. You can choose to have coverage for a specified length of time, typically between 10-30 years. This is the cheapest option since it's temporary, but you can always choose to renew your term when it expires. Fabric life insurance offers affordable term policies that take less than 10 minutes to apply for.</li></ul>
                            <ul class="text1"><li>Whole life insurance: This option covers you for life and so the premiums for it are much higher than a term policy. It also offers a cash value component since a piece of your premiums will partly go toward the policy and partly toward a savings account. The more you put in over the years, the more the value is built up.</li></ul>
                            <ul class="text1"><li>Universal life insurance: This policy also covers you for life and comes with a cash value account, but it's more flexible when it comes to choosing the premium payment amount you want to pay every month. Money from the cash value account can also be withdrawn or used to help pay your premiums.</li></ul>
                            <h2>What to Pay Attention to When Picking a Provider</h2>
                            <p>If you've taken a peek at what life insurance companies are out there, you'll find that there are tons of options, so how do you pick the right company?</p>
                            <h3>Do they pay their claims?</h3>
                            <p>Some life insurance companies will look for any reason to avoid paying a claim or they'll cancel policies if just one of your premium payments falls behind. This is why it's crucial to look up a life insurance company's reputation.</p>
                            <p>Read customer reviews online and search for any that mention non-payment. You can also ask an expert you know, such as a financial advisor.</p>
                            <h3>None</h3>
                            <p>With big companies like State Farm or Geico, it's pretty safe to say that they'll have the funds needed to pay a claim. With smaller companies, however, like a local insurance firm, you'll want to make sure they have the financial strength to pay the bill.</p>
                            <p>Check what their ratings are with trusted agencies like the BBB, J.D. Power, and A.M. Best. These are third-party agencies that methodically review and rate financial services and life insurance companies.</p>
                            <p>None</p>
                            <h3>How is their Customer Service?</h3>
                            <p>High-quality customer service is important. If it's difficult to get in touch with a representative from a company, that's a red flag and can be an indicator of other issues with a provider.</p>
                            <p>Customer service is important. They can help you understand the policies offered along with coverage exceptions that you may want to learn more about. Always test the waters by contacting the insurance company's customer service. Are they answering your questions clearly or were they vague? Did they follow up on an email you sent in a reasonable amount of time? Did it take too long to get someone on the phone?</p>
                            <p>Life insurance policy contracts can be confusing. Use this as an opportunity to learn more about a policy and to review the quality of customer service provided.</p>
                            <h3>Compare rates</h3>
                            <p>When you're shopping for life insurance rates, you'll want to first check to see that the company offers the coverage you're looking for, whether it's a term policy or a permanent policy, and how the prices look.</p>
                            <p>While prices for life insurance are relatively flat across companies, what differs is how these various life insurance companies evaluate risk. Factors like your age, gender, medical history, occupation, and hobbies all play a role in determining how risky you are to insure. Some companies will require a life insurance medical exam, for example, and some won't.</p>
                            <p>Get personalized offers from a handful of insurers to see what life insurance rates you can get based on your personal situation.</p>
                            <h2>Final Thoughts</h2>
                            <p>Choosing the right life insurance company is an important decision, so take your time with it. Begin by considering what type of insurance you need, how much you need, and who your beneficiary will be.</p>
                            <p>Then you can start researching to find strong life insurance companies with top ratings and who can match your needs.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Sarah Pritzker</span><br />Last update: 21 April 2020</div>
                        </div>
                        <p>Sarah Pritzker loves researching - and then sharing - all the info she can get her hand on in the world of finance. Whether it's comparing credit cards, contrasting life insurance policies, staking out investment opportunities, or investigating fraud, Sarah's got her finger on the pulse of the finance moment.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
