<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">LendingTree Review</div>
                    <div class="head_subtitle">
                        <img src="/imgs/content1_icon.png" alt="">
                        <span>Reviews 10,860</span>
                    </div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Find a customized quote easily</span>
                    </div>
                    <div class="head_button">
                        <span>Visit Site</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>LendingTree summary</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/content2_avatar_img.png" alt="">
                            <div class="avatar_information">
                                <div>Jessica Cotzin</div>
                                <div>Last update: 28 February 2022</div>
                            </div>
                        </div>
                        <div class="text">
                            <p>LendingTree lets borrowers take advantage of the competition between personal loan lenders to access better terms, rates, and loan amounts, even if you have poor credit. It gives customers the ability to compare offers simultaneously, empowering them to find a solution based on their unique circumstances. </p>
                            <p>Borrowers have the freedom to use a personal loan in a number of ways—from making home improvements or repairs to paying off medical expenses or consolidating debt. To begin comparing rates from lenders and seeing what you can get approved for, 5K Funds has a quick, simple, and secure online form.</p>
                            <p> APR can range anywhere from 5.99% to 35.99%and will vary depending on a variety of factors, such as a borrower’s credit score, credit history, and overall financial profile. Since 5K Funds doesn’t issue loans directly, fees and rates will vary according to the specific lender, so be sure to read through the terms upon approval.</p>
                            <img src="/imgs/content2_img.png" alt="">
                            <div class="text_list">
                                <div class="text_list_left">
                                    <div>PROS</div>
                                    <ul class="text1">
                                        <li>Large network of reliable lenders</li>
                                        <li>No fees for connecting with loan providers</li>
                                    </ul>
                                </div>
                                <div class="text_list_right">
                                    <div>PROS</div>
                                    <ul class="text2">
                                        <li>Large network of reliable lenders</li>
                                        <li>No fees for connecting with loan providers</li>
                                    </ul>
                                </div>
                            </div>
                            <h2>Loan Features</h2>
                            <p>The most obvious feature that stands out is the swift process for finding the right loan. Normally, researching loans and finding the right provider could take weeks or even months. LendingTree has effectively streamlined the process, and takes just a few minutes to show you potential lenders that match your needs and creditworthiness by using a single pre-qualification application.</p>
                            <p>LendingTree offers personal loans for a variety of reasons, including medical expenses, major spending, and debt consolidation. It can generally offer good interest rates thanks to its competitive marketplace, where lenders will compete for a borrower’s business, and not the other way around. Moreover, if you need to see the real impact of a loan, or your expected repayments, you can use one of LendingTree’s great calculators to understand a potential loan’s financial impact. </p>
                            <h2>LendingTree’s highlights:</h2>
                            <ul class="text1">
                                <li>A collection of articles and guides</li>
                                <li>A competitive online loan marketplace</li>
                                <li>A single application to prequalify for most lenders</li>
                                <li>Use your loan for any purpose</li>
                                <li>Access a variety of different loan products</li>
                                <li>Find unsecured personal loans for up to $50,000</li>
                                <li>See the implications of your loan with repayment calculators</li>
                                <li>Responsive customer support</li>
                            </ul>
                            <h2>The Application Process</h2>
                            <p>To get started, fill out a single application that pre-qualifies you for a range of different loans. This form does not ask for any sensitive personal details, instead focusing on your contact information and some financial specifics like your income level, housing status, and similar data.</p>
                            <p>LendingTree’s initial application does not include a hard credit pull, so your scores will remain unaffected. Once you have been approved, which takes a few minutes in most cases, you will be shown at least 5 options that match your creditworthiness and loan amount requirements. After choosing an option, you will finish applying directly with the lender selected.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="article_list_right_top">
                                <span>1,523 uesrs</span>
                                <img src="/imgs/content3_icon.png" alt="">
                            </div>
                            <div class="article_list_right_bottom">
                                <span>Picked a lender via<br />BestMoney this week</span>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="lending_container">
                <div class="lending_tree">
                    <div class="lending_tree_left">
                        <div class="lending_tree_top">
                            <h2>LendingTree at a glance</h2>
                            <div class="tree_top_right">
                                <div class="top_right_score">
                                    <span>4.6</span>
                                    <img src="/imgs/content3_score_icon.png" alt="">
                                </div>
                                <div class="top_right_more">Editorial score</div>
                            </div>
                        </div>
                        <div class="lending_tree_bottom">
                            <ul>
                                <li>
                                    <div class="bottom_list_left">
                                        <div class="list_top">
                                            <span>Online experience</span>
                                            <img src="/imgs/content3_1_icon.png" alt="">
                                        </div>
                                        <div class="list_bottom">"Has learning resources, access to rates, mobile app"</div>
                                    </div>
                                    <div class="bottom_list_right">5.0</div>
                                </li>
                                <li>
                                    <div class="bottom_list_left">
                                        <div class="list_top">
                                            <span>Online experience</span>
                                            <img src="/imgs/content3_1_icon.png" alt="">
                                        </div>
                                        <div class="list_bottom">"Has learning resources, access to rates, mobile app"</div>
                                    </div>
                                    <div class="bottom_list_right">5.0</div>
                                </li>
                                <li>
                                    <div class="bottom_list_left">
                                        <div class="list_top">
                                            <span>Online experience</span>
                                            <img src="/imgs/content3_1_icon.png" alt="">
                                        </div>
                                        <div class="list_bottom">"Has learning resources, access to rates, mobile app"</div>
                                    </div>
                                    <div class="bottom_list_right">5.0</div>
                                </li>
                                <li>
                                    <div class="bottom_list_left">
                                        <div class="list_top">
                                            <span>Online experience</span>
                                            <img src="/imgs/content3_1_icon.png" alt="">
                                        </div>
                                        <div class="list_bottom">"Has learning resources, access to rates, mobile app"</div>
                                    </div>
                                    <div class="bottom_list_right">5.0</div>
                                </li>
                                <li>
                                    <div class="bottom_list_left">
                                        <div class="list_top">
                                            <span>Online experience</span>
                                            <img src="/imgs/content3_1_icon.png" alt="">
                                        </div>
                                        <div class="list_bottom">"Has learning resources, access to rates, mobile app"</div>
                                    </div>
                                    <div class="bottom_list_right">5.0</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="lending_tree_right"></div>
                </div>
            </div>
            <div class="trustpilot_container">
                <div class="trustpilot_reviews">
                    <div class="reviews_left">
                        <div class="reviews_top">
                            <div class="top_title">Trustpilot reviews</div>
                            <img src="/imgs/content4_logo.png" alt="">
                            <div class="score">
                                <div>Excellent•Reviews 10,860</div>
                                <img src="/imgs/content1_icon.png" alt="">
                            </div>
                            <div class="progress_container">
                                <div>
                                    <el-progress type="circle" color="#2ac8a2" :percentage="81"></el-progress>
                                    <div class="title">Excellent</div>
                                </div>
                                <div>
                                    <el-progress type="circle" color="#2ac8a2" :percentage="11"></el-progress>
                                    <div class="title">Great</div>
                                </div>
                                <div>
                                    <el-progress type="circle" color="#2ac8a2" :percentage="2"></el-progress>
                                    <div class="title">Average</div>
                                </div>
                                <div>
                                    <el-progress type="circle" color="#2ac8a2" :percentage="1"></el-progress>
                                    <div class="title">Poor</div>
                                </div>
                                <div>
                                    <el-progress type="circle" color="#2ac8a2" :percentage="5"></el-progress>
                                    <div class="title">Bad</div>
                                </div>
                            </div>
                        </div>
                        <div class="reviews_bottom">
                            <div class="comment">
                                <div class="comment_avatar_list">
                                    <div class="comment_avatar">
                                        <img src="/imgs/content4_2_img.png" alt="">
                                        <div>
                                            <div>Jessica Cotzin</div>
                                            <img src="/imgs/content1_icon.png" alt="">
                                        </div>
                                    </div>
                                    <span>21 hours ago</span>
                                </div>
                                <h2>Im beyond grateful and surprisingly…</h2>
                                <p>Im beyond grateful and surprisingly shocked to i get approved so fast! And most of all, how quickly i got it transmitted in my bank account... prayers work 🙏 thanks Lending Tree</p>
                            </div>
                            <div class="comment">
                                <div class="comment_avatar_list">
                                    <div class="comment_avatar">
                                        <img src="/imgs/content4_2_img.png" alt="">
                                        <div>
                                            <div>Jessica Cotzin</div>
                                            <img src="/imgs/content1_icon.png" alt="">
                                        </div>
                                    </div>
                                    <span>21 hours ago</span>
                                </div>
                                <h2>Im beyond grateful and surprisingly…</h2>
                                <p>Im beyond grateful and surprisingly shocked to i get approved so fast! And most of all, how quickly i got it transmitted in my bank account... prayers work 🙏 thanks Lending Tree</p>
                            </div>
                            <div class="comment">
                                <div class="comment_avatar_list">
                                    <div class="comment_avatar">
                                        <img src="/imgs/content4_2_img.png" alt="">
                                        <div>
                                            <div>Jessica Cotzin</div>
                                            <img src="/imgs/content1_icon.png" alt="">
                                        </div>
                                    </div>
                                    <span>21 hours ago</span>
                                </div>
                                <h2>Im beyond grateful and surprisingly…</h2>
                                <p>Im beyond grateful and surprisingly shocked to i get approved so fast! And most of all, how quickly i got it transmitted in my bank account... prayers work 🙏 thanks Lending Tree</p>
                            </div>
                            <div class="comment">
                                <div class="comment_avatar_list">
                                    <div class="comment_avatar">
                                        <img src="/imgs/content4_2_img.png" alt="">
                                        <div>
                                            <div>Jessica Cotzin</div>
                                            <img src="/imgs/content1_icon.png" alt="">
                                        </div>
                                    </div>
                                    <span>21 hours ago</span>
                                </div>
                                <h2>Im beyond grateful and surprisingly…</h2>
                                <p>Im beyond grateful and surprisingly shocked to i get approved so fast! And most of all, how quickly i got it transmitted in my bank account... prayers work 🙏 thanks Lending Tree</p>
                            </div>
                            <div class="pagination">
                                <el-pagination
                                    small
                                    background
                                    layout="prev, pager, next"
                                    :total="1000">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                    <div class="reviews_right"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "article",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #c5c5c5;
        font-size: 18px;
        background: url(/imgs/content1_img.png) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #fff;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #c5c5c5;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 57px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 24px;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    height: 125px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    margin-top: 50px;
                    .article_list_right_top {
                        display: flex;
                        align-items: center;
                        span {
                            font-size: 24px;
                            font-weight: 700;
                            color: #222831;
                            margin-right: 35px;
                        }
                    }
                    .article_list_right_bottom {
                        background-color: #f5f8fb;
                        height: 73px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span {
                            color: #666666;
                            font-size: 18px;
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .lending_container {
        width: 100%;
        background-color: #f5f8fb;
        .lending_tree {
            width: 61%;
            margin: 50px auto 68px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .lending_tree_left {
                width: 750px;
                .lending_tree_top {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid #c5c5c5;
                    padding-bottom: 42px;
                    margin-bottom: 30px;
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 48px;
                    }
                    .tree_top_right {
                        .top_right_score {
                            span {
                                font-size: 48px;
                                font-weight: 700;
                                color: #222831;
                                line-height: 48px;
                                margin-right: 20px;
                            }
                        }
                        .top_right_more {
                            text-align: center;
                            font-size: 18px;
                            color: #666666;
                            line-height: 18px;
                        }
                    }
                }
                .lending_tree_bottom {
                    ul {
                        line-height: 18px;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        li {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 60px;
                            .bottom_list_left {
                                margin-right: 90px;
                                .list_top {
                                    span {
                                        font-size: 18px;
                                        font-weight: 700;
                                        color: #222831;
                                        margin-right: 20px;
                                    }
                                }
                                .list_bottom {
                                    width: 210px;
                                }
                            }
                            .bottom_list_right {
                                font-size: 36px;
                                font-weight: 700;
                                line-height: 36px;
                                color: #2ac8a2;
                            }
                        }
                    }
                }
            }
            .lending_tree_right {
                width: 325px;
            }
        }
    }
    .trustpilot_container {
        width: 100%;
        .trustpilot_reviews {
            width: 61%;
            margin: 50px auto 180px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .reviews_left {
                width: 750px;
                .reviews_top {
                    .top_title {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 24px;
                        margin-bottom: 35px;
                    }
                    .score {
                        div {
                            font-size: 14px;
                            color: #c5c5c5;
                            line-height: 14px;
                            margin-bottom: 10px;
                        }
                    }
                    .progress_container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin: 20px 0 20px 0;
                        text-align: center;
                        font-weight: 700;
                        font-size: 18px;
                        color: #222831;
                        line-height: 24px;
                    }
                }
                .reviews_bottom {
                    .comment {
                        padding: 55px 0 55px;
                        border-top: 1px solid #c5c5c5;
                        .comment_avatar_list {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .comment_avatar {
                                display: flex;
                                align-items: center;
                                img {
                                    margin-right: 15px;
                                }
                                div {
                                    font-size: 18px;
                                    font-weight: 700;
                                    line-height: 24px;
                                }
                            }
                            span {
                                font-size: 14px;
                                color: #c5c5c5;
                                line-height: 14px;
                            }
                        }
                        h2 {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 24px;
                            margin: 15px 0 15px 0;
                        }
                        p {
                            font-size: 18px;
                            color: #666666;
                            line-height: 24px;
                        }
                    }
                    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #2ac8a2 !important;
                    }
                }
            }
            .reviews_right {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .lending_container {
            .lending_tree {
                width: 95%;
                .lending_tree_left {
                    .lending_tree_top {
                        .tree_top_right {
                            .top_right_score {
                                span {
                                    font-size: 45px;
                                    margin-right: 0px;
                                }
                            }
                        }
                    }
                    .lending_tree_bottom {
                        ul {
                            flex-direction: column;
                        }
                    }
                }
                .lending_tree_right {
                    display: none;
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
        .lending_container {
            .lending_tree {
                .lending_tree_left {
                    .lending_tree_top {
                        h2 {
                            font-size: 29px;
                        }
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                .reviews_left {
                    .reviews_top {
                        .top_title {
                            font-size: 38px;
                        }
                        .progress_container {
                            div {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
</style>