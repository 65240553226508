<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Life Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">The importance of life insurance is that it provides financial security to your family in case you no longer provide them with life insurance, which makes life insurance a priority for people.Although life insurance companies today offer a wide variety of options, you must carefully evaluate all plans and compare life insurance quotes to make an informed investment decision.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/life_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Life Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/life_insurance_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Life Insurance Reviews</span>
                          </router-link>
                      </div>
                      <!-- <div class="head_card">
                          <router-link to="/auto_insurance_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Life Insurance Guide</span>
                          </router-link>
                      </div> -->
                      <div class="head_card">
                          <router-link to="/calculator" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Life Insurance Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Life Insurance</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.pic_img" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="item.star"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.name}}</span>
                                          <ul>
                                              <li v-for="(subitem, index) of item.features" :key="index">{{ subitem }}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What is life insurance?</h2>
                          <p>Life insurance is a contract with an insurer that promises to pay the insurer or associates for other illnesses, including other expenses, but also other events, upon the death of the insurer (usually a policyholder policy). According to the contract, such as possible breach of contract or payment of a breach fee.</p>
                          <p>Life insurance policies are legal contracts, and the terms of each contract describe the limitations of insured events. Often, the specific limited liability in the contract is that of the insurer; common examples are events such as those related to suicide, fraud, war, riots, and civil unrest. The insured is knowingly taking a risk by agreeing to an experimental medical procedure or a drug that causes injury or death if there is no clear indication that something will go wrong.</p>
                          <h2 class="anchor_h2">Why you need life insurance?</h2>
                          <p>The reasons for buying insurance vary from person to person. But the core of the decision to buy insurance is to provide financial protection for yourself and the people you care about.</p>
                          <p>5 reasons to buy life insurance.</p>
                          <p class="text_bold">1. Guaranteed protection</p>
                          <p>When you die, your beneficiary will receive a lump sum payment guaranteed to be paid in full (provided that all premiums have been paid and there are no outstanding loans). This is the necessary protection. When you need it, you can rely on it to help the people you love.</p>
                          <p class="text_bold">2. Income replacement</p>
                          <p>Imagine what would happen to your family if the income you provided suddenly disappeared. With lifetime insurance, you can help ensure your loved ones have the money they need to help</p>
                          <p class="text_bold">3. Tax-free benefit</p>
                          <p>Your beneficiaries will be able to enjoy every penny you leave them. This is because the benefits of a life insurance policy are usually tax-free.</p>
                          <p class="text_bold">4. Guaranteed cash value growth</p>
                          <p>When you pay premiums, your whole life policy creates cash value, guarantees increased tax deferral, and helps achieve a variety of financial goals: supplement retirement income, fund your children's or grandchildren's education, pay off your mortgage, protect Existing assets, building an emergency fund</p>
                          <p class="text_bold">5. Dividend potential</p>
                          <p>One of the benefits of buying whole life insurance from New York Life is that you will be eligible for dividends. While not guaranteed, when dividends are paid, you can pay them in cash and use them to offset your premiums, or use them to buy additional coverage with fully paid premiums, increasing your coverage and cash value, and use them to offset your premiums, or pay in cash. </p>
                          <h2 class="anchor_h2">Who should get life insurance?</h2>
                          <p>The key question in buying life insurance is whether there are people in your life, including family members and employees, who are financially dependent on you. Below are some types of people for whom the policy might bring more peace of mind.</p>
                          <ul class="text1">
                              <li>Parents with young children</li>
                              <li>Spouse who provides most of the income</li>
                              <li>Seniors without savings</li>
                              <li>Adults with private student loans</li>
                              <li>Entrepreneur</li>
                          </ul>
                          <h2 class="anchor_h2">Life Insurance Types</h2>
                          <p class="text_bold">Term Life Insurance:</p>
                          <p>Term life insurance gives you coverage for a fixed term (i.e. 10 years, 20 years, 30 years, etc.). The main attraction of this type of life insurance is that it tends to be the cheapest option in terms of premiums. Generally speaking, the younger and healthier you are when buying a policy, the cheaper the premiums tend to be.</p>
                          <p class="text_bold">Permanent Life Insurance:</p>
                          <p>Permanent life insurance will cover you for life as long as the premiums are paid. Permanent life insurance policies include whole life, universal and variable life insurance. Some permanent life insurance policies include a savings component that allows you to build up cash value.</p>
                          <p class="text_bold">Family life insurance:</p>
                          <p>Family life insurance is a term used to describe a life insurance policy that includes coverage for every member of your family. While the details of these policies vary, family life insurance typically includes whole life coverage for the breadwinner of the family, as well as term life coverage for their spouse and dependents.</p>
                          <p class="text_bold">Seniors' life insurance:</p>
                          <p>Seniors' life insurance is whole life insurance that is usually purchased by seniors to cover funeral costs and other final expenses after they pass away. </p>
                          <p>This type of life insurance can provide additional coverage for older Americans, especially if you have a term life insurance policy that may expire at age 70.</p>
                          <h2 class="anchor_h2">How to get life insurance?</h2>
                          <p class="text_bold">1. Decide if You Need Life Insurance </p>
                          <p>Refer to【Who should get life insurance】</p>
                          <p class="text_bold">2. Determine How Much Life Insurance You Need</p>
                          <p>The amount of life insurance you need depends on a variety of factors, including your personal and family income, the needs of your dependents or potential beneficiaries, and your financial goals</p>
                          <p class="text_bold">3. Determine Which Type of Life Insurance Is Right for You</p>
                          <p>There are two main types of life insurance to choose from: term life insurance, which lasts for a certain period, or permanent life insurance, as long as you live. Knowing the defining characteristics of each type can help you choose the right type.</p>
                          <p class="text_bold">4. Choose a Life Insurance Company</p>
                          <p>The life insurance market offers consumers a fair share of provider and policy choices. Consumers can also choose to work with an insurance agent, insurance broker, or insurance company. Understanding the differences between each term can help you find a company and policy that meets your needs.</p>
                          <p>Tips for Selecting an Insurance Company, Agent, or Broker</p>
                          <ul class="text1">
                              <li>Check Licensing</li>
                              <li>Review Company Ratings</li>
                              <li>Get Reviews & Referrals</li>
                              <li>Check for Bundling Discounts</li>
                              <li>Shop Around</li>
                              <li>Consider Your Preferences</li>
                          </ul>
                          <p class="text_bold">5. Purchase a policy</p>
                          <p>Once you've determined the type and amount of coverage you want, reviewed free quotes, and compared companies, it's time to start the application process.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is life insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Why you need life insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Who should get life insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Life Insurance Types</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">How to get life insurance?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { GetOffers } from '../../api/cindex';
import { imgsapiUrl } from '../../config/env';
export default {
    name: "life_insurance",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    created() {
        this.GetOffersList();
    },
    methods: {
        // 获取offer数据
        async GetOffersList() {
            const res = await GetOffers( { category_id: '153', page: '1', page_size: '4'  } )
            res.data.forEach(i => {
                i.pic_img = imgsapiUrl + i.img.split('.')[0] + '/' + i.img
                i.star = 5
            })
            this.offers_list = res.data
        },
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>