<template>
  <div class="header">
    <!-- 顶部导航栏 -->
    <div class="nav-topbar">
      <div class="container">
        <!-- 导航栏左边的区域 -->
        <div class="topbar-menu">
          <!-- javascript:; 点击时, 页面不会刷新 -->
          <!-- logo -->
          <!-- <a href="/debt"><img src="/imgs/header_logo.png" alt=""></a> -->
          <router-link to="/"><img src="/imgs/logo.png" alt=""></router-link>
          <el-menu
           :default-active="this.$route.path"
           router
           class="el-menu-demo"
           mode="horizontal"
           background-color="#092742"
           text-color="#fff"
           active-text-color="#3db697">
            <!-- <el-submenu v-for="(item,index) in menulist" :key='index' :index="index">
              <template slot="title">{{ item.name }}</template>
              <div class="menu_container">
                <div class="menu_left menu_text">
                  <div class="menu_titel">Compare</div>
                  <el-menu-item v-for="(sunitem,index) in item.child_node" :key='index' :index="sunitem.url"><i class="el-icon-right"></i>{{ sunitem.child_name }}</el-menu-item>
                </div>
                <div class="menu_center menu_text">
                  <div class="menu_titel">Reviews</div>
                  <el-menu-item v-for="(postitem,index) in item.posts" :key='index' :index="postitem.review_url" @click="toread(postitem.post_id, postitem.child_name, postitem.child_node_url, postitem.url)"><i class="el-icon-right"></i>{{ postitem.name }}</el-menu-item>
                </div>
              </div>
            </el-submenu> -->
            <el-submenu index="1">
              <template slot="title">Loans</template>
              <div class="menu_container">
                <div class="menu_left menu_text">
                  <div class="menu_titel">Compare</div>
                  <el-menu-item index="/personal_loans"><i class="el-icon-right"></i>Personal Loans</el-menu-item>
                </div>
                <div class="menu_center menu_text">
                  <div class="menu_titel">Reviews</div>
                  <el-menu-item index="/HolidayFunded"><i class="el-icon-right"></i>HolidayFunded</el-menu-item>
                  <el-menu-item index="/LoanPioneer"><i class="el-icon-right"></i>Loan Pioneer</el-menu-item>
                  <el-menu-item index="/Amone"><i class="el-icon-right"></i>Amone</el-menu-item>
                  <el-menu-item index="/GetCashReliefNow"><i class="el-icon-right"></i>GetCashReliefNow</el-menu-item>
                  <el-menu-item index="/ZippyLoan"><i class="el-icon-right"></i>Zippy Loan</el-menu-item>
                  <el-menu-item index="/LendingForBadCredit"><i class="el-icon-right"></i>Lending For Bad Credit</el-menu-item>
                  <el-menu-item index="/SearchKeyLoans"><i class="el-icon-right"></i>SearchKeyLoans</el-menu-item>
                </div>
              </div>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">Mortgages</template>
              <div class="menu_container">
                <div class="menu_left menu_text">
                  <div class="menu_titel">Compare</div>
                  <el-menu-item index="/mortgage_rates"><i class="el-icon-right"></i>Mortgage Rates</el-menu-item>
                </div>
                <div class="menu_center menu_text">
                  <div class="menu_titel">Reviews</div>
                  <el-menu-item index="/HarpRateQuiz"><i class="el-icon-right"></i>HarpRateQuiz</el-menu-item>
                  <el-menu-item index="/emortgage_review"><i class="el-icon-right"></i>eMortgage</el-menu-item>
                  <el-menu-item index="/HomeEquityQuiz"><i class="el-icon-right"></i>HomeEquityQuiz</el-menu-item>
                  <!-- <el-menu-item index="/veterans_united_home_loans_review"><i class="el-icon-right"></i>Veterans United Home Loans</el-menu-item> -->
                  <el-menu-item index="/FHARateSimplified"><i class="el-icon-right"></i>FHARateSimplified</el-menu-item>
                  <el-menu-item index="/HomeLightSimpleSale"><i class="el-icon-right"></i>HomeLightSimpleSale</el-menu-item>
                  <el-menu-item index="/QuickenCompare"><i class="el-icon-right"></i>Quicken Compare</el-menu-item>
                </div>
              </div>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">Insurance</template>
              <div class="menu_container">
                <div class="menu_left menu_text">
                  <div class="menu_titel">Compare</div>
                  <el-menu-item index="/life_insurance"><i class="el-icon-right"></i>Life Insurance</el-menu-item>
                  <el-menu-item index="/auto_insurance"><i class="el-icon-right"></i>Auto Insurance</el-menu-item>
                </div>
                <div class="menu_center menu_text">
                  <div class="menu_titel">Reviews</div>
                  <el-menu-item index="/ProtectingFamiliesEveryday"><i class="el-icon-right"></i>Protecting Families Everyday</el-menu-item>
                  <el-menu-item index="/GreatLife"><i class="el-icon-right"></i>Great Life</el-menu-item>
                  <el-menu-item index="/SelectMyPolicy"><i class="el-icon-right"></i>Select My Policy</el-menu-item>
                  <el-menu-item index="/EverydayLifeInsurance"><i class="el-icon-right"></i>Everyday Life Insurance</el-menu-item>
                  <el-menu-item index="/EasyAutoPolicy"><i class="el-icon-right"></i>EasyAutoPolicy</el-menu-item>
                  <el-menu-item index="/CoverageProfessor"><i class="el-icon-right"></i>CoverageProfessor</el-menu-item>
                  <el-menu-item index="/GetAutoQuote"><i class="el-icon-right"></i>Get Auto Quote</el-menu-item>
                </div>
              </div>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">Home Services</template>
              <div class="menu_container">
                <div class="menu_left menu_text">
                  <div class="menu_titel">Compare</div>

                  <el-menu-item index="/home_warranty"><i class="el-icon-right"></i>Home Warranty</el-menu-item>
                </div>
                <div class="menu_center menu_text">
                  <div class="menu_titel">Reviews</div>
                  <el-menu-item index="/american_home_shield_review"><i class="el-icon-right"></i>American Home Shield</el-menu-item>
                  <el-menu-item index="/ChoiceHomeWarranty"><i class="el-icon-right"></i>Choice Home Warranty</el-menu-item>
                  <el-menu-item index="/HQHomeWarranty"><i class="el-icon-right"></i>HQ Home Warranty</el-menu-item>
                  <el-menu-item index="/GovHomeProgram"><i class="el-icon-right"></i>GovHomeProgram</el-menu-item>
                  <el-menu-item index="/HomeProjectPros"><i class="el-icon-right"></i>HomeProjectPros</el-menu-item>
                </div>
              </div>
              
            </el-submenu>
          </el-menu>
        </div>
        <div class="topbar-user">
          <router-link to="/about_us">About Us</router-link>
          <router-link to="/contact_us">Partner with Us</router-link>
          <a class="nav-icon-login" href="javascript:;">Log in</a>
          <!-- <img class="nav-icon nav-icon-login" src="/imgs/header_nav7_icon_lock.png"> -->
          <!--</div>-->
        </div>
      </div>
    </div>
    <div class="min-nav-topbar">
      <div class="min-container">
        <div class="min-topbar-menu">
          <router-link to="/"><img src="/imgs/logo.png" alt=""></router-link>
        </div>
        <div class="min-topbar-user">
          <button @click="drawer = true">
            <div></div>
            <div></div>
            <div></div>
          </button>
          <el-drawer
            :visible.sync="drawer"
            size="300px">
          <div>
            <ul class="min_menu">
              <li @click="innerDrawer1 = true">Loans<i class="el-icon-right"></i></li>
              <li @click="innerDrawer2 = true">Mortgages<i class="el-icon-right"></i></li>
              <li @click="innerDrawer3 = true">Insurance<i class="el-icon-right"></i></li>
              <li @click="innerDrawer4 = true">Home Services<i class="el-icon-right"></i></li>
              <li><a href="/about_us" class="min_menu_li">About Us</a></li>
              <li><a href="/contact_us" class="min_menu_li">Partner with Us</a></li>
            </ul>
            <el-drawer
              :append-to-body="true"
              :visible.sync="innerDrawer1"
              size="300px">
              <ul class="min_menu">
                <div class="submenu_title">Compare</div>
                <li><a href="/personal_loans">Personal Loans</a></li>
                <div class="submenu_title">Reviews</div>
                <!-- <li v-for="(postitem,index) in menulist[0].posts" :key='index' @click="toread(postitem.post_id, postitem.child_name, postitem.child_node_url, postitem.url)"><a :href="postitem.review_url">{{ postitem.name }}</a></li> -->
                <li ><a href="/HolidayFunded">HolidayFunded</a></li>
                <li><a href="/LoanPioneer">Loan Pioneer</a></li>
                <li><a href="/Amone">Personal Loan</a></li>
                <li><a href="/GetCashReliefNow">GetCashReliefNow</a></li>
                <li><a href="/ZippyLoan">Zippy Loan</a></li>
                <li><a href="/LendingForBadCredit">Lending For Bad Credit</a></li>
                <li><a href="/SearchKeyLoans">SearchKeyLoans</a></li>
              </ul>
            </el-drawer>
            <el-drawer
              :append-to-body="true"
              :visible.sync="innerDrawer2"
              size="300px">
              <ul class="min_menu">
                <div class="submenu_title">Compare</div>
                <li><a href="/mortgage_rates">Mortgage Rates</a></li>
                <div class="submenu_title">Reviews</div>
                <!-- <li v-for="(postitem,index) in menulist[1].posts" :key='index' @click="toread(postitem.post_id, postitem.child_name, postitem.child_node_url, postitem.url)"><a :href="postitem.review_url">{{ postitem.name }}</a></li> -->
                <li><a href="/HarpRateQuiz">HarpRateQuiz</a></li>
                <li><a href="/emortgage_review">eMortgage</a></li>
                <li><a href="/HomeEquityQuiz">HomeEquityQuiz</a></li>
                <!-- <li><a href="/veterans_united_home_loans_review">Veterans United Home Loans</a></li> -->
                <li><a href="/FHARateSimplified">FHARateSimplified</a></li>
                <li><a href="/HomeLightSimpleSale">HomeLightSimpleSale</a></li>
                <li><a href="/QuickenCompare">Quicken Compare</a></li>
              </ul>
            </el-drawer>
            <el-drawer
              :append-to-body="true"
              :visible.sync="innerDrawer3"
              size="300px">
              <ul class="min_menu">
                <div class="submenu_title">Compare</div>
                <li><a href="/life_insurance">Life Insurance</a></li>
                <li><a href="/auto_insurance">Auto Insurance</a></li>
                <div class="submenu_title">Reviews</div>
                <!-- <li v-for="(postitem,index) in menulist[2].posts" :key='index' @click="toread(postitem.post_id, postitem.child_name, postitem.child_node_url, postitem.url)"><a :href="postitem.review_url">{{ postitem.name }}</a></li> -->
                <li><a href="/ProtectingFamiliesEveryday">Protecting Families Everyday</a></li>
                <li><a href="/GreatLife">Great Life</a></li>
                <li><a href="/SelectMyPolicy">Select My Policy</a></li>
                <li><a href="/smart_financial_review">Smart Financial</a></li>
                <li><a href="/EasyAutoPolicy">EasyAutoPolicy</a></li>
                <li><a href="/CoverageProfessor">CoverageProfessor</a></li>
                <li><a href="/GetAutoQuote">Get Auto Quote</a></li>
              </ul>
            </el-drawer>
            <el-drawer
              :append-to-body="true"
              :visible.sync="innerDrawer4"
              size="300px">
              <ul class="min_menu">
                <div class="submenu_title">Compare</div>
                <li><a href="/home_warranty">Home Warranty</a></li>
                <div class="submenu_title">Reviews</div>
                <!-- <li v-for="(postitem,index) in menulist[2].posts" :key='index' @click="toread(postitem.post_id, postitem.child_name, postitem.child_node_url, postitem.url)"><a :href="postitem.review_url">{{ postitem.name }}</a></li> -->
                <li><a href="/american_home_shield_review">American Home Shield</a></li>
                <li><a href="/ChoiceHomeWarranty">Choice Home Warranty</a></li>
                <li><a href="/HQHomeWarranty">HQ Home Warranty</a></li>
                <li><a href="/GovHomeProgram">GovHomeProgram</a></li>
                <li><a href="/HomeProjectPros">HomeProjectPros</a></li>
              </ul>
            </el-drawer>
            </div>
            </el-drawer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMenu } from '../api/cindex';
import {mapMutations} from 'vuex'
export default {
  name: "nav-header",
  mounted() {},
  data() {
    return {
      phoneList: [],
      activeIndex: '',
      drawer: false,
      innerDrawer1: false,
      innerDrawer2: false,
      innerDrawer3: false,
      innerDrawer4: false,
      menulist: []
    }
  },
  mounted() {
      // this.getmenu()
  },
  methods: {
    ...mapMutations('parameters', ['setPurl', 'setPname', 'setPid', 'setsonUrl']),
    async getmenu() {
      const res = await GetMenu()
      this.menulist = res.data
      console.log(this.menulist);
    },
    toread(id, pname, purl, sonurl) {
      this.setPurl(purl)
      this.setPname(pname)
      this.setsonUrl(sonurl)
      this.setPid(id)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import './../assets/scss/mixin.scss';
@import '../assets/scss/config.scss';

.header {

  // topbar样式
  .nav-topbar {
    background-color: #092742;

    .container {
      min-height: 53px;
      @include flex();


      .topbar-menu {
        align-items: center;
        font-size: 14px;
        display: flex;
        a {
          display: inline-block;
          color: $colorG;
          margin: auto 40px;
        }
      }

      .topbar-user {
        margin-right: 100px;
        a {
          display: inline-block;
          color: $colorG;
          margin-left: 40px;
          font-size: 15px;
          font-weight: 600;
        }
        .nav-icon {
          margin-left: 4px;
        }
        .nav-icon-login{
          display: none;
        }
        .nav-icon-login:after {
          z-index: 2;
          width: 24px;
          height: 22px;
          background: url("/imgs/header_nav7_icon_lock.png") no-repeat;
          content: '';
          position: absolute;
          background-size: contain;
          bottom: 19px;
          margin-left: 7px;
        }
      }
    }

  }

  .nav-header {
    .container {
      height: 112px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .header-menu {
        display: inline-block;
        width: 643px;
        padding-left: 264px;

        .item-menu {
          display: inline-block;
          color: #333333;
          font-size: 16px;
          font-weight: bold;
          line-height: 112px;
          margin-right: 20px;

          span {
            cursor: pointer;
          }

          &:hover {
            color: $colorA;

            .children {
              height: 220px;
              opacity: 1;
            }
          }

          .children {
            position: absolute;
            top: 112px;
            left: 0;
            width: 1226px;
            height: 0;
            overflow: hidden;
            opacity: 0;
            border-top: 1px solid #e5e5e5;
            box-shadow: 0 7px 6px 0 rgba(0, 0, 0, 0.11);
            z-index: 10;
            background-color: #ffffff;
            transition: height .5s;

            .product {
              float: left;
              width: 16.6%;
              height: 220px;
              font-size: 12px;
              line-height: 12px;
              text-align: center;
              position: relative;

              a {
                display: inline-block;

                .pro-img {
                  height: 137px;

                  img {
                    width: auto;
                    height: 111px;
                    margin-top: 26px;
                  }
                }

                .pro-name {
                  font-weight: bold;
                  margin-top: 19px;
                  margin-bottom: 8px;
                  color: $colorB;
                }

                .pro-price {
                  color: $colorA;
                }

                &:before {
                  content: ' ';
                  position: absolute;
                  top: 28px;
                  right: 0;
                  border-left: 1px solid $colorF;
                  height: 100px;
                  width: 1px;
                }

                &:last-child:before {
                  display: none;
                }
              }
            }
          }
        }
      }

      .header-search {
        width: 319px;

        .wrapper {
          height: 50px;
          border: 1px solid #E0E0E0;
          display: flex;
          align-items: center;

          input {
            box-sizing: border-box;
            border: none;
            border-right: 1px solid #E0E0E0;
            height: 50px;
            width: 264px;
            padding-left: 14px;
          }

          a {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url('/imgs/icon-search.png') no-repeat center;
            background-size: contain;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .min-nav-topbar {
    display: none;
  }
}

@media (max-width: 1350px) {
  .header {
    .nav-topbar {
      display: none;
    }
    .min-nav-topbar {
      display: block;
      line-height: 60px;
      background-color: #092742;
      .min-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .min-topbar-menu {
          a {
            display: inline-block;
            img {
              margin: 10px 10px 10px 15px;
              vertical-align: middle;
            }
          }
        }
        .min-topbar-user {
          margin-right: 50px;
          button {
            position: absolute;
            width: 30px;
            height: 30px;
            transition: all 300ms cubic-bezier(.61, .01, .42, 1);
            cursor: pointer;
            background: transparent;
            border: 0px;
            div {
              height: 0px;
              border: 1.5px solid #fff;
              width: 22px;
              display: block;
              position: absolute;
              transition: all 300ms cubic-bezier(.61, .01, .42, 1);
              background:#fff;
            }
            div:nth-child(1) {
              top: 0px;
            }

            div:nth-child(2) {
              top: 8px;
            }

            div:nth-child(3) {
              top: 16px;
            }

            div:nth-child(3):before {
              opacity: 0;
              animation: ball 1.5s linear infinite;
              content: '';
              border: 2px solid #fff;
              display: block;
              position: relative;
              top: 0.25px;
            }
          }
          button:hover {
            transition-delay: 100ms;
            transform: scale(1.1);
          }

          button:hover div:nth-child(3):before {
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: #F26451;
          }

          button:hover div {
            border: 2px solid #fff;
            height: 9px;
            border-radius: 50%;
            margin-left: -1px;
            margin-top: 2px;
            animation: atom 300ms linear 1;
            width: 25px;
            top: 0px;
            background:transparent;
          }

          button:focus {
            outline: 0px;
          }
          button:hover div:nth-child(1) {
            transform: rotate(-33deg);
          }

          button:hover div:nth-child(2) {
            transform: rotate(90deg);
          }

          button:hover div:nth-child(3) {
            transform: rotate(33deg);
          }

          button:hover div:nth-child(3):before {
            opacity: 1;
            transition: opacity 600ms cubic-bezier(.61, .01, .42, 1);
            ;
          }

          button:active:hover div:nth-child(3):before,
          button:active div:nth-child(3):before,
          button:active div:nth-child(2) {
            opacity: 0;
            transition: all 200ms;
          }

          button:active div {
            border: 1.5px solid #fff;
            height: 0px;
            border-radius: 0%;
            margin-left: -1px;
            margin-top: 6px;
            animation: division 300ms linear 1;
            width: 25px;
            top: 0px;
          }

          button:active div:nth-child(2) {
            width: 0px;
          }

          button:active div:nth-child(3) {
            transform: rotate(45deg);
          }

          button:active div:nth-child(1) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
  @keyframes atom {
    0% {
      transform: rotate(180deg);
    }
  }

  @keyframes division {
    0% {
      transform: rotate(180deg);
    }
  }

  @keyframes ball {
    0% {
      left: -20%;
      top: 10%;
    }
    10% {
      left: 10%;
      top: -35%;
    }
    25% {
      left: 45%;
      top: -50%;
    }
    40% {
      left: 80%;
      top: -20%;
    }
    50% {
      left: 98%;
      top: 18%;
    }
    60% {
      left: 80%;
      top: 50%;
    }
    75% {
      left: 45%;
      top: 80%;
    }
    90% {
      left: 0%;
      top: 60%;
    }
    100% {
      left: -20%;
      top: 10%;
    }
  }
}

</style>
<style lang="scss">
.el-menu-demo {
  display: inline-block;
  border: none !important;
  font-size: 15px;
  font-weight: 600;
}
@media (max-width: 1350px) {
  .el-menu-demo {
    width: 100% !important;
    flex-direction: column;
  }
}
</style>
<style lang="scss">
.el-menu--popup {
  width: 600px;
  .menu_container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 25px 0;
    .menu_left {
      width: 280px;
    }
    .menu_center {
      width: 280px;
      border-left: 1px solid #595773;
    }
    .menu_titel {
      font-size: 16px;
      color: #fff;
      margin-bottom: 10px;
      margin-left: 15px;
      font-weight: 700;
    }
    .menu_text {
      font-size: 15px;
    }
  }
}
.el-drawer {
  background-color: #092742 !important;
  color: #fff;
}
.el-drawer__close-btn {
  color: #fff !important;
}
.min_menu {
  color: #fff;
  li {
    border-bottom: 1px solid #504f62;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    a {
      display: flex;
      position: relative;
      width: 100%;
      font-size: 16px;
      font-weight: 400;
      color: #e1e2e3;
      line-height: 1.4;
      padding: 14px 17px 14px 20px;
    }
    a:before {
      content: "\2022";
      position: absolute;
      pointer-events: none;
      top: 15px;
      left: 5px;
      transform: rotate(90deg);
      font-size: 14px;
      color: #2ac8a2;
    }
  }
  .submenu_title {
    display: flex;
    padding: 14px 17px 14px 25px;
    position: relative;
    font-size: 16px;
    font-weight: 400;
    color: #2ac8a2;
    line-height: 1.4;
    border-bottom: 1px solid #504f62;
  }
  .min_menu_li {
    width: 100%;
    padding: 17px 0;
  }
  .min_menu_li:before {
    display: none;
  }
}
</style>