import axios from 'axios';
import { apiUrl } from '../config/env';
var web_id = 42
axios.defaults.headers.common['token'] = 'e62337a1a49baaf03e1a05b42f5b27d426d7875e02157d294cdaaa82'

// 接收Email
export const EmailSystem = data => {
    return axios.post(`${apiUrl}WEBManagement/page/v1/contactus`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 获取offers
export const GetOffers = data => {
    const params = data
    return axios.get(`${apiUrl}WEBManagement/product/v1/?web_id=` + web_id, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//获取菜单
export const GetMenu = data => {
    return axios.get(`${apiUrl}menu/v1/`).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}