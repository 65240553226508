<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Business Loans</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Small business loans are often taken for a wide variety of purposes. Like many others, you may be thinking of starting a business, or you may require capital to expand a business that you already have. The expenses associated with a loan can easily cost a lot more than most people expect. A small business loan is one possible option for accessing the funds you’re looking for.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/business_loans" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Business Loans</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/business_loans_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Business Loans Guide</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/business_loans_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Business Loans Reviews</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/business_loans" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Business Loans Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2>Compare Top Business Loans Lenders</h2>
                      </div>
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.img_url" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                            disabled
                                            :colors="colors"
                                            v-model="score">
                                          </el-rate>
                                      </div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.Amount}}</span>
                                          <ul>
                                              <li>Time in Business:{{item.time}}</li>
                                              <li>Min Credot Score: {{item.score}}</li>
                                              <li>Monthly Revenue: {{item.Amount}}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.offers_url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.offers_url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What are Business Loans?</h2>
                          <p>Business loans are taken specifically for business expenses. They are borrowed funds meant to pay for expenses a business can’t afford on its own. There are several different types of business loans. Each kind of loan has a specific purpose.</p>
                          <p>Small business loans have evolved over the years, and different loans have different qualifications and repayment requirements. While there are several types of small business loans, there are a few things you should take into consideration if you’re considering any kind of business loan, which we will delve into in further detail.</p>
                          <h2 class="anchor_h2">How do Business Loans Work?</h2>
                          <p>Business loans come with several stipulations that you must agree to before a lender will approve your loan.</p>
                          <p>First, you will need to meet the borrowing qualifications for a lender to be willing to provide you with funds. Qualifications vary by lender, but, in most cases, you will need to meet the following requirements:</p>
                          <ul class="text1">
                              <li>Personal & business credit score</li>
                              <li>Average income requirements</li>
                              <li>Collateral, where applicable</li>
                          </ul>
                          <p>Some lenders will be more flexible than others regarding minimum requirements. Exceeding the minimum requirements can sometimes enable you to get lower rates, depending on the lender.</p>
                          <p>If you qualify for a loan with a lender you’ve found, you may apply for a loan from them. When you’re looking at different small business loan products, there will be several variables to examine. </p>
                          <p>Factors such as interest rates and repayment terms affect the cost of the loan and the borrowers’ ability to repay the loan on time. </p>
                          <p>Loans come with a cost that is your responsibility to consider before applying.</p>
                          <p>Business loan interest rates are the amount charged for the loan. The rate is expressed as a percentage of the loan’s principal. This will normally be displayed as an annual percentage rate (APR). This is a figure that represents the cost of your loan on an annualized basis.</p>
                          <p>Once you’ve been approved for a loan and have agreed to the terms, you will receive the agreed-upon funds from the lender. You will then have to repay your loan based on the terms agreed upon with your lender.</p>
                          <h2 class="anchor_h2">Which Types of Business Loans are there?</h2>
                          <p>There are several types of business loans that you can choose from.</p>
                          <h2>Small Business Term Loan</h2>
                          <p>A small business term loan is a lump sum loan. Term loans are paid back at a fixed interest rate, with regular repayments. Repayment terms vary by lender, but small business term loans are typically paid back in about 5 years. When you take a small business loan, you will normally have to specify the intended purpose of the loan during the application process.</p>
                          <p>Like some long-term personal loans, small business term loans follow an amortization process. That means your repayments will start going towards the loan’s interest. Once the interest is paid off, your payments will go towards the loan’s principal.</p>
                          <ul class="text1">
                              <li>Amount Range: $500 to $5.5 million</li>
                              <li>APR Range: Varies with lender</li>
                              <li>Best Used For Renovations, Business acquisitions, Estate purchases</li>
                          </ul>
                          <h2>Small Business Lines Of Credit</h2>
                          <p>Small business lines of credit are a form of rotating business credit. You may borrow money from your line of credit, up to the specified limit you agreed to with your lender. When you draw funds, you will have to repay them. You may continue drawing funds as much as you choose to, as long as you don’t hit your credit limit.</p>
                          <p>Small business lines of credit are often compared to credit cards, which are another form of rotating credit. You are responsible for tracking your credit limit and paying back the funds you draw on time.</p>
                          <ul class="text1">
                              <li>Amount Range: $2,000 to $250,000</li>
                              <li>APR Range: 8% to 80%</li>
                              <li>Best Used For: Boosting supplies, increasing inventory  </li>
                          </ul>
                          <h2>Working Capital Loans</h2>
                          <p>Working capital refers to the capital used to pay for the day-to-day operating expenses a business incurs.</p>
                          <p>Many small business loans are taken to pay for large, long-term asset purchases or investments. Working capital loans, on the contrary, are taken to pay for the regular day-to-day expenses your business incurs, including mortgage and rent payments, utility bills, payroll, and other debt repayments.</p>
                          <p>Because of the nature of this form of borrowing, working capital loans typically carry shorter terms. They are also usually lent out in smaller amounts. </p>
                          <ul class="text1">
                              <li>Amount Range: Varies with lender</li>
                              <li>APR Range: 3% to 99%</li>
                              <li>Best Used For: Managing cash flow gaps </li>
                          </ul>
                          <h2>Invoice Financing</h2>
                          <p>If you have sent out invoices that are now long overdue, invoice financing is a potential course of action. Invoice financing is a form of business financing that is meant to help businesses recoup part of the balance of unpaid invoices.</p>
                          <p>Invoice financing takes place when you borrow against your unpaid invoices. Upon approval, the lender will send you funds, which you pay for with a fee. That means you will not be paid for the entire balance of your outstanding invoices. Instead, the lender will give you part of the balance, which you will pay back after your customer pays their overdue invoices.</p>
                          <p>People find invoice financing as a relatively quick business financing option, but it is normally more costly than the other alternatives. The fee you pay the lender to take your invoice will depend on the usual factors, such as your business credit score.</p>
                          <ul class="text1">
                              <li>Amount Range: Maximum of $850,000</li>
                              <li>APR Range: 10% to 84%</li>
                              <li>Best Used For: Improving cash flow, paying employees and suppliers </li>
                          </ul>
                          <h2>Invoice Factoring</h2>
                          <p>Invoice factoring is similar to business financing, with the largest difference being who collects your business’s unpaid invoices.</p>
                          <p>With invoice financing, as discussed above, the customer (your business) retains control (and responsibility) for collections.</p>
                          <p>With invoice factoring, the lender purchases your unpaid invoices from you at a discount. After they buy your invoice, they take responsibility for collection.</p>
                          <p>Both of these options will see you receiving a portion of your overdue invoices’ balances. The portion will depend on the lender and your business’s borrowing qualifications.</p>
                          <ul class="text1">
                              <li>Amount Range: $50,000 to $3,000,000</li>
                              <li>APR Range: 0.5% to 4% monthly</li>
                              <li>Best Used For Short-term liquidity, improving working capital</li>
                          </ul>
                          <h2>SBA Loans</h2>
                          <p>The US Small Business Administration (SBA) does not offer loans to small businesses. However, some lenders offer loans that are approved by the SBA, and thus normally packaged as “SBA Loans”.</p>
                          <p>The SBA sets special guidelines to which all SBA-guaranteed loans are subject. Lenders are restricted in their use of these loans in a few key ways:</p>
                          <p>Lenders can only give SBA loans to businesses meeting specified requirements</p>
                          <p>SBA loans come with maximum maturity dates</p>
                          <p>SBA loan interest rates are limited according to the SBA guidelines</p>
                          <p>Compared to other small business loans, SBA loans are harder to qualify for, but their interest rates come with low maximum limits for borrowers.</p>
                          <p>Make sure you meet the SBA loan eligibility requirements before exploring SBA loan options.</p>
                          <ul class="text1">
                              <li>Amount Range: Maximum of $5 million</li>
                              <li>APR Range: Varies with the amount</li>
                              <li>Best Used For: Buying a business, Start-up costs, Refinancing debt</li>
                          </ul>
                          <h2>Equipment Loans</h2>
                          <p>Equipment loans are small business loans with the purchased equipment serving as collateral. Small businesses normally take these loans to pay for business equipment. This can vary according to business needs, but small business equipment loans are often taken to pay for business vehicles or machinery. </p>
                          <p>For example, a small logistics firm may take a truck loan to purchase a new truck. The truck they purchase with the loan will serve as collateral until the logistics firm pays back the loan.</p>
                          <ul class="text1">
                              <li>Amount Range: $5,000 to $5,000,000</li>
                              <li>APR Range: As low as 7.5%</li>
                              <li>Best Used For: Purchase of equipment and machinery </li>
                          </ul>
                          <h2>Small Business Credit Cards</h2>
                          <p>Small business credit cards are similar to personal credit cards. But they differ in ways that make them more appropriate for business use. They are also meant to be used for strictly business purposes.</p>
                          <p>Business credit cards typically have higher credit limits. They also normally come with points/rewards benefits tailored more towards business needs. There are many different business credit cards available, so you can compare a wide range of rates, credit limits, and other features.</p>
                          <ul class="text1">
                              <li>Amount Range: Up to $50,000 </li>
                              <li>APR Range: 12% to 22%</li>
                              <li>Best Used For Working capital, emergency cash</li>
                          </ul>
                          <h2>Merchant Cash Advances</h2>
                          <p>A merchant cash advance (MCA) is not a loan, strictly speaking. What they are is a lump sum payment to a merchant (business). The borrower will then pay back the sum through a portion of future credit and/or debit sales. In this way, they differ from traditional loans, where you have regular repayment terms.</p>
                          <p>MCAs are paid back automatically while you continue making card sales. Those repayments will cease once you’ve repaid your MCA.</p>
                          <ul class="text1">
                              <li>Amount Range: Up to $500,000</li>
                              <li>APR Range: Up to 200%</li>
                              <li>Best Used For: Growing a business</li>
                          </ul>
                          <h2>Microloans</h2>
                          <p>Microloans (or microloans) are small loans. By business standards, a microloan will typically range from $500 to $100,000.</p>
                          <p>Microloans have emerged among the larger trends of microfinance. Technology has enabled the business financing process to become more efficient, enabling lenders to process loan applications faster. This allows many lenders to efficiently and cost-effectively offer very small business loans.</p>
                          <ul class="text1">
                              <li>Amount Range: Up to $50,000</li>
                              <li>APR Range: 7.75% to 11.75%</li>
                              <li>Best Used For Working capital, inventory, supplies</li>
                          </ul>
                          <h2 class="anchor_h2">Reasons To Get a Business Loan</h2>
                          <p>Many circumstances lead small business owners to seek a loan. All of the information and calculations can be overwhelming, especially if it is your first time taking out a loan. You may consider following common business loan guidelines to help you through the process.</p>
                          <p>Some of the more common reasons that business owners look for business financing options are listed below.</p>
                          <h2>Business Loan For Machinery Purchase</h2>
                          <p>Small businesses that rely on machinery may get a business loan to finance a machinery purchase. These loans are sometimes taken in by a wide range of manufacturing businesses. Logistics and agricultural businesses can also take business loans for machinery. </p>
                          <p>Normally, business loans for machinery use the purchased machinery as collateral. These loans are term loans that are paid back regularly.</p>
                          <h2>Equipment Financing For Your Business</h2>
                          <p>Most businesses use some kind of equipment as a part of their day-to-day operations. This can range from office equipment to construction equipment, and everything in between. </p>
                          <p>Business owners may choose to take a small business loan to finance their equipment purchases. Loan sizes can range greatly, with some loans being large enough to cover large equipment purchases. You may also be able to find a microloan that can cover smaller equipment purchases.</p>
                          <h2>Financing for Vehicles</h2>
                          <p>Many small businesses rely heavily on their vehicles to enable day-to-day operations to continue. Logistics firms and construction trade businesses are common examples. But many other businesses provide their employees with “company cars” to promote efficiency, enable workforce mobility, and bolster their corporate image.</p>
                          <p>Regardless of the reason why a business needs a vehicle, a vehicle is a significant purchase. This leads some small business owners to seek vehicle financing. Like many other loans, vehicle loans often use the purchased vehicle as collateral.</p>
                          <h2>Purchase Inventory and Materials</h2>
                          <p>Maintaining inventory is a crucial task for most small businesses. Small businesses regularly make regular purchases to maintain their inventory and material stockpiles.</p>
                          <p>Cash flow fluctuations and other financial challenges can pose a challenge to small business owners. When cash flow is compromised, business loans are a potential course of action for maintaining adequate inventory and material stores.</p>
                          <h2>Marketing Loan</h2>
                          <p>Businesses that rely on publicity or marketing funnels often make significant investments in marketing efforts. Substantial marketing campaigns come with a high price tag, but with the potential for significant returns.</p>
                          <p>Some businesses take out small business loans to finance marketing projects. </p>
                          <h2>Business Real Estate Loan</h2>
                          <p>Real estate is key to some businesses, but it is often a pricy asset. So, some small business owners choose to use a business loan to buy property.</p>
                          <h2>Emergency Business Loan</h2>
                          <p>Emergencies, by definition, are not planned for. That can make them one of the most difficult issues for businesses to handle. Financially, emergencies can be devastating.</p>
                          <p>In the face of an emergency, some businesses pursue emergency business loans in order to be able to continue their operations.</p>
                          <p>The Small Business Administration (SBA) offers low-interest disaster assistance loans. Recently they also started offering disaster working capital loans for small businesses affected by COVID-19.</p>
                          <h2>Working Capital Loan</h2>
                          <p>Working capital loans are business capital loans taken to cover day-to-day expenses. They are normally taken by businesses facing cash flow issues, and they are used to pay for regular, predictable expenses. These expenses often include payroll, mortgage, rent, and other regular expenses.</p>
                          <h2>Business Expansion Loan</h2>
                          <p>Businesses that are planning on major expansions have the option of taking a loan to finance their plans. A business expansion loan is taken when a business is trying to expand its operations.</p>
                          <h2 class="anchor_h2">Which Business Loan should you get?</h2>
                          <p>There are many business loans available, comparing the best small business loan may assist the borrower to find a lender that suits their needs. We encourage you to do thorough research before closing on your loan and beginning the repayment process.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">What are Business Loans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">How do Business Loans Work?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Which Types of Business Loans are there?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===3}">Reasons To Get a Business Loan</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===4}">Which Business Loan should you get?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "business_loans",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: [
                 {
                    id: 1,
                    name: 'Reliant Funding',
                    img_url: '/imgs/offers_imgs/reliant_l.svg',
                    offers_url: 'https://apply.reliantfunding.com/',
                    time: '6+ months',
                    Revenue: '$5,000 ',
                    score: '525',
                    Amount: '$5K-$400K'
                },{
                    id: 2,
                    name: 'National Funding',
                    img_url: '/imgs/offers_imgs/national_funding_l.svg',
                    offers_url: 'https://www.nationalfunding.com/landing/af-small-business-loans/?',
                    time: '12+ months',
                    Revenue: '$20,000',
                    score: '575',
                    Amount: '$10K-$500K'
                },{
                    id: 3,
                    name: 'Fora Financial',
                    img_url: '/imgs/offers_imgs/Forafinancial_l.svg',
                    offers_url: 'https://www.forafinancial.com/landing/customerv8-3-lendstart/',
                    time: '6+ months',
                    score: '$12,000',
                    APR: '500',
                    Amount: '$5K - $750K'
                },{
                    id: 4,
                    name: 'Lendio',
                    img_url: '/imgs/offers_imgs/lendio_l.svg',
                    offers_url: 'https://lp.lendio.com/partners-lf?',
                    time: '12+ months',
                    score: '$12,000',
                    APR: '580',
                    Amount: '$1K-$5M'
                },{
                    id: 5,
                    name: 'Rapid Finance',
                    img_url: '/imgs/offers_imgs/Rapid_finance_d.svg',
                    offers_url: 'https://quote.rapidfinance.com/djt/contact-info?',
                    time: '12+ months',
                    score: '$8,000',
                    APR: '500',
                    Amount: '$5K-$1M'
                },{
                    id: 6,
                    name: 'Fundera',
                    img_url: '/imgs/offers_imgs/fundera-by-nerdwallet_full-color-logo.svg',
                    offers_url: 'https://www.fundera.com/apply-for-a-loan?',
                    time: '12+ months',
                    score: '$10,000',
                    APR: '600',
                    Amount: '$5K-$5M'
                }
            ]
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/navimgcrad.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>

<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>