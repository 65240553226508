<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">About TopFinanceDirectory</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>TopFinanceDirectory is an online finance marketplace for various financial knowledge including kinds of Loans and Insurance,like life,auto,health and home, Mortgage and Home services. We collect information from thousands of products and services to help you make the right choice and find the best. Our goal is to help each and every customer navigate their personal finances and achieve their version of financial stability.</span>
                    </div>
                </div>
            </div>
            <div class="scale_container">
                <div class="scale_box">
                    <div class="number">
                        <span>11</span>
                        <span>years in business</span>
                    </div>
                    <div class="number">
                        <span>150</span>
                        <span>top tier brands</span>
                    </div>
                    <div class="number">
                        <span>+100M</span>
                        <span>happy customers</span>
                    </div>
                    <div class="number">
                        <span>8M</span>
                        <span>loans served</span>
                    </div>
                </div>
            </div>
            <div class="introduction_01">
                <div class="decisions_simplified_box">
                    <img class="about_us_01" src="/imgs/about_us_01.png" alt="">
                    <div class="decisions_simplified">
                        <img src="/imgs/about_us_number_01.png" alt="">
                        <div class="title">Personal Finance Satisfied</div>
                        <p class="text_p">We’re not a typical finance company. We share financial knowledge to help you compare and find the smartest financial products to unlock your dreams. We believe that everyone should be able to make financial decisions with confidence, and we’re building a team with the knowledge, passion, and skills to make your personal finance satisfied.</p>
                    </div>
                </div>
            </div>
            <div class="introduction_02_03">
                <div class="introduction_box">
                    <div class="fresh_start">
                        <img class="number_02" src="/imgs/about_us_number_02.png" alt="">
                        <div class="title">Our mission</div>
                        <p class="text_p">Confident financial decisions are attributed to your extensive financial knowledge. Our purpose is to equip people with the tools, information, and insight they need to make sound financial decisions throughout their lives. So they can quickly locate what they require and resume their lives.</p>
                        <div class="title">Our values</div>
                        <p class="text_p">Everything we do is shaped by our values. They aren't just words on a page; they are lived and breathed every day.</p>
                        <img class="img_right" src="/imgs/about_us_02.png" alt="">
                        <img class="img_left" src="/imgs/about_us_03.png" alt="">
                    </div>
                    <div class="in_depth">
                        <h2>03</h2>
                        <div class="title">No Obligation. No Hidden Fees.</div>
                        <p class="text_p">Our service is completely free and it only takes a few minutes to get started. Don’t worry - you have no obligation to work with any of your matches.</p>
                        <p class="text_p">We put our customers at the center of everything we do, empowering millions of people to meet their financial goals. Working in highly collaborative, agile teams, we’re defining a new industry. At TopFinanceDirectory, you’ll have lots of opportunities to contribute, innovate, and advance.</p>
                    </div> 
                </div>
            </div>
            <div class="introduction_04">
                <div class="experts_clients_box">
                    <div class="experts_clients">
                        <img class="img_number" src="/imgs/about_us_number_04.png" alt="">
                        <div class="title">Looking for objective advice, expert info, and helpful tools to answer your money questions? Turn to the TopFinanceDirectory.</div>
                        <p class="text_p">Our millions of readers come from all over the world and from all walks of life. Some are learning about money and investing for the first time, while others are experienced investors, business owners, professionals, financial advisors, and executives looking to expand their knowledge and skills. No matter who they are, we are here to help.</p>
                        <img class="img_list_4" src="/imgs/about_us_04.png" alt="">
                        <img class="img_list_5" src="/imgs/about_us_05.png" alt="">
                        <img class="img_list_6" src="/imgs/about_us_06.png" alt="">
                        <img class="img_list_7" src="/imgs/about_us_07.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "about_us",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    } 
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                width: 61%;
                margin: 11px auto 0;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
        }
    }
    .scale_container {
        width: 100%;
        margin-top: 80px;
        .scale_box {
            width: 61%;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .number {
                display: flex;
                flex-direction: column;
                align-items: center;
                span:first-child {
                    color: #2AC8A2;
                    font-size: 48px;
                    line-height: 48px;
                    margin-bottom: 20px;
                }
                span:last-child {
                    font-size: 18px;
                    color: #000000;
                    line-height: 18px;
                    font-weight: 500;
                }
            }
        }
    }
    .introduction_01 {
        width: 100%;
        margin-top: 347px;
        .decisions_simplified_box {
            height: 446px;
            width: 61%;
            margin: auto;
            border-radius: 12px;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10);
            position: relative;
            img {
                position: absolute;
                left: 0;
                top: -237px;
                left: -370px;
            }
            .decisions_simplified {
                float: right;
                padding-top: 80px;
                width: 370px;
                position: relative;
                img {
                    position: absolute;
                    top: -35px;
                    left: 0;
                }
            }
        }
    }
    .introduction_02_03 {
        width: 100%;
        margin-top: 156px;
        .introduction_box {
            width: 61%;
            height: 1050px;
            border-radius: 12px;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10);
            margin: auto;
            padding: 90px 0px 50px;
            position: relative;
            .fresh_start {
                width: 396px;
                position: relative;
                text-align: justify;
                margin-left: 65px;
                .number_02 {
                    position: absolute;
                    top: -118px;
                    left: 0px;
                }
                .img_right {
                    position: absolute;
                    right: -651px;
                    top: 0px;
                }
                .img_left {
                    position: absolute;
                    right: -187px;
                    top: 490px;
                }
            }
            .in_depth {
                position: absolute;
                bottom: 45px;
                right: 0;
                padding: 0 105px;
                text-align: justify;
                .title {
                    text-align: right;
                }
                .title:before {
                    left: 650px;
                }
                .title:after {
                    left: 650px;
                }
                h2 {
                    font-size: 48px;
                    font-weight: 700;
                    color: #222831;
                    line-height: 48px;
                    text-align: right;
                }

            }
        }
    }
    .introduction_04 {
        width: 100%;
        margin-top: 156px;
        .experts_clients_box {
            height: 1265px;
            width: 61%;
            margin: 0  0 192px 0;
            border-radius: 0 12px 12px 0;
            box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10);
            .experts_clients {
                width: 370px;
                margin: auto;
                padding: 80px 0 0 50px;
                position: relative;
                .title {
                    line-height: 25px;
                }
                .img_number {
                    position: absolute;
                    top: -35px;
                    left: 0;
                }
                .img_list_4 {
                    position: absolute;
                    top: 80px;
                    left: 595px;
                }
                .img_list_5 {
                    position: absolute;
                    top: 360px;
                    left: 0;
                }
                .img_list_6 {
                    position: absolute;
                    top: 500px;
                    left: 595px;
                }
                .img_list_7 {
                    position: absolute;
                    top: 770px;
                    left: 0;
                }
            }
        }
    }
    @media (max-width: 1440px) {
        .introduction_01 {
            margin-top: 200px;
            .decisions_simplified_box {
                .about_us_01 {
                    width: 90%;
                    top: -80px;
                    left: -300px;
                }
            }
        }
        .introduction_02_03 {
            .introduction_box {
                .fresh_start {
                    .img_right {
                        right: -560px;
                    }
                    .img_left {
                        right: -75px;
                        top: 463px;
                    }
                }
                .in_depth {
                    .title:after {
                        left: 270px;
                    }
                    .title:before {
                        left: 270px;
                    }
                }
            }
        }
        .introduction_04 {
            .experts_clients_box {
                .experts_clients {
                    .img_list_4 {
                        left: 465px;
                    }
                    .img_list_5 {
                        left: -150px;
                    }
                    .img_list_6 {
                        left: 465px;
                    }
                    .img_list_7 {
                        left: -150px;
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
                .head_subtitle {
                    width: 95%;
                }
            }
        }
        .scale_container {
            .scale_box {
                width: 95%;
            }
        }
        .introduction_01 {
            margin-top: 100px;
            .decisions_simplified_box {
                width: 95%;
                height: 100%;
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                padding-bottom: 35px;
                .about_us_01 {
                    position: static;
                    width: 75%;
                }
                .decisions_simplified {
                    float: none;
                    width: 75%;
                }
            }
        }
        .introduction_02_03 {
            .introduction_box {
                width: 95%;
                height: 100%;
                padding: 50px 0px 50px;
                .fresh_start {
                    margin-bottom: 200px;
                    margin-left: 20px;
                    .number_02 {
                        top: -79px;
                    }
                    .img_right {
                        width: 75%;
                        top: -140px;
                        right: -325px;
                    }
                    .img_left {
                        width: 75%;
                        right: 15px;
                        top: 340px;
                    }
                }
                .in_depth {
                    position: static;
                    .title:after {
                        display: none;
                    }
                    .title:before {
                        display: none;
                    }
                }
            }
        }
        .introduction_04 {
            .experts_clients_box {
                height: 1060px;
                .experts_clients {
                    .img_list_4 {
                        display: none;
                    }
                    .img_list_5 {
                        width: 100%;
                        left: -60px;
                    }
                    .img_list_6 {
                        width: 85%;
                        left: 380px;
                        top: 550px;
                    }
                    .img_list_7 {
                        width: 100%;
                        left: -60px;
                    }
                }
            }
        }
    }
    @media (max-width: 760px) {
        .nav_img {
            .nav_img_content {
                .head_title {
                    font-size: 33px;
                }
                .head_line {
                    .title_underline_left {
                        width: 150px;
                    }
                    .title_underline_right {
                        width: 150px;
                    }
                }
                .head_subtitle {
                    width: 85%;
                }
            }
        }
        .scale_container {
            .scale_box {
                justify-content: space-evenly;
                .number {
                    margin-bottom: 40px;
                }
            }
        }
        .introduction_02_03 {
            margin-top: 100px;
            .introduction_box {
                .fresh_start {
                    width: 90%;
                    .img_left {
                        top: 365px;
                    }
                    .img_right {
                        display: none;
                    }
                }
                .in_depth {
                    width: 90%;
                    padding: 0 15px;
                    .title:after {
                        left: 30px;
                    }
                    .title:before {
                        left: 30px;
                    }
                }
            }
        }
        .introduction_04 {
            margin-bottom: 200px;
            .experts_clients_box {
                width: 98%;
                height: 415px;
                .experts_clients {
                    width: 90%;
                    padding: 40px 0 0 0;
                    .img_list_5 {
                        top: 335px;
                        left: 0px;
                    }
                    .img_list_6 {
                        display: none;
                    }
                    .img_list_7 {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.title {
    font-size: 18px;
    font-family: Roboto, Roboto-Bold;
    font-weight: 700;
    text-align: left;
    color: #353449;
    line-height: 18px;
    margin-bottom: 15px;
    padding-left: 20px;
    position: relative;
}
.title:before {
    width: 5px;
    height: 5px;
    background: #2ac8a2;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: -1px;
}
.title:after {
    width: 5px;
    height: 5px;
    background: #c4c4c4;
    border-radius: 50%;
    content: '';
    display: block;
    position: absolute;
    top: 13px;
    left: -1px;
}
.text_p {
    color: #666666;
    line-height: 21px;
    font-size: 14px;
    margin-bottom: 30px;
}
</style>
