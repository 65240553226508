<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">5K Funds</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: `${this.sonUrl}` }">{{this.parent_page_name}}</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: `${this.parent_url}` }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>{{ this.posttitle }}</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>{{ this.content.title }}</h1>
                          <div class="text" v-html="this.content.content"></div>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li v-for="item of h2_list" :key="item.id">
                                        <div @click="scrollTo(item.id)">{{ item.title }}</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { GetOnePostlist } from '../api/index';
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            posttitle: '',
            content: '',
            h2_list: [],
            parent_page_name: '',
            parent_page_url: ''
        }
    },
    computed: {
        ...mapGetters('parameters', ['parent_url', 'post_id', 'parent_name', 'sonUrl'])
    },
    watch: {
        content: function () {
            this.$nextTick(() => {
                let h2list = document.getElementsByClassName("anchor_h2")
                for (let i = 0; i < h2list.length; i++) {
                    this.h2_list.push({id: i, title: h2list[i].innerText})
                }
            })
        },
        post_id: function () {
            this.getpostccontent()
        },
    },
    created() {
        // this.getposttitle()
        this.getpostccontent()
    },
    methods: {
        // 获取文章标题
        // getposttitle() {
        //     this.posttitle = window.location.href.split('?')[1];
        // },
        // 获取文章内容
        async getpostccontent() {
            this.content = ''
            this.parent_page_name = this.parent_name
            this.parent_page_url = this.parent_url
            const res = await GetOnePostlist({post_id: this.post_id})
            res.data.content = res.data.content.replace(/<h2>/g, "<h2 class='anchor_h2'>")
            this.posttitle = res.data.title
            this.content = res.data
        },
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import '../assets/scss/config.scss';
@import '../assets/scss/navimgnotcrad.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style lang="scss">
@import '../assets/scss/textnavigation.scss';
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>