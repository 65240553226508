<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Veterans United</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
                <div class="Bread_crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                        <el-breadcrumb-item>Veterans United</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Veterans United</h1>
                          
                          <h2 class="anchor_h2">Veterans United at a glance</h2>
                          <p>In addition to VA loans, <a href="https://www.veteransunited.com/">Veterans United</a> offers a wide range of lending products to service members, veterans, and qualifying reservists. Potential borrowers can quickly obtain current pricing information, contact customer support at any time of the day or night, and apply online.</p>
                          <p>Here's how Veterans United's overall score was calculated:</p>
                          <ul class="text1">
                              <li>3 out of 5 ratings for a wide range of loan kinds</li>
                              <li>5 out of 5 stars for application ease</li>
                              <li>4 out of 5 stars for rates and costs</li>
                          </ul>
                          <h2 class="anchor_h2">Veterans United mortgage loan types</h2>
                          <p><a href="https://www.veteransunited.com/">Veterans United</a> is the country's largest lender of VA home purchase loans.</p>
                          <p>Mortgages sponsored by the United States Department of Veterans Affairs are one of the most valuable benefits available to active-duty military personnel, veterans, and their families.</p>
                          <p class="text_img"><img src="../../../../public/imgs/reviews_list/veterans_united_home_loans_review_1.jpg" alt="">"Given the nature of their service, veterans, service members, and their families often confront particular financial and credit issues," says Chris Birk, director of education at Veterans United Home Loans. "Saving the customary 5% down payment for a conventional loan might take years for military families." In most cases, no money down is required with a VA loan.</p>
                          <p>In recent years, the overall volume of VA loans has hit new highs, topping $375 billion in 2020. Veterans United was founded on the back of that wave of popularity.</p>
                          <p>The lender also provides the VA's energy-efficient mortgages, which allow veterans to include the cost of energy-saving upgrades in their monthly mortgage payment. Veterans United, like most nonbank lenders, does not offer home equity loans or home equity lines of credit (HELOCs).</p>
                          <h2 class="anchor_h2">Veterans United ease of application</h2>
                          <img src="../../../../public/imgs/reviews_list/veterans_united_home_loans_review_2.jpg" alt="">
                          <p>Most people start the process of getting a mortgage with Veterans United by going online or calling. Through the My Veterans United platform, you can apply for a loan and get prequalified.</p>
                          <p>Borrowers with a poor credit history may be able to qualify by paying on time for other services such as a cell phone, P.O. box, or cable subscription, according to Birk.</p>
                          <p>You'll be given "tasks" to perform via this online portal once you've signed a contract for a home, such as uploading essential documents. Your loan team will keep in touch with you throughout the process, letting you know what's required at each stage, according to Birk.</p>
                          <p><a href="https://www.veteransunited.com/">Veterans United</a> offers "any time, any place" customer service, so you may get a mortgage — or just get a question addressed — when it's convenient for you. Despite the fact that Veterans United has only 17 locations, it serves consumers in all 50 states and the District of Columbia.</p>
                          <h2 class="anchor_h2">Veterans United mortgage rates and fees</h2>
                          <p>The fees charged by the lender vary "depending on the loan product, the borrower's credit history, and the market area we serve," according to Birk.</p>
                          <p>Understanding the cost of the loan is one of the most significant factors to consider when choosing a mortgage provider. According to the most recent available Home Mortgage Disclosure Act data, NerdWallet evaluates lenders on two aspects involving fees and mortgage rates in order to provide consumers a broad idea of what a lender might charge:</p>
                          <ul class="text1">
                              <li>For the average origination charge, Veterans United receives three out of five stars.</li>
                              <li>When comparing offered mortgage rates to the best available rates on comparable loans, Veterans United has a 4 out of 5 star rating.</li>
                          </ul>
                          <p>Borrowers should think about how lender fees and mortgage rates interact. While this isn't always the case, paying upfront fees can help you save money on your mortgage. To cut their reported interest rate and make it more appealing, some lenders will demand larger upfront costs. Some lenders simply charge larger costs up front.</p>
                          <p>To lower your mortgage rate, you can purchase discount points, which are a fee paid along with your closing costs.</p>
                          <p>When deciding whether or not to pay greater upfront fees, think about how long you want to stay in your house and how much money you have to put toward closing expenses when you sign the loan paperwork.</p>
                          <h2 class="anchor_h2">Veterans United rate transparency</h2>
                          <p>On its website, <a href="https://www.veteransunited.com/">Veterans United</a> discloses current mortgage rates, however not all mortgage options are offered. Fill out an online form or contact Veterans U to get a rate quote based on your credit score, debt-to-income ratio, and preferred loan period.</p>
                          <h2 class="anchor_h2">Who is a Veterans United loan good for?</h2>
                          <img src="../../../../public/imgs/reviews_list/veterans_united_home_loans_review_3.jpg" alt="">
                          <p>If you want to take out a VA loan, Veterans United could be an excellent choice. The organization specializes in VA loans and offers a variety of tools to assist you learn more about them and how they function.</p>
                          <p>Veterans United's free Lighthouse Program may be useful to you if you're a veteran or military member who is having trouble qualifying for a VA loan due to low credit. This program can provide you with resources to help you improve your financial literacy and credit profile.</p>
                          <p><a href="https://www.veteransunited.com/">Veterans United</a> isn't the greatest option if you're searching for a conventional loan or a different sort of government-backed mortgage, such as an FHA loan. On its website, the company provides very little information.</p>
                          <h2 class="anchor_h2">How to apply for a Veterans United mortgage</h2>
                          <p>Veterans United lays out the application process on its website if you're ready to apply for a VA loan. To get started, acquaint yourself with VA loans and make sure you satisfy the eligibility requirements.</p>
                          <p>After that, you'll contact a lender and start the application process. You can apply for your Certificate of Eligibility on your own or with the help of Veterans United.</p>
                          <p>It's time to finish your application when you've completed these steps and gotten a rate quote. You'll accomplish this by submitting your W-2, tax records, and any other financial documents requested.</p>
                          <h2 class="anchor_h2">Offers a free credit counseling service</h2>
                          <p><a href="https://www.veteransunited.com/">Veterans United</a> provides free credit counseling to veterans, service members, and military families that are having financial difficulties. A loan officer will direct you to the company's Lighthouse Program if you don't qualify for a VA loan due to low credit.</p>
                          <p>This tool can assist you in locating and correcting problems on your credit reports, as well as taking proactive efforts to enhance your credit.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Veterans United at a glance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Veterans United mortgage loan types</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Veterans United ease of application</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Veterans United mortgage rates and fees</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Veterans United rate transparency</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">Who is a Veterans United loan good for?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">How to apply for a Veterans United mortgage</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)">Offers a free credit counseling service</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>