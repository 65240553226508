<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Debt Consolidation Loans Reviews</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">Read TopFinanceDirectory’s review of debt consolidation companies.Compare and choose the right debt consolidation provider based on your personal needs.</div>
                </div>
            </div>
            <div class="article_list_container">
                <div class="article_list">
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                    <div class="article">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a href="javascpit:;">
                                        <img src="/imgs/tax_relief_reviews_list_imgs_1.svg" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>Anthem Tax Services</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li>Incorporates principles of trust, service, honesty, integrity, &amp; education in their services</li>
                                            <li>Operates remotely and licensed in all 50 states with 30+ years of experience</li>
                                            <li>Pursues the best options for taxpayers depending on their personal situations</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" href="javascpit:;" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <a href="javascpit:;" class="visit_site" title="Anthem Tax Services">Read more »</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text_container">
                <div class="text">
                    <h2>TopFinanceDirectory’s Debt consolidation Reviews Methodology</h2>
                    <p>When it comes to debt consolidation loans reviews, TopFinanceDirectory depends on its committed, experienced, and specialized team to contextualize technical information. In order to draft unbiased, unprejudiced, and reliable debt consolidation reviews, TopFinanceDirectory follows a systematic review methodology and rating methodology.</p>
                    <p>The team of experts spends hours after hours to review various debt consolidation services’ features and make a comparative analysis. In fact, TopFinanceDirectory’s experts take their time to figure out the essence of each detail. Of course, the objective of TopFinanceDirectory is to save the valuable time and money of people.</p>
                    <p>Since debt consolidation is the best way to take out a loan and pay off one or more debts, TopFinanceDirectory makes sure to highlight its short-term and long-term benefits. Interestingly, the experts at TopFinanceDirectory offer personalized suggestions that make it clear to readers without having to dive into technical information. It also suggests suitable debt consolidation services and balances the review with debt consolidation considerations.</p>
                    <h2>Is TopFinanceDirectory Legit?</h2>
                    <p>By utilizing a comprehensive and strategic evaluation process, and combining this with valuable user feedback, TopFinanceDirectory is able to offer their customers reliable and trustworthy data. When it comes to getting important answers to complicated financial questions, TopFinanceDirectory is an industry expert who has made it their mission to provide relevant and timely information with the goal of making their client’s financial journey an easier one.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "debt_consolidation_reviews",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnotnavigation.scss';
@import '../../assets/scss/reviewslist.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
    .pagination >>> .el-pagination {
        text-align: center;
    }
</style>