<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Debt consolidation</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Debt is a reality of life for most Americans. Debt interest from credit cards, along with student loan payments, auto loans, and mortgage payments, leads many Americans to a difficult situation, in which. debt consolidation is worth considering.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/debt_consolidation" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Debt Consolidation Loans</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/debt_consolidation_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>TDebt Consolidation Guide</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/debt_consolidation_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Debt Consolidation Loan Reviews</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/debt_consolidation" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Debt Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.img_url" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.APR}}</span>
                                          <ul>
                                              <li>{{item.Features1}}</li>
                                              <li>{{item.Features2}}</li>
                                              <li>{{item.Features3}}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.offers_url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.offers_url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What is debt consolidation?</h2>
                          <p>Debt consolidation refers to loans and other financial services aimed at streamlining debt payments to be more manageable. That may mean taking a new, large loan which is used to pay off other multiple loans immediately.</p>
                          <p>People who have several debts may find that combining them into a single debt achieves several favorable outcomes, including:</p>
                          <ul class="text1">
                              <li>Simpler management of the debt repayment process</li>
                              <li>Favorable payoff terms</li>
                              <li>Lower monthly payments</li>
                              <li>A better interest rate</li>
                              <li>A possible path to saving their credit score</li>
                          </ul>
                          <p>Debt consolidation is a process that typically begins with a free consultation. One typically discusses their financial challenges with a financial professional, who will guide the inquirer through various options. While the consultation may involve a loan, in many cases they will also try to negotiate more favorable terms with creditors.</p>
                          <p>Creditors are normally happy to work with debt consolidation companies because it gives creditors an opportunity to maximize their return of money owed to them.</p>
                          <p>Debt consolidation loans require one to go through a loan application process. Upon qualifying, interest rates, and terms will depend on one’s credit history and other factors.</p>
                          <h3>Bottom Line</h3>
                          <p>Debt consolidation is a process that simplifies the repayment process and can decrease the overall amount owed. One uses a single loan to pay off existing debts resulting in a more favorable outcome for the borrowers.</p>
                          <h2 class="anchor_h2">How does Debt Consolidation Work?</h2>
                          <p>Debt consolidation starts with choosing a debt consolidation company to work with. One can compare different debt consolidation companies on Lendstart. Factors to consider comparing between different companies include:</p>
                          <ul class="text1">
                              <li>Upfront costs</li>
                              <li>Professional certifications</li>
                              <li>Online reviews</li>
                              <li>Interest rates</li>
                              <li>Loan terms </li>
                              <li>Fees and penalties</li>
                          </ul>
                          <p>After choosing a debt consolidation company, one will typically go through an initial consultation. A financial professional will review the individual’s situation and make recommendations.  </p>
                          <p>After going through the loan application process, one consolidates their debts and begins paying back the new loan. One then will make regular repayments towards the new single loan. The interest rate may be higher or lower than the total interest one was paying before taking the debt consolidation loan. Obviously, debt consolidation loans still come with interest that must be paid back over the agreed term. The debt consolidation process ends when the new loan is paid off.</p>
                          <h2 class="anchor_h2">Example of Debt Consolidation</h2>
                          <p>You have 3 credit cards with interest rates of 19.99%. Your total credit card debt is $5,000, and you must pay $1,000 in interest if you miss your next payment. You also have a car loan with a current balance of $10,000 at an interest rate of 5%. That means you must pay $500 in interest.</p>
                          <p>You take a debt consolidation loan of $15,000 with an interest rate of 8%. Your new interest payment for both loans is $1,200.</p>
                          <h2 class="anchor_h2">What Types of Debt Consolidation Exist?</h2>
                          <p>There is more than one approach to debt consolidation. Here are some of the major categories one can find when searching for help with debt.</p>
                          <h2>Debt Consolidation Loans</h2>
                          <p>The simplest form of debt consolidation is a loan. One simply takes a loan to pay for all current loans. These are normally unsecured personal loans with moderate to high-interest rates, depending on credit history.</p>
                          <p>Debt Consolidation Programs</p>
                          <p>Debt consolidation programs often include additional steps which are alternatives to a loan. These programs, also known as debt management plans, are newly arranged payment schedules and terms. Debt consolidation companies try to negotiate more favorable terms that make it easier for one to repay. In some cases, these programs will still lead to a debt consolidation loan.</p>
                          <h2>Home Equity Line of Credit (HELOC) For Debt Consolidation</h2>
                          <p>A home equity line of credit (HELOC) is a more affordable way to finance debt consolidation. One can normally borrow up to 80% of their home’s equity to consolidate other loans. A HELOC functions similarly to a credit card, in that one can draw money when needed. Generally speaking, one can find more favorable rates with this kind of loan. However, it is important to weigh this advantage against placing the home’s equity as collateral.</p>
                          <h2>Balance Transfer with a Credit Card</h2>
                          <p>One may use a new, promotional credit card deal to transfer a credit card balance to a new card. Combining debt under a new card with a lower interest rate can save on interest.</p>
                          <h2>Borrowing Against a Retirement Accounts</h2>
                          <p>Some people found that retirement accounts are a great way to access funds rather than borrowing from financial institutions. There are several ways to borrow from retirement savings accounts.</p>
                          <h2>401 (k)</h2>
                          <p>Borrowing against 401 (k) loans can be helpful in tough financial circumstances. Loans against a 401 (k) usually do not take into account one’s credit history. A 401 (k) loan enables one to access some of their retirement savings with no taxes imposed. One can borrow up to $50,000 or 50% of their present assets. </p>
                          <h2>Roth IRA</h2>
                          <p>Borrowing against a Roth IRA has certain restrictions and conditions that must be met to avoid penalties. Generally, there is a 10% penalty if one would like to withdraw the earnings on their contributions. The IRS also has certain requirements if one would like to borrow the funds for a short period. Both instances require filling the appropriate forms. </p>
                          <h2>Savings Account</h2>
                          <p>A savings account loan is a low interest loan that uses the money in a given savings account as collateral for the loan. An additional benefit to this kind of loan is that there is no need for a credit check since the individual already owns the collateral.</p>
                          <h2 class="anchor_h2">Pros and Cons of Debt Consolidation</h2>
                          <p>Here are some pros and cons of debt consolidation.</p>
                          <h2>Pros</h2>
                          <ul class="text1">
                              <li>Simplified Repayments</li>
                              <li>Fixed Repayment Schedule</li>
                              <li>Lower Total Interest Expenses</li>
                              <li>Repaying Debts Sooner</li>
                              <li>Saving Credit Score</li>
                          </ul>
                          <h2>Cons</h2>
                          <ul class="text1">
                              <li>Incomplete Solution to Long-Term Financial Difficulties</li>
                              <li>Upfront Costs</li>
                              <li>Temporary Drop in Credit Score</li>
                          </ul>
                          <p>Most people find that the best debt consolidation loans are when the pros significantly outweigh the cons for the individual borrower’s needs.</p>
                          <h2 class="anchor_h2">When Debt Consolidation may be a Poor Choice</h2>
                          <p>Despite its benefits, debt consolidation may not resolve financial hardship because it does not guarantee that one will avoid debt in the future. Furthermore, some debt consolidation loans are burdened with extra fees such as loan origination fees, closing costs, balance transfer fees, and annual fees. </p>
                          <h2>Consider Budgeting</h2>
                          <p>Some people may find it effective to make a realistic budget that will help them prioritize their debt payments and regular expenses moving forward instead of using debt consolidation. Moreover, some people emphasize making timely payments that will mitigate the probability of accumulating large amounts of interest. Similar tips and ideas can be found in our debt consolidation guide.</p>
                          <h2 class="anchor_h2">What are the Alternatives to Debt Consolidation?</h2>
                          <p>Depending on the nature of one’s debt, there may be other options.</p>
                          <p>If the money is owed to the IRS, there are simple paths to coming up with a new payment schedule. Go to the IRS website for additional information on payment plans.</p>
                          <p>If one has medical debts, try speaking to the hospital administration to see if they can be partially forgiven or canceled before resorting to debt consolidation.</p>
                          <p>If one has a total debt balance below $10,000, consider paying down the debt as is. In fact, most debt consolidation companies will suggest alternatives to debt consolidation if the debt isn’t so severe.</p>
                          <h2 class="anchor_h2">What are the Requirements for Debt Consolidation?</h2>
                          <p>Companies will have different requirements for debt consolidation services and loans. Often, they will clearly state the minimum debt amount one must owe in order to apply for their services. Additionally, they will require a credit score within a given range and a source of income.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">What is debt consolidation?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">How does Debt Consolidation Work?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Example of Debt Consolidation</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===3}">What Types of Debt Consolidation Exist?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===4}">Pros and Cons of Debt Consolidation</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)" :class="{active: active===5}">When Debt Consolidation may be a Poor Choice</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)" :class="{active: active===6}">What are the Alternatives to Debt Consolidation?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)" :class="{active: active===7}">What are the Requirements for Debt Consolidation?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "debt_consolidation",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: [{
                    id: 1,
                    name: 'Freedom DR',
                    img_url: '/imgs/offers_imgs/freedom_loriginal_size.svg',
                    offers_url: 'https://start.freedomfinancialnetwork.com/270o2/estimated-debt?',
                    APR: '$20,000',
                    Features1: 'Over $15 billion in debt resolved',
                    Features2: 'Served over 800,000 clients since 2002',
                    Features3: 'Offers a personalized plan with no upfront fees',
                }, {
                    id: 2,
                    name: 'JG Wentworth',
                    img_url: '/imgs/offers_imgs/JGW_l.svg',
                    offers_url: 'https://quote.jgwentworth.com/ds-aff-lendstart-lp1',
                    APR: '$10,000 ',
                    Features1: '30 Years experience in financial services',
                    Features2: 'Become debt-free in 24-48 months',
                    Features3: 'A+ Better Business Bureau Rating',
                }, {
                    id: 3,
                    name: 'ClearOne Advantage',
                    img_url: '/imgs/offers_imgs/Clearone_l.svg',
                    offers_url: 'https://relief.clearoneadvantage.com/free-debt-relief-estimate',
                    APR: '$10,000 ',
                    Features1: 'No upfront fees',
                    Features2: 'Personalized plans to suit your budget',
                    Features3: '30-day satisfaction guarantee',
                }, {
                    id: 4,
                    name: 'CuraDebt',
                    img_url: '/imgs/offers_imgs/curadebtwithout_text.svg',
                    offers_url: 'https://www.curadebt.com/apply-loan/',
                    APR: '$10,000',
                    Features1: 'Offers a variety of services',
                    Features2: 'Free debt consultation',
                    Features3: 'Well-established company',
                }, {
                    id: 5,
                    name: 'Accredited',
                    img_url: '/imgs/offers_imgs/accredited2_l.svg',
                    offers_url: 'https://www.accrediteddebtrelief.com/free-quote/aff/dr/',
                    APR: '$15,000',
                    Features1: 'Lower your m. payment by 50%+',
                    Features2: 'Pay off your debt in 12-48 months',
                    Features3: 'A+ Rating with the BBB',
                }, ]
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/navimgcrad.scss';


.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>