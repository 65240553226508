<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="popular_article_container">
                <div class="popular_article">
                    <div class="popular_article_left">
                        <img src="/imgs/learning_center_imgs.png" alt="">
                    </div>
                    <div class="popular_article_right">
                        <ul class="popular_list">
                            <li>
                                <div class="popular_date">
                                    <span>Home Buying</span>
                                    <span>2022/3/17</span>
                                </div>
                                <span class="popular_Introduction">Learn everything you need to know about buying a home.</span>
                            </li>
                            <li>
                                <div class="popular_date">
                                    <span>Home Buying</span>
                                    <span>2022/3/17</span>
                                </div>
                                <span class="popular_Introduction">Learn everything you need to know about buying a home.</span>
                            </li>
                            <li>
                                <div class="popular_date">
                                    <span>Home Buying</span>
                                    <span>2022/3/17</span>
                                </div>
                                <span class="popular_Introduction">Learn everything you need to know about buying a home.</span>
                            </li>
                            <li>
                                <div class="popular_date">
                                    <span>Home Buying</span>
                                    <span>2022/3/17</span>
                                </div>
                                <span class="popular_Introduction">Learn everything you need to know about buying a home.</span>
                            </li>
                            <li>
                                <div class="popular_date">
                                    <span>Home Buying</span>
                                    <span>2022/3/17</span>
                                </div>
                                <span class="popular_Introduction">Learn everything you need to know about buying a home.</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="article_list_container">
                <div class="article_list">
                    <h2>More high-yield savings options:</h2>
                    <div class="article">
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                        <div class="one_article">
                            <img src="/imgs/Learning_center_list_imgs.png" alt="">
                            <h2>First Time Home Buyer Guide</h2>
                            <p>Give us some details on the kind of loan you need and how you’ll use it. We’ll send your request out to our lender network, and within seconds, you’ll see competing offers. Gain insights about financial decision making with our easy to understand expert reviews and guides.</p>
                            <span>Read more »</span>
                        </div>
                    </div>
                    <div class="pagination">
                        <el-pagination
                            small
                            background
                            layout="prev, pager, next"
                            :total="1000">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "learning_center",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import '../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #c5c5c5;
        font-size: 18px;
        background: url(/imgs/Slice_58.png) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #fff;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #c5c5c5;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
        }
    }
    .popular_article_container {
        width: 100%;
        .popular_article {
            width: 61%;
            margin: 50px auto 50px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .popular_article_left {
                width: 547px;
                margin-right: 40px;
            }
            .popular_article_right {
                width: 593px;
                .popular_list {
                    font-size: 18px;
                    color: #666666;
                    line-height: 18px;
                    li {
                        border: 1px solid #c5c5c5;
                        border-radius: 4px;
                        padding: 16px 9px 16px 29px;
                        .popular_date {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            span:first-child {
                                font-weight: 700;
                                color: #222831;
                            }
                        }
                    }
                    li:first-child {
                        border: 1px solid #2ac8a2;
                        color: #2ac8a2;
                        span:first-child {
                            color: #2ac8a2;
                        }
                    }
                    li:not(:last-child) {
                        margin-bottom: 45px;
                    }
                }
            }
        }
    }
    .article_list_container {
        width: 100%;
        .article_list {
            width: 61%;
            margin: 50px auto 220px;
            h2 {
                font-weight: 700;
                font-size: 48px;
                line-height: 48px;
                color: #222831;
                margin-bottom: 60px;
            }
            .article {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                .one_article {
                    width: 31%;
                    margin-bottom: 80px;
                    img {
                        min-width: 340px;
                    }
                    h2 {
                        font-size: 18px;
                        font-weight: 700;
                        color: #353449;
                        line-height: 18px;
                        margin: 40px 0 15px 0;
                        padding-left: 20px;
                        position: relative;
                    }
                    h2:before {
                        width: 5px;
                        height: 5px;
                        background: #2ac8a2;
                        border-radius: 50%;
                        content: '';
                        display: block;
                        position: absolute;
                        top: 3px;
                        left: -1px;
                    }
                    h2:after {
                        width: 5px;
                        height: 5px;
                        background: #c4c4c4;
                        border-radius: 50%;
                        content: '';
                        display: block;
                        position: absolute;
                        top: 13px;
                        left: -1px;
                    }
                    p {
                        text-align: justify;
                        color: #666666;
                        line-height: 14px;
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                    span {
                        color: #2684ff;
                        line-height: 12px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .popular_article_container {
            width: 100%;
            .popular_article {
                width: 90%;
                flex-direction: column-reverse;
                align-items: center;
                .popular_article_left {
                    width: 100%;
                    margin-right: 0;
                    text-align: center;
                }
                .popular_article_right {
                    width: 547px;
                    margin-bottom: 25px;
                }
            }
        }
        .article_list_container {
            .article_list {
                width: 95%;
                margin: 0px auto 220px;
                h2 {
                    text-align: left;
                    padding-left: 40px;
                    font-size: 40px;
                }
                .article {
                    justify-content: space-around;
                    .one_article {
                        width: 340px;
                    }
                }
            }
        }
    }
    @media (max-width: 760px) {
        .popular_article_container {
            width: 100%;
            .popular_article {
                width: 90%;
                flex-direction: column-reverse;
                align-items: center;
                .popular_article_left {
                    width: 100%;
                    margin-right: 0;
                    text-align: center;
                    img {
                        width: 360px;
                    }
                }
                .popular_article_right {
                    width: 360px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
    .pagination >>> .el-pagination {
        text-align: center;
    }
</style>