<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">EasyAutoPolicy</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance' }">Auto Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>EasyAutoPolicy</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>EasyAutoPolicy</h1>
                          <img src="../../../../public/imgs/reviews_list/everquote_review_1.jpeg" alt="">
                          <h2 class="anchor_h2">Intro to EasyAutoPolicy</h2>
                          <p><a href="https://myautosavings.com/">EasyAutoPolicy</a> is a good place to start if you want to compare auto insurance companies. EasyAutoPolicy connects customers with reputable auto insurance carriers, brokers, and agents such as Esurance, AAA, Farmers, StateFarm, Nationwide, American Family Insurance, Progressive, and Liberty Mutual.</p>
                          <h2 class="anchor_h2">Company Overview</h2>
                          <p>EasyAutoPolicy, based in Cambridge, Massachusetts, has been in the online insurance marketplace since 2011. They started as an online marketplace for auto insurance but have since expanded to include home, renters, life, health, and commercial insurance.</p>
                          <h2 class="anchor_h2">How to Get a Quote from EasyAutoPolicy?</h2>
                          <p>Obtaining rates from EasyAutoPolicy is easy and straightforward. The entire procedure takes only about two to three minutes.</p>
                          <img src="../../../../public/imgs/blog_imgs/everquote_auto_insurance_review_1.png" alt="">
                          <p>First, EasyAutoPolicy requests information about you and your vehicle, such as your home ZIP code, current insurance, if you have it, the number of vehicles you want to insure, and the year, make, and model of your vehicle.</p>
                          <p>Following that, <a href="https://myautosavings.com/">EasyAutoPolicy</a> displays the best auto insurance matches based on your profile.</p>
                          <p>Finally, licensed auto insurance agents will contact you online or by phone to discuss quotes and potential savings.</p>
                          <img src="../../../../public/imgs/blog_imgs/everquote_auto_insurance_review_2.png" alt="">
                          <p>After you've completed these steps, you'll be well on your way to obtaining auto insurance for your vehicle.</p>
                          <p>Costs change depending on a variety of factors, but <a href="https://myautosavings.com/">EasyAutoPolicy</a> mentions that the average quote from their partners for a good driver and typical coverage is $140 per car per month. The following factors will have an impact on your cost:</p>
                          <ul class="text1">
                              <li>The vehicle itself: Auto insurance companies frequently base their rates on data from similar vehicles. If drivers in your car's make and model make few claims, your quote will be lower. In addition, expensive vehicles that are more expensive to repair may have higher rates.</li>
                              <li>Credit score: If you have a bad credit score, it will be more difficult to find cheap auto insurance.</li>
                              <li>Vehicle usage and location: Whether the vehicle is used for business or personal reasons, as well as the ZIP code where it is parked at night, have an impact on insurance costs.</li>
                              <li>Accident, speeding tickets</li>
                              <li>Driver’s age and driving experience</li>
                          </ul>
                          <h2 class="anchor_h2">What Kind of Coverage Does EverQuot Offer?</h2>
                          <ul class="text1">
                              <li>Basic Protection</li>
                              <li>Liability for bodily harm</li>
                              <li>Property Detriment Liability collision</li>
                              <li>Comprehensive Coverage</li>
                              <li>Accidental Personal Injury</li>
                              <li>Medical Billing</li>
                              <li>Uninsured/Underinsured Driver</li>
                              <li>Extra Protection</li>
                              <li>Reimbursement for Rental</li>
                              <li>Towing and Labor Charges Sound System Glass Vet Bills</li>
                          </ul>
                          <h2 class="anchor_h2">Customer’s Feedback on EasyAutoPolicy</h2>
                          <img src="../../../../public/imgs/reviews_list/everquote_review_2.jpg" alt="">
                          <p>Some review sites, including the Better Business Bureau (BBB), Google Reviews, and Facebook, show a high percentage of negative reviews and numerous complaints about EasyAutoPolicy from users who claim to have been "misled" or "scammed." Some of these reviews reflect customers' feelings about the insurance company from which they obtained quotes. Others are unique to EasyAutoPolicy. A recent Clearsurance survey of 129 EasyAutoPolicy users, on the other hand, was much more positive.</p>
                          <p><a href="https://myautosavings.com/">EasyAutoPolicy</a> received a positive rating from 81.4 percent of the 129 survey respondents, giving it an overall rating of 4.1 out of 5 stars. 79 percent of respondents said they were completely satisfied with how the site used their information. Similarly, 75.2 percent of respondents said that using EasyAutoPolicy provided a better experience than not using the site. Sixty-two percent of EasyAutoPolicy users said they would recommend it to a friend.</p>
                          <img src="../../../../public/imgs/blog_imgs/everquote_auto_insurance_review_3.png" alt="">
                          <img src="../../../../public/imgs/blog_imgs/everquote_auto_insurance_review_4.png" alt="">
                          <h2 class="anchor_h2">How Does EasyAutoPolicy Stack up Against the Competition?</h2>
                          <p>In fact, EasyAutoPolicy is ideal for anyone looking for savings or a better deal on car insurance rates. However, where your data is going is an important distinction to make when comparing different online car insurance quote comparison experiences.</p>
                          <p><a href="https://myautosavings.com/">EasyAutoPolicy</a> declares to offer personalized car insurance quotes that meet "both your needs and your budget." Meanwhile, EasyAutoPolicy is also a lead generation website, which means it can sell your contact information to insurance companies and independent agents. Everquote serves as a go-between, connecting customers with insurance providers. Your contact information will be forwarded to a variety of insurance carriers, who may contact you to provide a quote.</p>
                          <div class="FAQ_container">
                              <div class="FAQ_list">
                                  <div class="FAQ_list_left">
                                      <div class="FAQ_onecontailner">
                                          <h2 class="anchor_h2">FAQ</h2>
                                          <el-collapse accordion class="FAQ">
                                              <el-collapse-item title="Is EasyAutoPolicy a Reliable Business?" name="1">
                                                  <div>Yes, EasyAutoPolicy is a legitimate company. The company operates one of the country's largest online marketplaces for insurance shopping. They have handled over 35 million consumer quote requests. Employees are also committed to assisting all customers in saving both time and money.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="How Long Does the EasyAutoPolicy Process Take?" name="2">
                                                  <div>It takes you around five minutes to complete the form. Within minutes of submitting your form, you will begin receiving quotes from local agents. Following that, you will receive online quotes from insurance companies. Some of these may necessitate the completion of an additional form and the re-entry of your information.</div>
                                              </el-collapse-item>
                                          </el-collapse>
                                      </div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Intro to EasyAutoPolicy</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Company Overview</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">How to Get a Quote from EasyAutoPolicy?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What Kind of Coverage Does EverQuot Offer?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Customer’s Feedback on EasyAutoPolicy</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">How Does EasyAutoPolicy Stack up Against the Competition?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">FAQ</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
    
    .FAQ >>> .el-collapse-item__header {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    .FAQ >>> .is-active {
        color: #2ac8a2;
    }
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 35px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 100px;
            text-align: left;
        }
    }
</style>