<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>What is Debt-to-Income Ratio and Why It's Important</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Bradley Schnitzer</div>
                                <div>Last update: 25 February 2021</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/What is Debt-to-Income Ratio and Why It's Importantblog_1.jpg" alt="">
                            <p>Most of us want to make more money relative to our expenses when possible.</p>
                            <p>However, daily spending isn't the only thing to worry about.</p>
                            <p>Keeping your debt at reasonable levels is a cornerstone of financial strength. Reducing your debt allows you to enjoy a larger percentage of your income and wastes less of your money on interest.</p>
                            <p>But how do you measure if your debt is too much? How do you know when you can afford another loan or credit card or if you should hold off and pay some down?</p>
                            <p>That's where the debt-to-income ratio comes in.</p>
                            <p>Below, we'll explore debt-to-income ratio further, as well as why it's important. We'll then finish up with a few tips on how to improve yours.</p>
                            <h2>What Is Debt-to-Income Ratio?</h2>
                            <p>Debt-to-income ratio is exactly what it sounds like — it's how much debt you're carrying relative to your income.</p>
                            <p>In particular, it compares your monthly debt expenses against your monthly gross income (your income before taxes).</p>
                            <p>This ratio shows how much of your income is “claimed” by your debts.</p>
                            <h3>How to Calculate Your Debt-to-Income Ratio</h3>
                            <p>To calculate your debt-to-income ratio, you must first add up all your monthly debt payments.</p>
                            <p>That includes the obvious, like payments on personal</p>
                            <p>loans, auto loans, student loans, payday loans, credit cards (the minimum payments), and so on.</p>
                            <p>However, it also includes housing expenses. Yes, renters include their rent in their debt-to-income ratio. This is because you reliably owe rent to your landlord every month.</p>
                            <p>For homeowners, your monthly mortgage payments consisting of principal and interest are obviously included. That said, property taxes, insurance, and homeowner's association fees are all part of your debt-to-income ratio, too, since they're part of living in your home.</p>
                            <p>After calculating your monthly debt payment total, sum up your monthly gross income (again, your income before taxes).</p>
                            <p>This is much easier, as you usually just include your paycheck. If you have investments or other income sources that make up a substantial amount of your income, those are likely included as well.</p>
                            <p>Now that you have both numbers, divide your monthly debt total by your monthly gross income to receive a decimal ratio. If you'd like, you can multiply this decimal by 100 to arrive at a percentage.</p>
                            <p>This represents the total percentage of your income that your debt gets ahold of first.</p>
                            <h3>Let's look at an example.</h3>
                            <p>Say your rent is $1,000, your car payment is $300, you have a $200 student loan payment, and you pay $100 per month on credit cards. That's $1,600 of debt for debt-to-income purposes.</p>
                            <p>Now, imagine your salary is your only source of income. You earn $60,000 per year.</p>
                            <p>Divide $60,000 by 12 to arrive at $5,000 gross monthly income.</p>
                            <p>From there, divide $1,600 (your total monthly debt payments) by $5,000 to get 0.32.</p>
                            <p>Finally, multiply 0.32 by 100 to arrive at a debt-to-income ratio of 32%. In other words, you have to put 32% of your total income towards debt every month.</p>
                            <p>Now that you know how to calculate your debt-to-income ratio, let's explore why you should keep an eye on it.</p>
                            <h2>Why Should I Care About My Debt-to-Income Ratio?</h2>
                            <p>Debt-to-income ratio plays a vital part in a couple of key areas of your finances.</p>
                            <p>Let's look closer at why you should pay attention to your debt-to-income ratio.</p>
                            <h3>Mortgages and Refinancing</h3>
                            <p>It doesn't matter whether you're getting a mortgage to buy a home or </p>
                            <p>Thus, home lenders look at many more factors than, say, a lender like </p>
                            <p>One of these factors is the debt-to-income ratio. They want to see what your borrowing habits are, but they also want to make sure you have plenty of room in your budget to cover a monthly mortgage payment for years to come.</p>
                            <p>Now, home lenders actually look at two different types of debt-to-income ratios:</p>
                            <ul class="text1"><li>Front-end DTI</li>
                            <li>Back-end DTI</li></ul>
                            <p>Front-end debt-to-income ratio is the simpler of the two. It only includes your housing-related expenses. That includes a mortgage payment if you currently have one, as well as insurance, property taxes, and homeowner's association fees.</p>
                            <p>Lenders will calculate this, but they care more about the back-end ratio.</p>
                            <p>The back-end debt-to-income ratio is the ratio we calculated earlier. It's all your monthly debt payments, including housing.</p>
                            <p>Lenders care about this one because it's more reflective of your ability to cover a mortgage payment.</p>
                            <p>Mortgage lenders prefer to see a back-end debt-to-income ratio that doesn't exceed 36%.</p>
                            <p>However, this differs depending on where you get the loan from. For example, the FHA's maximum accepted debt-to-income ratio is 57%, although it's lower in some cases.</p>
                            <h3>Your Own Financial Health</h3>
                            <p>Debt-to-income ratio is vital for you to know for the same reason lenders want to know — it tells you how much income you have for things that aren't obligations.</p>
                            <p>See, someone making $100,000 per year with $70,000 in debts is worse off than another person earning $50,000 but only carrying $10,000 in debt. The latter person will have a much easier time covering their payments.</p>
                            <p>Now, having a non-zero ratio isn't bad. Even the most financially savvy individuals may have mortgages, car loans, and student loans.</p>
                            <p>Your mortgage buys you home (or rental property), your auto loan provides transportation, and your student loans gave you skills and knowledge useful for acquiring stable employment.</p>
                            <h2>Quick Tips to Lower Your Debt-to-Income Ratio</h2>
                            <h3>Pay Off Your Smallest Debt</h3>
                            <p>One of the fastest ways to improve your debt-to-income ratio is to pay off a debt.</p>
                            <p>And the fastest debt to pay off is the smallest.</p>
                            <p>Once that debt is gone, you'll have to cut down on your total monthly payments, lowering your debt-to-income ratio.</p>
                            <p>You'll then have more money to put towards other debts, making the rest of the process easier.</p>
                            <h3>Consolidate and Refinance</h3>
                            <p>Combining </p>
                            <ul class="text1"><li>It combines several debts into one, making it easier to track and pay down said debt.</li>
                            <li>It scores you a lower interest rate (if you do the refinance right) and extends your loan term, lowering your monthly payment — therefore, decreasing your debt-to-income ratio.</li></ul>
                            <p>Your best bet is to go through a loan aggregation site like </p>
                            <h3>Track Your Ratio Regularly</h3>
                            <p>Recalculate your debt-to-income ratio monthly. It'll keep you motivated to stay on the right track.</p>
                            <p>Plus, it'll help you catch any problems, such as if you took on extra debt this month without thinking about it.</p>
                            <h2>Keep an Eye on Your DTI</h2>
                            <p>Your debt-to-income ratio plays a critical role in buying a home or getting a better mortgage rate. More importantly, however, it's an excellent financial health indicator.</p>
                            <p>Sure, you might have a healthy salary, but income doesn't matter when most of it goes towards debt payments and interest.</p>
                            <p>Make sure to keep tabs on your debt-to-income ratio and pay down debt when you notice it creeping up. Your future self will thank you.</p>


                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Bradley Schnitzer</span><br />Last update: 25 October 2020</div>
                        </div>
                        <p>Bradley Schnitzer is a copywriter and content writer that helps businesses gain loyal customers through informative content pieces and persuasive copy. He is passionate about personal finance and helping budding freelancers further their careers. He spends his free time reading, hitting the gym, pursuing several intellectual interests, and drinking coffee.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    .buttom_container {
                        text-align: center;
                        margin-top: 88px;
                        .buttom {
                            padding: 13px 18px;
                            background: #2ac8a2;
                            border-radius: 50px;
                            font-size: 18px;
                            color: #ffffff;
                            line-height: 18px;
                            font-weight: 700;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
