<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Ladder Life Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance' }">Life Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Select My Policy</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Select My Policy</h1>
                          <img src="../../../../public/imgs/reviews_list/ladder_life_insurance_review_1.jpg" alt="">
                          <h2 class="anchor_h2">About Select My Policy</h2>
                          <p>Liberty Life Insurance Company, a subsidiary of American Health Group, offers a simple life insurance plan for families looking to protect their loved ones: term life insurance. So far, American Health Group has covered more than 15 million members. For those looking for basic coverage with a fixed benefit period, free living may be a viable option. Since Freedom Life is part of the USHealth Group, you can combine your policy with other benefits offered by the parent company, including health, dental or vision coverage.</p>
                          <p>Liberty Life Insurance Company is headquartered in Fort Worth, Texas. Its life insurance products may be underwritten by two other American Health Group subsidiaries, National Foundation Life Insurance Company and Enterprise Life Insurance Company.</p>
                          <p>All Freedom Life insurance products are sold through agents only; you cannot get quotes or buy insurance online.</p>
                          <p>Not all states have Freedom Life insurance policies.</p>
                          <h2 class="anchor_h2">Types of Life Insurance Offered</h2>
                          <p>Liberty Life Insurance offers only one type of life insurance. The plan, called LifeProtector, is a 10-year life insurance plan. This means the program lasts for 10 years after the initial purchase date. If you exceed your 10-year tenure, you have the option to renew, although your interest rate may increase. This special policy can be extended until the age of 70. This term life insurance policy is simple and affordable. Monthly premiums range from $10 to $50. Your exact premium will depend on factors such as your age, current health conditions and the amount of death coverage you choose.</p>
                          <p>This term life insurance policy comes with no riders, so your benefits cannot be customized. This is also not a life insurance policy that guarantees acceptance. You go through a standard application process, and if the company deems you too risky to insure, you may be rejected.</p>
                          <p>While Freedom Life Insurance only offers one type of life insurance, they do offer many other types of insurance through their parent company, USHealth Group. These include a variety of different medical plans with a focus on coverage for serious injuries and illnesses. American Health Group also offers several different types of accident insurance as well as dental and vision insurance.</p>
                          <h2 class="anchor_h2">Pros and Cons</h2>
                          <p>Pros</p>
                          <p>Other insurance products available: In addition to term life insurance, Freedom life's parent company also offers a range of other insurance products. You can also get health insurance, accident protection, dental insurance and vision insurance.</p>
                          <p>Better-than-average complaint rate: Freedom Life's complaint rate was lower than expected for a company of its size, indicating a relatively positive customer experience.</p>
                          <p>Cons</p>
                          <p>Only one life insurance option: While most life insurance companies offer multiple life insurance plans such as term, whole and general life insurance, Freedom life has only one option: 10-year term life insurance. If you want any other plan or terms, you have to find another insurance company.</p>
                          <p>Not Available in All States: Unfortunately, the "Life Free" policy does not apply in all 50 states.</p>
                          <p>No Riders or Endorsements: Other insurance companies have riders that you can add to your policy to enhance and customize your coverage. Freedom Life has no insurance riders; the policy is the same for everyone and cannot be changed.</p>
                          <h2 class="anchor_h2">Customer Service</h2>
                          <p>Freedom Life and USHealth Group have limited online client resources. You can't get automated quotes through the website, and Freedom Life doesn't have tools like life insurance calculators or live chat that other companies offer.</p>
                          <p>To contact customer support, you can email info@ushealthgroup.com or call 800-387-9027. However, the availability of its representatives is limited. Customer service is only available Monday through Friday from 7:00am to 7:00pm and Saturday from 8:00am to 1:00pm.</p>
                          <h2 class="anchor_h2">Review</h2>
                          <p>Another thing to consider when shopping for life insurance is the reviews the company receives online. These reviews can give you an idea of what other customers have experienced with the company and what to expect if you purchase a policy. However, it's also important to take reviews with a grain of salt, as different people's experiences can vary widely.</p>
                          <p>Freedom Life Insurance is not currently accredited by the Better Business Bureau. When you visit their listing on the BBB website, you will find mostly negative reviews. The reviews cited poor customer service and unclear insurance terms from Freedom Life and USHealth Group. You can also find negative reviews when you visit their listings on other review sites like Yelp and Google Business.</p>
                          <p>While these reviews reflect the experiences of individual customers, it's still important to take them into consideration when shopping for life insurance. So many negative reviews usually indicate that future customers may also experience poor customer service patterns.</p>
                          <p>Another factor to watch is Freedom Life Insurance's complaint rate. This statistic reflects the relationship between the number of complaints received by an insurance company and the total number of customers. It shows whether their number of complaints is above average or below average. Freedom Life Insurance currently has no complaint record with the National Association of Insurance Commissioners, which puts their complaint rate well below the average for companies their size.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">About Select My Policy</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Types of Life Insurance Offered</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Pros and Cons</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Customer Service</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Review</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>