<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">FundsJoy</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Amone</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Amone</h1>
                          <img src="../../../../public/imgs/reviews_list/fundsjoy_review_1.jpg" alt="">
                          <h2 class="anchor_h2">The history of Amone</h2>
                          <p>Since founding in 1999, amone’s goal has been to provide benefit to potential borrowers by streamlining their borrowing process and making it simple for them to compare personal loans and other financial products online.AmONE do this by gathering all of America's top lenders and other financial solution providers in one place. We also get rid of the uncertainty and frustration that frequently accompany looking for financial assistance.AmONE is more dedicated than ever to assisting customers realize the control they can have over their finances by using the outcomes and experiences of previous users to improve the results of future visitors. AmONE is owned and operated by QuinStreet, Inc. (Nasdaq: QNST), a pioneer in delivering online marketplace solutions to match searchers with brands in digital media.</p>
                          <p>AmONE is committed to establishing and upholding a secure and encouraging atmosphere where customers can receive assistance in locating and connecting with reputable businesses that specialize in personal loans, debt consolidation, and credit repair.goal at AmOne is to make it simple and safe for consumers and small business owners to satisfy their financial needs.</p>
                          <h2 class="anchor_h2">How it works</h2>
                          <p>AmONE help consumers and small business owners nationwide solve their financial needs in the safest and easiest manner possible.</p>
                          <ul class="text1">
                            <li>Introduce yourself to us.</li>
                            <p>Through our online application, please respond to a few simple questions about who you are and your financial aspirations.</p>
                            <li>Find reliable lenders</li>
                            <p>Instantly get a list of dependable lenders who most closely fit your criteria.</p>
                            <li>Obtain the ideal personal loan.</li>
                            <p>While we continue to be accessible to assist you in the process, select your lender and submit your loan application.</p>
                            <li>It's simple to begin.</li>
                            <p>It shouldn't be difficult to find and apply for a personal loan. Fill out our simple form for one application.</p>
                          </ul>
                         <h2 class="anchor_h2">What can AmOne do?</h2>
                         <p>Financial sector specialists that care about people and are motivated to support those who wish to improve their financial status created our patented matching technology.In order to give you, the customer, all the information you need to make the best financial decisions for your situation, our process swiftly locates prospective loan options as well as other financial solutions.You can be sure that you will get the same excellent service and the greatest outcomes whether you communicate with us on the phone or online!</p>
                         <h2 class="anchor_h2">How AmOne picked?</h2>
                         <p>AmOne looked at dozens of lenders and personal loans to identify the top ones based on a range of requirements, such as cosigned loans, debt consolidation, short or no credit history, terrible or good credit, loan size, and more.</p>
                         <h2 class="anchor_h2">Methodology</h2>
                         <p>AmOne audited more than 30 lenders to determine which ones provide personal loans.</p>
                         <p>Once we had a more manageable selection, we studied the personal loans provided by 20 lenders, taking into account more than ten different factors, such as credit score requirements, costs, accessibility, customer satisfaction, APR rates, loan amounts, repayment terms, the application process, and more. We scored loans based on those characteristics to determine the best personal loans.</p>
                         <p>But we go further than that. AmOne will keep performing spot checks to make sure the most recent loan information is included here and will update our list once a year.</p>
                         <h2 class="anchor_h2">How AmOne Helps You Find Your Best Personal Loans</h2>
                         <p>Finding the finest personal loan is straightforward and simple with AmOne. Utilizing cutting-edge technology, our free service rapidly matches your loan request with reputable lenders. AmOne has been connecting customers with lenders for the past 17 years in order to provide the best loan options and the quickest process.</p>
                         <p>Our financial search consultants, who are accessible to answer all of your inquiries through phone, offer live support from qualified professionals. Enter your information in our short personal loan application or give us a call at 1-800-781-5187 between the hours of 9:00 a.m. and 9:00 p.m. on weekdays and 9:00 a.m. and 5:30 p.m. on weekends in Eastern time to get the best personal loan option for you.</p>
                         <h2 class="anchor_h2">You can trust AmOne</h2>
                         <ul class="text3">
                            <li>1.Personal privacy</li>
                            <p>AmOne never stores sensitive personal information like your date of birth or social security number.</p>
                            <li>2.No payment required</li>
                            <p>AmOne will never ask for any payment for our service, nor for your checking or credit card information.</p>
                            <li>3.Easy access</li>
                            <p>AmOne will always provide you with a quick response to questions and an easy way to access your options.</p>
                            <p>Pros&Cons</p>
                            <p>Pros</p>
                            <ul class="text3">
                                <li>Personal privacy</li>
                                <li>No payment required</li>
                                <li>Easy access</li>
                            </ul>
                            <p>Cons</p>
                            <ul class="text3">
                                <li>High maximum APR</li>
                                <li>No grace period for late fees</li>
                            </ul>
                         </ul>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">The history of Amone</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">How it works</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">What can AmOne do?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">How AmOne picked?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Methodology</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">How AmOne Helps You Find Your Best Personal Loans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">You can trust AmOne</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>