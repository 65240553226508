<template>
  <div>
      <div class="container">
          <div class="nav-img">
              <div class="nav-img-content">
                  <div class="subheading">Accurate as of Feb 21st 2022</div>
                  <div class="head_title">Best Personal Loans March 2022</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">If you need to secure a loan for a car, you can benefit by choosing personal loan companies that specialize in this type of lending product. A personal loan for Car financing will give you the money you need to pay for a car for driving to work, school, or for any other transportation needed. Compare and choose the right personal loan for your needs.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/content_icon2.png" alt="">
                              <span>Best Personal Loans</span>
                          </div>
                      </div>
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/content_icon3.png" alt="">
                              <span>Best Personal Loans</span>
                          </div>
                      </div>
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/content_icon4.png" alt="">
                              <span>Best Personal Loans</span>
                          </div>
                      </div>
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/content_icon5.png" alt="">
                              <span>Best Personal Loans</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="list_container">
              <div class="list_content">
                  <div class="list_head">
                      <div class="list_content_head">Compare Top Personal Loan Lenders</div>
                        <div class="list_content_text">By taking out a personal loan for Vst financing, you can buy a car with additional amenities, or which may not be affordable if you tried to buy it outright. Today’s best personal loans for car financing come with competitive interest rates, which allow you to make fixed monthly payments that are both reasonable and economical. Whether you want to buy a pre-owned vehicle or a new car, you will find that a Car loan secured online is the easiest and fastest way to fund a vehicle conveniently and fast.</div>
                  </div>
                  <div class="list_content_search">
                      <div class="search">
                          <el-input
                          class="search_input"
                          suffix-icon="el-icon-search"
                          v-model="search_input"></el-input>
                      </div>
                      <div class="condition">
                          <div class="condition_title">
                              <p>Which mortgage lender is right for you?</p>
                          </div>
                          <div class="condition_list_container">
                              <div class="condition_list">
                                  <div class="condition_list_condition">
                                      <span>Loan Purpose <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <div>Other</div>
                                          <div>Debt Consolidation Loan</div>
                                          <div>Home Improvement</div>
                                          <div>Student Loans</div>
                                          <div>Medical Expenses</div>
                                          <div>Large Purchases</div>
                                          <div>Special Occasion</div>
                                      </div>
                                  </div>
                                  <div class="condition_list_condition">
                                      <span>My Credit Score <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <div>Other</div>
                                          <div>Debt Consolidation Loan</div>
                                          <div>Home Improvement</div>
                                          <div>Student Loans</div>
                                          <div>Medical Expenses</div>
                                          <div>Large Purchases</div>
                                          <div>Special Occasion</div>
                                      </div>
                                  </div>
                                  <div class="condition_list_condition">
                                      <span>Loan Amount <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <div>Other</div>
                                          <div>Debt Consolidation Loan</div>
                                          <div>Home Improvement</div>
                                          <div>Student Loans</div>
                                          <div>Medical Expenses</div>
                                          <div>Large Purchases</div>
                                          <div>Special Occasion</div>
                                      </div>
                                  </div>
                                  <div class="condition_list_condition">
                                      <span>Closing soon? <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <div>Other</div>
                                          <div>Debt Consolidation Loan</div>
                                          <div>Home Improvement</div>
                                          <div>Student Loans</div>
                                          <div>Medical Expenses</div>
                                          <div>Large Purchases</div>
                                          <div>Special Occasion</div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article">
                                  <div class="left">
                                      <div class="left_top">
                                          <img src="/imgs/content2_logo.png" alt="">
                                          <img src="/imgs/content2_icon.png" alt="">
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>5.99-35.99</span> %
                                          <ul>
                                              <li>Loan Term:3-72 months</li>
                                              <li>Min Credot Score:300</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <div class="button">View Rates</div>
                                      <div class="remark">Read Review</div>
                                  </div>
                              </div>
                              <div class="article">
                                  <div class="left">
                                      <div class="left_top">
                                          <img src="/imgs/content2_logo.png" alt="">
                                          <img src="/imgs/content2_icon.png" alt="">
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>5.99-35.99</span> %
                                          <ul>
                                              <li>Loan Term:3-72 months</li>
                                              <li>Min Credot Score:300</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <div class="button">View Rates</div>
                                      <div class="remark">Read Review</div>
                                  </div>
                              </div>
                              <div class="article">
                                  <div class="left">
                                      <div class="left_top">
                                          <img src="/imgs/content2_logo.png" alt="">
                                          <img src="/imgs/content2_icon.png" alt="">
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>5.99-35.99</span> %
                                          <ul>
                                              <li>Loan Term:3-72 months</li>
                                              <li>Min Credot Score:300</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <div class="button">View Rates</div>
                                      <div class="remark">Read Review</div>
                                  </div>
                              </div>
                              <div class="article">
                                  <div class="left">
                                      <div class="left_top">
                                          <img src="/imgs/content2_logo.png" alt="">
                                          <img src="/imgs/content2_icon.png" alt="">
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>5.99-35.99</span> %
                                          <ul>
                                              <li>Loan Term:3-72 months</li>
                                              <li>Min Credot Score:300</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <div class="button">View Rates</div>
                                      <div class="remark">Read Review</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="article_list_right">
                      <div class="article_list_right_top">
                          <span>1,523 uesrs</span>
                          <img src="/imgs/content3_icon.png" alt="">
                      </div>
                      <div class="article_list_right_bottom">
                          <span>Picked a lender via<br />BestMoney this week</span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="question_container">
              <div class="question_list">
                  <div class="question_list_left">
                      <h2>What is a personal loan?</h2>
                      <p>A personal loan is a flexible form of credit that can be used to pay for almost anything, such as a kitchen renovation or large purchase, or consolidate debts to a single payment. Because personal loans typically have lower interest rates than other forms of credit, it’s a useful financial product to refinance high-interest debt to a lower-cost payment.</p>
                      <p> Personal loans can be secured or unsecured. Secured loans may require collateral, such as your car, but may also have lower APRs. Unsecured loans are backed by only your promise to repay the lender, but may have slightly higher rates. Lender offers will vary based on their requirements, but eligibility is often determined by factors such as your credit score, income and other debts.</p>
                      <p>Types of personal loans:</p>
                      <ul>
                          <li><span>Debt consolidation</span> – If you’re struggling to manage your debt, unable to make on-time, consistent payments or just want to group various accounts.</li>
                          <li><span>Credit card refinancing</span>– Pay off your credit card with a lower-rate personal loan, you could save hundreds or even thousands of dollars in repayment.</li>
                          <li><span>Home improvement</span>– Homeowners have a wide variety of expenses. A personal loan could give you the funding you need in the short-term without harming your finances in the long-term.</li>
                          <li><span>Other large expenses</span> – Personal loans can be used for a variety of expected and unexpected expenses, from wedding planning, moving costs, car repairs, medical bills, and other bigger purchases.</li>
                      </ul>
                  </div>
                  <div class="question_list_right">
                      <div class="accordion">
                          <ul>
                              <li>What is a personal loan?</li>
                              <li>What is a mortgage rate?</li>
                              <li>oday's Current Mortgage Interest Rates</li>
                              <li>Today's Current Mortgage Interest Rates</li>
                              <li>Buying a House in 2022</li>
                              <li>Conclusion</li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
          <div class="answer_container">
              <div class="answer_list">
                  <div class="answer_list_right">
                  <div class="answer">
                      <h2>What is a mortgage rate?</h2>
                      <p>A mortgage rate is the annual interest that a homeowner owes on their total mortgage loan balance. Put simply, it reflects how much the borrower pays to take out their mortgage loan. </p>
                      <p>A mortgage is a loan that is secured by property that is being purchased. In the event the borrower can’t repay the mortgage, the lender can use the underlying property to try and recoup the remaining balance on the mortgage.</p>
                      <p>Buying a home is generally the largest purchase that most people make. Understanding mortgage loans is critical. Getting the right mortgage for your situation can significantly impact your overall financial situation and can help you make the home of your dreams an affordable reality.</p>
                  </div>
                  <div class="answer">
                      <h2>What is a mortgage rate?</h2>
                      <p>A mortgage rate is the annual interest that a homeowner owes on their total mortgage loan balance. Put simply, it reflects how much the borrower pays to take out their mortgage loan. </p>
                      <p>A mortgage is a loan that is secured by property that is being purchased. In the event the borrower can’t repay the mortgage, the lender can use the underlying property to try and recoup the remaining balance on the mortgage.</p>
                      <p>Buying a home is generally the largest purchase that most people make. Understanding mortgage loans is critical. Getting the right mortgage for your situation can significantly impact your overall financial situation and can help you make the home of your dreams an affordable reality.</p>
                  </div>
                  <div class="answer">
                      <h2>What is a mortgage rate?</h2>
                      <p>A mortgage rate is the annual interest that a homeowner owes on their total mortgage loan balance. Put simply, it reflects how much the borrower pays to take out their mortgage loan. </p>
                      <p>A mortgage is a loan that is secured by property that is being purchased. In the event the borrower can’t repay the mortgage, the lender can use the underlying property to try and recoup the remaining balance on the mortgage.</p>
                      <p>Buying a home is generally the largest purchase that most people make. Understanding mortgage loans is critical. Getting the right mortgage for your situation can significantly impact your overall financial situation and can help you make the home of your dreams an affordable reality.</p>
                  </div>
                  <div class="answer">
                      <h2>What is a mortgage rate?</h2>
                      <p>A mortgage rate is the annual interest that a homeowner owes on their total mortgage loan balance. Put simply, it reflects how much the borrower pays to take out their mortgage loan. </p>
                      <p>A mortgage is a loan that is secured by property that is being purchased. In the event the borrower can’t repay the mortgage, the lender can use the underlying property to try and recoup the remaining balance on the mortgage.</p>
                      <p>Buying a home is generally the largest purchase that most people make. Understanding mortgage loans is critical. Getting the right mortgage for your situation can significantly impact your overall financial situation and can help you make the home of your dreams an affordable reality.</p>
                  </div>
                  <div class="answer">
                      <h2>What is a mortgage rate?</h2>
                      <p>A mortgage rate is the annual interest that a homeowner owes on their total mortgage loan balance. Put simply, it reflects how much the borrower pays to take out their mortgage loan. </p>
                      <p>A mortgage is a loan that is secured by property that is being purchased. In the event the borrower can’t repay the mortgage, the lender can use the underlying property to try and recoup the remaining balance on the mortgage.</p>
                      <p>Buying a home is generally the largest purchase that most people make. Understanding mortgage loans is critical. Getting the right mortgage for your situation can significantly impact your overall financial situation and can help you make the home of your dreams an affordable reality.</p>
                  </div>
                  </div>
                  <div class="answer_list_left"></div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "loans",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import '../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav-img {
        width: 100%;
        height: 763px;
        color: #c5c5c5;
        font-size: 18px;
        background: url(/imgs/content_img_personal.png) no-repeat;
        .nav-img-content {
            text-align: center;
            margin-top: 140px;
            .subheading {
                position: relative;
            }
            .subheading:before {
                width: 15px;
                height: 15px;
                content: '';
                background: url(/imgs/content_icon1.png) no-repeat;
                background-size: contain;
                position: absolute;
                left: 810px;
            }
            .head_title {
                color: #fff;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_line {
                margin: 30px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_content {
                width: 61%;
                margin: auto;
            }
            .head_card_list {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin-top: 85px;
                .head_card {
                    margin-right: 55px;
                    .head_card_content {
                        width: 212px;
                        height: 212px;
                        background-color: #fff;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12px;
                        font-size: 24px;
                        color: #222831;
                        img {
                            width: 55px;
                            margin-bottom: 15px;
                        }
                    }
                }
                .head_card:hover {
                    position: relative;
                    bottom: 15px;
                    box-shadow: 0px 15px 20px 5px rgba(0,0,0,0.10); 
                }
            }
        }
    }
    .list_container {
        width: 100%;
        .list_content {
            width: 61%;
            margin: 60px auto 50px;
            .list_head {
                .list_content_head {
                    font-size: 48px;
                    font-weight: 700;
                    color: #222831;
                    margin-bottom: 16px;
                }
                .list_content_text {
                    font-size: 14px;
                    color: #666666;
                    line-height: 21px;
                }
            }
            .list_content_search {
                margin-top: 20px;
                .search {
                    .search_input {
                        width: 880px;
                    }
                }
                .condition {
                    margin-top: 20px;
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10);
                    padding-bottom: 35px;
                    border-radius: 12px;
                    .condition_title {
                        background: #f5f8fb;
                        p {
                            padding: 11px 0 11px 60px;
                            font-weight: 700;
                            font-size: 20px;
                            color: #222831;
                        }
                    }
                    .condition_list_container {
                        .condition_list {
                            padding: 0px 30px;
                            margin-top: 20px;
                            .condition_list_condition {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 30px;
                                span {
                                    font-weight: 600;
                                    color: #0062bd;
                                    line-height: 14px;
                                    font-size: 14px;
                                    img {
                                        margin-left: 2px;
                                    }
                                }
                                .search_option {
                                    display: flex;
                                    div {
                                        padding: 4px 7px;
                                        border-radius: 4px;
                                        font-size: 14px;
                                    }
                                    div:first-child {
                                        background-color: #2ac8a2;
                                        color: #fff;
                                    }
                                    div:not(:first-child) {
                                        margin-left: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .article_container {
        width: 100%;
        .article_list {
            width: 61%;
            margin: 0 auto 80px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_list_left {
                width: 750px;
                box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10);
                border-radius: 12px;
                padding: 0 15px;
                .article_content {
                    .article_content_container {
                        .article {
                            display: flex;
                            justify-content: space-between;
                            color: #666666;
                            padding: 32px 0;
                            border-bottom: 1px solid #c5c5c5;
                            .left {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                .left_top {
                                    margin-bottom: 15px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                }
                            }
                            .center {
                                .center_top {
                                    font-size: 18px;
                                    color: #666666;
                                    span {
                                        font-size: 36px;
                                        font-weight: 700;
                                        color: #0062bd;
                                    }
                                    span:after {
                                        content: 'APR.';
                                        font-size: 14px;
                                        color: #666666;
                                        font-weight: 400;
                                        position: absolute;
                                    }
                                    ul {
                                        margin-top: 16px;
                                        li {
                                            font-size: 11px;
                                            color: #666666;
                                            line-height: 20px;
                                            background: url("/imgs/Snipaste_2022-03-21_18-33-23.png") no-repeat 0 5px;
                                            text-indent: 20px;
                                        }
                                    }
                                }
                            }
                            .right {
                                text-align: center;
                                .button {
                                    padding: 9px 35px;
                                    background-color: #092742;
                                    color: #fff;
                                    margin-bottom: 13px;
                                    font-size: 14px;
                                    font-weight: 700;
                                }
                                .remark {
                                    font-size: 11px;
                                    color: #666666;
                                }
                            }
                        }
                        .article:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
            .article_list_right {
                width: 325px;
                height: 125px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                .article_list_right_top {
                    display: flex;
                    align-items: center;
                    span {
                        font-size: 24px;
                        font-weight: 700;
                        color: #222831;
                        margin-right: 35px;
                    }
                }
                .article_list_right_bottom {
                    background-color: #f5f8fb;
                    height: 73px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    span {
                        color: #666666;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .question_container {
        width: 100%;
        background-color: #f5f8fb;
        .question_list {
            width: 61%;
            margin: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .question_list_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                h2 {
                    font-size: 36px;
                    margin: 80px 0 44px 0;
                    color: #222831;
                }
                p {
                    margin-bottom: 25px;
                }
                ul {
                    li {
                        margin-bottom: 80px;
                        span {
                            font-weight: Bold;
                        }
                    }
                    li:before {
                        content: " ";
                        background-color: #2ac8a2;
                        display: inline-block;
                        font-weight: bold;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }
            }
            .question_list_right {
                width: 325px;
                .accordion {
                    margin-top: 80px;
                    padding: 20px 45px;
                    background-color: #fff;
                    border-radius: 12px;
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    font-size: 18px;
                    ul {
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5 ;
                            margin-bottom: 17px;
                            padding-bottom: 17px;
                        }
                        li:first-child {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    .answer_container {
        width: 100%;
        .answer_list {
            width: 61%;
            margin: 50px auto 210px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .answer_list_right {
                width: 750px;
                .answer {
                    line-height: 36px;
                    margin-top: 50px;
                    padding-bottom: 30px;
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 44px;
                    }
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 23px;
                        text-align: justify;
                        margin-bottom: 30px;
                    }
                }
                .answer:not(:last-child) {
                    border-bottom: 1px solid #c5c5c5;
                }
            }
            .answer_list_left {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav-img {
            height: 980px;
            background-size: cover;
            .nav-img-content {
                margin-top: 60px;
                .subheading:before {
                    left: 512px;
                }
                .head_content {
                    width: 80%;
                    font-size: 20px;
                }
                .head_card_list {
                    flex-wrap: wrap;
                    .head_card {
                        margin: 20px 50px;
                    }
                }
            }
        }
        .list_container {
            .list_content {
                width: 90%;
                .list_content_search {
                    .search {
                        .search_input {
                            width: 600px;
                        }
                    }
                    .condition {
                        .condition_list_container {
                            .condition_list {
                                .condition_list_condition {
                                    flex-wrap: wrap;
                                    span {
                                        font-size: 20px;
                                        margin-bottom: 15px;
                                    }
                                    .search_option {
                                        flex-wrap: wrap;
                                        justify-content: center;
                                        div {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .article_container {
            .article_list {
                width: 90%;
                flex-direction: column-reverse;
                .article_list_left {
                    width: 96%;
                }
                .article_list_right {
                    margin-bottom: 30px;
                }
            }
        }
        .question_container {
            .question_list {
                width: 90%;
                flex-wrap: wrap;
                .question_list_right {
                    display: none;
                }
            }
        }
        .answer_container {
            .answer_list {
                width: 90%;
                flex-wrap: wrap;
            }
        }
    }
    @media (max-width: 760px) {
        .nav-img {
            height: 1670px;
            .nav-img-content {
                .subheading:before {
                    left: 320px;
                }
            }
        }
        .list_container {
            .list_content {
                .list_content_search {
                    .search {
                        .search_input {
                            width: 300px;
                        }
                    }
                    .condition {
                        .condition_title {
                            p {
                                padding: 11px 0 11px 15px;
                            }
                        }
                    }
                }
            }
        }
        .article_container {
            .article_list {
                .article_list_left {
                    width: 90%;
                    .article_content {
                        .article_content_container {
                            .article {
                                flex-direction: column;
                                align-items: center;
                                div {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                .article_list_right {
                    width: 100%;
                }
            }
        }
        .question_container {
            .question_list {
                .question_list_left {
                    width: 100%;
                }
            }
        }
        .answer_container {
            .answer_list {
                .answer_list_right {
                    width: 100%;
                }
            }
        }
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>