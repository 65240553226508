<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Calculator Center</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>This loan calculator will help you determine the monthly payments on a loan. Simply enter the loan amount, term and interest rate in the fields below and click calculate. This calculator can be used for mortgage, auto, or any other fixed loan types.</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Calculator Center</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
                <div class="article_list">
                    <div class="article_list_left">
                        <div class="select_calculator">
                            <el-select v-model="typevalue">
                                <el-option
                                v-for="item in typeoptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="calculator_panel_box" v-bind:class="{'iscalculator': typevalue === 2}">
                            <div class="calculator_panel">
                                <div class="input_title">Initial deposit</div>
                                <div class="amount_input input_bottom">
                                    <el-input v-model="FromLoan.amount" oninput ="value=value.replace(/[^\d.]/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')" @change="_input">
                                        <i slot="prefix" class="iconfont icon-meiyuan4"></i>
                                    </el-input>
                                </div>
                                <div class="input_title">Loan term in years/months</div>
                                <div class="time_input input_bottom">
                                    <div class="time_input_date">
                                        <el-input v-model="FromLoan.time"></el-input>
                                    </div>
                                    <div class="time_input_my">
                                        <el-select v-model="FromLoan.typetime">
                                            <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="input_title">Interest rate per year</div>
                                <div class="interest_rate_input input_bottom">
                                    <el-input v-model="FromLoan.interestrate"  oninput ="value=value.replace(/[^\d.]/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')">
                                        <i slot="suffix" class="iconfont icon-80"></i>
                                    </el-input>
                                    <el-button class="calculation_button" @click="LoanCalculate">Calculate</el-button>
                                </div>
                                
                            </div>
                            <div class="calculator_result">
                                <span class="result_title result_mb">Monthly Payments</span>
                                <h2 class="result_mb">$ {{this.LoanResult.MonthlyPayments}}</h2>
                                <div class="result_total result_total_border result_mb">
                                    <span>Total Principal Paid</span>
                                    <span>${{this.FromLoan.amount}}</span>
                                </div>
                                <div class="result_total result_mb">
                                    <span>Total Interest Paid</span>
                                    <span>${{this.LoanResult.TotalInterestPaid}}</span>
                                </div>
                                <a href="/personal_loans" class="button result_mb">Compare Loan Rates</a>
                                <!-- <span class="more result_mb">Show amortization schedule</span> -->
                            </div>
                        </div>
                        <div class="calculator_panel_box" v-bind:class="{'iscalculator': typevalue === 1}">
                            <div class="calculator_panel">
                                <div class="input_title">Coverage amount</div>
                                <div class="amount_input input_bottom">
                                    <el-select v-model="FromInsurance.amount" @change="GetInsuranceResults()">
                                        <el-option
                                            v-for="item in AmountOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                            >
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="input_title">Policy length</div>
                                <div class="time_input input_bottom">
                                    <div class="time_input_my insurance_select">
                                        <el-select v-model="FromInsurance.selectdate" @change="GetInsuranceResults()">
                                            <el-option
                                            v-for="item in dateoptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </div>
                                <div class="interest_rate_input input_bottom">
                                    <div class="title">
                                        <div>Age</div>
                                        <el-input @change="GetInsuranceResults()" class="input" v-model="FromInsurance.age"  oninput ="value=value.replace(/[^\d.]/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')"></el-input>
                                    </div>
                                    <div class="title">
                                        <div>Gender</div>
                                        <el-select v-model="FromInsurance.gender" @change="GetInsuranceResults()">
                                            <el-option
                                            v-for="item in genderoptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                    <div class="title">
                                        <div>ZIP code</div>
                                        <el-input maxlength="5" @change="GetInsuranceResults()" v-model="FromInsurance.zip"  oninput ="value=value.replace(/[^\d.]/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2')"></el-input>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="calculator_result insurance_result">
                                <span class="insurance_title result_mb">Monthly estimates for</span>
                                <div class="insurance_result_container">
                                    <div class="square_container">
                                        <div class="Best square"></div>
                                        <div class="square_title">Best health</div>
                                        <div class="square_amount">${{this.BestHealth}}</div>
                                    </div>
                                    <div class="square_container">
                                        <div class="Good square"></div>
                                        <div class="square_title">Good health</div>
                                        <div class="square_amount">${{this.GoodHealth}}</div>
                                    </div>
                                    <div class="square_container">
                                        <div class="Average square"></div>
                                        <div class="square_title">Average health</div>
                                        <div class="square_amount">${{this.AverageHealth}}</div>
                                    </div>
                                </div>
                                <el-button class="insurance_button" @click="GetInsuranceResults">Now compare quotes</el-button>
                            </div>
                        </div>
                        <!-- <div class="text">
                            <p>Without taking out loans, many of us would not be able to buy a home, a car or afford a higher education. The fact is, mortgages, auto loans and other types of loans can help us to advance and reach important goals in our lives.</p>
                            <p>The cost of a loan depends on the type of loan, the lender, the market environment and your credit history and income. Borrowers with the best credit profile usually get the best interest rates. Before you shop for a loan, find out your credit score and look at your credit report to make sure it’s accurate. You can get your credit report and credit score for free on Bankrate.</p>                            <p>LendingTree lets borrowers take advantage of the competition between personal loan lenders to access better terms, rates, and loan amounts, even if you have poor credit. It gives customers the ability to compare offers simultaneously, empowering them to find a solution based on their unique circumstances. </p>
                            <p> All loans are either secured or unsecured. A secured loan requires the borrower to put up an asset as collateral to secure the loan for the lender. An auto loan is an example of a secured loan. If you don’t make your car payments, the lender will repossess the car. An unsecured loan requires no collateral. Most personal loans are unsecured.</p>
                            <p> While shopping for any loan, it’s a good idea to use a loan calculator. A calculator can help you narrow your search for a home or car by showing you how much you can afford to pay each month. It can help you compare loan costs and see how differences in interest rates can affect your payments, especially with mortgages.</p>
                            <p> The right loan calculator will show you the total cost of a loan, expressed as the annual percentage rate, or APR. Loan calculators can answer a lot of questions and help you make good financial decisions.</p>
                            <p> Here are some details about the most common types of loans and the loan calculators that can help you in the process.</p>
                            <h2>Mortgage</h2>
                            <p>Bankrate’s mortgage calculator gives you a monthly payment estimate after you input the home price, your down payment, the interest rate and length of the loan term. Use the calculator to price different scenarios. You might discover you need to adjust your down payment to keep your monthly payments affordable. You can also see the loan amortization schedule, or how your debt is reduced over time with monthly principal and interest payments. If you want to pay off a mortgage before the loan term is over, you can use the calculator to figure out how much more you must pay each month to achieve your goal.</p>
                            <p>Other mortgage calculators can answer a variety of questions: What is your DTI, or debt-to-income ratio? That’s a percentage that lenders look at to gauge your debt load. Should you take out a 15-year mortgage or a 30-year? Fixed interest rate or variable?</p>
                            <p> It’s critical to nail down the numbers before buying a home because a mortgage is a secured loan that is secured by the home itself. If you fail to make the monthly payments, the lender can foreclose and take your home.</p>
                            <h2>Home equity loan</h2>
                            <p>Home equity loans, sometimes called second mortgages, are for homeowners who want to borrow some of their equity to pay for home improvements, a dream vacation, college tuition or some other expense. A home equity loan is a one-time, lump-sum loan, repaid at a fixed rate, usually over five to 20 years. Bankrate’s home equity calculator helps you determine how much you might be able to borrow based on your credit score and your LTV, or loan-to-value ratio, which is the difference between what your home is worth and how much you owe on it.</p>
                            <h2>Home equity line of credit (HELOC)</h2>
                            <p>A HELOC is a home equity loan that works more like a credit card. You are given a line of credit that can be reused as you repay the loan. The interest rate is usually variable and tied to an index such as the prime rate. Our home equity calculators can answer a variety of questions, such as: Should you borrow from home equity? If so, how much could you borrow? Are you better off taking out a lump-sum equity loan or a HELOC? How long will it take to repay the loan?</p>
                            <h2>Conclusion</h2>
                            <p>Whether it’s protecting yourself against other, more expensive forms of debt or boosting your returns on an investment, personal loans are financially constructive tools when you use them right.</p>
                            <p> Make sure to use a personal loan calculator before moving forward with an offer. The personal loan calculator will help you evaluate which offer is the most cost-effective, as well as help you plan for repayment.</p>    
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import jsonData from "../static/quotes.json";
export default {
    name: "calculator",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            input3: '9',
            value: 1,
            typevalue: 1,
            options: [{value: 1, label: 'Months'}, {value: 2, label: 'Years'}],
            typeoptions: [{value: 1, label: 'Loan Calculator'}, {value: 2, label: 'Insurance Calculator'}],
            genderoptions: [{value: 'M', label: 'Male'}, {value: 'F', label: 'Female'}],
            dateoptions: [{value: 10, label: '10yrs'}, {value: 15, label: '15yrs'}, {value: 20, label: '20yrs'}, {value: 25, label: '25yrs'}, {value: 30, label: '30yrs'}, {value: 35, label: '35yrs'}, {value: 40, label: '40yrs'}, ],
            AmountOptions:[{value: 100000, label: '100K'}, {value: 125000, label: '125K'}, {value: 150000, label: '150K'}, {value: 175000, label: '175K'}, {value: 200000, label: '200K'}, {value: 225000, label: '225K'}, {value: 250000, label: '250K'}, {value: 300000, label: '300K'}, {value: 350000, label: '350K'}, {value: 400000, label: '400K'}, {value: 450000, label: '400K'}, {value: 500000, label: '500K'}, {value: 550000, label: '550K'}, {value: 600000, label: '600K'}, {value: 650000, label: '650K'}, {value: 700000, label: '700K'}, {value: 750000, label: '750K'}, {value: 800000, label: '800K'}, {value: 900000, label: '900K'}, {value: 1000000, label: '1M'}, {value: 1250000, label: '1.25M'}, {value: 1500000, label: '1.5M'}, {value: 1750000, label: '1.75M'}, {value: 2000000, label: '2M'}, {value: 2250000, label: '2.25M'}, {value: 2500000, label: '2.5M'}, {value: 2750000, label: '2.75M'}, {value: 3000000, label: '3M'}, {value: 4000000, label: '4M'}, {value: 5000000, label: '5M'}, {value: 6000000, label: '6M'}, {value: 7000000, label: '7M'}, {value: 8000000, label: '8M'}, {value: 9000000, label: '9M'}, {value: 10000000, label: '10M'}, ],
            FromInsurance: {
                selectdate: 10,
                amount: 500000,
                gender: 'M',
                age: '',
                zip: ''
            },
            FromLoan: {
                amount: '5,000',
                time: 60,
                interestrate: 4.5,
                typetime: 1
            },
            LoanResult: {
                MonthlyPayments: 93.22,
                TotalPrincipalPaid: '5,000',
                TotalInterestPaid: 592.91
            },
            BestHealth: '- -',
            GoodHealth: '- -',
            AverageHealth: '- -',
            is: true,
            commonJson: {},
            guaranteeamount: 100
        }
    },
    mounted() {
        this.commonJson = jsonData
        console.log(this.commonJson);
    },
    methods: {
        benxi(num, year, lilv, ismonth) {
            //每月月供额=〔贷款本金×月利率×(1＋月利率)＾还款月数〕÷〔(1＋月利率)＾还款月数-1〕
            if (ismonth === 2) {
                var month = parseInt(year) * 12;
            } else {
                var month = year;
            }
            var monthlilv = parseFloat(lilv) / 12,
                dknum = parseFloat(num);
                
            //每月月供
            var yuegong = (dknum * monthlilv * Math.pow((1 + monthlilv), month)) / (Math.pow((1 + monthlilv), month) - 1);
            //总利息=还款月数×每月月供额-贷款本金
            var totalLixi = month * yuegong - dknum;
            //还款总额 总利息+贷款本金
            var totalPrice = totalLixi + dknum,
                leftFund = totalLixi + dknum;

            //循环月份
            var monthdataArray = [],
                nowmonth = new Date().getMonth(),
                realmonth = 0;

            for (var i = 1; i <= month; i++) {
                realmonth = nowmonth + i;
                var yearlist = Math.floor(i / 12);

                realmonth = realmonth - 12 * yearlist;

                if (realmonth > 12) {
                    realmonth = realmonth - 12
                }
                //console.log(realmonth)
                //每月应还利息=贷款本金×月利率×〔(1+月利率)^还款月数-(1+月利率)^(还款月序号-1)〕÷〔(1+月利率)^还款月数-1〕
                var yuelixi = dknum * monthlilv * (Math.pow((1 + monthlilv), month) - Math.pow((1 + monthlilv), i - 1)) / (Math.pow((1 + monthlilv), month) - 1);
                //每月应还本金=贷款本金×月利率×(1+月利率)^(还款月序号-1)÷〔(1+月利率)^还款月数-1〕
                var yuebenjin = dknum * monthlilv * Math.pow((1 + monthlilv), i - 1) / (Math.pow((1 + monthlilv), month) - 1);
                leftFund = leftFund - (yuelixi + yuebenjin);
                if (leftFund < 0) {
                    leftFund = 0
                }
                monthdataArray[i - 1] = {
                    monthName: realmonth + "月",
                    yuelixi: yuelixi,
                    yuebenjin: yuebenjin,
                    //剩余还款
                    leftFund: leftFund
                }
            }
            return {
                yuegong: yuegong,
                totalLixi: totalLixi,
                totalPrice: totalPrice,
                monthdataArray: monthdataArray,
                totalDknum: num,
                year: year
            };
        },
        // 金额格式化
        _input(e) {
            if (this.FromLoan.amount > 99999999) {
                return this.$message.error("Please enter correct amount!")
            }
            this.FromLoan.amount = this.comdify(e)
        },
        comdify(n) {
            if(!n) return n;
            let str = n.split('.');
            let re = /\d{1,3}(?=(\d{3})+$)/g;
            let n1 = str[0].replace(re, "$&,");
            return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}`;
        },
        //去除千分位中的‘，'
        delcommafy(num){
            if(!num) return num;
            num = num.toString();
            num = num.replace(/,/gi, '');
            return num;
        },
        LoanCalculate() {
            if (this.FromLoan.amount > 99999999) {
                return this.$message.error("Please enter correct amount!")
            }
            const i = this.benxi(this.delcommafy(this.FromLoan.amount), this.FromLoan.time, this.FromLoan.interestrate / 100, this.FromLoan.typetime)
            console.log(i);
            console.log(this.comdify(i.yuegong + ''));
            this.LoanResult.MonthlyPayments = this.comdify((i.yuegong.toFixed(2)) + '')
            this.LoanResult.TotalInterestPaid = this.comdify((i.totalLixi.toFixed(2)) + '')
        },
        // 获取保险计算结果
        GetInsuranceResults() {
            for (const i in this.FromInsurance) {
                if (this.FromInsurance.age < 18 || this.FromInsurance.age > 80 ) {
                    return this.$message.error("Please enter the correct age!")
                }
                if (this.FromInsurance[i] === '') {
                    return
                }
            }
            const result = this.commonJson[this.FromInsurance.amount][this.FromInsurance.age][this.FromInsurance.selectdate][this.FromInsurance.gender]
            this.BestHealth = result.b
            this.GoodHealth = result.g
            this.AverageHealth = result.a
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import '../assets/scss/config.scss';
@import '../assets/scss/textnavigation.scss';
@import '../assets/scss/navimgnotcrad.scss';
@import '../assets/iconfont/iconfont.css';

.container {
    @include flex();
    flex-wrap: wrap;
    .article_container {
        .article_list {
            .article_list_left {
                .select_calculator {
                    width: 450px;
                    margin-bottom: 25px;
                }
                .calculator_panel_box {
                    display: flex;
                    justify-content: space-between;
                    background-color: #f5f8fb;
                    margin-bottom: 60px;
                    .calculator_panel {
                        width: 45%;
                        padding: 50px 30px 20px;
                        .input_title {
                            font-size: 13px;
                            color: #222831;
                            line-height: 13px;
                            margin-bottom: 10px;
                        }
                        .input_bottom {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 20px;
                            .bottom_slider {
                                width: 60%;
                            }
                            .guarantee_amount {
                                font-size: 24px;
                                color: #2b2727;
                                font-weight: bold;
                            }
                            .insurance_select {
                                width: 100%;
                            }
                            .calculation_button {
                                margin-left: 25px;
                                padding: 10px 25px !important;
                            }
                            .title {
                                div {
                                    margin-bottom: 10px;
                                }
                            }
                            .title:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                        .time_input {
                            display: flex;
                        }
                    }
                    .calculator_result {
                        width: 45%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        padding: 50px 0;
                        font-size: 13px;
                        line-height: 13px;
                        .result_title {
                            color: #222831;
                        }
                        .insurance_title {
                            color: #222831;
                            font-size: 13px;
                            font-weight: 700;
                        }
                        h2 {
                            font-size: 24px;
                            font-weight: 700;
                            color: #222831;
                        }
                        .result_total {
                            width: 100%;
                            display: flex;
                            justify-content: space-around;
                        }
                        .result_total_border {
                            padding-bottom: 14px;
                            border-bottom: 1px solid #c5c5c5;
                        }
                        .button {
                            padding: 13px 18px !important;
                            background: #2ac8a2;
                            border-radius: 50px;
                            color: #fff;
                            font-size: 18px;
                            font-weight: 700;
                        }
                        .more {
                            font-size: 13px;
                            color: #2684ff;
                        }
                    }
                    .insurance_result {
                        align-items: flex-start;
                        .insurance_result_container {
                            width: 80%;
                            .square_container {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                width: 100%;
                                border-bottom: 1px solid #e9ebed;
                                padding: 19px 0;
                                .square {
                                    width: 38px;
                                    height: 24px;
                                }
                                .Best {
                                    background: #00995e;
                                }
                                .Good {
                                    background: #80bab0;
                                }
                                .Average {
                                    background: #a8c4d0;
                                }
                                .square_title {
                                    flex: 1;
                                    padding-left: 15px;
                                }
                                .square_amount {
                                    font-size: 24px;
                                    font-weight: 700;
                                }
                            }
                        }
                        .insurance_button {
                            padding: 13px 25px !important;
                        }
                    }
                }
            }
        }
    }

    .iscalculator {
        display: none !important;
    }
    
    @media (max-width: 1280px) {
        .article_container {
            .article_list {
                width: 90%;
                .article_list_left {
                    .calculator_panel_box {
                        .calculator_result {
                            h2 {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }

    }
    @media (max-width: 760px) {
        .article_container {
            margin-top: 35px;
            .article_list {
                .article_list_left {
                    .select_calculator {
                        width: 100%;
                    }
                    .calculator_panel_box {
                        margin-top: 35px;
                        flex-direction: column;
                        align-items: center;
                        .calculator_panel {
                            width: 85%;
                        }
                        .calculator_result {
                            width: 85%;
                            .result_mb {
                                margin-bottom: 30px;
                            }
                        }
                        .insurance_result {
                            .insurance_button {
                                margin-top: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style scoped>
    .amount_input >>> .el-input__inner {
        border-radius: 12px;
        height: 77px;
        font-size: 36px;
        font-weight: 700;
        color: #222831;
    }
    .amount_input >>> .el-input__prefix {
        display: flex;
        align-items: center;
    }
    .time_input_date >>> .el-input__inner,
    .time_input_my >>> .el-input__inner {
        height: 44px;
        font-size: 14px;
        font-weight: bold;
        color: #222831;
    }
    .time_input_date >>> .el-input__inner {
        border-radius: 12px 0 0 12px;
    }
    .time_input_my >>> .el-input__inner {
        text-align: center;
        border-left: none;
        border-radius: 0 12px 12px 0;
    }
    .interest_rate_input >>> .el-input__suffix {
        display: flex;
        align-items: center;
    }
    .interest_rate_input >>> .el-input__inner {
        font-size: 14px;
        font-weight: 700;
        color: #222831;
        line-height: 14px;
        border-radius: 12px;
    }
    .select_calculator >>> .el-input__inner {
        border: 0;
        font-size: 36px;
        font-weight: 700;
        color: #222831;
    }
    .select_calculator >>> .el-select__caret {
        font-size: 36px !important;
        color: #222831 !important;
    }
    .insurance_select >>> .el-input__inner {
        border-left: 1px solid #DCDFE6;
        border-radius: 12px;
    }
    @media (max-width: 760px) {
        .select_calculator >>> .el-input__inner {
            font-size: 20px;
        }
        .select_calculator >>> .el-select__caret {
            font-size: 20px !important;
            color: #222831 !important;
        }
    }
</style>