import { render, staticRenderFns } from "./terms_of_use.vue?vue&type=template&id=4900c760&scoped=true&"
import script from "./terms_of_use.vue?vue&type=script&lang=js&"
export * from "./terms_of_use.vue?vue&type=script&lang=js&"
import style0 from "./terms_of_use.vue?vue&type=style&index=0&id=4900c760&lang=scss&scoped=true&"
import style1 from "./terms_of_use.vue?vue&type=style&index=1&id=4900c760&lang=scss&scoped=true&"
import style2 from "./terms_of_use.vue?vue&type=style&index=2&id=4900c760&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4900c760",
  null
  
)

export default component.exports