import { render, staticRenderFns } from "./personal_loans_reviews.vue?vue&type=template&id=fd73c370&scoped=true&"
import script from "./personal_loans_reviews.vue?vue&type=script&lang=js&"
export * from "./personal_loans_reviews.vue?vue&type=script&lang=js&"
import style0 from "./personal_loans_reviews.vue?vue&type=style&index=0&id=fd73c370&lang=scss&scoped=true&"
import style1 from "./personal_loans_reviews.vue?vue&type=style&index=1&id=fd73c370&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd73c370",
  null
  
)

export default component.exports