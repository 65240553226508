<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Auto Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Get auto insurance quotes from top insurance companies for the same level of coverage so you can evaluate businesses and policies exactly. Auto insurance is overcomplicated, but fortunately, we have filtered the ones which save you hundreds of dollars.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/auto_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Auto Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/auto_insurance_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Auto Insurance Reviews</span>
                          </router-link>
                      </div>
                      <!-- <div class="head_card">
                          <router-link to="/auto_insurance_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Auto Insurance Guide</span>
                          </router-link>
                      </div> -->
                      <div class="head_card">
                          <router-link to="/calculator" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Auto Insurance Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Auto Insurance</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.pic_img" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="item.star"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.name}}</span>
                                          <ul>
                                              <li v-for="(subitem, index) of item.features" :key="index">{{ subitem }}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What is car insurance?</h2>
                          <p>Car insurance protects you financially and covers damage to your vehicle if you're at fault for someone else's injuries or damages. If you or your passengers are injured in an accident or are hit by an uninsured or underinsured motorist, your auto insurance may cover your medical expenses. Your policy protects you up to specific restrictions that you and your insurer have agreed upon.</p>
                          <h2 class="anchor_h2">Is it necessary to get your car insurance?</h2>
                          <p>Auto insurance is necessary because it provides financial protection in the event that you are involved in an automobile accident and cause damage to or injury to another person's vehicle. Car insurance can also cover damage caused by an accident or another covered risk, such as falling objects, fire, or theft.</p>
                          <p>You may be breaking the law if you don't have car insurance for yourself and your vehicle, depending on where you reside. Fines and license suspension may be imposed if you drive without insurance. The aim of obtaining auto insurance is to be able to compensate others for damage you do, and to avoid having to pay out of pocket for expenses incurred as a result of a car accident or another type of collision, such as crashing into a pole.</p>
                          <h2 class="anchor_h2">How does car insurance work for us?</h2>
                          <p>After realizing the importance of car insurance, here comes to the frequently inquired question, “How does car insurance work for us?” </p>
                          <p>It's critical to understand how auto insurance works so that you're protected every time you go behind the wheel. To ensure that you have the correct insurance coverage for your car, start by locating a reputable insurance company. After you make a car insurance claim, your car insurance may be able to assist you in paying for injuries or property damage if you are involved in a car accident.</p>
                          <p>The insurance provider promises to pay your damages as stipulated in your policy in exchange for a premium. Individual policies are priced to allow you to tailor coverage amounts to your specific needs and budget. The policy period is usually six or twelve months, and it is renewable. When it's time to renew a policy and pay a new premium, an insurer will contact the customer.</p>
                          <p>Whether or not they need a minimum level of auto insurance, nearly every state requires vehicle owners to carry bodily injury liability insurance, which covers the costs of injuries or death caused by you or another motorist while driving your automobile. They perhaps require property damage liability as well, which compensates people for harm caused to another vehicle or property by you or another driver driving your vehicle.</p>
                          <h2 class="anchor_h2">What does auto insurance cover?</h2>
                          <p class="text_bold">Collision coverage </p>
                          <p>Damage to your car after a car accident, no matter who was at fault</p>
                          <p class="text_bold">Comprehensive coverage</p>
                          <p>A policy that helps pay for the replacement or repair of your car if it is stolen or damaged in a non-collision occurrence, sometimes known as "other than collision," typically covers damage caused by fire, vandalism, or falling objects (like a tree or hail).</p>
                          <p class="text_bold">Personal Injury Protection/Medical payments coverage </p>
                          <p>the cost of medical treatment, rehabilitation, and, in some cases, lost wages and burial costs</p>
                          <p class="text_bold">Bodily injury liability</p>
                          <p>Damage to your car after a car accident, no matter who was at fault</p>
                          <h2 class="anchor_h2">How much does car insurance cost on average?</h2>
                          <p>“How much do I need to pay for auto insurance?” This is another point which nearly everyone cares about. Age, gender, years of driving experience, accident and moving violation history, and other characteristics all influence auto insurance costs. Most states, once again, need a certain amount of auto insurance. The state-mandated minimum varies, but many people get additional coverage to be safe.</p>
                          <p>Furthermore, if you're financing a car, the lender may require you to have specific forms of auto insurance. For example, if you're buying a high-priced automobile that will likely depreciate rapidly once you drive it off the lot, gap insurance may be required. If you're in an accident, gap insurance can assist pay off the difference between the vehicle's worth and the amount you still owe on it.</p>
                          <p>It is found that auto insurance premiums for male drivers are usually higher than for female drivers. Men paid an average of 7.8% more for their auto insurance plans than women in their 20s, indicating the greatest cost disparity between genders. As drivers get older, the cost gap narrows, and the data revealed that women in their 50s and 60s pay a few dollars more in premiums than their male counterparts.</p>
                          <table border="1" rules="all" width="100%">
                                <tr>
                                    <th>Driver’s Age</th>
                                    <th>Average Cost for men</th>
                                    <th>Average Cost for women</th>
                                </tr>
                                <tr>
                                    <td>20’s</td>
                                    <td>$2,247</td>
                                    <td>$2,083</td>
                                </tr>
                                <tr>
                                    <td>30’s</td>
                                    <td>$1,692</td>
                                    <td>$1,646</td>
                                </tr>
                                <tr>
                                    <td>40’s</td>
                                    <td>$1,596</td>
                                    <td>$1,576</td>
                                </tr>
                                <tr>
                                    <td>50’s</td>
                                    <td>$1,684</td>
                                    <td>$1,692</td>
                                </tr>
                                <tr>
                                    <td>60’s</td>
                                    <td>$1,710</td>
                                    <td>$1,748</td>
                                </tr>
                            </table>
                            <h2 class="anchor_h2">What does car insurance not cover?</h2>
                            <p>Wear and tear, persons who regularly use your car but aren't mentioned on your policy, and driving on a ridesharing platform aren't covered by a standard auto insurance policy. However, you may sometimes supplement your auto insurance coverage by purchasing a supplemental policy or warranty. You can, for example, add rideshare coverage to your Progressive vehicle insurance policy.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is car insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Is it necessary to get your car insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">How does car insurance work for us?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What does auto insurance cover?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">How much does car insurance cost on average?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">What does car insurance not cover?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { GetOffers } from '../../api/cindex';
import { imgsapiUrl } from '../../config/env';
export default {
    name: "life_insurance",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    created() {
        this.GetOffersList();
    },
    methods: {
        // 获取offer数据
        async GetOffersList() {
            const res = await GetOffers( { category_id: '158', page: '1', page_size: '4'  } )
            res.data.forEach(i => {
                i.pic_img = imgsapiUrl + i.img.split('.')[0] + '/' + i.img
                i.star = 5
            })
            this.offers_list = res.data
        },
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
    .text_bold {
        font-weight: bold;
    }
</style>

<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>