<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">BetterLoansMutual</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Loan Pioneer</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Loan Pioneer</h1>
                          <img src="../../../../public/imgs/reviews_list/better_loans_mutual_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is loanPioneer.com?</h2>
                          <p>Finding a personal loan is easy with the help of LoanPioneer.com. it assists clients in obtaining up to $5,000 in loans through our network of lenders.</p>
                          <p>LoanPioneer provide free connections between customers and respectable lenders for anyone in need of quick, hassle-free financial assistance. A few times the following business day, you can receive your money swiftly!</p>
                          <h2 class="anchor_h2">Loan requirement</h2>
                          <ul class="text1">
                            <li>at least 18 years old </li>
                            <li>valid social security number citizen or permanent resident of the United States </li>
                            <li>Having a job, being self-employed, or getting another monthly paycheck </li>
                            <li>Most lenders expect you to put your funds into a legitimate checking account. </li>
                            <li>Even if you fulfill these conditions, you can still not be accepted.</li>
                          </ul>
                          <h2 class="anchor_h2">APR discloure</h2>
                          <p>LoanPioneer is not a lender and therefore does not have control over the APR you will be offered. We display a Representative APR based on data from multiple lenders. The APR range is from 5.99% to 35.99% Maximum APR.</p>
                          <p>If you are not eligible for a standard loan product (5.99% to 35.99% APR), you may be offered an alternative product to consider based on your specific circumstances.</p>
                          <h2 class="anchor_h2">Why Choose LoanPioneer.com?</h2>
                          <ul class="text3">
                            <li>Safe & secure online form</li>
                            <li>Fast approval & quick cash</li>
                            <li>All credit types may apply</li>
                            <li>Loans for any purpose</li>
                          </ul>
                          <h2 class="anchor_h2">What Services Do They Provide? </h2>
                          <p>The simple application process, which allows you to identify eligible lenders in as little as a few minutes; Personal loans in the amount of $100 to $5,000 are available; the service is free. </p>
                          <p>Overall, they enable you to locate a lender and be linked to their website. You'll find out the specific conditions later. </p>
                          <h2 class="anchor_h2">What you need to know before application?</h2>
                          <p> Not all states have access to this service. You will not be linked to a lender or lending partner if you seek to connect with one in a state where such loans are outlawed, or in a place where LoanPioneer.com does not have an available lender or lending partner. You should read and understand the conditions of any loan given by any lender or lending partner, and you should refuse any loan offer that you cannot afford to repay or that has terms that you do not agree with.</p>
                          <p>You authorize LoanPioneer.com and its partners to conduct a credit check, which may include verifying your social security number, driver license number, or other identification, as well as a review of your creditworthiness, and to continue to monitor your credit status through additional credit checks or other methods, by submitting your information via this website. Credit checks are often conducted by one of the main credit agencies, such as Experian, Equifax, or TransUnion, but alternative credit bureaus, such as Teletrack, DP Bureau, or others, may also be used. You also provide LoanPioneer.com permission to share your personal data and credit history with a network of approved lenders and lending partners.</p>
                          <h2 class="anchor_h2">Disclosure of Terms by the lender or lending partner. </h2>
                          <p>All fees and rate information pertaining to the loan being offered will be provided by the lenders and lending partners with whom you are connected, including any potential late-payment fees and the rules under which you may be allowed (if permitted by applicable law) to refinance, renew, or rollover your loan. The lender or lending partner determines loan fees and interest rates exclusively based on the lender's or lending partner's internal rules, underwriting criteria, and applicable law.The loan terms supplied by a lender and lending partner are unknown to LoanPioneer.com, and it has no influence over them. You should read and understand the conditions of any loan given by any lenders or lending partners, and you should refuse any loan offer that you cannot afford to repay or that has terms that you do not agree with.</p>
                          <h2 class="anchor_h2">Late payments have a negative impact on your credit score. </h2>
                          <p>Please keep in mind that skipping or making a late payment might have a negative influence on your credit score. Make sure you only accept loan terms that you can afford to repay to protect yourself and your credit history. If you are unable to make a payment on time, you should contact your lenders and lending partners as soon as possible to discuss your options for dealing with late payments.</p>
                          <h2 class="anchor_h2">Pros and Cons</h2>
                          <p>Pros</p>
                          <ul class="text1">
                            <li>Allows for the discovery of the best circumstances in a matter of minutes; </li>
                            <li>Work with customers who have a variety of credit scores;</li>
                            <li>Provide an easy-to-use website; </li>
                            <li>Customers are not charged any fees for their services.</li>
                          </ul>
                          <p>Cons</p>
                          <ul class="text1">
                            <li>Their services are not available in every state in the United States; </li>
                            <li>There are no options for getting in touch.</li>
                          </ul>
                          <p>Summary</p>
                          <p>In general, this is one of many services available to help you borrow money. </p>
                          <p>They do their job by giving you basic information, assisting you in finding lenders, and referring you to their website. </p>
                          <p>The remaining tasks are solely between you and a lender. There isn't a lot of information about them on the web, and there aren't any customer reviews. </p>
                          <p>If you prefer to keep things easy, LoanPioneer.com will help you find a suitable lender in minutes.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is loanPioneer.com?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Loan requirement</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">APR discloure</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Why Choose LoanPioneer.com?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">What Services Do They Provide?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">What you need to know before application?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">Disclosure of Terms by the lender or lending partner. </div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)">Late payments have a negative impact on your credit score. </div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(8)">Pros and Cons</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>