<template>
  <div class="footer">
    <div class="footer-container">
    <!-- <div class="footer-search">
      <div class="search-top">
        <div class="search-title">GET IN TOUCH</div>
        <div class="search-btn pc_button" @click="onSubmit">Send</div>
      </div>
      <div class="search-bottom">
        <div class="bottom-left">
          <input v-model="form.fullname" class="name-input" type="text" placeholder="Name">
          <input v-model="form.email" class="email-input" type="text" placeholder="E-mail">
        </div>
        <div class="bottom-right">
          <textarea v-model="form.message" class="message-input" type="text" placeholder="Your message"></textarea>
        </div>
      </div>
      <div class="search-top">
        <div class="search-btn m_button" @click="onSubmit">Send</div>
      </div>
    </div> -->
    <div class="footer-logo">
      <img src="/imgs/logo.png" alt="">
      <div class="footer-content">
        <el-input type="textarea" :rows="7" v-model="textarea1" :disabled="true"></el-input>
      </div>
    </div>
    <div class="footer-link">
      <div class="title">QUICK LINKS</div>
      <div class="link-list">
        <ul style="flex: 1;">
          <li><a href="/California_privacy_policy">California privacy policy</a></li>
          <!-- <li><a href="/how_we_rate">How We Rate</a></li> -->
          <li><a href="/privacy_policy">Privacy preferences</a></li>
          <li><a href="/Personal_information">Do not sell or share my personal information</a></li>
        </ul>
        <div class="line"></div>
        <ul>
          <li><a href="/privacy_policy">Privacy Policy</a> <br /></li>
          <li><a href="/terms_of_use">Terms of Use</a></li>
          <li><a href="/FAQ">Security FAQs</a></li>
          <!-- <li><a href="/blog_list">Reading Center</a></li> -->
        </ul>
        <!-- <div class="left-link">
          <a href="/how_we_rate">How We Rate</a>
          <a href="/privacy_rights_policy">Privacy preferences</a>
          <a href="/terms_of_use">Terms of Use</a>
        </div>
        <div class="line"></div>
        <div class="right-link">
          <a href="/ccpa">California privacy policy</a>
          <a href="/privacy_policy">Privacy Policy</a> <br />
          <a href="/blog_list">Reading Center</a>
        </div> -->
      </div>
    </div>
    </div>
    <div style="text-align: center;margin-top: 15px;">
      © 2023 TopFinanceDirectory, Inc.All Rights Reserved.
    </div>
    <div class="th-back-top" v-show="flag" @click="clickHidden">
        <span class="bg-back2top2"></span>
    </div>
  </div>
</template>

<script>
import { EmailSystem } from '../api/cindex';
export default {
  name: "nav-footer",
  data() {
    return {
      form: {
        fullname: '',
        email: '',
        message: ''
      },
      textarea1: 'Disclaimer: TopFinanceDirectory strives to keep its information accurate and up to date. This information may be different than what you see when you visit a financial institution, service provider or specific product’s site. All financial products, shopping products and services are presented without warranty. When evaluating offers, please review the financial institution’s Terms and Conditions. Pre-qualified offers are not binding. If you find discrepancies with your credit score or information from your credit report, please contact TransUnion® directly.',
      flag: false
    }
  },
  created() {
    window.addEventListener('scroll', this.windowScrollListener);
        //获取页面可视化高度

      //  window.addEventListener("scroll",()=>{
      //   //  console.log(document.documentElement.scrollTop);  
      //   //获取页面滚动的高度
      //      let scrollTop = document.documentElement.scrollTop;
      //      if(scrollTop > 300){
      //          this.flag = true;
      //      }else{
      //          this.flag = false;
      //      }

      //  })
  },
  destroyed() {
    window.removeEventListener('scroll', this.windowScrollListener)
  },
  methods: {
      async onSubmit() {
        var pass = false
        const chexkemail = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
        console.log();
        for (const i in this.form) {
          if (this.form[i] === '' || !chexkemail.test(this.form.email)) {
            this.$message.warning('Please fill in correct information!')
            return
          } else {
            pass = true
          }
        }
        if (pass) {
            const res = await EmailSystem(this.form)
            if (res.statu_code === 200) {
                this.$message.success('The submission was successful!');
                for (const i in this.form) {
                  this.form[i] = ''
                }
            } else {
                this.$message.error('Submit failed!');
            }
        }
      },
       clickHidden(){
         if (document.body.scrollTop) {
           document.body.scrollTop = 0;
         } else {
           document.documentElement.scrollTop = 0;
         }
       },
       //监听窗口滚动
      windowScrollListener() {
        //获取操作元素最顶端到页面顶端的垂直距离
        var scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollTop >= 300) {
          this.flag = true; //大于480时显示元素
        }
        if (scrollTop < 300) {
          this.flag = false; //小于480时隐藏元素
        }
      }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import '../assets/scss/config.scss';

.footer {
  //border-top:4px solid $colorA;
  background-color: #092742;
  font-size: 16px;
  color: $colorD;
  padding-bottom: 35px;

  .footer-container {
    width: 100%;
    position: relative;
    margin: auto auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: flex-start
  }

  .footer-search {
    position: absolute;
    top: -67px;
    width: 1180px;
    height: 196px;
    background: #2AC8A2;
    border-radius: 0 100px 0 100px;

    .search-top {
      display: flex;
      margin-top: 20px;
      margin-left: 95px;
      margin-right: 95px;
      justify-content: space-between;
      .m_button {
        display: none;
      }
      .search-title {
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
      }

      .search-btn {
        width: 117px;
        height: 32px;
        text-align: center;
        background: #092742;
        border-radius: 30px 30px 30px 30px;

        font-size: 18px;
        line-height: 32px;
        font-weight: bold;
        color: #FFFFFF;
      }
      .search-btn:hover {
        cursor: default;
      }
    }

    .search-bottom {
      display: flex;
      margin-top: 13px;
      margin-left: 95px;
      margin-right: 95px;
      justify-content: space-between;

      input {
        border: none;
        width: 266px;
        padding-left: 22px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 12px 12px 12px 12px;
        font-size: 13px;
        font-weight: 400;
        color: #AFAFAF;
      }

      .bottom-left {
        display: flex;
        flex-direction: column;
        input::placeholder{
          color: #afafaf;
        }
        .name-input {
          margin-bottom: 13px;
        }
      }

      .bottom-right {
        .message-input {
          width: 642px;
          height: 85px;
          background: #FFFFFF;
          border-radius: 12px;
          text-align: left;
          padding: 15px 0 0 15px;
          resize:none;
          border: none;
        }
        .message-input::placeholder{
          color: #afafaf;
        }
      }
    }
  }

  .footer-logo {
    padding-top: 64px;
    margin-right: 135px;

    img {
      margin-bottom: 25px;
    }

    .footer-content {
      width: 521px;
      height: 171px;
      font-weight: 400;
      color: #c4c4c4;
      line-height: 20px;
      text-align: justify;
    }
  }

  .footer-link {
    padding-top: 64px;

    .title {
      font-size: 24px;
      font-weight: bold;
      color: #FFFFFF;
      margin-bottom: 41px;
    }

    .link-list {
      width: 500px;
      display: flex;
      .left-link {
        width: 45%;
      }
      .right-link {
        width: 45%;
      }
      a {
        font-size: 18px;
        font-weight: 400;
        color: #2684FF;
        display: inline-block;
        margin-bottom: 25px;
        margin-right: 11px;
      }

      .line {
        height: 135px;
        border: 1px solid #AFAFAF;
        margin-left: 56px;
        margin-right: 56px;
      }
    }
  }
.th-back-top{
    position:fixed;
    right:15px;
    bottom:104px;
    height: 39px;
    width: 80px;
    z-index:99;
    opacity:0.8;
    text-align:right;
}
.bg-back2top2 {
    display: inline-block;
    background: url(/imgs/home_content4_icon_top.png) no-repeat 0 0;
    -webkit-background-size: 100%;
    background-size: 100%;
    width: 57px;
    height: 57px;
}
  @media (max-width: 1199px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
      .footer-search {
        width: 95%;
        height: 319px;
        left: 50%;
        transform: translateX(-50%);
        .search-top {
          justify-content: space-around;
        }
        .search-bottom { 
          flex-direction: column;
          .bottom-left {
            margin-bottom: 15px;
            input {
              width: 100%;
              padding-left: 5px;
            }
          }
          .bottom-right {
            .message-input {
              width: 100%;
              padding: 15px 0 0 2px;
            }
          }
        }
      }
      .footer-logo {
        width: 100%;
        padding-top: 100px;
        margin: 0 15px 50px;
        img {
          padding-left: 35px;
        }
        .footer-content {
          width: 100%;
        }
      }
      .footer-link {
        width: 100%;
        padding-top: 0;
        margin: 0 15px 0;
        .link-list {
          padding-left: 55px;
        }
        .title {
          padding-left: 35px;
        }
      }
    }
  }
  @media (max-width: 760px) {
    .footer-container {
      flex-direction: column;
      .footer-search {
        top:-180px;
        width: 360px;
        height: 335px;
        .search-top {
          margin: 13px 60px;
          align-items: center;
          .pc_button {
            display: none;
          }
          .m_button {
            display: block;
            width: 100%;
          }
          .search-title {
            font-size: 18px;
          }
        }
        .search-bottom {
          flex-direction: column;
          margin: 13px 60px;
          .bottom-left {
            margin-bottom: 15px;
          }
        }
      }
      .footer-logo {
        width: 360px;
        margin: 0 auto 25px;
        padding-top: 64px;
        img {
          padding-left: 20px;
        }
        .footer-content {
          width: 335px;
          padding: 0 10px 0;
        }
      }
      .footer-link {
        margin: 0;
        .link-list {
          width: 100%;
          padding-left: 0;
          justify-content: center;
          .line {
            margin-left: 13px;
            margin-right: 30px;
          }
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.footer-content >>> .el-textarea__inner {
  background-color: #092742 !important;
  color: #C4C4C4 !important;
  border: 1px solid #092742 !important;
  min-height: 100% !important;
  resize: none;
  font-family: ABeeZee-Regular, AbhayaLibre-Bold, BarlowCondensed-SemiBold, Barlow-Light, Helvetica Neue, Helvetica, Arial, Microsoft Yahei, Hiragino Sans GB, Heiti SC, WenQuanYi Micro Hei, sans-serif;
}
.footer-content >>> .el-textarea__inner::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.footer-content >>> .el-textarea__inner::-webkit-scrollbar-thumb {
  background-color: #ddd !important;
  border-radius: 3px !important;
}

@media (max-width: 1199px) {
  .footer-content >>> .el-textarea__inner {
    padding: 0 55px 0;
    font-size: 18px;
  }
}

@media (max-width: 760px) {
  .footer-content >>> .el-textarea__inner {
    padding: 0 25px 0;
    font-size: 17px;
  }
}

</style>