import Vue from 'vue'
import router from './router'
//vuex
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'
import './assets/iconfont/ABeeZeeRegular.css'
import './assets/iconfont/RobotoRegular.css'
import * as echarts from 'echarts'
import {
    TooltipComponent,
    LegendComponent,
    TitleComponent,
    ToolboxComponent,
    VisualMapComponent,
    GeoComponent
} from 'echarts/components'
import { PieChart, MapChart } from 'echarts/charts'
import { LabelLayout } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
// import 'amfe-flexible'

import App from './App.vue'
echarts.use([
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout,
    TitleComponent,
    ToolboxComponent,
    VisualMapComponent,
    GeoComponent,
    MapChart,
    CanvasRenderer
])
import {
    Row,
    Col,
    Card,
    Button,
    Tag,
    Divider,
    Menu,
    MenuItem,
    Aside,
    Container,
    Main,
    Message,
    DropdownMenu,
    Submenu,
    MenuItemGroup,
    Input,
    Rate,
    Pagination,
    Progress,
    Form,
    FormItem,
    Select,
    TimeSelect,
    Option,
    Collapse,
    CollapseItem,
    Table,
    TableColumn,
    Radio,
    RadioGroup,
    RadioButton,
    slider,
    Breadcrumb,
    BreadcrumbItem,
    Drawer,
} from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

// 配置请求地址
// axios.defaults.baseURL = '/api';
// axios.defaults.baseURL = 'https://len.vqmjc.cc/api';
// axios.defaults.timeout = 8000;


Vue.use(Row);
Vue.use(Col);
Vue.use(Card);
Vue.use(Button);
Vue.use(Tag);
Vue.use(Divider);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Aside);
Vue.use(Container);
Vue.use(Main);
Vue.use(DropdownMenu);
Vue.use(Submenu);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(Rate);
Vue.use(Pagination);
Vue.use(Progress);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(TimeSelect);
Vue.use(Option);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(slider);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Drawer);
Vue.prototype.$message = Message;
Vue.prototype.$echarts = echarts;

Vue.use(VueAxios, axios);
Vue.config.productionTip = false

new Vue({
    // 导入路由
    router,
    store,
    render: h => h(App),
}).$mount('#app')