<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>Why A Partial Student Loan Refinance Could Be Perfect For You?</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Jessica Cotzin</div>
                                <div>Last update: 29 March 2021</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/Why A Partial Student Loan Refinance Could Be Perfect For Youblog_1.jpg" alt="">
                            <p>The student debt crisis has affected millions of people throughout the US. In fact, experts estimate that there's currently a total of $1.6 trillion in student debt throughout the country.</p>
                            <p>As a result, millennials are putting off things like buying a house and getting married.</p>
                            <p>The good news is, the US government is taking steps to address the crisis, and some debt forgiveness may be on the way. President Biden has indicated that he would be willing to cancel up to $10,000 for each person with federal student loans.</p>
                            <p>Additionally, Congress recently passed a stimulus bill that will forgive loans for people with borrower defense claims. Borrower defense claims are designed to protect people whose schools misled them or engaged in any form of misconduct.</p>
                            <p>While refinancing your student loans can help you get a lower interest rate and reduce the total amount you pay on your loans, you may be hesitant to refinance with the new government debt cancellation programs rolling out.</p>
                            <p>This is where a partial student loan refinance can help. Here's how to partially refinance your student loans, and situations where this strategy can be helpful.</p>
                            <h2>How to Partially Refinance Your Loans</h2>
                            <p>Refinancing student loans involves having a new lender pay off your remaining student debt balance and then paying that new lender back directly.</p>
                            <p>For many people, refinancing student loans can save a significant amount of money in the long run by securing a lower interest rate on your loans, which could potentially add up to</p>
                            <h3>Who Should Refinance their Loans?</h3>
                            <p>While student loan refinancing is a tried and true strategy for lowering your monthly loan payments and freeing up some of your debt, it's not for everyone.</p>
                            <p>Before you commit to refinancing, you'll want to check to see what kind of interest rates are available to you.</p>
                            <p>If your credit has improved since your college years, or your finances are more stable in general, there's a good chance you'll qualify for a better interest rate. There are also times where the Federal Reserve cuts rates down for everybody because of the broader economy.</p>
                            <p>There is plenty of online tools that you can use to estimate what types of interest rates you'll qualify for. This will give you an idea of your options and help you make the best decision for your needs.</p>
                            <p>Credible is a loan marketplace that gives you an immediate look at your refinancing options without the hassle of submitting an application.</p>
                            <p>If you only want to refinance part of your loans, it's easy to do. If you have multiple student loans, you can choose to refinance one at a time, or if you have just one student loan, you can ask your new lender for an underpayment.</p>
                            <p>Not every lender offers underpayment options, but if they do, be sure to ask the lender before you go through the application process. Online lenders like Earnest and Splash Financial specialize in student loan refinancing and you can get a quote by submitting their short online forms.</p>
                            <h2>Why Should I Partially Refinance My Loans?</h2>
                            <p>There are a number of situations where it makes sense to partially refinance your student loans. A partial refinance can help you save money without accidentally excluding you from any loan cancellation initiatives.</p>
                            <h3>Student Debt Cancellation and Suspension Initiatives</h3>
                            <p>With so much government attention on student loans right now, you'll want to make sure you're eligible for loan relief should it pass in the future.</p>
                            <p>The US government has suspended federal student loan payments through September 30th, 2021 due to the COVID-19 pandemic. This means there are no payments due and there is zero percent interest.</p>
                            <p>You won't want to refinance any loans that qualify for this suspension, but you may still want to refinance any private loans you have. Remember, when you opt to refinance your loans, they'll no longer be through the government which is why they don't qualify for these repayment plans.</p>
                            <p>Since President Biden has indicated that he's willing to cancel up to $10,000 in student loan debt for each person, you may want to hold off on refinancing this amount just to make sure you qualify for the cancellation in the future. Some Democrats have pushed for even higher cancellation numbers, some as high as $50,000.</p>
                            <h3>Income-Driven Repayment Plans</h3>
                            <p>Depending on your current income, you may already qualify for an income-driven repayment plan from the federal government. This program helps people with relatively low incomes save money on their monthly payments.</p>
                            <p>If you refinance your federal loans, you'll no longer qualify for these income-driven repayment plans. This is especially important to consider if you're uncertain about your income or your finances at the moment.</p>
                            <p>The pandemic has caused a massive economic downturn that has affected millions of jobs. Tools like income-driven repayment plans are going to be crucial for many people.</p>
                            <p>There are also some student loan forgiveness programs already in place for individuals in certain industries. For example, some teachers qualify for $17,500 in student loan forgiveness if they teach for five consecutive years.</p>
                            <p>There are also forgiveness plans in place for public service employees and a number of other repayment initiatives.</p>
                            <p>It's always worth taking the time to see what sort of aid is available to you before refinancing your loans. You might be surprised by what you qualify for.</p>
                            <p>If there are federal forgiveness plans that apply to you, you'll want to make sure to account for these and only partially refinance your loans.</p>
                            <h3>Multiple Loans</h3>
                            <p>There are many people who have multiple student loans in place—some federal, some private. Each loan is going to have different conditions and different interest rates.</p>
                            <p>In this case, refinancing only one of your loans is often the best way to go. This can help you keep a lower interest rate and government benefits you already have while saving money on higher-interest loans.</p>
                            <h3>Shared Loans</h3>
                            <p>Parents will often cosign or take out student loans for their children to help them get better interest rates.</p>
                            <p>If your parents took this route but you're helping to pay the loans back, you may want to consider partially refinancing the loan. In this situation, you would refinance just the portion of debt that you need to repay, depending on your personal agreement with your parents.</p>
                            <p>There are also situations where you co-signed your spouse's loan and then divorced later on. If you need to continue making payments on the loan due to an agreement with your spouse, then partial refinancing can help you save money. It can even separate you from your former spouse's portion of the debt.</p>
                            <h2>Final Thoughts</h2>
                            <p>Student loans can cost thousands of dollars in interest alone over the course of a lifetime. This is a crisis that has held millions of people back from achieving their financial goals, whether it's taking out a mortgage or saving for retirement.</p>
                            <p>Refinancing student loans is a smart choice for many people, but it doesn't always make sense to refinance everything at once. Partial refinancing is a bit more flexible—it can help you save money where you need to without missing out on any government loan initiatives.</p>
                            <p>This is also a great solution for anyone splitting loan responsibilities with other people, like your parents or your spouse. A partial student loan refinance is more common than many people realize, so be sure to check with your lender to see if this option is available for you.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Jessica Cotzin</span><br />Last update: 29 March 2021</div>
                        </div>
                        <p>Jessica Cotzin is a writer and the TopFinanceDirectory authority on small businesses and personal loans. She has been writing about personal finance and the loans industry for a number of years, and holds a bachelor's degree in journalism from Florida Atlantic University.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    .buttom_container {
                        text-align: center;
                        margin-top: 88px;
                        .buttom {
                            padding: 13px 18px;
                            background: #2ac8a2;
                            border-radius: 50px;
                            font-size: 18px;
                            color: #ffffff;
                            line-height: 18px;
                            font-weight: 700;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
