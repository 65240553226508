<template>
    <div>
        <div class="container">
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>Privacy Rights Policy</h2>
                        </div>
                        <div class="text">
                            <p>[Last Revised: March 1,2022]</p>
                            <p>Samson Ads LLC. (“ <strong>Company</strong>” or “<strong>we</strong>” or “ <strong>us</strong>”) values the privacy rights of our users (“ <strong>you</strong>” or “ <strong>yours</strong>”). Thus, we have designed this Privacy Rights Policy (“ <strong>Privacy Rights Policy</strong>” or “ <strong>Policy</strong>”) as an overview of your rights regarding your personal data, under the following legislation:</p>
                            <ul class="text1">
                                <li>The EU General Data Protection Regulation (“ <strong>GDPR</strong>”), which shall apply to you in the event you are a resident of the European Economic Area (“ <strong>EEA</strong>”); and</li>
                                <li>The California Consumer Privacy Act of 2018 (" <strong>CCPA</strong>" or “ <strong>Act</strong>”) which shall apply to you in the event you are a “California Resident”, as defined under the CCPA.</li>
                            </ul>
                            <p>This Policy applies solely to your rights concerning Personal Data or Personal Information (as defined under the applicable law) processed by us. If you wish to submit a request to exercise any of your rights, please fill in the </p>
                            <h2>Your Right to be Informed</h2>
                            <p>You have the right to be informed with the Company’s details (e.g. name, address, etc.), as well as why and how we process Personal Data. This right includes, among others, the right to be informed with the identity of the business, the reasons and lawful basis for processing Personal Data, and additional information necessary to ensure the fair and transparent processing of Personal Data. Further, you have the right to be informed on the categories of Personal Information collected, sold, disclosed by us in the previous 12 months, therefore we ensure our privacy policy discloses all of the above and is updated every 12 months. Please see our privacy policy available </p>
                            <h2>Right to Access</h2>
                            <p>You have a right to request us to confirm whether we process certain Personal Data related you, as well as a right to obtain a copy of such Personal Data, with additional information regarding how and why we use this Personal Data. After we receive such request, we will analyze and determine the veracity and appropriateness of the access request and provide you with the applicable confirmation of processing, the copy of the Personal Data or a description of the Personal Data and categories of data processed, the purpose for which such data is being held and processed, and details about the source of the Personal Data if not provided by you. Our response detailed above will be provided within the period required by law (please see additional information under “</p>
                            <h2>The Right of Rectification</h2>
                            <p>The Company must ensure that all personal data that it holds and uses about a data subject is correct. If such data is not accurate, a data subject has the right to require that the Company updates such data so it is accurate. In addition, if the Company has passed on incorrect information about a data subject to a third party, the data subject also has a right to oblige the Company to inform those third parties that this information should be updated.</p>
                            <h2>Rectification Access</h2>
                            <p>If Personal Data held by us is <strong>not accurate</strong>, you may require us to update such data so it is accurate. Further, in the event we have passed on incorrect information about you to a third party, you also have a right to oblige us to inform those third parties that the applicable information should be updated.</p>
                            <h2>Erasure Rights ("right to be forgotten" or “right of deletion”, as applicable)</h2>
                            <p>conditions are satisfied. The Company is legally obligated to comply with a request to delete personal data if: the data is no longer needed for the original purpose and no new lawful purpose exists for continued processing; the lawful basis for processing is consent of the data subject and such consent is withdrawn; the data subject exercises his or her right to object to the Company’s processing of his or her personal data, and the Company has no overriding grounds for processing the data; the personal data is processed unlawfully; or erasure of the data is necessary to comply with applicable laws. In addition, if the Company has passed on personal data to a third party, a data subject also has a right to oblige the Company to tell those third parties that the information should be erased. The right to erasure is not absolute. Even if a data subject falls into one of the categories described above, the Company is entitled to reject the data subject’s request and continue processing data if such processing is: necessary to comply with legal obligations; necessary to establish, exercise or defend legal claims; or is necessary for scientific research, etc.; necessary to perform a contract between you and us; necessary to detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for that activity; necessary to debug to identify and repair errors that impair existing intended functionality; and to enable solely internal uses that are reasonably aligned with your expectations based on the our relationship with you  – all subject to applicable laws.</p>
                            <h2>Right to Object</h2>
                            <p>With regards to Personal Data processed by us under the lawful basis of our legitimate interests (such as direct marketing), you may object to our processing on such grounds. However, even if we receive your objection, we will be permitted to continue processing the Personal Data in the event that (subject to applicable laws and regulations):</p>
                            <ul class="text1">
                                <li>our legitimate interests for processing override your rights, interests and freedoms;</li>
                                <li>the processing of such Personal Data is necessary to establish, exercise or defend a legal claim or right, etc.</li>
                            </ul>
                            <h2>The Right of Restriction</h2>
                            <p>A data subject may limit the purposes for which the Company may process its personal data. The Company’s processing activities may be restricted if: the accuracy of the data is contested; processing is unlawful and data subject requests restriction instead of erasure; the Company no longer needs the data for its original purpose, but the data is still required to establish, exercise or defend legal rights; or consideration of overriding grounds in the context of an erasure request.</p>
                            <h2>Data Portability</h2>
                            <p>You may request us to send or "port" your Personal Data held by us to a third-party entity, however note, the GDPR and CCPA apply differently to this right, thus, we will handle this according to the jurisdiction you are subject to.</p>
                            <h2>Nondiscrimination</h2>
                            <p>Under the CCPA, you must not be discriminated for exercising any of your rights, including by denied goods or services, charging you with different fees for goods or services, including through the use of discounts or other benefits or imposing penalties; suggested you will receive a different price or rate for goods or services.Notwithstanding the above it is allowed to set up schemes for providing financial incentives and you can opt-in to become part of them.</p>
                            <h2>Response Timing and Format</h2>
                            <p>We endeavor to respond to a verifiable consumer request with undue delay and in any event within 30 days from the receipt of the request subject to GDPR and between 10-45 days from receipt of a request subject to CCPA. If we require more time, we will inform you of the reason and extension period in writing. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your Personal Data that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.Further, note, under the CCPA your rights only apply to the Personal Information collected 12 months prior to the request and you are not entitled to submit more than 2 requests in a 12 months period.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "privacy_rights_policy",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            is_content_anchor: true
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 15px 0 14px 0;
                    display: flex;
                    line-height: 21px;
                    font-size: 14px;
                    color: #666666;
                }
                .text {
                    margin-top: 40px;
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    table {
                        text-align: center;
                        th {
                            background-color: #2ac8a2;
                            color: #fff;
                            padding: 31px 114px;
                        }
                        td {
                            padding: 30px 25px;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #fff;
                            line-height: 20px;
                            background-color: #2ac8a2;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                            border-radius: 12px 12px 0px 0px;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 25px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px 45px;
                                padding-bottom: 15px;
                                div {
                                    font-size: 18px;
                                    line-height: 18px;
                                    color: #222831;
                                    text-align: left;
                                }
                                .active {
                                    font-weight: bold;
                                    color: #222831;
                                }
                            }
                            li:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 954px;
            }
        }
    }
    .article_bottom {
        background-color: #f5f8fb;
        .article_text {
            .article_text_left {
                .text {
                    margin: 30px 0 220px 0
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .article_container {
            margin-top: 0px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                    .text {
                        h2 {
                            text-align: left;
                        }
                    }
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        text-align: justify;
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                        table {
                            th {
                                padding: 25px 0;
                            }
                            td {
                                padding: 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
</style>