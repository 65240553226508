<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Bestow Life Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance' }">Life Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Great Life</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Great Life</h1>
                          <img src="../../../../public/imgs/reviews_list/bestow_life_insurance_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Great Life is Trying to Make Buying Life Insurance Faster and less Painful.</h2>
                          <p>Life insurance is an amazing tool that can provide financial security to your family if the worst happens and you die. Proceeds from life insurance can help your family cover short or long-term financial responsibilities and help replace any income you bring in.</p>
                          <p>However, the process of buying life insurance can be frustrating. You may have to answer a ton of questions and, in some cases, get a medical exam or blood draw.</p>
                          <p>Thankfully, there's a life insurance company called Great Life that's trying to make buying life insurance faster and less painful, both figuratively and literally.</p>
                          <h2 class="anchor_h2">Benefits of Buying Life Insurance</h2>
                          <p>Life insurance provides your family with much needed financial protection, in your absence. It gives you peace of mind and confidence and helps you save taxes. There are several benefits of life insurance. Let us take a look at some of them:</p>
                          <ul>
                            <li>√ Financial security: Life insurance products can provide you and your loved ones with financial security. You can financially secure your family by choosing a substantial life cover+ so that they can maintain their standard of living in your absence.</li>
                            <li>√ Wealth creation: These life insurance plans help you create wealth over time and build savings. Life insurance plans can be used to save for your future needs. They offer options to cover different types of risks and goals.</li>
                            <li>√ Disciplined investment: With regular premium payments, life insurance plans inculcate financial discipline. They offer unique investment opportunities for every risk type with a wide range of products, such as unit linked life insurance plans, guaranteed savings insurance plans, endowment plans, and more.</li>
                            <li>√ Retirement planning: Life insurance products like annuity plans, savings plans, endowment plans, and others help you build a retirement pool. These plans offer guaranteed income on maturity and help you secure your retirement with adequate savings.</li>
                            <li>√ Tax savings: Life insurance plans offer many tax benefits to facilitate better savings. The premiums paid are tax deductible under Section 80C and 80D of the Income Tax Act, 1961. The maturity benefits also enjoy a tax-free status under Section 10(10D) in most cases.</li>
                          </ul>
                          <h2 class="anchor_h2">Why do you need Life Insurance?</h2>
                          <ul>
                            <li>√ To provide complete financial security to your loved ones: Life insurance makes sure your loved ones won't suffer financially in your absence. There is no way to replace a loved one, but planning with life insurance does help in taking care of the financial needs of the family</li>
                            <li>√ Accomplish your financial goals: Life insurance plans help you achieve your financial goals be it securing your family from life's uncertainties, saving for your child's education or buying your dream house. Life insurance plans inculcate a habit of disciplined saving. Paying a nominal amount as a premium at a frequency of your choice will help accumulate money over a period of time. Also, new age insurance plans allow you to withdraw money at key milestones without any additional charges</li>
                            <li>√ Peace of Mind: In these uncertain times, all anyone wants is a safety net that not only provides financial security but also helps in building a sizable corpus over time. A life insurance plan is an instrument that can provide both. A life insurance plan ensures that the constant stress of financial planning is reduced considerably thus providing one an absolute peace of mind</li>
                            <li>√ Save tax: When it comes to tax planning, life insurance plays a vital part in optimizing your taxable income. You can claim a deduction on your life insurance premiums under Section 80C of the Income Tax Act, 1961. Also, the payouts for death claims are tax-exempted subject to conditions under Section 10(10D) of the Income Tax Act, 1961</li>
                          </ul>
                          <h2 class="anchor_h2">Who needs life insurance the most?</h2>
                          <ul>
                            <li>√ Newly married couples: Life insurance can offer newly married couples peace of mind and financial security. It can help your spouse live their life comfortably and meet all financial liabilities.</li>
                            <li>√ Parents with young children: Parents with young children can purchase life insurance to ensure that their children have a financial cushion to fall back on if they are not around to take care of them. Life insurance plans can also help parents save for their kids’ future needs.</li>
                            <li>√ People nearing retirement with fewer savings: Life insurance plans allow you to save and invest your money. You can opt for low-risk options and secure your retirement and old age.</li>
                            <li>√ Business owners: Life insurance benefits can help your family carry on your business in your absence. The payouts can also help them pay off creditors or clear their debts.</li>
                          </ul>
                          <h2 class="anchor_h2">Great Life is also Working Hard to Satisfy the User Experience.</h2>
                          <p>It is worth mentioning that Great Life has also made great efforts to satisfy the user experience. Great Life's website is clean and easy to navigate. You can get an initial quote by entering your date of birth, height, weight, gender and zip code, then apply for a policy. If you have questions about your policies, you can reach the team via live chat, email or phone. If you are the beneficiary, you can initiate the claims process on Great Life's website. You will then receive the North American Life and Health Insurance claim package.</p>
                          <h2 class="anchor_h2">Some Points to Note about Great Life.</h2>
                          <p>However, like Haven Life Insurance, Great Life only sells term life insurance. If many or most life insurance companies sell term, whole, universal and variable universal life insurance, Great Life only sells term life insurance. If you want to buy permanent insurance, you need to buy it from another company. Second, while Great Life is available to most people, it does not have a license to issue policies to New York residents. If you live in that state, you are not eligible for Great Life's long-term living policy.</p>
                          <p>In general, a simplified issuance policy like Great Life's plan is more expensive than a fully covered policy. However, we found that Great Life was very competitively priced for young people.</p>
                          <p>Anyone looking to find term life insurance should start by getting a free quote from Great Life. It's an easy application and some may get an answer instantly.</p>
                          <p>Speaking of which, how do you go about buying Great Life?</p>
                          <h2 class="anchor_h2">How to Buy Great Life?</h2>
                          <p>From initial quotation to final approval, the entire application is an online process. If you have any questions, you can contact one of Great Life's authorized agents by phone, email, or via its live chat function. If it's off-hours, you can try searching for answers through a chatbot.</p>
                          <p>You can get quotes online by answering some basic questions about yourself. Please note that initial quotes are approximate estimates. Only after you apply and provide details about yourself, such as your citizenship, medical history, lifestyle and hobbies, will you receive an official offer for the policy you want. The information you will also need to provide includes your beneficiary, your term, and the amount of coverage you want.</p>
                          <p>Before you apply, you'll need to have your Social Security number ready and basic information about yourself, including height, weight and health history, as well as family medical history details.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Great Life is Trying to Make Buying Life Insurance Faster and less Painful.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Benefits of Buying Life Insurance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Why do you need Life Insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Who needs life insurance the most?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Great Life is also Working Hard to Satisfy the User Experience.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">Some Points to Note about Great Life.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">How to Buy Great Life?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>