<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Lower My Bills</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
                <div class="Bread_crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                        <el-breadcrumb-item>HarpRateQuiz</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>HarpRateQuiz</h1>
                          <img src="../../../../public/imgs/reviews_list/lowermybills_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is HarpRateQuiz.com?</h2>
                          <p>The Home Affordable Refinance Program (HARP) is a federal program in the United States, founded by the Federal Housing Agency in March 2009. The program was developed to help homeowners with little to no equity refinance onto a lower rate mortgage. This program benefits homeowners who are up to date with payments but have struggled to refinance due to the affected home prices from the US housing market collapse.</p>
                          <p>There has been numerous changes to the HARP program since it was first introduced, early in 2011 the Loan-To-Value ceiling was removed, along with other restrictions that were in place. In 2013, the eligibility date was changed, from the initial date the loan was acquired by Fannie Mae or Freddie Mac to the date on the note, which drastically increased the number of homeowners that were eligible. The HARP program was also extended and now has to be approved before December 2018.</p>
                          <p>Most Homeowners use HARP to refinance into a lower interest rate mortgage, which ultimately lowers monthly payments, adjust the loan term or switch to a fix-rate mortgage. According to the Government HARP website, the average homeowner saves $179 per month.</p>
                          <p>Even homeowners that were initially turned down for HARP may now be eligible since some of the restrictions were removed.</p>
                          <h2 class="anchor_h2">How does HarpRateQuiz.com work?</h2>
                          <p>HARP Quiz is an online lender marketplace that matches customers with lenders based on the financial products they seek and the different eligibility requirements established by each company. HARP Quiz is the property of Suited Connector, a Colorado-based limited liability company with a Better Business Bureau rating of A+.</p>
                          <p>If you're thinking about buying your first house, you might want to consider the best home loans in 2021. You might also look into the finest refinance mortgage lenders if you're curious about whether you could lower your mortgage payment by refinancing. Let's pretend that you're considering refinancing your mortgage.</p>
                          <h2 class="anchor_h2">How much does HarpRateQuiz.com.com cost?</h2>
                          <p>HarpRateQuiz.com is a service that is absolutely free to use. However, you should be aware that the products recommended on the website may come with fees. For example, a website-recommended credit card may include annual fees, and mortgage lenders may charge a service fee.</p>
                          <p>HarpRateQuiz.com is a good place to start if you're interested in learning more about money. It's also a useful tool for assessing your financial goals as well as your present mortgage or other loan products. While this website cannot make you a direct offer because it is not a lender or insurance firm, it can help you compare lenders and insurance companies.</p>
                          <p>There aren't many people who wouldn't profit from this website because it's a free resource. Only if a piece of information is required to assist you in using the website's various calculators and tools will you be asked to give personal information.</p>
                          <p>Whether you're seeking for information on how to do something or how to do something, you've come to the right place.</p>
                          <h2 class="anchor_h2">When it comes to reverse mortgages, it's pointless</h2>
                          <p>That is, assuming you can access to a list of loan possibilities at all. We spent roughly three minutes filling out the web form when we recently assessed the HarpRateQuiz.coms service. It requests basic information such as name and address, as well as more detailed information about the property, such as its estimated current valuation and the balance of any current mortgage(s). Finally, we selected the tab to examine all of our offers, but it just gave us links to TransUnion for credit planning and protection, as well as Truebill for canceling subscriptions we weren't using.There was another box behind those two that mentioned something about refinancing, but we couldn't see it because we couldn't scroll down the page, and we had also stated in our form that we didn't have a mortgage on any property.</p>
                          <h2 class="anchor_h2">To receive more irrelevant results, use the refresh button</h2>
                          <p>It was like spinning the wheel of fortune to hit the refresh button: what offers would we get this time? We received links to companies that specialize in VA loans (despite the fact that we stated on the application that we had no military background), credit card companies for people who need to rebuild credit (despite the fact that our credit scores were in the "excellent" range), and mortgage offers for homeowners in states where we don't live.</p>
                          <h2 class="anchor_h2">As a lender overview, it could be useful</h2>
                          <p>The landing page for "top reverse mortgage lenders" on the HarpRateQuiz.com website is the only thing we found beneficial. It provides a fairly good overview of many of today's largest industry participants, including many of the ones discussed here. You could use that to start your reverse mortgage research, but when you fill out the form on the HarpRateQuiz.com site, you're unlikely to be linked with any of those providers directly.</p>
                          <h2 class="anchor_h2">HarpRateQuiz.com is a website that helps you save money on your expenses.</h2>
                          <p>This website is chock-full of information and features that you can use regardless of the type of financial product you're looking for.</p>
                          <p>Detailed details</p>
                          <p>HarpRateQuiz.com is a vast website that contains a wealth of information. Almost every financial topic you can think of is likely to be covered in an article. Even if you aren't planning to take out a loan or refinance your mortgage, the material on this page can help you find answers to questions you may not even be aware of.</p>
                          <h2 class="anchor_h2">Mortgage analysis</h2>
                          <p>This program basically assigns a grade to your current mortgage based on how well it fulfills your financial objectives. To utilize the tool, first enter your mortgage goal, which might be anything from lowering your interest rate to lowering your monthly payment to paying off your mortgage early. Then you'll enter the amount of your current mortgage debt, the valuation of your home, your monthly payment, and your interest rate. Based on this data, the program will analyze your circumstances and assign a grade based on the current mortgage alternatives.</p>
                          <p>HarpRateQuiz.com is an excellent resource for anyone interested in learning more about financial matters. It's also a useful tool for assessing your financial goals as well as the mortgage or other loan products you currently have. While this website cannot make you a direct offer because it is not a lender or an insurance firm, you can use it to compare lenders and insurance companies.</p>
                          <p>Because this is a free resource, there aren't many people who would be unable to benefit from it. Only if a piece of information is required to assist you use the website's various calculators and tools will you be asked to give personal information.</p>
                          <p>This website can be a great resource in your life, whether you're looking for information on how to defer credit card payments or which mortgage kinds give the lowest monthly installments.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is HarpRateQuiz.com?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">How does HarpRateQuiz.com work?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">How much does HarpRateQuiz.com.com cost?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">When it comes to reverse mortgages, it's pointless</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">To receive more irrelevant results, use the refresh button</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">As a lender overview, it could be useful</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">HarpRateQuiz.com is a website that helps you save money on your expenses.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)">Mortgage analysis</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>