<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Credit Repair</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">We have carefully reviewed and partnered with some of the leading credit repair companies. They have helped thousands of consumers with successfully disputing and removing inaccurate items from their credit file.</div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_list">
                    <div class="article_list_left">
                        <div class="text">
                            <h2>What is credit repair?</h2>
                            <img src="/imgs/credit_repair_article_imgs_1.png" alt="">
                            <p>You probably already know just how important your credit score is, and how this three-digit manifestation of your credit health can affect your life. Without a solid credit score, you may struggle to get your own apartment, buy a home, or finance a car, for example — and that’s just the beginning.</p>
                            <p> If your credit has been damaged due to late payments, debts in collections, or neglect, credit repair may be exactly what you need. You can do the repair work yourself, or you can choose to hire a firm to help. A good credit repair company can work as your advocate, providing you with counsel regarding your credit score and how it’s determined. They also work to improve your credit score on your behalf, usually by negotiating with creditors and credit bureaus to remove negative marks, resolve issues and help you repair your credit — once and for all.</p>
                            <p>Unfortunately, there are numerous credit repair scams to be aware of. Some credit repair companies will ask for money upfront before they have done any work to help repair your credit, for example, while others will ask you to take unethical steps, such as disputing information on your credit report that’s actually true. Another scam to be aware of is any time a company promises you a “new credit identity” or asks you to give false information.</p>
                            <p> Fortunately, credit repair companies are governed by the Credit Repair Organization Act (CROA). This law requires credit repair companies to take certain steps such as informing you of your legal rights, giving you three full days to cancel your contract with them and letting you know the full costs of their services upfront. The law also gives you options if a credit repair company doesn’t live up to their promises, including the ability to sue them in federal court, the ability to seek punitive damages, and the right to join a class action lawsuit against the company.</p>
                            <p> If you’re ready to repair your credit, a credit repair agency can help provided they are reputable. Keep reading to learn more about how these companies work and what to expect.</p>
                        </div>
                    </div>
                    <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div>Types of Information We Collect</div>
                                    </li>
                                    <li>
                                        <div>Who We Are</div>
                                    </li>
                                    <li>
                                        <div>Collection And Use Of Personal Information</div>
                                    </li>
                                    <li>
                                        <div>Verifiable Client Requests</div>
                                    </li>
                                    <li>
                                        <div>Information We Collect</div>
                                    </li>
                                    <li>
                                        <div>Information You Provide</div>
                                    </li>
                                    <li>
                                        <div>California Privacy Policy</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article_bottom">
                <div class="article_text">
                    <div class="text_container">
                        <div class="text">
                            <h2>Does credit repair work for everyone?</h2>
                            <p>Credit repair can help consumers boost their credit scores and get out from under their credit problems from the past. In that respect, yes, credit repair can work for everyone if you give it enough time. For the process to work, however, you need to work alongside your credit repair agency to make needed changes such as paying down debt and paying your bills on time.</p>
                            <p>However, you don’t have to hire a credit repair firm to get your credit score back in good standing. It’s possible to dispute negative information on your credit report via the three credit reporting agencies yourself, for example. Petersen notes that most consumers who have a good faith dispute can write a brief letter identifying the errors, explain why the information is false or misleading, and inform the credit reporting agencies of what the corrected credit report should state. Consumers who take the time to write their own dispute letter may also see better results versus the results they may get when a credit repair company sends a generic letter on their behalf.</p>
                            <p> You can also get a free copy of your credit report from all three agencies online for free from AnnualCreditReport.com instead of relying on a credit repair company to get your credit report for you. Last but not least, you can take steps to improve your credit on your own, including paying down debts in delinquency, making all your payments on time, and lowering your utilization by paying down the amounts you owe.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="credit_repair_container">
                <div class="credit_repair">
                    <div class="credit_repair_title">
                        <div class="title_left">
                            <h2>What services do credit repair companies provide?</h2>
                            <div class="title_left_text">While credit repair companies don’t have the legal power to do anything you can’t do yourself, they do play an important role for people who feel overwhelmed trying to fix their credit themselves. There are legitimate reasons consumers may turn to a credit repair company, including getting fraudulent accounts removed from their credit report amending mismerged credit accounts that belong to someone else, and accounts in bankruptcy that are misreported.</div>
                        </div>
                        <img src="/imgs/credit_repair_article_imgs_2.png" alt="">
                    </div>
                    <div class="text">
                        <p>Legitimate credit repair providers assist consumers by reviewing the consumer’s credit reports, identifying information which is adverse to the consumer, interviewing the consumer, and obtaining documents from the consumer to determine whether any of the derogatory information is false.</p>
                        <p> From there, they typically prepare a letter to the credit bureaus disputing the errors. The dispute letter should identify the errors with specificity, briefly explain why the disputed information is false or misleading, and state what the credit report should say instead.</p>
                        <p> While a dispute letter is almost always used as a measure to move toward better credit for the consumer, additional strategies credit repair companies take to fix their client’s credit can vary depending on their unique situation and their credit woes.</p>
                        <p>Some of the steps credit repair companies can take on your behalf include:</p>
                        <ul class="text1">
                            <li>They may help you get a copy of your credit report from the three credit reporting agencies – Experian, Equifax, and TransUnion.</li>
                            <li>They will go over your credit report to determine what problems exist and the best course of action to fix them.</li>
                            <li> They may help you utilize federal consumer protection laws to protect you and your credit from unfair debt collection practices.</li>
                            <li>They may analyze your credit score and offer targeted information that can help you improve your credit score as you progress through their program.</li>
                            <li>They may negotiate with your creditors on your behalf.</li>
                            <li> They may dispute any information you feel is inaccurate on your credit report with the goal of having negative information removed altogether.</li>
                            <li>Credit repair agencies may also help consumers fix incorrect credit limits that can inflate or skew credit utilization and adversely impact a consumer’s credit score.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="not_do_container">
                <div class="text_container">
                    <div class="text">
                        <h2>What credit repair companies won't do.</h2>
                        <p>While having a third-party company handle all your credit issues for you may sound ideal, it’s important to keep your expectations in check. Credit repair companies can absolutely help you improve your credit score over time, but they do have limitations.</p>
                        <h3>Credit repair companies cannot do anything you cannot do yourself.</h3>
                        <p>While credit repair agencies will work with your creditors and the three credit bureaus to improve your credit and have negative information removed, you can also take all of these steps yourself without paying for credit repair. You can also call up your lenders and negotiate with them yourself. You do not need a credit repair firm for that. In fact, many lenders may not even allow a third party to negotiate on the customer’s behalf.</p>
                        <h3>Credit repair companies can’t remove accurate information from your credit report — even if you pay them.</h3>
                        <p>While credit repair companies may work hard to get your credit report clear of any negative information, they cannot get accurate negative information removed from your report. Thanks to the fact credit repair organizations are overseen by the Credit Repair Organizations Act (CROA) , they also cannot promise to have information removed from your reports.</p>
                        <h3>They may not offer budgeting or money management advice.</h3>
                        <p>While credit repair agencies have the goal of getting you out of debt and repairing your credit, they don’t offer the same services as credit counseling agencies.Credit counseling agencies offer a more holistic approach to solving your money and credit problems, focusing most of their efforts on helping you make a budget, manage money better, and reduce spending.</p>
                        <p> On the flip side, however, credit counseling services don’t take the same steps to boost your credit score and fix negative marks on your credit report as credit repair agencies do. Before you choose one service over the other, it helps to educate yourself on both options.</p>
                    </div>
                </div>
            </div>
            <div class="FAQ_container">
                <div class="FAQ_box">
                    <div class="FAQ_list_left">
                        <div class="FAQ_onecontailner">
                            <h2>Overview</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="How can Credible help me refinance my student loans?" name="1">
                                    <div>Credible enables you to compare the student loan refinancing products from multiple lenders you qualify for side-by-side, based on all of the criteria that are important to you, like total repayment amount, APR, repayment options, and monthly payment. It takes just two minutes to find out if you are eligible for prequalified rates with our partner lenders!</div>
                                </el-collapse-item>
                                <el-collapse-item title="How do I know if I am eligible?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Can I use Credible to refinance my student loans if I didn't graduate?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="What types of loans can I refinance? Federal and private loans? Parent PLUS loans?" name="4">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="My loans are in deferment or forbearance. Will this affect my eligibility?" name="5">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How much does it cost to use Credible?" name="6">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                    <div class="FAQ_list_right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "credit_repair",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            is_content_anchor: true,
            activeName: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/textnotnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .article_container {
        .article_list {
            margin: 40px auto 50px;
            .article_list_left {
                margin-top: 0px;
            }
        }
    }
    .article_bottom {
        background: #f5f8fb;
        .text_container {
            .text {
                margin: 50px auto 50px;
            }
        }
    }
    .credit_repair_container {
        width: 100%;
        .credit_repair {
            width: 61%;
            margin: 120px auto 90px;
            .credit_repair_title {
                display: flex;
                .title_left {
                    h2 {
                        color: #222831;
                        line-height: 72px;
                        font-size: 48px;
                        font-weight: 700;
                        margin-bottom: 85px;
                    }
                    .title_left_text {
                        border-radius: 12px;
                        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.10); 
                        padding: 58px 62px;
                        color: #666666;
                        line-height: 24px;
                        font-size: 18px;
                        text-align: justify;
                    }
                }
                img {
                    width: 100%;
                }
            }
        }
    }
    .not_do_container {
        width: 100%;
        background-color: #f5f8fb;
        .text_container {
            .text {
                margin: 50px auto 50px;
            }
        }
        
    }
    .FAQ_container {
        width: 100%;
        .FAQ_box {
            width: 61%;
            margin: 50px auto 220px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .FAQ_list_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .FAQ_onecontailner {
                    margin-bottom: 60px;
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 24px;
                        margin-bottom: 30px;
                    }
                    .FAQ {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        padding: 30px 40px 20px;
                    }
                }
            }
            .FAQ_list_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    h2 {
                        font-size: 24px;
                        font-weight: 700;
                        color: #fff;
                        line-height: 24px;
                        background-color: #2ac8a2;
                        padding: 18px 45px;
                        text-align: left;
                        border-radius: 12px 12px 0px 0px;
                    }
                    ul {
                        li {
                            padding: 19px 5px;
                            font-size: 18px;
                            color: #222831;
                            line-height: 18px;
                            margin: 0 45px;
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                        li:first-child {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .credit_repair_container {
            .credit_repair {
                width: 80%;
                .credit_repair_title {
                    flex-direction: column;
                    .title_left {
                        .title_left_text {
                            line-height: 30px;
                            padding: 58px 30px;
                            margin-bottom: 25px;
                        }
                    }
                }
            }
        }
        .FAQ_container {
            margin-top: 50px;
            .FAQ_box {
                width: 90%;
                flex-direction: column-reverse;
                .FAQ_list_left {
                    width: 100%;
                    z-index: 9;
                }
                .FAQ_list_right {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 760px) {
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
    
    .text {
        margin-top: 40px;
        font-size: 18px;
        color: #666666;
        line-height: 24px;
        margin-bottom: 25px;
        p {
            margin-bottom: 25px;
        }
        img {
            width: 100%;
            margin-bottom: 25px;
        }
        h2 {
            font-size: 36px;
            font-weight: 700;
            color: #222831;
            margin-bottom: 25px;
            line-height: 36px;
        }
        table {
            text-align: center;
            margin-bottom: 35px;
            th {
                background-color: #2ac8a2;
                color: #fff;
            }
        }
    }
    @media (max-width: 1199px) {
        .text {
            line-height: 30px;
            h2 {
                line-height: 45px;
            }
        }
    }
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
    
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 25px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 70px;
            line-height: 20px;
            text-align: left;
        }
    }
</style>