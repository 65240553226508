<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">5K Funds</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Lending For Bad Credit</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Lending For Bad Credit</h1>
                          <img src="../../../../public/imgs/reviews_list/5kfunds_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is Lending For Bad Credit？</h2>
                          <p>When you apply for a loan, lenders will look at your Credit score and Credit history to determine how much of a risk it is to them to lend you money. Bad — or low — Credit is a score that falls between 300 and 579 and is caused by factors like thin Credit history, multiple late payments and maxed-out Credit cards. </p>
                          <p>If you have bad Credit, it can be difficult to get approved for a loan. You'll likely need to turn to lenders that offer loans for bad Credit. These loans are either secured (backed by collateral, like a home or car) or unsecured and often come with higher interest rates than other personal loans. </p>
                          <p>Before signing on the dotted line, be completely sure you need a bad Credit loan, as the higher rates and potentially unfavorable terms could put you in more debt down the road. </p>
                          <p>Bad Credit loans generally come with fixed rates and are offered by lenders willing to loan you funds despite your limiting Credit rating. These types of loans can help you build Credit so you can eventually become eligible for better loan terms and other financial opportunities.</p>
                          <h2 class="anchor_h2">How to get Lending For Bad Credit？</h2>
                          <p>It’s possible to get a personal loan with bad or no Credit. However, if you take the time to work on your Credit, you could save a lot of money by benefiting from a lower interest rate. If you need a personal loan now, however, consider these tips to finding the best personal loan for bad Credit for you:</p>
                          <p>Check your Credit score. Keeping tabs on your score can help you gauge which lenders you may qualify with. You can check your Credit score at LendingTree to see where you stand, then find ways to improve your Credit score. Since your score is generated by the information in your Credit report, you can also request your Credit reports from the three major bureaus through AnnualCreditReport.com to look for and dispute any errors.</p>
                          <p>Seek lenders that consider more than just your Credit. Lenders whose assessments of your Creditworthiness aren’t solely dependent on your FICO Score may be easier to qualify with, depending on your financial situation. For instance, some lenders may also take into account your income, employment and education level.</p>
                          <p>Prequalify for loans online. Through prequalification, you can check whether you’re likely to be approved for a loan and what rates you might be eligible for — the process won’t harm your Credit score, as it only generates a soft inquiry. This can be a good way to assess what you could qualify for and how much it would cost you in the long term.</p>
                          <p>Compare loan offers. Once you’ve received a few personal loan offers, you’ll want to select the best option for you. That means reviewing interest rates, lender fee structures and available terms.</p>
                          <p>Submit an application. After narrowing down your options, it’s time to submit a formal application. This will trigger a hard inquiry, which will temporarily lower your Credit score. After applying, you may need to wait a few business days to receive a loan decision. If your application is denied, you’ll typically be notified of the factors that contributed to the denial.</p>
                          <h2 class="anchor_h2">Where can we get Lending For Bad Credit?</h2>
                          <p>When searching for a personal loan with low or bad Credit, it is important to consider all of your options before committing to an online lender.</p>
                          <p>While bad Credit can lead to limitations in the borrowing process, there are lenders, banks and Credit unions who gear their products specifically toward borrowers in this situation. Plus, it's not uncommon for financial institutions to provide helpful financial resources and member benefits should you borrow from them.</p>
                          <p>1. Online personal loans for bad Credit</p>
                          <p>Applying to online lenders can be a good option for borrowers with bad Credit, especially if you submit an application through an online lending network. These online networks often allow you to submit a single initial application and then compare offers from responding lenders. Online lending networks make it easier to find the lenders that offer the most competitive rates, which makes it easier to sift through the details to find the best fit for you. </p>
                          <p>Direct lenders also can offer online personal loans. To make the process easier, do your research beforehand to see if the lender you'd like to work with offers loans to borrowers within your Credit range. </p>
                          <p>2. In-person lenders for bad Credit loans</p>
                          <p>If you prefer to get started in person, applying directly with individual lenders, banks and Credit unions could be a good option. Before turning to new lenders, see if the institution you bank with offers personal loans for your Credit range — member discounts or exclusive ebenefits may be available. Compare the rates you're offered at these institutions with new lenders as well.</p>
                          <h2 class="anchor_h2">What is the Pros and Cons?</h2>
                          <p>Pros:</p>
                          <p>Flexible eligibility: Loans for borrowers with Credit that's average and below are more likely to base approval on factors other than just Credit score. For example, Upstart also factors in education and job history when evaluating an application.</p>
                          <p>Potential Credit boost: A positive repayment history is one of the fastest ways to grow a Credit score.</p>
                          <p>Lower rates than Credit cards: While loans of this nature carry higher rates when compared to other personal loans, they generally have lower interest rates than most Credit cards.</p>
                          <p>Cons:</p>
                          <p>Possible fees: Before applying, read the fine print in the terms and conditions to look for any hidden fees. Due to the lower Credit range of the applicants, many bad Credit loans charge high fees that can detract from the overall value of the loan.</p>
                          <p>Collateral requirements: Some lenders only offer secured loans for borrowers who have low Credit, which poses a risk to the borrower should they become unable to make the monthly payments.</p>
                          <p>Increased predatory potential: Many predatory lenders will advertise their products similarly to bad Credit loans. However, these often come with rates that are near impossible to manage and leave the borrower in years of debt.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is Lending For Bad Credit？</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">How to get Lending For Bad Credit？</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Where can we get Lending For Bad Credit?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What is the Pros and Cons?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>