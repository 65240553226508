<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Personal Loans Guide</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_subtitle">Have you ever wondered how to get a personal loan with a small credit score? or what is the real difference between a personal loan and debt consolidation? You can find out the answers to these questions and more with our Personal Loans explained articles.</div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2 class="anchor_h2">How to Get a Personal Loan Approved in 7 Steps?</h2>
                          <p>Whether someone is working on a home improvement project or paying off medical bills, personal loans can provide financial relief to those who can’t afford to pay for something upfront. The process of researching, applying, and receiving a loan can be straightforward as long as the potential borrower knows what to look for.</p>
                          <p>Here’s how to apply for a personal loan:</p>
                          <h2>1. Determine Your Credit Score</h2>
                          <p>When it comes to how to get approved for a personal loan, a borrower’s credit score usually plays a large role in whether they’re approved. A borrower’s credit score may also determine what kind of interest rates they receive—the higher the credit score, the lower the rates. Credit scores are tracked by three credit bureaus: Equifax, Experian, and TransUnion.</p>
                          <p>During the application process, many lenders offer soft credit checks. This allows borrowers to find out if they qualify with a lender and what types of rates they’ll get without damaging their credit score. If a borrower proceeds with the loan, the lender will have to run a hard credit check which can negatively impact their score temporarily.</p>
                          <p>Before applying for a loan, borrowers should check their credit score for any discrepancies that could potentially lower their score and, thus, prevent them from being approved. Borrowers can check their credit scores for free at AnnualCreditReport.com.</p>
                          <p>If a borrower has a poor credit score, it may be beneficial to wait until their credit score improves before applying for a loan. Borrowers with poor credit scores can have a difficult time qualifying for personal loans and may be subject to high-interest rates.</p>
                          <h2>2. Compare Estimated Rates</h2>
                          <p>It’s important for borrowers to do their research and compare personal loan rates before agreeing to sign with a lender. Many lenders offer prequalification, or soft credit checks, and borrowers can use these estimates to compare interest rates. Other items borrowers should compare between lenders are repayment terms, amounts, fees, and the minimum credit required. These factors may determine whether or not a borrower can take on a loan.</p>
                          <p>Borrowers can also use a personal loan calculator to estimate what their monthly repayment plan may look like. To use this, borrowers will typically need the amount being borrowed, the interest rate on the loan, and how long they have to pay it back. However, until you have an offer, a personal loan calculator may not provide exact numbers.</p>
                          <p>By thoroughly researching lenders, borrowers can compare the best personal loans available to try to obtain an offer that works best for them.</p>
                          <h2>3. Figure out a Payment Plan</h2>
                          <p>Before initiating the steps for applying for a personal loan, borrowers should determine how much they can afford to pay on a monthly basis. Borrowers can do this by creating a budget and putting together an estimated list of their income and expenses. This way, helped some borrowers determine whether they can afford the loan so they can avoid defaulting on it.</p>
                          <p>Borrowers should also calculate how large of a loan they’ll need and try to stick as closely to that as possible to avoid taking out too much. They’ll also need to account for fees that may be included with the loan. Some lenders charge borrowers origination or administrative fees that will come out of the total loan amount. This will impact how much money a borrower will receive. Thus, before agreeing to a loan, it’s important to compare origination fee charges.</p>
                          <p>To learn more, read our guide on fees commonly associated with personal loans.</p>
                          <h2>4. Consider All Available Options</h2>
                          <p>If a borrower has poor credit, they might have a difficult time qualifying with a personal loan lender. However, some lenders offer alternative means to qualify for a loan.</p>
                          <p>By using a co-signer, typically a trusted family member or friend, borrowers can bring in a third party that is willing to sign for the loan with them. In these instances, the co-signer usually has better credit than the original borrower and must take responsibility for the loan should the borrower stop making payments.</p>
                          <p>Borrowers can also seek out secured loans which require collateral. This can offset some of the risks a lender takes on when providing funds to an individual who might not otherwise qualify. However, if the borrower isn’t able to repay the loan, the lender can take possession of the collateral.</p>
                          <p>For those with poor credit, it may be worthwhile considering putting off the loan and improving your credit score in the meantime.</p>
                          <h2>5. Apply and Compare Lenders for the Best Rates</h2>
                          <p>Consumers don’t have to feel obligated to settle for the first offer they receive. Instead, it can be in their best interest to research lenders and compare interest rates, loan amounts, and repayment terms. As previously stated, some lenders allow borrowers to do soft credit inquiries to see what they qualify for. This can be a helpful tool in comparing lenders without damaging their credit score.</p>
                          <p>Steps for getting a personal loan may be different depending on the lender. Online lenders typically perform the entire process remotely while banks and credit unions may require that borrowers come in person to the institute’s branch.</p>
                          <h2>6. Read the Fine Print</h2>
                          <p>Even if borrowers feel comfortable going with a particular lender, it’s still important to read the fine print on any loan that is offered. Consumers don’t want to overlook any specifics that could come back and impact them financially down the road.</p>
                          <p>Important details to take note of are any fees associated with the loan, APR rates, automatic withdrawal options, how interest rates are impacted, and any bank account-related ramifications, such as if there is a need to maintain a certain minimum balance.</p>
                          <p>Borrowers may also want to examine a lender’s late payment and returned check policies as well as any fees associated with those.</p>
                          <p>While many lenders are flexible with how borrowers can use a personal loan, some lenders require that borrowers disclose the purpose of the loan which can also impact APR rates. Some lenders gear their personal loans toward specific purposes and may have prohibited uses as well.</p>
                          <h2>7. Accept The Loan And Start Repayment</h2>
                          <p>If a borrower is approved for a personal loan with a lender, they’ll be required to submit a hard credit check before signing and officially accepting the loan. The time it takes to receive a loan entirely depends on the lender. While some lenders offer same-day funding, it may take several business days for other lenders to deposit the money.</p>
                          <p>To avoid missing any repayment deadlines, borrowers will want to keep track of the payment schedule. Many lenders provide automatic payment services so borrowers can keep up on their due dates. Some lenders may even offer small discounts to borrowers who sign up for autopay features.</p>
                          <p>In order to save money in the long run, if borrowers have disposable income, they may want to consider making extra payments on the loan in order to pay it off more quickly. The longer you maintain a loan, the more interest will build on it.</p>
                          <h2 class="anchor_h2">What Information And Documentation Are Necessary?</h2>
                          <p>When applying for a loan, borrowers will fill out an initial application where they supply the lender with information about how much money they’d like to borrow and how they’d use the funds. This can include uses like debt consolidation, home improvement projects, or paying off medical bills. The borrower will also inform the lender as to their income (this can be in the form of alimony or social security), contact information, address, and where they work (if applicable).</p>
                          <p>After a lender receives the application, they typically will ask that borrowers provide documentation to verify the information in the personal loan application before they officially approve a borrower. This is to avoid cases of identity fraud as well as to make sure the borrower can repay the loan.</p>
                          <p>To keep the momentum of the application process going, borrowers will want to gather the required documents ahead of time. Examples of documentation a lender may ask for can include:</p>
                          <ul class="text1">
                              <li><span class="text_bold">Proof of income:</span>Copy of pay stub, W-2, bank statement, social security payments, or tax return</li>
                              <li><span class="text_bold">Proof of identification:</span>Copy of driver’s license, passport, or social security card</li>
                              <li><span class="text_bold">Address verification:</span>Proof of residence, utility bills, or lease agreements</li>
                              <li><span class="text_bold">Contact information:</span>Phone number, email, and mailing address</li>
                          </ul>
                          <h2 class="anchor_h2">Where Can the Best Personal Loans Be Found?</h2>
                          <p>The decision to take out a personal loan is complex and can involve a lot of time and research. However, while some options, like taking out a boat loan, can be pushed back so borrowers can do more research or get their credit score up, some personal loans for purposes like paying off medical debt may require a faster turnaround time.</p>
                          <p>Medical procedures and hospital stays can be expensive, even if an individual has insurance. This can make an already stressful situation even more taxing, especially if a person can’t afford the bills. A medical loan with a lender that offers flexible terms, low-interest rates, and few fees can make paying off medical bills much more feasible. For more information, read our in-depth guide on medical loans.</p>
                          <p>Even if someone purchases their dream home, there may still be projects that have to get done like replacing a deck, getting new carpeting, or putting in new appliances. While these updates are important to maintaining a home, they can be expensive fixes. A personal loan that you can pay off in increments as opposed to a lump sum can make these projects more attainable for homeowners. To learn more, read our definitive guide on home improvement loans.</p>
                          <p>Whether it’s new or used, purchasing a vehicle can be a costly endeavor. Consumers have to take into account fees, interest, repairs, and even inflation, all of which can quickly add up. By digging into our definitive guide on car loans, potential borrowers can get a read on where to find a car loan that best fits them.</p>
                          <p>Furthermore, our in-depth guide on boat loans can provide insight for borrowers looking to get a new watercraft. This type of loan makes the most sense if borrowers have a good credit score that may give them access to the lowest rates.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">How to Get a Personal Loan Approved in 7 Steps?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">What Information And Documentation Are Necessary?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Where Can the Best Personal Loans Be Found?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>