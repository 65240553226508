<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Nationwide Life Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance' }">Life Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Nationwide Life Insurance</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Nationwide Life Insurance</h1>
                          <img src="../../../../public/imgs/reviews_list/nationwide_life_insurance_review_1.jpg" alt="">
                          <h2 class="anchor_h2">The Development History of Nationwide Insurance.</h2>
                          <p>Let's take a look at this big, all-around company: Nationwide Insurance. In 1926, the Farm Bureau Mutual Automobile Insurance Co. was established in Ohio to sell only auto insurance. Over the next 10 years, it expanded into property and life insurance by acquiring American Life Insurance Company and a fire insurance company.</p>
                          <p>In 1955, the company changed its name to Nationwide. It was the first major insurer to endorse Medicare (before it was required by law) and the first to offer seat belt incentives to encourage increased seat belt use. In 1968, it began offering the first 24-hour claims reporting service.</p>
                          <p>For 60 years, Nationwide has created products for policyholders including retirement savings, long-term care benefits, sales systems that simplify underwriting, early warning tools and iPhone apps.</p>
                          <p>Today, Nationwide is a Fortune 100 company that provides auto, motorcycle, home, pet, farm, life and business insurance and other financial and professional medical services.</p>
                          <h2 class="anchor_h2">Nationwide Ranks High on Our List of the Best life Insurance Companies.</h2>
                          <p>Nationwide tops our list of the best life insurance companies for a number of reasons: generous no-cost benefits, excellent customer service, a solid financial foundation, and a streamlined exam waiver application process with coverage up to $5 million. With nationwide coverage, the company offers one of the largest portfolios of life insurance products we've seen. Let's see where his specific strengths lie.</p>
                          <p>Superior Customer Satisfaction: Nationwide's ability to delight customers is one of the top reasons we named it the Best Life Insurance Company of 2022. Ranked 2 nationally for customer satisfaction among 21 companies surveyed in J.D. Power's 2021 U.S. Individual Life Insurance Study. The company has also received far fewer consumer complaints than expected. Of the 91 companies we reviewed, the company was in the top 25% of size.</p>
                          <p>It may be true that there is one policy type for everyone nationwide. We checked the availability of six common policy types, including term, whole, universal (UL), indexed, variable, last-fee, and nationwide. This is important because if you like the company but aren't sure what kind of policy you need, you have plenty of options.</p>
                          <p>Most national policies cover chronic, critically and terminally ill patients at no additional cost. These riders allow you to receive an early death benefit if you suffer from one of the above conditions. In contrast, only 10 of the 91 companies we reviewed offered these three living benefits for free on some policies. Nationwide Insurance is our highest rated company and includes all three on most policies.</p>
                          <p>Nationwide offers an extremely high death benefit on the insurance policy and no medical examination is required. While you do need to be in good health to qualify for no medical checkup life insurance, many companies limit the maximum death benefit you can receive to less than $1 million.Also, many insurance companies don't allow you to pay your premiums with a credit card, but Nationwide does. </p>
                          <h2 class="anchor_h2">Nationwide Sells Four Types of Life Insurance.</h2>
                          <img src="../../../../public/imgs/reviews_list/nationwide_life_insurance_review_2.jpg" alt="">
                          <p>If you really want to buy <a href="https://www.nationwide.com/">Nationwide Life Insurance</a>, you can take a look at their company's products.</p>
                          <p>Nationwide sells four types of life insurance: term, whole, universal and variable universal. All of its policies require a medical examination.</p>
                          <p class="text_bold">Term Life</p>
                          <p>Term life insurance is one of the most popular forms of life insurance and is relatively inexpensive because it only provides coverage for a specific period. With Nationwide's YourLife Guaranted Level term policy, with terms ranging from 10 to 30 years, you can buy up to $1 million in coverage. The premium level of the plan is higher, which means it will not increase during the life of the policy.</p>
                          <p class="text_bold">Whole Life</p>
                          <p>Whole life insurance is much more expensive than term life insurance because it lasts a lifetime. In addition to the death benefit, whole life policies can also accumulate cash value. In accordance with Nationwide's policy, your account is guaranteed a fixed rate of cash value.</p>
                          <p class="text_bold">Universal Life</p>
                          <p>Like whole life insurance, universal life insurance is permanent. Both plans have a death benefit and cash value, but universal coverage is more flexible. You can reduce or increase your death benefit, and if you accumulate enough money in your policy, you can use your cash value to pay premiums.</p>
                          <p class="text_bold">Four Universal Life Options</p>
                          <img src="../../../../public/imgs/reviews_list/nationwide_life_insurance_review_3.jpg" alt="">
                          <ul class="text1">
                              <li><span class="text_bold">Indexed Universal Life Accumulator:</span> For those looking to maximize your policy's income potential, this plan is designed to provide a higher growth rate for your cash value.</li>
                              <li><span class="text_bold">Indexed Universal Life Protector:</span> This plan has a fixed benefit and a higher cash value growth rate than the standard lifetime plan.</li>
                              <li><span class="text_bold">Indexed Universal Life Accumulator:</span>Survivor's insurance plan covers two people and pays death benefits only if two people die.</li>
                              <li><span class="text_bold">YourLife care Matters:</span>This policy includes universal life insurance and long-term care coverage.</li>
                          </ul>
                          <p class="text_bold">Variable Life</p>
                          <p>Variable universal life policies are permanent. A portion of your premiums are deposited into a tax-deferred savings account, which you can choose to invest in selected funds nationwide. Since these accounts depend on the performance of the stock market, they have higher growth opportunities, but also higher risk.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">The Development History of Nationwide Insurance.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Nationwide Ranks High on Our List of the Best life Insurance Companies.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Nationwide Sells Four Types of Life Insurance.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>