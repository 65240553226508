<template>
    <div>
        <div class="container">
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>California Privacy Policy</h2>
                        </div>
                        <div class="text">
                            <p>Effective date: January 1, 2023</p>
                            <p>This California Privacy Policy contains disclosures required by the California Consumer Privacy Act (“CCPA”) and applies only to “personal information” that is subject to that law. Most of our data collection, use, and sharing practices are governed by our Gramm-Leach-Bliley Privacy Policy, however, if you are a California resident, CCPA may apply to our Services as they relate to business products featured on our site (for example, business credit cards and small business loans). This California Privacy Policy should be read in conjunction with the&nbsp;<a href="/privacy_policy">TopFinanceDirectory Privacy Policy</a> and <a href="/terms_of_use">TopFinanceDirectory Terms of Use</a>, which are incorporated herein by reference.</p>
                            <h2>A.&nbsp;Personal Information We Collect, Disclose for a Business Purpose, and Sell</h2>
                            <p>We collect the categories of personal information about California consumers identified in the chart below and use and disclose to third parties this information for business or commercial purposes. In addition, as is common practice among companies that operate online, we allow certain third parties, including nonaffiliated business partners, advertising networks, analytics providers, and other advertising providers, to directly collect information about your online activities on the Site and across your browsers and devices using cookies, web beacons, mobile advertising identifiers and other technologies. These third parties may use this information to display online advertisements tailored to your interests and preferences across your browsers and devices, to conduct ad campaign measurement and Site analytics, to detect, prevent and report fraud, or to carry out their own business and commercial purposes. The collection of information by some of these third parties may constitute a “sale” of personal information, as defined under the CCPA. For more information about these practices, please review the <strong>“Third-Party Web Beacons and Third-Party Buttons”</strong>&nbsp;section of the <a href="/privacy_policy">TopFinanceDirectory Privacy Policy</a>. Or, for more information about how to opt out of personal information sharing that constitutes a sale under the CCPA, please refer to the <strong>“Your Rights Regarding Personal Information”</strong>&nbsp;section below.</p>
                            <div class="nw-table" data-nw-component-type-name="Table" data-nw-component-type-slug="nw_table" data-nw-instance-id="735147640">
                            <div id="tablepress-6421-scroll-wrapper" class="tablepress-scroll-wrapper">
                            
                            <table id="tablepress-6421" class="tablepress tablepress-id-6421 slimtable centering-none table_visibility-public">
                            <thead>
                            <tr class="row-1 odd">
                            <th class="column-1">Categories of Personal Information </th><th class="column-2">Categories of sources from which information is collected:</th><th class="column-3">Business or commercial purposes for collection, use, and sharing:</th><th class="column-4">Disclosed for business purposes to the following categories of third parties:</th>
                            </tr>
                            </thead>
                            <tbody class="row-hover">
                            <tr class="row-2 even">
                            <td class="column-1">Personal and online identifiers such as first and last name, email address, or unique online identifiers</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-3 odd">
                            <td class="column-1">Sensitive Personal Information such as Social Security number, bank account number, or other financial information</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-4 even">
                            <td class="column-1">Characteristics of protected classifications under California or federal law (such as age or  gender)</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-5 odd">
                            <td class="column-1">Commercial or transaction information such as financial products or services purchased or obtained from or through us</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-6 even">
                            <td class="column-1">Internet or other electronic network activity information such as the websites you visit immediately before and after visiting our site and your interactions with our Services</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-7 odd">
                            <td class="column-1">Geolocation information</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-8 even">
                            <td class="column-1">Professional or employment-related information</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-9 odd">
                            <td class="column-1">Education information</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-10 even">
                            <td class="column-1">Inferences drawn from the above information about your predicted characteristics and preferences</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            <tr class="row-11 odd">
                            <td class="column-1">Other information about you that is linked to the personal information above</td><td class="column-2">All categories listed below.</td><td class="column-3">All purposes listed below.</td><td class="column-4">All categories listed below.</td>
                            </tr>
                            </tbody>
                            </table>

                            </div>	</div>

                            <h2>B. Categories of Sources</h2>
                            <p>We collect this personal information directly from you. We may also, at your direction, receive credit reports or scores from consumer reporting agencies such as TransUnion or Equifax, or third-party services that provide a mechanism to share information you have provided to the third party through the use of an application program interface (API), such as Facebook Connect or the Twitter API. In addition, you may direct and enable us to collect personal information you maintain in financial accounts with other third-party financial institutions and similar companies. We may also collect personal information about you (for example, information regarding how you interact with our Services) using automated tools as described elsewhere in this policy.</p>
                            <h2>C.&nbsp;Why We Collect, Use, and Share California Information</h2>
                            <p>We use and disclose the personal information we collect for our commercial and business purposes, as further described in this Privacy Policy.</p>
                            <p>Commercial purposes include, without limitation: marketing, advertising, authentication, identity resolution, fraud prevention, fulfillment services, and fulfilling the requests of our customers.</p>
                            <p>Business purposes, as identified in the CCPA, include: audits and related activities; legal compliance; detecting and protecting against security incidents, fraud, and illegal activity; debugging services and systems; administrative and support services such as processing orders and payments and analytics; internal research and operations; activities to maintain and improve our Services; and other one-time uses.</p>
                            <p>We disclose the categories of personal information designated above to the following categories of third parties: service providers, lending partners, referral partners, joint marketing partners, credit reporting agencies, financial service aggregators, government entities, advertising networks, internet service providers, data analytics providers, operating system providers, professional services organizations, and social networks.</p>
                            <p>We also allow third parties to collect information about your use of our site through third party cookies and the data collected by these third parties cookies may be used to display cross-context behavioral online advertisements tailored to your interests and preferences across your browsers and devices or to conduct ad campaign measurement.</p>
                            <h2>D.&nbsp;Your Rights Regarding Personal Information</h2>
                            <p>California residents have certain rights with respect to the personal information collected by businesses. If you are a California resident, you may exercise the following rights regarding your personal information, subject to certain exceptions and limitations:</p>
                            <ol>
                            <li>For certain categories of personal information, the <strong>right to request</strong> a list of what personal information (if any) we disclosed to third parties for their own direct marketing purposes in the preceding calendar year and the names and addresses of those third parties.</li>
                            <li>The <strong>right to know</strong> the categories and specific pieces of personal information we collect, use, disclose and, if applicable, sell about you; the categories of sources from which we collected your personal information; our purposes for collecting or, if applicable, selling your personal information; the categories of your personal information that we have disclosed for a business purpose or, if applicable, sold; and the categories of third parties to whom we have disclosed for a business purpose or, if applicable, sold personal information.</li>
                            <li>The <strong>right to request that we delete</strong> the personal information we have collected from you.</li>
                            <li>The <strong>right to data portability</strong>, meaning you have the right to obtain and reuse personal data covered by the CCPA for your own purposes.</li>
                            <li>The <strong>right to limit the use of your sensitive personal information</strong> to specifically permitted purposes.</li>
                            <li>The <strong>right to correct inaccurate</strong> personal information we have on you.</li>
                            <li>The <strong>right to opt out of our sale(s)</strong> <strong>or sharing</strong> of your personal information.</li>
                            <li>The <strong>right not to receive discriminatory treatment</strong> for the exercise of the privacy rights conferred by the CCPA.</li>
                            </ol>
                            <h2>E. Our Retention of Your Personal Information</h2>
                            <p>Unless you specifically ask us to delete your personal information, we retain your personal information as long as it is necessary to comply with our data retention requirements and provide you with the Services you have requested and successfully run our business. However, even if you request a deletion, we may be required to maintain your information for as long as necessary to:</p>
                            <ul>
                            <li>comply with our legal or regulatory compliance needs (e.g. maintaining records of transactions you have made with us);</li>
                            <li>to exercise, establish or defend legal claims; and/or</li>
                            <li>to protect against fraudulent or abusive activity on our Services.</li>
                            </ul>
                            <p>This means we may keep different information for different periods. If your account is canceled because you’ve not used it in a long time, we may delete this information straight away.</p>
                            <p>There may be occasions where we are unable to fully delete, anonymize, or de-identify your information due to technical, legal, regulatory compliance or other operational reasons. Where this is the case, we will take reasonable measures to securely isolate your personal information from any further processing until such time as we are able to delete, anonymize, or de-identify it.</p>
                            <h2>F. How to Exercise Your California Consumer Rights</h2>
                            <p><em><strong>To Exercise Your Right to Request, Know or Delete</strong></em></p>
                            <p>To exercise your California consumer rights to request, know or delete, please complete the Privacy Request Form, which includes required verifying information, as further described below. You may submit the completed and signed CCPA Request Form by:</p>
                            <ul>
                            <li>Visiting the TopFinanceDirectory privacy portal,</li>
                            <li>Sending us an email at <a href="mailto:demand@samsonads.com">demand@samsonads.com</a> with the subject line “CCPA Request,” the Form attached and the relevant right identified in the body of the email,</li>
                            <li>clicking “Submit a Question,” then selecting “Other Questions or Concerns” from the drop-down menu and attaching the Form with “CCPA Request” in the subject line and the relevant right identified in the description section.<a name="opt_out"></a></li>
                            </ul>
                            <p><em><strong>To Opt Out of Sales or Sharing of Personal Information</strong></em></p>
                            <p>To the extent that the third-party information collection on the Site constitutes the sale or sharing of personal information, we provide you with instructions for how you can exercise your right to opt out of certain cookie-related collection and use practices.</p>
                            <p>On certain of our websites, users can make choices regarding the use of cookies and related technologies through a cookie banner management tool. On all our other websites and digital properties, users can:</p>
                            <ol>
                            <li>Visit privacyrights.info to opt out from sales of this type of personal information by third-party businesses that participate in the DAA’s CCPA Opt-Out Tool, <strong>and</strong></li>
                            <li>Visit&nbsp;<a href="https://myaccount.google.com/data-and-personalization">myaccount.google.com/data-and-personalization</a>, scroll down to the “Ad personalization” section, click “Go to ad settings” and toggle “Ad personalization” to OFF, <strong>and</strong></li>
                            <li>Follow the instructions in the <strong>“Third-Party Web Beacons and Third-Party Buttons”</strong>&nbsp;section in the&nbsp;TopFinanceDirectory Privacy Policy to opt out of interest-based advertising and other cookie-related information collection and processing.</li>
                            </ol>
                            <p>To exercise any other CCPA rights, complete and submit the CCPA Request Form, which includes required verifying information, as further described below:</p>
                            <ol>
                            <li>Send us an email at <a href="mailto:demand@samsonads.com">demand@samsonads.com</a> with the subject line “CCPA Request” and the relevant right identified in the body of the email, <strong>or</strong></li>
                            <li>click “Submit a Question,” then select “Other Questions or Concerns” from the drop-down menu and submit with “CCPA Request” in the subject line and the relevant right identified in the description section.</li>
                            </ol>
                            <h2>G. Verification Process and Required Information</h2>
                            <p>Note that we may need to request additional information from you to verify your identity or understand the scope of your request, although you will not be required to create an account with us to submit a request or have it fulfilled. We will require you to provide information that we have on record for identity verification purposes. In certain circumstances, we may decline a request to exercise the right to know and/or right to deletion, particularly where we are unable to verify your identity.</p>
                            <h2>H. Authorized Agent</h2>
                            <p>You may designate an authorized agent to make a CCPA request on your behalf by writing to us at the contact information provided above. To the extent we are unable to verify your identity when you make the designation request, we may request additional information from you. Note, if designating an entity to act on your behalf, the CCPA requires that such entity must be registered with the California Secretary of State.</p>
                            <h2>I. Minors’ Right to Opt-In</h2>
                            <p>We do not sell the personal information of consumers we know to be under 16&nbsp;years of age.</p>
                            <h2>J. Small Business Loan Information</h2>
                            <p>From time to time, you may provide us with your personal information for the purpose of requesting small business loans from one or more of our partners. You agree that the disclosure of your personal information in connection with your request is not a sale of your personal information under the CCPA.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ccpa",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            is_content_anchor: true
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 15px 0 14px 0;
                    display: flex;
                    line-height: 21px;
                    font-size: 14px;
                    color: #666666;
                }
                .text {
                    margin-top: 40px;
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                        text-align: left;
                    }
                    table {
                        text-align: center;
                        th {
                            background-color: #2ac8a2;
                            color: #fff;
                            padding: 31px 40px;
                        }
                        td {
                            padding: 30px 25px;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #fff;
                            line-height: 20px;
                            background-color: #2ac8a2;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                            border-radius: 12px 12px 0px 0px;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 25px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px 45px;
                                padding-bottom: 15px;
                                div {
                                    font-size: 18px;
                                    line-height: 18px;
                                    color: #222831;
                                    text-align: left;
                                }
                                .active {
                                    font-weight: bold;
                                    color: #222831;
                                }
                            }
                            li:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 954px;
            }
        }
    }
    .article_bottom {
        background-color: #f5f8fb;
        .article_text {
            .article_text_left {
                .text {
                    margin: 30px 0 220px 0
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .article_container {
            margin-top: 0px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                    .text {
                        h2 {
                            text-align: left;
                        }
                    }
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        text-align: justify;
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                        table {
                            th {
                                padding: 25px 0;
                            }
                            td {
                                padding: 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
</style>