<template>
    <div>
        <div class="container">
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>How We Rate</h2>
                        </div>
                        <div class="text">
                            <p>There are dozens of brands offering products and services online, but it can be difficult to filter through the endless information and verify its accuracy. We have created comparison websites to help you compare key elements and features, and make an informed decision. Our goal is to save you time and effort by simplifying and clarifying the decision process, while choosing a service or product that suits your needs.Trying to differentiate between all the available options is not easy. There are brands you might already be familiar with, and that can be a good starting point, but how do you know that the brands shouting the loudest are really the best suited for your needs? There are in fact huge differences between brands, including in the level of service, the terms and conditions, the specific features, and a whole host of other factors.We have a team of reviewers who perform market research, conduct in-depth analyses, aggregate data, and evaluate the information we collect. According to the evaluation, we present the brands that we believe offer high quality products and services. There are quite a few aspects we consider when we review and rate, including our own subjective evaluation and internal methodology. The following is an explanation the review, rating and scoring elements.</p>
                            <h2>Our Review</h2>
                            <p>The team researches, evaluates, and compares the key features of each brand, product, and service prior to displaying it.</p>
                            <h2>User’s experience and brands’ reputation</h2>
                            <p>User feedback and experience are important to us, so we take these into consideration when we rate products and services. We also encourage our users to rate, comment, and share their experiences on our site. These reviews and ratings help us understand the popularity and quality of the product.</p>
                            <h2>Pricing</h2>
                            <p>Where applicable, we will take into consideration the best price for a similar service.</p>
                            <h2>Features</h2>
                            <p>We evaluate and compare each of the service or product’s features. We look at the features and elements that are common or essential to each product or service type, as well as special features that a brand, product, or service provides.</p>
                            <h2>Popularity and conversion rates</h2>
                            <p>Data gives us a clear picture of how our users feel about a particular brand. We can measure interest in each product or service by observing the number of users who visit their website, as well as the conversion rate. The conversion rate measures how many people sign up with – or purchase from – a brand after visiting their website. In our rating and scoring we take into consideration the conversion rates because we believe it is an indication of its popularity, and thus, its value. For example, if a specific service’s site is accessed by a significant number of users, or has a significant amount of registered users, we believe it indicates users’ preference and satisfaction.</p>
                            <h2>Advertising Fees</h2>
                            <p>We are able to maintain a free, high-quality service and provide you with the comparison information free of charge by receiving advertising fees. This means that we receive fees from the brands and service providers we review on this website. These advertising fees , combined with our criteria and methodology explained above, impact the placement and position of the brands within the comparison table. In the event that rating or scoring are assigned by us, this will be based on the position of the brand in the comparison table.However, the advertising fees we receive are NOT the main criteria. If we find a specific brand not to be trustworthy or top rated, we will not present it on our website. One thing we will never do is promote a brand or services we don’t trust or where the feedback has been overwhelmingly negative.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "how_we_rate",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            is_content_anchor: true
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 15px 0 14px 0;
                    display: flex;
                    line-height: 21px;
                    font-size: 14px;
                    color: #666666;
                }
                .text {
                    margin-top: 40px;
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    table {
                        text-align: center;
                        th {
                            background-color: #2ac8a2;
                            color: #fff;
                            padding: 31px 114px;
                        }
                        td {
                            padding: 30px 25px;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #fff;
                            line-height: 20px;
                            background-color: #2ac8a2;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                            border-radius: 12px 12px 0px 0px;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 25px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px 45px;
                                padding-bottom: 15px;
                                div {
                                    font-size: 18px;
                                    line-height: 18px;
                                    color: #222831;
                                    text-align: left;
                                }
                                .active {
                                    font-weight: bold;
                                    color: #222831;
                                }
                            }
                            li:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 954px;
            }
        }
    }
    .article_bottom {
        background-color: #f5f8fb;
        .article_text {
            .article_text_left {
                .text {
                    margin: 30px 0 220px 0
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .article_container {
            margin-top: 0px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                    .text {
                        h2 {
                            text-align: left;
                        }
                    }
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        text-align: justify;
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                        table {
                            th {
                                padding: 25px 0;
                            }
                            td {
                                padding: 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
</style>