import { render, staticRenderFns } from "./nationwide_life_insurance_review.vue?vue&type=template&id=cf98e260&scoped=true&"
import script from "./nationwide_life_insurance_review.vue?vue&type=script&lang=js&"
export * from "./nationwide_life_insurance_review.vue?vue&type=script&lang=js&"
import style0 from "./nationwide_life_insurance_review.vue?vue&type=style&index=0&id=cf98e260&lang=scss&scoped=true&"
import style1 from "./nationwide_life_insurance_review.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf98e260",
  null
  
)

export default component.exports