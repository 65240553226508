<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Are You Making Multiple Payments With High Interest?</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">Resolve your debt with one low monthly payment program</div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_list">
                    <div class="article_list_left">
                        <div class="text">
                            <h2>What is Debt Relief?</h2>
                            <img src="/imgs/debt_relief_article_imgs1.png" alt="">
                            <h3>Don’t let debt overwhelm you</h3>
                            <p>Living with debt can be very stressful, not only to you but also to your bank account. Luckily, you don’t have to suffer. There are services out there that can help you manage and consolidate your debt. Once you know your credit score and have a comprehensive overview of your debt, you’re ready to start tackling goals. While becoming debt free is probably your main goal, it’s best to start smaller, based on your situation. That’s where the expertise of a debt relief expert comes in handy!</p>
                            <h3>Who it’s meant for and what it does</h3>
                            <p>Debt relief is a solution for individuals that want to manage multiple types of debt with a single monthly payment. A debt relief company will negotiate all your debts to help you get better monthly payments and interest rates. They may even be able to negotiate down some of your current debts. Then, you’ll pay your debt relief company one monthly payment to go toward all your debts. Debt relief providers will typically charge a fee for services, so be prepared to spend just a little of money for that expert advice and actionable steps to improve your situation!</p>
                        </div>
                    </div>
                    <div class="article_list_right">
                    </div>
                </div>
            </div>
            <div class="introduction_container">
                <div class="introduction">
                    <div class="left_box">
                        <div class="text">
                            <h2>Benefits</h2>
                            <p>If you have overwhelming debt that cannot be settled, bankruptcy is also an option. After taking the means test to determine if you should enroll for bankruptcy, it could allow you to just walk away from your debt and hit the reset button, but at a cost to your credit score.</p>
                            <p> That’s where debt settlement companies come in. The longer you go without making a payment, the more likely your creditor is willing to forgive. So if you don’t see bankruptcy as an option, but you want to settle some of your debts, hiring a debt relief company can make sense</p>
                        </div>
                    </div>
                    <div class="right_box">
                        <div class="text">
                            <h2>Drawbacks</h2>
                            <p>Debt relief companies will manage your relationship with your creditors in order to negotiate your debt and reduce the amount of money you owe. Essentially, you agree to stop paying your creditors and instead, deposit money into an escrow account each month managed by the debt relief company. As you continue to miss payments, your creditors will continue to lose money, to a point where they may be willing to settle for less than what you owe. But not all creditors will work with debt settlement companies, the FTC and the CFPB warn. This will also have a negative impact on your credit score in the short run.</p>
                            <p> Debt relief companies cannot help with debts that are secured with collateral, such as an auto loan or home mortgage. They also cannot help with federal student loans or state and federal taxes.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="map_container">
                <div class="map">
                    <div class="text3">
                        <h2>Places with the highest rates of debt delinquency</h2>
                        <p>States With the Highest Rates of Debt Delinquency (90 Days Late or More)</p>
                    </div>
                    <div id="main"></div>
                </div>
            </div>
            <div class="table_container">
                <div class="table">
                    <el-table
                        :data="tableData"
                        height="450"
                        border
                        stripe
                        header-cell-style="background:#2ac8a2;color:#fff;text-align:center;"
                        style="width: 100%">
                        <el-table-column
                            prop="rank"
                            label="Rank"
                            width="60">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="State">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="%of Adults Who Are Delinquent">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Delinquent Deby Per Capita(All Adults)">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Total Delinquent Balances($Billions)">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="No.of Delinquent Adults">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Adults Delinquent on Student Loans">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Adults Delinquent on Credit Cards">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Adults Delinquent on Auto Loans">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="Adults Delinquent on Mortgages">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="text3">
                    <p>Source: LendingTree analysis of Federal Reserve, TransUnion and anonymized My LendingTree user data. <br /><br />Notes: Per Capita is the total outstanding personal debt divided by the number of adults in that state.</p>
                    <!-- <div class="text_botton_box">
                        <span class="text_button"><img src="/imgs/debt_relief_share_button.png" alt=""> Share</span>
                    </div> -->
                </div>
            </div>
            <div class="knowledge-center-box">
                <span class="knowledge-title">Knowledge Center</span>
                <div class="line"></div>
                <span class="knowledge-content">VIEW OUR MOST FREQUENTLY ASKED MORTGAGE QUESTIONS</span>
                <div class="card-list">
                <!-- 轮播图和左侧的导航菜单 -->
                <div class="swiper-box">
                    <!-- 轮播图 -->
                    <swiper :options="swiperOption">
                    <swiper-slide v-for="(item, index) in slideList" :key="index">
                        <!--<a :href="'/#/product/' + item.id">-->
                        <a :href="item.url">
                        <img :src="item.img" alt="">
                        </a>
                        <p class="card-title">{{item.title}}</p>
                        <p class="card-text">{{item.text}}</p>
                    </swiper-slide>
                    <!--<div class="swiper-pagination" slot="pagination"></div>-->
                    <!-- <div class="swiper-button-prev" slot="button-prev">
                        <img src="/imgs/icon_left.png" alt="">
                    </div>
                    <div class="swiper-button-next" slot="button-next">
                        <img src="/imgs/icon_right.png" alt="">
                    </div> -->
                    </swiper>
                    <div class="swiper-prev" slot="button-prev">
                        <img src="/imgs/icon_left.png" alt="">
                    </div>
                    <div class="swiper-next" slot="button-next">
                        <img src="/imgs/icon_right.png" alt="">
                    </div>
                    <a href="/blog_list" class="button-read">Read More</a>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import usaJosn from './USA'

export default {
    name: "debt_relief",
    components: {
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            input1: '4,0000',
            input2: '5',
            input3: '9',
            value: 1,
            options: [{value: 1, label: 'Months'}, {value: 2, label: 'Years'}],
            // 轮播图设置
            swiperOption: {
                // autoplay: true,
                loop: true,
                simulateTouch: true,
                allowTouchMove: true,
                // 轮播图动画
                // effect: 'cube',
                slidesPerView: 'auto',
                spaceBetween: 20,
                // 轮播图分页
                pagination: {
                el: '.swiper-pagination',
                clickable: true
                },
                // 导航
                navigation: {
                nextEl: '.swiper-prev',
                prevEl: '.swiper-next'
                }
            },

            // 轮播图列表
            slideList: [
                {
                id: '42',
                img: '/imgs/card_list1.jpg',
                title: '5 Personal Loan Mistakes to Avoid',
                text: "Getting approved for a personal loan isn't always easy, and there are some pitfalls you'll want to avoid in the process. Let's take a look at five of the most common ones so you can stay financially responsible while putting money in your pocket.",
                url: "/Personal_Loan_Mistakes_to_Avoid"
                },
                {
                id: '45',
                img: '/imgs/card_list2.jpg',
                title: 'Can You Refinance Student Loans More than Once',
                text: "Whether it's your student loans, a mortgage, or a personal loan, refinancing can be a smart move that has the potential to save you money on your existing loans by lowering payments or securing a lower interest.",
                url: "/Can_You_Refinance_Student_Loans_More_than_Once"
                },
                {
                id: '46',
                img: '/imgs/card_list3.jpg',
                title: 'Choosing the Right Life Insurance Company For You',
                text: "Choosing any kind of insurance, whether it's health insurance, car insurance, life insurance, or any other type, really, can be a challenger to navigate if you've never done it before!",
                url: "/Choosing_the_Right_Life_Insurance_Company_For_You"
                },
            ],
            tableData: [
                { name: 'Alabama', value: 4822023 },
                { name: 'Alaska', value: 731449 },
                { name: 'Arizona', value: 6553255 },
                { name: 'Arkansas', value: 2949131 },
                { name: 'California', value: 38041430 },
                { name: 'Colorado', value: 5187582 },
                { name: 'Connecticut', value: 3590347 },
                { name: 'Delaware', value: 917092 },
                { name: 'District of Columbia', value: 632323 },
                { name: 'Florida', value: 19317568 },
                { name: 'Georgia', value: 9919945 },
                { name: 'Hawaii', value: 1392313 },
                { name: 'Idaho', value: 1595728 },
                { name: 'Illinois', value: 12875255 },
                { name: 'Indiana', value: 6537334 },
                { name: 'Iowa', value: 3074186 },
                { name: 'Kansas', value: 2885905 },
                { name: 'Kentucky', value: 4380415 },
                { name: 'Louisiana', value: 4601893 },
                { name: 'Maine', value: 1329192 },
                { name: 'Maryland', value: 5884563 },
                { name: 'Massachusetts', value: 6646144 },
                { name: 'Michigan', value: 9883360 },
                { name: 'Minnesota', value: 5379139 },
                { name: 'Mississippi', value: 2984926 },
                { name: 'Missouri', value: 6021988 },
                { name: 'Montana', value: 1005141 },
                { name: 'Nebraska', value: 1855525 },
                { name: 'Nevada', value: 2758931 },
                { name: 'New Hampshire', value: 1320718 },
                { name: 'New Jersey', value: 8864590 },
                { name: 'New Mexico', value: 2085538 },
                { name: 'New York', value: 19570261 },
                { name: 'North Carolina', value: 9752073 },
                { name: 'North Dakota', value: 699628 },
                { name: 'Ohio', value: 11544225 },
                { name: 'Oklahoma', value: 3814820 },
                { name: 'Oregon', value: 3899353 },
                { name: 'Pennsylvania', value: 12763536 },
                { name: 'Rhode Island', value: 1050292 },
                { name: 'South Carolina', value: 4723723 },
                { name: 'South Dakota', value: 833354 },
                { name: 'Tennessee', value: 6456243 },
                { name: 'Texas', value: 26059203 },
                { name: 'Utah', value: 2855287 },
                { name: 'Vermont', value: 626011 },
                { name: 'Virginia', value: 8185867 },
                { name: 'Washington', value: 6897012 },
                { name: 'West Virginia', value: 1855413 },
                { name: 'Wisconsin', value: 5726398 },
                { name: 'Wyoming', value: 576412 },
                { name: 'Puerto Rico', value: 3667084 }
            ]
        }
    },
    mounted() {
        this.drawChart()
        this.getdatle()
    },
    methods: {
        drawChart() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("main"));
        myChart.hideLoading();
        this.$echarts.registerMap('USA', usaJosn, {
            Alaska: {
                left: -131,
                top: 25,
                width: 15
            },
            Hawaii: {
                left: -110,
                top: 28,
                width: 5
            },
            'Puerto Rico': {
                left: -76,
                top: 26,
                width: 2
            }
        })
        // 指定图表的配置项和数据
        let option = {
            title: {
            text: ' ',
            subtext: ' ',
            sublink: 'http://www.census.gov/popest/data/datasets.html',
            left: 'right'
            },
            tooltip: {
            trigger: 'item',
            showDelay: 0,
            transitionDuration: 0.2
            },
            visualMap: {
            left: 'right',
            min: 500000,
            max: 38000000,
            inRange: {
                color: [
                '#313695',
                '#4575b4',
                '#74add1',
                '#abd9e9',
                '#e0f3f8',
                '#ffffbf',
                '#fee090',
                '#fdae61',
                '#f46d43',
                '#d73027',
                '#a50026'
                ]
            },
            text: ['High', 'Low'],
            calculable: true
            },
            toolbox: {
            show: false,
            //orient: 'vertical',
            left: 'left',
            top: 'top',
            feature: {
                dataView: { readOnly: false },
                restore: {},
                saveAsImage: {}
            }
            },
            series: [
            {
                name: 'USA PopEstimates',
                type: 'map',
                roam: true,
                map: 'USA',
                emphasis: {
                label: {
                    show: true
                }
                },
                data: [
                { name: 'Alabama', value: 4822023 },
                { name: 'Alaska', value: 731449 },
                { name: 'Arizona', value: 6553255 },
                { name: 'Arkansas', value: 2949131 },
                { name: 'California', value: 38041430 },
                { name: 'Colorado', value: 5187582 },
                { name: 'Connecticut', value: 3590347 },
                { name: 'Delaware', value: 917092 },
                { name: 'District of Columbia', value: 632323 },
                { name: 'Florida', value: 19317568 },
                { name: 'Georgia', value: 9919945 },
                { name: 'Hawaii', value: 1392313 },
                { name: 'Idaho', value: 1595728 },
                { name: 'Illinois', value: 12875255 },
                { name: 'Indiana', value: 6537334 },
                { name: 'Iowa', value: 3074186 },
                { name: 'Kansas', value: 2885905 },
                { name: 'Kentucky', value: 4380415 },
                { name: 'Louisiana', value: 4601893 },
                { name: 'Maine', value: 1329192 },
                { name: 'Maryland', value: 5884563 },
                { name: 'Massachusetts', value: 6646144 },
                { name: 'Michigan', value: 9883360 },
                { name: 'Minnesota', value: 5379139 },
                { name: 'Mississippi', value: 2984926 },
                { name: 'Missouri', value: 6021988 },
                { name: 'Montana', value: 1005141 },
                { name: 'Nebraska', value: 1855525 },
                { name: 'Nevada', value: 2758931 },
                { name: 'New Hampshire', value: 1320718 },
                { name: 'New Jersey', value: 8864590 },
                { name: 'New Mexico', value: 2085538 },
                { name: 'New York', value: 19570261 },
                { name: 'North Carolina', value: 9752073 },
                { name: 'North Dakota', value: 699628 },
                { name: 'Ohio', value: 11544225 },
                { name: 'Oklahoma', value: 3814820 },
                { name: 'Oregon', value: 3899353 },
                { name: 'Pennsylvania', value: 12763536 },
                { name: 'Rhode Island', value: 1050292 },
                { name: 'South Carolina', value: 4723723 },
                { name: 'South Dakota', value: 833354 },
                { name: 'Tennessee', value: 6456243 },
                { name: 'Texas', value: 26059203 },
                { name: 'Utah', value: 2855287 },
                { name: 'Vermont', value: 626011 },
                { name: 'Virginia', value: 8185867 },
                { name: 'Washington', value: 6897012 },
                { name: 'West Virginia', value: 1855413 },
                { name: 'Wisconsin', value: 5726398 },
                { name: 'Wyoming', value: 576412 },
                { name: 'Puerto Rico', value: 3667084 }
                ]
            }
            ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
        },
        getdatle() {
            for (let i = 0; i < this.tableData.length; i++) {
                this.tableData[i]['rank'] = i + 1
                this.tableData[i]['address'] = i + 1 + '%'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .introduction_container {
        width: 100%;
        background-color: #f5f8fb;
        .introduction {
            width: 61%;
            margin: 50px auto 50px;
            display: flex;
            justify-content: space-between;
            .left_box {
                width: 45%;
            }
            .right_box {
                width: 45%;
            }
            h2 {
                position:relative;
            }
            h2:before {
                width: 54px;
                height: 7px;
                background: #2ac8a2;
                border-radius: 12px;
                content: '';
                display: block;
                position: absolute;
                top: 45px;
            }
            .text {
                margin-top: 40px;
                font-size: 18px;
                color: #666666;
                line-height: 24px;
                margin-bottom: 25px;
                h3 {
                    margin-bottom: 10px;
                }
                p {
                    margin-bottom: 25px;
                }
                img {
                    width: 100%;
                    margin-bottom: 25px;
                }
                h2 {
                    font-size: 36px;
                    font-weight: 700;
                    color: #222831;
                    margin-bottom: 35px;
                    line-height: 36px;
                }
                table {
                    text-align: center;
                    margin-bottom: 35px;
                    th {
                        background-color: #2ac8a2;
                        color: #fff;
                    }
                }
            }
        }
    }
    .map_container {
        width: 100%;
        .map {
            width: 61%;
            margin: 0 auto 57px;
            #main {
                width: 1180px;
                height: 386px;
            }
        }
    }
    .table_container {
        width: 100%;
        .table {
            width: 61%;
            margin: 50px auto 50px;
        }
    }
    .knowledge-center-box {
        margin-top: 100px;
        margin-bottom: 100px;
        width: 100%;
        height: 800px;
        //display: flex;
        //flex-direction: column;
        //justify-content: center;
        //align-items: center;
        text-align: center;

        .knowledge-title {
        display: inline-block;
        margin-bottom: 10px;
        font-size: 48px;
        font-family: Roboto, Roboto-Regular, San Francisco Display-Bold, San Francisco Display;
        font-weight: bold;
        color: #353449;
        line-height: 56px;
        }

        .line {
        width: 54px;
        height: 7px;
        margin: auto auto;
        background: #2AC8A2;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        }

        .knowledge-content {
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 233px;
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #666666;
        }

        .card-list {
        background: #F6F6F6;
        position: relative;
        height: 500px;
        
        .swiper-box {

            //--swiper-theme-color: #ff6600;/* 设置Swiper风格 */
            //--swiper-navigation-color: #00ff33;/* 单独设置按钮颜色 */
            //--swiper-navigation-size: 30px;/* 设置按钮大小 */
            position: absolute;
            top: -163px;
            left: 50%;
            transform: translateX(-50%);
            width: 1140px;
            margin: auto auto;
            .swiper-slide {
            width: 360px;
            }
            img {
            display: inline-block;
            width: 359px;
            height: 359px;
            border-radius: 12px 12px 12px 12px;
            }

            .card-title {
            font-size: 18px;
            font-weight: bold;
            color: #353449;
            margin-top: 15px;
            margin-bottom: 15px;
            text-align: left;
            padding-left: 20px;
            position:relative;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            }
            .card-title:before {
            width: 5px;
            height: 5px;
            background: #2ac8a2;
            border-radius: 50%;
            content: '';
            display: block;
            position: absolute;
            top: 3px;
            left: -1px;
            }
            .card-title:after {
            width: 5px;
            height: 5px;
            background: #c4c4c4;
            border-radius: 50%;
            content: '';
            display: block;
            position: absolute;
            top: 13px;
            left: -1px;
            }
            
            .card-text{
            font-size: 14px;
            width: 359px;
            text-align: justify;
            }
            .swiper-button-prev img {
            padding-left: 15px;
            width: 76px;
            height: 76px;
            }

            .swiper-button-next img {
            width: 76px;
            height: 76px;
            }

            // 消除左侧的导航按钮
            .swiper-button-prev:after, .swiper-container-rtl, .swiper-button-next:after {
            content: ''
            }
        }

        .swiper-prev {
            position: relative;
            img{
            position: absolute;
            top: -271px;
            left: -110px;
            padding-left: 15px;
            width: 76px;
            height: 76px;
            }
        }

        .swiper-next {
            position: relative;
            img {
            position: absolute;
            top: -271px;
            left: 1155px;
            width: 76px;
            height: 76px;
            }
        }

        .button-read {
            display: inline-block;
            margin-top: 40px;
            margin-bottom: 59px;
            // width: 178px;
            // height: 37px;
            padding:3px 30px;
            background: #2AC8A2;
            border-radius: 50px 50px 50px 50px;

            font-size: 18px;
            line-height: 37px;
            font-family: Roboto-Bold, Roboto;
            font-weight: bold;
            color: #FFFFFF;
        }
        }
    }
    
    @media (max-width: 1199px) {
        .introduction_container {
            .introduction {
                width: 80%;
                flex-direction: column;
                .left_box {
                    width: 100%;
                }
                .right_box {
                    width: 100%;
                }
                .text {
                    line-height: 30px;
                    h2 {
                        line-height: 45px;
                    }
                }
            }
        }
        .map_container {
            .map {
                width: 91%;
                #main {
                    width: 600px;
                    margin: auto;
                }
            }
        }
        .table_container {
            .table {
                width: 90%;
            }
        }
        .knowledge-center-box {
        .card-list {
            .swiper-box {
            width: 760px;
            left: 50%;
            transform: translateX(-50%);
            .swiper-prev {
                display: none;
            }
            .swiper-next {
                display: none;
            }
            }
        }
        }
    }
    @media (max-width: 760px) {
        .introduction_container {
            .introduction {
                .text {
                    h2 {
                        font-size: 27px;
                        line-height: 35px;
                    }
                }
            }
        }
        .map_container {
            .map {
                width: 91%;
                #main {
                    width: 340px;
                    margin: auto;
                }
            }
        }
        .knowledge-center-box {
        margin-top: 35px;
        margin-bottom: 375px;
        .card-list {
            .swiper-box {
            width: 360px;
            left: 50%;
            transform: translateX(-50%);
            .swiper-slide {
                width: 359px;
            }
            .swiper-prev {
                display: none;
            }
            .swiper-next {
                display: none;
            }
            }
        }
        }
    }
}
</style>

<style lang="scss"  scoped>
.text3 {
    margin-top: 40px;
    font-size: 18px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 25px;
    text-align: center;
    p {
        margin-bottom: 25px;
    }
    img {
        width: 100%;
        margin-bottom: 25px;
    }
    h2 {
        font-size: 36px;
        font-weight: 700;
        color: #222831;
        margin-bottom: 25px;
        line-height: 36px;
    }
    table {
        text-align: center;
        margin-bottom: 35px;
        th {
            background-color: #2ac8a2;
            color: #fff;
        }
    }
    .text_botton_box {
        padding: 13px 60px;
        background: #2ac8a2;
        width: 200px;
        margin: auto;
        border-radius: 50px;
        .text_button {
            color: #fff;
            display: flex;
            justify-content: center;
            img {
                width: 23px;
                margin: 0 15px 0 0;
            }
        }
    }
}
.text_justify {
    text-align: justify;
}
</style>

<style scoped>
    .amount_input >>> .el-input__inner {
        border-radius: 12px;
        height: 77px;
        font-size: 36px;
        font-weight: 700;
        color: #222831;
    }
    .amount_input >>> .el-input__prefix {
        display: flex;
        align-items: center;
    }
    .time_input_date >>> .el-input__inner,
    .time_input_my >>> .el-input__inner {
        height: 44px;
        font-size: 14px;
        font-weight: bold;
        color: #222831;
    }
    .time_input_date >>> .el-input__inner {
        border-radius: 12px 0 0 12px;
    }
    .time_input_my >>> .el-input__inner {
        text-align: center;
        border-left: none;
        border-radius: 0 12px 12px 0;
    }
    .interest_rate_input >>> .el-input__suffix {
        display: flex;
        align-items: center;
    }
    .interest_rate_input >>> .el-input__inner {
        font-size: 14px;
        font-weight: 700;
        color: #222831;
        line-height: 14px;
        border-radius: 12px;
    }
</style>