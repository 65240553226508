<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">The Ultimate Guide to Mortgage Loans</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_subtitle">Purchasing a new home can be an exciting milestone in someone’s life. However, from putting in an application to saving up for a down payment, there are a lot of steps involved in getting a mortgage approved. Homebuyers may want to educate themselves on the process in order to avoid any unexpected surprises and maintain the momentum of any progress.</div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2 class="anchor_h2">How Mortgage Applications are Reviewed?</h2>
                          <p>When purchasing a new home, there is a step-by-step mortgage process. Before they even submit an application for a mortgage, homebuyers will have to put forth a lot of preparation. First, buyers should check their credit scores and ensure that it’s at a good place where they can get approved for a loan, specifically looking at factors such as payment history, debt-to-income ratio, and credit utilization rate. Buyers should also get a copy of their credit report and thoroughly check it for discrepancies. </p>
                          <p>Though requirements may vary from lender to lender, applicants will generally need to gather the following documentation to present to the mortgage company:</p>
                          <ul class="text1">
                              <li>Contact information</li>
                              <li>Proof of address</li>
                              <li>Government-issued ID</li>
                              <li>Proof of employment</li>
                              <li>Proof of income</li>
                              <li>Rental history</li>
                              <li>Credit report</li>
                          </ul>
                          <p>Once all the documentation has been gathered, the information will be given to an underwriter who will then begin the review process. In any case, before jumping into the mortgage application process, those who are new to mortgages can read our first-time homebuyers guide.</p>
                          <h2 class="anchor_h2">What Does an Underwriter Look At?</h2>
                          <p>Underwriters are an integral part of how mortgages are approved. After a homebuyer turns in their mortgage application, it is the underwriter’s job to examine it and make sure it aligns with the loan’s criteria. An underwriter will also examine the applicant’s finances, the value of the home, and the down payment. </p>
                          <p>The following are a number of aspects that an underwriter examines:</p>
                          <h2>Ability to Repay the Loan</h2>
                          <p>The ability-to-repay rule is the determination a mortgage lender makes as to whether a potential borrower is capable of being able to pay off a loan they provide. An underwriter will evaluate this based on the homebuyer’s credit history, income, assets, employment, and expenses. They can also create a qualified mortgage, a type of loan with “stable features,” to follow this guideline.</p>
                          <h2>Likelihood to Repay the Loan</h2>
                          <p>A borrower’s likelihood of repaying a loan is largely determined by a person’s credit score and history. This is a means that many lenders use to evaluate the likelihood of someone repaying a loan—the higher the score, the better the likelihood, in the lender’s eyes, that the loan will be repaid. Factors like payment history and credit-utilization rate play a large factor.</p>
                          <h2>The Value of the Home</h2>
                          <p>As part of the process, an underwriter can have an appraisal done of the property to make sure the value aligns with how much the lender is offering. The appraiser will evaluate the property and place a value on it. The underwriter will then compare that to the loan amount. If the value is much lower than the loan, the application may get deferred.</p>
                          <h2>The Source and Amount of the Down Payment</h2>
                          <p>A down payment typically makes up between 3% to 20% of a person’s mortgage and can help lower their interest rate as well as minimum monthly payments. However, the source of a potential borrower’s down payment matters to underwriters. Borrowers can provide a down payment from their savings, gifts from family or friends, down payment assistance programs, home equity, etc.</p>
                          <h2 class="anchor_h2">Already Owning a Home Provides More Options</h2>
                          <p>Mortgage rates have been at record lows due to the COVID-19 pandemic, but rates are beginning to rise once more. However, because the housing market is extremely competitive and the supply chain is causing issues for the construction of new homes, prices are up. Already owning a property, however, can give homebuyers an advantage.</p>
                          <h2>Home Equity Loans</h2>
                          <p>Home equity loans, also known as second mortgages, are a way for homeowners to utilize the equity they’ve built with their homes. Keep in mind that when an individual takes out a second mortgage, they are using their home as collateral on the loan. Also, when a person borrows against their home, they’re decreasing the equity they’ve built. To obtain a home equity loan, borrowers will need to follow home equity loan requirements in 2022.</p>
                          <h2>Refinancing</h2>
                          <p>Refinancing a home is a way for homeowners to potentially lower their interest rates, change their terms, and decrease their minimum monthly payments. With mortgage refinancing, a homeowner is essentially replacing their current loan with a new one. Because the homeowner is getting a new loan, they’ll still be responsible for paying closing costs, typically 2% to 6% of the loan. To learn more, read more</p>
                          <h2>Exploring Adjustable vs Fixed Rate</h2>
                          <p>When choosing a mortgage loan, potential borrowers will need to look at fixed-rate vs adjustable-rate mortgages. Fixed rates mean that a borrower’s monthly payments will remain the same throughout the entirety of the loan. Adjustable rates change throughout the life of the loan and can rise and fall along with the market. Fixed rates can allow borrowers to better budget for their mortgage payments while adjustable rates allow for more flexibility. </p>
                          <h2 class="anchor_h2">Preparation is Key</h2>
                          <p>Before purchasing a new home, it’s recommended to understand what is a mortgage as well as what the application process is for a loan. This process can be long and arduous, and potential borrowers will need to gather multiple documents to give to the loan company. Homebuyers can avoid any unpleasant surprises by being prepared with this information. This is also helpful as coming across unexpected information can interrupt the momentum of the application process.</p>
                          <p>While on the hunt for a new home, potential borrowers should be sure to do their research and compare lenders in order to find the best mortgage rates. They should not feel obligated to take the first loan they’re offered. This approach can not only save them money in the long run on interest rates but can also help homebuyers find more attractive terms that may better fit their financial situation.</p>
                          <p>As part of the research process, potential borrowers can look into and compare mortgage reviews to find the lender that may be the best fit for them. Homebuyers can analyze details such as rates, loan terms, down payment requirements, minimum credit score criteria, whether they offer prequalification, as well as other details about the lender. If multiple lenders offer prequalification, potential borrowers can compare the mortgage loan offers from each lender.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">How Mortgage Applications are Reviewed?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">What Does an Underwriter Look At?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Already Owning a Home Provides More Options</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===2}">Preparation is Key</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "mortgage_rates_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>