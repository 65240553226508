<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Haven Life Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance' }">Life Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Protecting Families Everyday</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Protecting Families Everyday</h1>
                          <img src="../../../../public/imgs/reviews_list/haven_life_insurance_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is Protecting Families Everyday?</h2>
                          <p>If you’re the kind of person who doesn’t like to leave anything to chance, this article can show you how the lifetime coverage, fixed premiums, and guaranteed benefits of Protecting Families Everyday can help you—and your loved ones—feel secure about your legacy.</p>
                          <p>With Protecting Families Everyday, your insurance benefit is 100% guaranteed.</p>
                          <p>When it comes to protecting your family’s lifestyle and future, Protecting Families Everyday is as dependable as any financial vehicle. That’s because this coverage offers a host of guaranteed benefits and features that make it a strong choice for anyone who wants to know that their protection will be there today, tomorrow—or whenever it is needed. Of course, a life insurance benefit depends on the timely payment of premiums.</p>
                          <h2 class="anchor_h2">Protecting Families Everyday Ratings</h2>
                          <p>If you want to buy life insurance but don't know which insurance company to choose, you will search on Google, and you will find the most popular insurance at present - Life Insure Yes. Why can I say so with such confidence? Please see the following data:</p>
                          <p>Ranked #1 in Best Life Insurance Companies of 2022</p>
                          <p>Ranked #1 in Best Term Life Insurance Companies of 2022</p>
                          <p>Ranked #1 in Best No Medical Exam Life Insurance Companies of 2022</p>
                          <p>Also Life Insure Yes's overall performance in the Nerd Wallet rating is 5 stars. Nerd Wallet ratings are determined by our editorial team. The scoring formula takes into account consumer experience, complaints data from the National Association of Insurance Commissioners and financial strength ratings. There is no doubt that Haven Life has come out on top in the US insurance market. </p>
                          <h2 class="anchor_h2">Why should you choose Life Insure Yes?</h2>
                          <p>Life Insure Yes deserves serious consideration by anyone looking for convenient, affordable term life insurance. The online application is designed to be user-friendly, making it an easy and quick process. You can get a quote before you apply, but if you choose to go ahead and complete the application process, you will need to answer a series of questions about your age, lifestyle, health and medical history.</p>
                          <p>Depending on coverage and other variables unique to your situation, Life Insure Yes policies can be purchased for less than $20 a month. Since the process is done online, if you are eligible and ready to pay your premiums, you have the potential to get coverage right away.</p>
                          <p>Additionally, the option of obtaining life insurance without a medical exam may appeal to those looking to get coverage quickly, or those who may find it difficult to qualify for coverage based on health conditions. However, if you are looking for a whole life policy, Protecting Families Everyday currently only offers term life insurance and disability insurance.</p>
                          <p>In most cases, life insurance companies require applicants to undergo medical exams and tests in order to qualify for the policy. If you don't want to, Protecting Families Everyday has a simplified question policy. You can get exam-free life insurance up to $500,000.</p>
                          <p>If you're looking for a simple life policy—the most affordable type of life insurance—Protecting Families Everyday can be a useful option. You can apply and buy insurance online without having to meet with an insurance agent. Also, some Protecting Families Everyday policies are simplified issues so you don't have to do a medical exam, making Protecting Families Everyday our pick for the best life insurance company.</p>
                          <h2 class="anchor_h2">Affordable life insurance can be yours!</h2>
                          <ul class="text3">
                            <li>√ Protect your loved ones with life insurance today "Finding the right life insurance policy has never been so simple and affordable"</li>
                            <p>A life insurance policy means that if you were to die, you can ensure that your loved ones are protected financially, depending on the type of policy you choose, you could receive a lump sum pay out to clear a mortgage or a series of installments to cover the financial commitments.</p>
                            <p>There are several different kinds of policies available in the US market. In general however, most policies can either be defined as term policies or whole life policies.</p>
                            <li>√ What are the benefits?</li>
                            <p>Life Cover is a simple, affordable way to help make sure your family’s life can go on in the event that you’re not around, with premiums from as little as £5 per month, protect your family now.</p>
                            <li>√ It won’t happen to me? It could happen to you!</li>
                            <p>Would your family struggle to cope financially without your income? Can you afford having no Life Cover? And for them to have no financial protection? Don’t delay act now and get protected.</p>
                          </ul>
                          <h2 class="anchor_h2">Protecting families and their futures.</h2>
                          <p>Whether you’re looking to provide for your loved ones, plan your retirement, or build your wealth, our financial professionals can help.</p>
                          <ul class="text3">
                            <li>√ Solutions tailored to your needs.</li>
                            <p>You want to provide your family with financial protection and peace of mind. We can help. Connect virtually with a financial professional safely from the comfort of your own home with a computer, mobile phone, or tablet. All you need is an internet connection.</p>
                            <li>√ ur commitment to our policy owners.</li>
                            <p>Our policy owners are our top priority, that’s why we publish an annual performance report to keep them informed on all things New York Life.  And 2023 will be our 169th consecutive year of paying dividends to our eligible Whole Life policy owners.</p>
                          </ul>
                          <h2 class="anchor_h2">Answers for every stage of life</h2>
                          <ul class="text3">
                            <li>√ Planning a life together</li>
                            <p>If you meet a certain someone, we'll help you understand options that provide financial security. So you can live your happily ever after.</p>
                            <li>√ Growing your family</li>
                            <p>Adding to your family means adding to your financial needs. We’re here to guide you as you work to secure the future of your loved ones. </p>
                            <li>√ Moving toward retirement</li>
                            <p>You should enjoy retirement. We can help. Whether it’s maximizing savings, providing streams of income, or planning for long-term care, we have the solutions you need for your next stage in life</p>
                          </ul>
                          <h2 class="anchor_h2">Three simple steps to get you three:</h2>
                          <ul class="text3">
                            <li>1.	Complete your details</li>
                            <li>2.	Speak to our experienced, friendly professionals</li>
                            <li>3.	Receive a tailored quote best suited to your requirements</li>
                          </ul>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is Protecting Families Everyday?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Protecting Families Everyday Ratings</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Why should you choose Life Insure Yes?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Affordable life insurance can be yours!</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Protecting families and their futures.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">Answers for every stage of life</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">Three simple steps to get you three:</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>