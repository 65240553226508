<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Personal Loans</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Compare and choose the best Personal Loan for your needs, whether you're looking for debt consolidation, credit card refinancing, or a large purchase. You may start now by filtering the results, checking your rate, and learning more about borrowing in the following tutorial.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/personal_loans" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Personal Loans</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/personal_loans_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Personal Loans Reviews</span>
                          </router-link>
                      </div>
                      <!-- <div class="head_card">
                          <router-link to="/personal_loans_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Personal Loans Guide</span>
                          </router-link>
                      </div> -->
                      <div class="head_card">
                          <router-link to="/calculator" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Personal Loans Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Personal Loans</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.pic_img" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="item.star"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.name}}</span>
                                          <ul>
                                              <li v-for="(subitem, index) of item.features" :key="index">{{ subitem }}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What is Personal Loans?</h2>
                          <p>A Personal Loan allows you to borrow money from a lender for nearly any reason, with a set period, fixed interest rate, and monthly repayment schedule. In most cases, no collateral is required. A Personal Loan may be a suitable alternative if you need some immediate cash to cover expenses like utilities, medical bills and expenses, damaged appliances or automobiles, and other urgent financial necessities. Personal loans are a quick and easy way to get money, with loan amounts ranging from hundreds to thousands of dollars depending on your state and loan type. There will be no surprises because the loan agreement explicitly states the amount of the loan, the financial costs, and the due date and amount of payment. Personal loans are available as secured or unsecured loans. Secured loans may need collateral, such as your automobile, but they may also have lower annual percentage rates. Unsecured loans are backed just by your pledge to pay back the lender, although they may come with slightly higher interest rates. Lender offers will vary depending on their requirements, but elements including your credit score, income, and previous debts are frequently used to assess eligibility.</p>
                          <h2 class="anchor_h2">Why do You Choose Personal Loans?</h2>
                          <p>Because the interest rate and loan terms on a Personal Loan are set, you can choose a loan and payment amount that matches your budget, which is ideal for debt consolidation. You'll also know when your debt will be completely paid off. Consolidating high-interest credit card debt with a Personal Loan may even help you boost your credit score.</p>
                          <p>Banks, credit unions, and online lenders are the most common sources of Personal Loans. When you apply for a Personal Loan and are granted, you will either receive a lump sum of money upfront or be able to withdraw cash whenever you need them. Then you'll repay the money you borrowed, plus interest and fees, over a certain period of time, which may be anywhere from a few weeks to a few years.Then it is important to choose Personal Loans that fit your budget.</p>
                          <h2 class="anchor_h2">What Types of Personal Loans that Fit Your Budget?</h2>
                          <p><span class="text_bold">Credit Card Consolidation: </span>A credit card consolidation loan combines several high-interest credit card bills into a single loan with a set rate, duration, and single low monthly payment. It can help you save money over the course of the loan by lowering your interest rate, allowing you to pay off debt faster. A credit card consolidation loan could help you improve your credit by broadening your credit mix, demonstrating that you can make on-time monthly payments, and lowering your total debt (as long as you don't run up new balances on the merged cards). </p>
                          <p><span class="text_bold">Balance Transfer: </span>A balance transfer loan is a Personal Loan that allows Bank to pay some or all of your creditors on your behalf. You decide which accounts will be paid and how much of your new loan will be applied to each. Any residual monies from your loan will be put directly into your bank account after your creditors have been paid.</p>
                          <p><span class="text_bold">Debt Consolidation: </span>A debt consolidation loan combines several amounts from credit cards and other high-interest loans into a single loan with a fixed rate and term. It can help you save money by lowering your interest rate or make paying off debt faster easier. Your monthly payment may potentially be reduced with a debt consolidation loan. A debt consolidation loan may also help you enhance your credit by diversifying your credit mix, demonstrating that you can make on-time monthly payments, and lowering your total debt (as long as you're not taking on any new debt).</p>
                          <p><span class="text_bold">Home improvement: </span>Homeowners face a wide range of costs. A Personal Loan could provide you with the funds you require in the near term without causing long-term financial harm</p>
                          <p><span class="text_bold">Other Large Expenses: </span>Personal loans can be used for a range of needs, including wedding plans, moving costs, auto repairs, medical bills, and other large purchases.</p>
                          <h2 class="anchor_h2">What Things Should You Know When Looking for a Personal Loan?</h2>
                          <p>APR</p>
                          <p>In general, the lender with the lowest suggested APR is your best bet, because a lower APR means you'll pay less throughout the loan's duration. The lower the interest rate, the more money you'll have each month to pay down debt or save.</p>
                          <p>FEES</p>
                          <p>Consider loan origination fees and prepayment penalties, for example. When you take out a loan, you will be charged a loan origination fee, which can range from 1% to 8% of the total loan amount. When you pay off your loan before the end of the term, you will be charged a prepayment penalty. Keep in mind that these costs are not charged by all lenders.</p>
                          <h2 class="anchor_h2">What do You Need to Apply for a Personal Loan?</h2>
                          <ul class="text1">
                              <li>Government-issued identification </li>
                              <li>Checking account proof of income </li>
                              <li>Number of the Social Security Administration</li>
                          </ul>
                          <h2 class="anchor_h2">How do I Get a Personal Loan?</h2>
                          <p>Follow these procedures if you're looking for a Personal Loan: </p>
                          <p class="text_bold">1. Do your homework on lenders.</p>
                          <p>Because there are so many Personal Loans available, do some research and identify the lenders and loans that interest you. Compare their interest rates, periods, and fees after that. </p>
                          <p class="text_bold">2. Select a Personal Loan and gather required documentation </p>
                          <p>Collect the documents you'll need to apply for the best Personal Loan for your budget and demands. A government-issued ID, such as a driver's license or passport, as well as pay stubs or other proof of income, would almost certainly be required.</p>
                          <p class="text_bold">3. Fill up an application and send it in. </p>
                          <p>Fill out the application in person or online. Prepare to provide personal information such as your name, email address, and phone number. You may also be asked to supply information about your earnings and work history. </p>
                          <p class="text_bold">4. Be patient and await approval. </p>
                          <p>Once you've submitted your application, you'll have to wait for the lender to analyze it and respond with a decision. Many lenders, fortunately, provide speedy approval decisions and may notify you of your approval the same day or within 24 hours. </p>
                          <p class="text_bold">5. Collect the funds </p>
                          <p>You won't have to wait long for your money if you've been authorized for a loan and sign the agreement fast. You may receive the funds by direct deposit, depending on the lender.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">What is Personal Loans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===0}">Why do You Choose Personal Loans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===1}">What Types of Personal Loans that Fit Your Budget?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===2}">What Things Should You Know When Looking for a Personal Loan?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===3}">What do You Need to Apply for a Personal Loan?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)" :class="{active: active===4}">How do I Get a Personal Loan?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { GetOffers } from '../../api/cindex';
import { imgsapiUrl } from '../../config/env';
export default {
    name: "personal_loans",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    created() {
        this.GetOffersList();
    },
    methods: {
        // 获取offer数据
        async GetOffersList() {
            const res = await GetOffers( { category_id: '151', page: '1', page_size: '4' } )
            res.data.forEach(i => {
                i.pic_img = imgsapiUrl + i.img.split('.')[0] + '/' + i.img
                i.star = 5
            })
            this.offers_list = res.data
        },
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/navimgcrad.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>

<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>