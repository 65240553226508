<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Compare Insurance For Free</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Save more when you shop around!</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/auto_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Auto Insurance</span>
                          </router-link>
                      </div>
                      <!-- <div class="head_card">
                          <router-link to="/health_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Health Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/homeowners_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Home Insurance</span>
                          </router-link>
                      </div> -->
                      <div class="head_card">
                          <router-link to="/life_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Life Insurance</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2 class="anchor_h2">The benefits of bundling home and auto insurance</h2>
                          <p>Some companies will offer extra savings if you bundle your home insurance and your auto insurance. In other words, you’re insuring both your home and your vehicle through to same company, and in return they are offering you extra savings. When getting quotes, make sure to ask what it would cost you to bundle both home and auto insurance. Then, you can compare each quote to see which one offers the best deal for the lowest rate.</p>
                          <h2 class="anchor_h2">Tips on comparing insurance quotes</h2>
                          <p>When comparing different quotes on your auto or home insurance, it’s important to compare apples to apples. In other words, the first thing you need to do is make sure you’re comparing the same types of coverage. Other tips on comparing insurance quotes include:</p>
                          <ul class="text1">
                              <li>Always ask for the price of a bundle package for home and auto insurance</li>
                              <li>Compare individual rates and bundled rates, side by side</li>
                              <li>Look at user reviews to see what others are saying about specific companies and policies</li>
                              <li>If you’re confused, ask to speak with an agent who will walk you through the policy line by line</li>
                          </ul>
                          <h2 class="anchor_h2">How to figure out how much coverage you need</h2>
                          <p>With home insurance, your mortgage lender will let you know what the minimum required coverage amount is, based on the purchase price and location of your home. Minimum required auto insurance coverage varies by state. Usually, liability insurance is all that’s required, but some states require a bit more than just liability. Check with your state to make sure you are meeting the minimum requirement.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">The benefits of bundling home and auto insurance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">Tips on comparing insurance quotes</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">How to figure out how much coverage you need</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "mortgage_rates",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>