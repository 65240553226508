<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>5 Personal Loan Mistakes to Avoid</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Jessica Cotzin</div>
                                <div>Last update: 8 June 2020</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/5 Personal Loan Mistakes to Avoidblog_1.jpg" alt="">
                            <p>Whether it's to fund an upcoming family vacation, pay off debt, or even </p>
                            <p>Getting approved for a personal loan isn't always easy, and there are some pitfalls you'll want to avoid in the process. Let's take a look at five of the most common ones so you can stay financially responsible while putting money in your pocket.</p>
                            <h2>But First: How Do Personal Loans Work?</h2>
                            <p>Keep in mind that a personal loan is just another type of credit. A few forms of credit include credit cards, vehicle loans, mortgages, and so on, but </p>
                            <p>They typically come with lower interest rates than, say, credit cards, making them a popular financial tool, but just like with other forms of credit, personal loans should be taken out responsibly.</p>
                            <p>Here are a few common personal loan terms you'll see that will help you better understand the application process, and which we'll mention throughout this article:</p>
                            <ul class="text1"><li>APR: Annual Percentage Rate, or APR, is one of the most important terms to understand. There are often fees associated with taking out a personal loan, such as an origination fee or application fee. The APR has all of the fees plus the interest baked in so you can see the real cost of the loan. Always look at the APR when comparing lenders.</li>
                            <li>Principal: This is the amount you're borrowing. So if you apply for a personal loan of $5,000, that number would be considered the principal. This amount will gradually decrease as you pay off the loan, but the APR charge will stay the same (assuming it's a fixed and not variable rate).</li>
                            <li>Interest: The interest is essentially what the lender is charging to lend you the money, which you'll repay over the life of the loan. Every month, you'll pay back a piece of the loan amount (the principal) plus the monthly interest, which is typically a percentage rate.</li>
                            <li>Monthly Payment: The monthly payment made to the lender will include a portion of the principal you owe as well as the interest which you'll be obligated to pay over the course of the loan.</li>
                            <li>Origination Fees: This is an upfront fee some lenders will charge you when processing your personal loan application and can sometimes be referred to as points. Not all lenders charge an origination fee, but if they do, it will be calculated into your APR.</li></ul>
                            <p>Now let's move on to some of the most common personal loan mistakes you can avoid this season!</p>
                            <h2>#1 Not Checking Your Credit Report</h2>
                            <p>Before you start sending in personal loan applications, check your credit report. You're entitled to one free credit report each year from the three major bureaus—TransUnion, Experian, and Equifax.</p>
                            <p>If you're curious about what your financial profile looks like, this is your credit report. It's a breakdown of all of your credit history and is crucial when it comes time to apply for loans of all types since it's something lenders will examine to determine your </p>
                            <p>Here is what you can expect to find on your credit report:</p>
                            <h3>Personal information</h3>
                            <ul class="text1"><li>Your name</li>
                            <li>Current and previous addresses</li>
                            <li>Date of birth</li>
                            <li>Social Security number</li>
                            <li>Phone number</li></ul>
                            <h3>Credit accounts</h3>
                            <ul class="text1"><li>All current and previous credit accounts and account types, such as loans, mortgages, etc.</li>
                            <li>The credit amount and limit</li>
                            <li>Account balances and payment history</li>
                            <li>Opening and closing date of accounts</li>
                            <li>The name of the lender/creditor</li></ul>
                            <h3>Public Records and Inquiries</h3>
                            <ul class="text1"><li>Foreclosures</li>
                            <li>Bankruptcies</li>
                            <li>Liens</li>
                            <li>Civil suits</li>
                            <li>Companies that have looked at your credit report</li></ul>
                            <p>As you can see, your credit report provides incredibly detailed insight about your financial standing, past, and present, and so it's important that you're aware of what's on it. Most lenders will explicitly state on either their website or over the phone who is and isn't eligible to take out a loan with them. For example, some third-party lenders won't offer personal loans to anyone with a recent bankruptcy or foreclosure. Knowing this will help save you time and manage your expectations.</p>
                            <p>It's also a good idea to take a look at your credit report just in case there is an error. If you do find a mistake, dispute it immediately so it can be removed from your report.</p>
                            <h2>#2 Only Paying Attention to the Monthly Payments</h2>
                            <p>When taking out a loan, it's normal to narrow our focus on what the monthly payments will be, since that's a financial burden taken on for months, maybe even years until the loan is paid off. You want to make sure it's an amount you're comfortable paying. While monthly payments are certainly an important factor when considering various lenders, it shouldn't have the final say in your decision.</p>
                            <p>As mentioned earlier, there are a few other costs associated with taking out a personal loan, such as interest rates, application fees, and origination fees. A common tactic many lenders—even the most reputable—will employ to earn your business is to offer a lower monthly payment as a way of veiling these fees. Additionally, a lower monthly payment may mean a longer loan term, which will cost you more in interest in the long run.</p>
                            <p>In general, a loan will come with these extra fees, but not all, some companies offer their services without any application fees.</p>
                            <h2>#3 Not Comparing Lenders</h2>
                            <p>We get it, you need money and want to secure a personal loan fast. Slow your roll! Remember, there are a ton of loan providers out there—not just your traditional banks or credit unions. Put in some time to research and compare lenders, including third-party online providers, to find the best rates rather than jumping at the first one.</p>
                            <p>If you're pressed on time and don't want to go through filling out entire personal loan applications with every lender, you'll find that many online loan providers will allow you to submit a quick preliminary application—usually takes just a couple of minutes to fill out—to see what kind of rates you're pre-approved for. Additionally, there are online loan marketplaces, </p>
                            <p>When comparing lenders, the most important things to look at are the APR, monthly payment, and the loan term.</p>
                            <h2>#4 Choosing Loan Terms that Don't Match your Goals</h2>
                            <p>No two financial profiles are the same and we each may have varying reasons and goals when taking out a personal loan. For instance, you might be taking out a personal loan to pay off medical bills or fund a large vacation and your primary goal is to secure a loan with a lower monthly payment and a longer repayment term. If your goal, however, is to get out of debt faster, having this longer repayment term won't help.</p>
                            <p>Cost is another thing to consider when opting for a longer repayment term since you'll be paying more interest over the life of the loan. For example, if you've decided to take out a loan for $5,000, know that you'll be paying that principal amount plus interest and fees. While your interest rate is the same no matter what the loan term, selecting a longer loan term will ultimately cost you more in the long run.</p>
                            <p>Again, it all comes down to what your goals are, so be sure to understand the terms when taking out a personal loan.</p>
                            <h2>#5 Missing or Making Late Payments</h2>
                            <p>Aside from putting money in your pocket, a personal loan has other benefits, such as helping to </p>
                            <p>Additionally, if you've taken out a secured loan, missing or making late payments will run you the risk of losing the collateral attached to the loan. This is why it's important to evaluate all aspects of taking out a personal loan, including ensuring that you can afford the monthly payments.</p>
                            <p>Remember that you're obligated to these monthly payments for the duration of the loan, and being late or missing just one payment could hurt your financial standing.</p>
                            <h2>Conclusion</h2>
                            <p>The key when taking out a personal loan is to do so responsibly and to take your time. Rushing through the process could mean less desirable loan terms and not hitting your financial goals.</p>
                            <p>A personal loan is a powerful financial tool when used smartly, so do your research, find the best lender for you, and be prepared so you can avoid these common personal loan mistakes this season.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Jessica Cotzin</span><br />Last update: 8 June 2020</div>
                        </div>
                        <p>Jessica Cotzin is a writer and the TopFinanceDirectory authority on small businesses and personal loans. She has been writing about personal finance and the loans industry for a number of years, and holds a bachelor's degree in journalism from Florida Atlantic University.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
