<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">AAA Car Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance' }">Auto Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Coverage Professor</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Coverage Professor</h1>
                          <img src="../../../../public/imgs/reviews_list/aaa_auto_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Save Money with Coverage Professor</h2>
                          <p>With Coverage Professor, you don't have to compromise on car insurance. You can get both the reliable protection and the low rates you want! Our friendly agents work with you to offer quality coverage that works with your budget. Depending on your situation, that could mean cheaper, more affordable car insurance and even cheap car insurance overall. And rest assured that "Coverage Professor" never refers to the quality of our products and services; it simply means affordable. From exceptional services to low down payments to low monthly payments to multiple car insurance discounts to multiple ways to pay, you can truly have it all.</p>
                          <h2 class="anchor_h2">Affordable auto insurance is a call, click, or visit away</h2>
                          <p>Every driver is different. So is every car insurance policy. At Coverage Professor, we get it. That's why we offer you choices every step of the way. With affordable coverage options, extra services, great payment plans, and money-saving discounts that could help you get the cheap car insurance you want, we're here to help you build the best auto insurance policy for your needs. Start with a fast and free car insurance quote online, and you'll see! </p>
                          <h2 class="anchor_h2">Why Buy Car Insurance?</h2>
                          <p>While car insurance coverage requirements vary by state, almost every state requires some form of liability insurance. It's the law. What's more, the right car insurance coverage can help protect you and your loved ones financially in the event of a car accident, theft, or other covered loss.</p>
                          <p>Since 1991, Coverage Professor has been providing drivers across the country with affordable car insurance and quality services—regardless of their insurance history. No matter the circumstances, Coverage Professor has always worked alongside drivers to determine the best policy for their needs, and we're ready to help you, too.</p>
                          <h2 class="anchor_h2">What Kind of Car Insurance Does Coverage Professor?</h2>
                          <p>Get to know our car insurance coverages below, and don't hesitate to contact us with any questions. Friendly agents are standing by to walk you through the ins and outs of auto insurance! Read on to learn about the different types of coverages we offer.</p>
                          <ul class="text3">
                            <li>√ Liability Insurance</li>
                            <p>Liability insurance is one of the most basic types of car insurance. You might hear other drivers refer to this family of coverage as "liability only" or "minimum auto insurance,” which generally includes bodily injury and property damage liability coverages. If you cause an accident, these coverages help pay for the other driver's medical expenses and property damage (but not yours).</p>
                            <li>√ Collision Coverage</li>
                            <p>Collision insurance is a type of coverage that will pay for your car to be repaired or replaced if it hits another vehicle or object or is hit by another driver in a hit-and-run accident. You may be required to carry collision coverage (along with comprehensive insurance) if your car is leased or financed. You must meet the deductible before your coverage helps cover the claim.</p>
                            <li>√ Comprehensive Insurance</li>
                            <p>Comprehensive insurance (also referred to as “other than collision coverage”) helps pay for damages to your car if it's stolen or damaged in an event other than a collision, like a storm or contact with an animal. There's a good chance you'll be required to carry comprehensive (and collision coverage) if your car is leased or financed. A deductible payment is required before your insurance helps pay the remaining costs.</p>
                            <li>√ Uninsured Motorist</li>
                            <p>Uninsured motorist coverage, or UM Coverage, will help pay for your expenses if you're injured in a car accident and the at-fault driver can't be identified, doesn't have liability insurance, or doesn't have enough liability insurance to cover your claim. In some states, uninsured motorist coverage applies only for bodily injuries; property damage is also available in others. In certain states, coverage for an “underinsured motorist” (where the at-fault driver has liability insurance, but it is not sufficient to cover your damages) is sold separately.</p>
                            <li>√ Personal Injury Protection</li>
                            <p>Personal injury protection, or PIP, is a coverage that helps pay for certain medical, hospital, and funeral expenses in the event of an auto accident, no matter who is at fault. In some states, Personal injury protection is required in addition to your other auto insurance coverage, while other states offer it as optional insurance. PIP coverage is not available in all states.</p>
                            <li>√ Towing Coverage</li>
                            <p> If your car breaks down or can't be driven after an accident, towing coverage can be purchased with comprehensive and collision coverage to help cover or reimburse you for emergency towing costs up to the limit selected. If you don’t have comp & collision, Coverage Professor also offers a roadside assistance plan with 24-hour emergency towing service.</p>
                            <li>√ Rental Reimbursement</li>
                            <p> Rental cars aren't always cheap. Rental reimbursement is an optional coverage that can typically only be purchased with comprehensive and collision coverage to help reimburse you for the cost of a rental car if your vehicle can't be driven after an auto accident or other covered loss.</p>
                            <li>√ Medical Payments</li>
                            <p>Medical payments (often called Med Pay) is an optional car insurance coverage that will help pay for you or your passengers' medical, hospital, and funeral expenses in the event of an auto accident, no matter who is at fault. Med Pay typically moves with you, meaning that you’re covered if you’re walking, riding in a friend’s car, or on public transportation, both in and out of state.</p>
                          </ul>
                          <h2 class="anchor_h2">Get Cheap Car Insurance in 3 Simple Steps</h2>
                          <p>With Coverage Professor, car Insurance can be both simple AND affordable.</p>
                          <ul class="text3">
                            <li>1. Get a Personalized Quote</li>
                            <p>Online, over the phone, or in-store, your auto quote factors in your individual needs and budget. We offer multiple discounts up to 25%* for safe driving, purchasing multiple insurance products, and more. Plus, we offer low rates and low down payment options to help make car insurance more affordable.</p>
                            <li>2. Customize Your Plan</li>
                            <p>Whether you need an auto policy that fulfills your state's minimum requirements or provides more coverage, we’ll help you custom-build a plan that fits your life perfectly. Simply select the payment date, amount, and method of payment that’s convenient for you.</p>
                            <li>3. Enjoy Affordable Coverage</li>
                            <p>With Coverage Professor, you can be confident about your coverage, drive legally, and protect your financial future. Our quality insurance coverages and great services, including 24/7 claims service and optional plans like roadside assistance, will make you feel more secure on the road and everywhere in between.</p>
                          </ul>
                          <h2 class="anchor_h2">For Expats, U.S. Citizens & Residents</h2>
                          <p>Choose the U.S. car insurance that fits your specific needs and budget.</p>
                          <ul class="text3">
                            <li>√ Get real time quotes from several “A” rated companies</li>
                            <li>√ Compare quotes quickly and easily</li>
                            <li>√ Select the best policy for your needs and budget</li>
                          </ul>
                          <p>Get A Quote</p>
                          <p>Whether you’re a foreign national, a permanent resident, a U.S. citizen returning home, or an expat moving to America and looking for car insurance in the U.S.A., you can pick from several coverage options.</p>
                          <p>Save time and get a car insurance quote in the United States that fits your needs by comparing rates from multiple insurance carriers across the United States.</p>
                          <h2 class="anchor_h2">What are the Main Types of Car Insurance in the U.S.?</h2>
                          <p>The common types of insurance in the U.S. are:</p>
                          <ul class="text3">
                            <li>√ Liability Insurance – covers the other driver’s property and bodily injuries if you’re at fault in an accident</li>
                            <li>√ Comprehensive Coverage – protects your vehicle from non-driving events such as theft or acts of nature, like hail damage</li>
                            <li>√ Collision Insurance – covers car accidents or collision with a stationary object</li>
                            <li>√ Most U.S. states require basic liability insurance that provides coverage in the event you injure a person (bodily liability) or damage their property (property damage liability). Each state in America has different minimum car insurance requirements, but many follow the “25/50/25 limit” which is:</li>
                            <ul class="text1">
                                <li>$25,000 bodily injury liability per person</li>
                                <li>$50,000 bodily injury liability per accident</li>
                                <li>$25,000 property damage liability per accident</li>
                            </ul>
                            <li>√ In addition to the above types of auto insurance in the U.S., there is also stated amount and agreed/guaranteed value insurance, but these coverages are usually only applied in unique circumstances, such as insuring expensive or modified vehicles.</li>
                          </ul>
                          <h2 class="anchor_h2">Which Insurance Do I Need?</h2>
                          <p>The right insurance for you depends on multiple factors. Work with a reputable insurance broker, who will guide you through the complexities.</p>
                          <ul class="text3">
                            <li>√ Your state’s legal limits, which vary by state.</li>
                            <li>√ Financial risk you are willing to assume should something happen to your car, as described above. If you are unable or unwilling to pay out of pocket for those events, then you should consider comprehensive and collision coverage.</li>
                          </ul>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Save Money with Coverage Professor</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Affordable auto insurance is a call, click, or visit away</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Why Buy Car Insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What Kind of Car Insurance Does Coverage Professor?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Get Cheap Car Insurance in 3 Simple Steps</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">For Expats, U.S. Citizens & Residents</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">What are the Main Types of Car Insurance in the U.S.?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)">Which Insurance Do I Need?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>