<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">SoFi</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>SearchKeyLoans</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>SearchKeyLoans</h1>
                          <img src="../../../../public/imgs/reviews_list/sofi_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is SearchKeyLoans？</h2>
                          <p>SearchKeyLoans is a marketplace for personal loans, dealing with loan sizes of between $500 and $5,000. These loans cover a wide variety of purposes and have a great range of APRs and term lengths.A personal loan is a longer-duration loan that’s repaid over time. At SearchKeyLoans.com, you can request $1,000 to $5,000. They will not charge you a fee for submitting a request through the site. All the lenders that are part of the SearchKeyLoans networks have been carefully vetted to make sure that they are legit. </p>
                          <h2 class="anchor_h2">Search Key Loans, Loan Types and Terms</h2>
                          <p>Loan types:Bill paying, emergency situations, auto repairs, medical expenses, business loans, funding vacations, home improvement, and student refinancing</p>
                          <p>Loan amount:$500-$5,000</p>
                          <p>Repayment terms:61 Days to 84 months</p>
                          <p>APR:4.84%-35.99%</p>
                          <p>Minimum credit score:Varies</p>
                          <p>Lender type:Market</p>
                          <p>Good for:Trusted lenders, wide range of term lengths, easy application process</p>
                          <h2 class="anchor_h2">How to Apply for SearchKeyLoans？</h2>
                          <p>To get started with the SearchKeyLoans offering, you use the online form. Therefore, you are not going to have to submit anything in person. You select how much you are looking to borrow, as well as basic information. There is no hard credit check conducted at this stage and you do not have to pay any fee for conducting a search.</p>
                          <p>You will then be given a quote regarding the personal loan that you can get your hand son, including what APR you will be paying. There will be a range of options offered to you as this is a marketplace. As soon as you select a specific lender, then you will need to complete a comprehensive application. There will usually be a hard credit check completed at this stage.</p>
                          <p>You also need to provide verification documents to make sure that what you have said is legit. The entire process from start to finish with SearchKeyLoans is simple and efficient. Once your application is approved, you will often be able to get the funds in your hands on the following business day.</p>
                          <h2 class="anchor_h2">SearchKeyLoans Pros & Cons</h2>
                          <p>Pros:</p>
                          <p>Low-cost</p>
                          <p>Simple application process</p>
                          <p>Trusted lenders</p>
                          <p>Wide variety of term lengths</p>
                          <p>Cons:</p>
                          <p>Not much of a track record</p>
                          <p>Small loan amounts</p>
                          <p>Not a direct lender</p>
                          <h2 class="anchor_h2">Frequently Asked Questions:</h2>
                          <p>How Long Before My Loan is Funded?</p>
                          <p>If you are connected with a provider and eventually approved for a personal loan, then you may receive your money in as little as one business day. We know how important it is for you to get your money in a hurry. We do everything we can to make that happen.</p>
                          <p>What Fees Does SearchKeyLoans.com Charge?</p>
                          <p>SearchKeyLoans.com offers a free loan request service. We do not collect any money from you at any time. The way we make money is by connecting you with a lender or lending partner, who will then pay us for helping facilitate the introduction.</p>
                          <p>How Much Can I Borrow?</p>
                          <p>You can use our form to request up to $5,000. If a loan provider offers you funding, there will likely be a time limit on how long you have to accept it. You will need to make your decision within that timeframe to avoid needing to request another loan.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is SearchKeyLoans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Search Key Loans, Loan Types and Terms</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">How to Apply for SearchKeyLoans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">SearchKeyLoans Pros & Cons</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Frequently Asked Questions：</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>