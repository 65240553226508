<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">American Residential Warranty</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty' }">Home Warranty</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>GovHomeProgram</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>GovHomeProgram</h1>
                          <img src="../../../../public/imgs/reviews_list/american_residential_warranty_review_1.jpg" alt="">
                          <h2 class="anchor_h2">GovHomeProgram Review (January 2023)</h2>
                          <p>In this GovHomeProgram review, our review team will analyze the company's plan, pricing and reputation in depth.</p>
                          <p>This Old House Reviews Team01/25/2023 9:57 AM</p>
                          <p>GovHomeProgram was founded in 2012 and is famous for its unique products and low premiums. The Old House Reviews team completed a comprehensive GovHomeProgram review, evaluating the supplier's plan, claim process, cost, customer rating, etc. Read on to make sure if you choose the best home warranty company for you.</p>
                          <h2 class="anchor_h2">Advantages and disadvantages of GovHomeProgram</h2>
                          <p>Here are some advantages and disadvantages of GovHomeProgram:</p>
                          <p>Advantage:</p>
                          <p>Generous insurance cap for HVAC and air conditioning systems 90 days maintenance guarantee discount for multi-year insurance plan</p>
                          <p>Shortcoming:</p>
                          <p>If there are multiple types of maintenance, you can charge multiple service fees during the visit</p>
                          <p>GovHomeProgram Program and Coverage</p>
                          <p>GovHomeProgram offers three home warranty programs:</p>
                          <p>Bronze: most suitable for household appliances</p>
                          <p>Gold: the best choice for major systems</p>
                          <p>• Platinum care: the best comprehensive coverage</p>
                          <p>Optional Accessories</p>
                          <p>You can also choose to pay extra monthly fees to obtain additional insurance. Here are additional options you can choose:</p>
                          <p>·Household appliances: secondary refrigerator, stand-alone refrigerator, refrigerator ice maker</p>
                          <p>System: septic tank system, lawn automatic sprinkler system, central vacuum</p>
                          <p>Water pump: well pump, sewage pump</p>
                          <p>Swimming pool: swimming pool/spa equipment</p>
                          <p>Additional: plumbing devices, lighting devices, switches, sockets</p>
                          <p>Obtaining insurance for additional items can add significant value to your family warranty plan. For example, if you have a pool, you don't want to miss protecting related devices in case of failure. Since the standard insurance plan does not include items such as independent freezers, it is important to obtain additional insurance before the additional freezers in the garage deteriorate.</p>
                          <p>Insurance exclusions</p>
                          <p>In order for your system and equipment to be included in any GovHomeProgram plan, they must be in normal working condition on the effective date of the plan and free from any pre-existing diseases. There are some exceptions, such as:</p>
                          <p>Solar or instantaneous water heater</p>
                          <p>• Water softener</p>
                          <p>Solar heating system</p>
                          <p>"Geothermal heat pump"</p>
                          <p>Improper pipe installation</p>
                          <p>• Window type air conditioner</p>
                          <p>• Humidifier</p>
                          <p>The GovHomeProgram plan has specific restrictions on the repair and replacement of different types of equipment. For example, the allowance or coverage limit for heating and cooling systems is up to $3000. The repair or replacement cost of electrical appliances, pipes and appliances can be up to $500, and the additional insurance coverage is usually limited to $400.</p>
                          <h2 class="anchor_h2">GovHomeProgram pricing</h2>
                          <p>According to the quotation sample of a 1800 square foot single-family house in Charlotte, North Carolina * we received, the monthly rate of GovHomeProgram plan is the following price, and the service fee is between 60 - 75 dollars. One representative explained that the lower service telephone charge was a kind of telephone promotion.</p>
                          <p>Gold care: $44.42</p>
                          <p>Bronze care: $44.42</p>
                          <p>Platinum care: $47.75</p>
                          <p>*The price and coverage of your insurance plan may vary depending on your state and the size of your home.</p>
                          <p>Although these house warranty prices are accurate, we recommend getting quotes from GovHomeProgram, because the prices may vary depending on where you live, the insurance coverage you choose and how big your house is.</p>
                          <p>Get a free quote today by filling out this online form and your zip code or calling 888-370-3956.</p>
                          <h2 class="anchor_h2">GovHomeProgram Review</h2>
                          <p>GovHomeProgram has a B rating from the US Business Improvement Bureau (BBB). Although some GovHomeProgram comments are negative, there are other customers who have different ideas.</p>
                          <p>We found several complaints about the customer service hotline. On the other hand, some customers praised the agents for being knowledgeable and helpful.</p>
                          <h2 class="anchor_h2">Our rating method</h2>
                          <p>This old house review team supports our family warranty rating and recommendations, and uses detailed rating methods to objectively score each supplier. We conduct research by talking with company representatives, asking for quotations, analyzing sample contracts of each company, and conducting focus groups and consumer surveys. Then, we scored each supplier according to our review criteria, including coverage, value, credibility and transparency, availability and customer service, and finally got 100 points.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">GovHomeProgram Review (January 2023)</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Advantages and disadvantages of GovHomeProgram</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">GovHomeProgram pricing</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">GovHomeProgram Review</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Our rating method</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
            .Bread_crumbs >>> .el-breadcrumb__item {
                margin-bottom: 2px !important;
            }
</style>