<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Home Warranty | Reviews</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        Read My Finance Saving’s review of Home Warranty. Compare and choose the right Home Warranty provider based on your personal needs.
                    </div>
                </div>
            </div>
            <div class="article_list_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty' }">Home Warranty</el-breadcrumb-item>
                      <el-breadcrumb-item>Reviews</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
                <div class="article_list">
                    <div class="article" v-for="item of offers_list" :key="item.id">
                        <div class="one_article">
                            <div class="top_part">
                                <div class="logo">
                                    <a :href="item.url" target="_blank">
                                        <img :src="item.pic_img" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="middle_part">
                                <div class="left_part">
                                    <div class="title">Min Tax Amount</div>
                                    <div class="apr_tax">$8000</div>
                                </div>
                                <div class="right-part">
                                    <div class="title">Service Fees</div>
                                    <div class="apr_tax">$250+</div>
                                </div>
                            </div>
                            <div class="description_part">
                                <div class="selling_lines">
                                    <h3>{{ item.name }}</h3>
                                    <span> </span>
                                    <div class="lines">
                                        <ul>
                                            <li v-for="(subitem, index) of item.detail" :key="index">{{ subitem }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom_part">
                                <a target="_blank" :href="item.url" rel="nofollow" class="main_btn"><span>Visit site</span></a>
                                <!-- <a :href="item.reviews_url" class="visit_site" title="Anthem Tax Services">Read more »</a> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetOffers } from '../../api/cindex';
import { imgsapiUrl } from '../../config/env';
import {mapMutations} from 'vuex'
export default {
    name: "personal_loans_reviews",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            offers_list: []
        }
    },
    created() {
        this.GetOffersList()
    },
    methods: {
        ...mapMutations('parameters', ['setPurl', 'setPname', 'setPid', 'setsonUrl']),
        // 获取offer数据
        async GetOffersList() {
            const res = await GetOffers( { category_id: '162' } )
            res.data.forEach(i => {
                if (i.img) {
                    i.pic_img = imgsapiUrl + i.img.split('.')[0] + '/' + i.img
                }
                i.detail = JSON.parse(i.detail)
                i.reviews_url = i.url
            })
            console.log(res.data);
            this.offers_list = res.data
        },
        toread(id) {
            this.setPurl('/home_warranty_reviews')
            this.setPname('Home Warranty')
            this.setsonUrl('/home_warranty')
            this.setPid(id)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnotnavigation.scss';
@import '../../assets/scss/reviewslist.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
    .pagination >>> .el-pagination {
        text-align: center;
    }
</style>