<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Business Loans Guide</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_subtitle">Are you considering taking out a business loan? Make sure you read our guide to answer you questions regarding collaterals, business loan types and steps that need to be taken to apply.</div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2 class="anchor_h2">The Guide to Getting Approved for Business Loans</h2>
                          <p>For some business owners, a loan can make a substantial difference in whether or not they’re able to expand their company and, thus, potentially make a larger profit. By fully understanding the loan process, knowing what to look for, and what factors to compare, business owners can potentially make more informed decisions about what type of loan is the best fit for them.</p>
                          <h2>Step 1. Build your Business Credit Score</h2>
                          <p>One of the key factors in how to get approved for a business loan is a borrower’s credit score. There are two types of credit scores: a business credit score and a personal credit score. While they share some similarities, each carries important differences. For instance, a good business credit score is usually considered to be 80 and above while 740 and above is considered a very good FICO credit score. Business credit scores can be tracked by Dun & Bradstreet, Equifax, and Experian. Business owners can establish and increase their business credit score by legally registering their business, paying their bills on time, working with vendors that report payments to credit bureaus, and creating business financial accounts. If a consumer recently started their business, chances are, they won’t have much of a business credit history. In these types of instances, lenders will instead use their personal credit score to base their decision on. To check their business credit score, consumers typically need to pay the above-mentioned credit bureaus a fee. If someone is just starting out as a business owner, they can learn more about the business loans to build up their knowledge base on what to know about financing their ventures.</p>
                          <h2>Step 2. Gauge the Minimum Requirements and your Current Position</h2>
                          <p>While every lender has different approval criteria, they’ll typically take these considerations into account: credit score, how long the business has existed, revenue, and cash flow. The type of credit score borrowers needs and the age of their business will vary from lender to lender and depend on the type of loan they apply for. This is why, before taking out a loan, it’s important for business owners to know where they stand both with their credit and finances as these factors can make or break whether they are approved. It’s also highly recommended for business owners to shop around to compare the rates and terms they’re being offered. There are several types of business loans a person can take out—Small Business Administration (SBA) loans and conventional business loans. It’s recommended to know the differences between SBA loans and conventional business loans as this can guide business owners as to which to apply for.</p>
                          <h2>Step 3. Gather Relevant Financial Documents</h2>
                          <p>Knowing how to apply for a business loan is an important part of the process. Most lenders require that borrowers supply documentation to verify the information. This will give lenders insight into whether a business owner is a good fit. Borrowers may need to gather the following financial documents:</p>
                          <ul class="text1">
                              <li>Income tax returns</li>
                              <li>Balance sheets</li>
                              <li>Bank account statements</li>
                              <li>Income statements</li>
                              <li>Government issues identification</li>
                              <li>Leasing contracts</li>
                              <li>Business licenses</li>
                              <li>Plans for how you will use the loan</li>
                          </ul>
                          <p>While each lender is different, many of these document requirements may be similar for multiple lenders. So, if a business owner gathers this documentation, they may be able to present them to various lenders. However, since each lender will have different criteria, borrowers may need to ask lenders ahead of time what financial documents they may need. Once a business owner has gathered the necessary documents for a loan, they can then take these steps for applying for a business loan.</p>
                          <h2>Step 4. Articulate How the Loan will Help Expand your Business</h2>
                          <p>When giving out loans, most lenders will probably want to know how the funds will be used toward growing the business and how they will be repaid. To satisfy lenders’ criteria, borrowers may have to submit a plan on how they plan to use the loan. When talking with a lender, borrowers may need to be prepared to defend their plan by demonstrating how a loan would help expand their business and increase profitability. Borrowers may need to supply information on sales, a market analysis, a company description, and information on products or services. To prepare ahead of time, borrowers can ask lenders what kind of specific information they’re looking for when asking how the loan will be used. While some lenders may have stipulations on what borrowers can put a loan toward, oftentimes, borrowers can use business loans for the following expenses:</p>
                          <ul class="text1">
                              <li>Payroll</li>
                              <li>Opening a storefront</li>
                              <li>Purchasing supplies</li>
                              <li>Refinancing debt</li>
                              <li>Hire more staff</li>
                              <li>Licensing fees</li>
                          </ul>
                          <h2>Step 5. Provide Collateral</h2>
                          <p>When a lender provides a loan, they are taking on a certain amount of risk. By requiring collateral, lenders decrease their risk. However, in the event that a borrower cannot pay back a loan, the lender can legally take that collateral as a form of compensation. There are various types of collateral for business loans and the type of collateral a borrower may have to offer can depend on the lender. For example, a lender may allow a business owner to take out a loan to finance equipment for the company and use the purchased equipment as collateral for the loan. Depending on the type of loan, the SBA typically does not require lenders to secure loans under $25,000, but if the loan amount exceeds $350,000, lenders may want to acquire as much collateral as possible to ensure repayment. Similarly, private lenders may also want collateral that will equal to the amount they are lending.</p>
                          <h3>Personal Guarantee</h3>
                          <p>A personal guarantee is when a borrower agrees that if their business is unable to pay back a loan, they, as an individual, will repay it. It’s similar to a borrower serving as their own cosigner. As a result, that debt can negatively impact a business owner’s personal credit, especially if they’re unable to repay.</p>
                          <h3>Unsecured Business Loans</h3>
                          <p>While business loans typically require collateral, some lenders offer unsecured loans—meaning borrowers won’t have to put up any valuable assets. Unsecured loans put less risk on the borrower as, if they’re unable to repay, the lender can’t collect the collateral. On the other hand, unsecured loans can be more difficult to qualify for as they require higher credit scores and can come with higher interest rates than loans that necessitate collateral.</p>
                          <h2 class="anchor_h2">Tips for Choosing the Right Lender</h2>
                          <p>Before agreeing to take on a business loan, there are various factors consumers should take into consideration that can determine whether taking on debt is in their and their business’s best interest.</p>
                          <p>In particular, it’s important for business owners to research lenders, compare the types of loans that are offered, and decide which one is the best fit for them. Other aspects to contemplate include rates, amounts, loan repayment plans, the length of time a business has to be operating for, whether collateral is required, and the minimum required credit score. These conditions can not only determine whether a business owner may even qualify for a loan but how much they’ll pay over the lifetime of the loan.</p>
                          <p>Borrowers can learn more about their options by comparing business loan reviews and evaluating which lender may be right for them. There are also a number of considerations for funding your business with a personal loan that is worth exploring.</p>
                          <h2 class="anchor_h2">Make Informed Decisions for Your Business</h2>
                          <p>Business loans can offer financial support to business owners who are looking to expand their company. They can be used for various means such as to hire more employees, purchase equipment, or lease a storefront space. To learn more about lenders, potential borrowers can compare the best business loans and rates.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">The Guide to Getting Approved for Business Loans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">Tips for Choosing the Right Lender</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Make Informed Decisions for Your Business</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "business_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>