<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">CashRequestOnline</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>HolidayFunded</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>HolidayFunded</h1>
                          <img src="../../../../public/imgs/reviews_list/cash_request_online_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is HolidayFunded?</h2>
                          <p>Holidayfunded.com is an online marketplace where a lender can decide right away whether to provide you the money you require. We collaborate with numerous lenders, advertisers, and other networks across the country, and we don't charge you anything to utilize this website. One of our personal loan lenders will probably make you an offer once you respond to a few short questions about yourself and choose the amount of money you want to borrow, which may be up to $10,000. If you are not selected, we may provide you the chance to submit your information to other networks so that you can be considered for a smaller loan of up to $1,500 if you so like.</p>
                          <p>You may speed up the financing procedure with holidayfunded.com and have the money you need in as little as one working day. Long bank forms that required filling out, scanning, faxing, and standing in line are a thing of the past. With just one easy form, Holidayfunded.com gives you access to our network of several lenders as well as other networks, saving you both time and money.</p>
                          <p>HolidayFunded..com try to match customers with lenders that can give them the money they need in minutes by collaborating with a network of several lenders and advertisements across the country. Everything in our loan application process happens online, from form submission through lender approval and funding. If approved, financing could be available to you as soon as the following business day, though this depends on the lender.</p>
                          <p>With personal loans from 12 to 60 months offered by some of the lenders in our network, you have the freedom and flexibility to budget your borrowing over time. Look no further than holidayfunded.com if you require a competitive personal loan quickly.</p>
                          <h2 class="anchor_h2">Get connected with lender </h2>
                          <p>Receive a prompt response to your loan application. Holidayfunded.com will send you to the lender's website once you submit your information so you can check the loan terms and conditions if the lender is interested in working with you. The lender will then frequently get in touch with you to validate your personal data and complete the loan. They might call, email, text, or use another method to get in touch with you. To ensure that money is placed as soon as possible, you should react as soon as feasible.</p>
                          <h2 class="anchor_h2">Do I Qualify for a Personal Loan?</h2>
                          <p>The lending institutions that take part in our referral service will use their own standards to decide if a potential borrower qualifies for a loan. To be eligible for these loans, borrowers must fulfill a number of standard requirements. Before applying for a personal loan on holidayfunded.com, confirm that you match the requirements below:</p>
                          <ul class="text1">
                            <li>You must be at least 18 years old.</li>
                            <li>possess a Social Security number that is active</li>
                            <li>being an American citizen or lawful resident</li>
                            <li>possess a reliable source of revenue (e.g. employment, SSI, etc.)</li>
                            <li>possess a working bank account for deposits and withdrawals</li>
                            <li>possess a working phone number and email address</li>
                            <li>meeting the required minimum income</li>
                          </ul>
                          <h2 class="anchor_h2">Responsible Lending</h2>
                          <p>It's critical to make timely loan repayments if you want to take advantage of all the advantages of a personal loan. As soon as a circumstance develops that could result in you missing a payment, you should also get in touch with your lender. This will give you the chance to discuss new conditions with your lender and could result in cost savings.</p>
                          <p>It is not advisable to employ short-term credit as a long-term financial solution. Only when your monthly spending is less than your monthly income should you use new monthly credit payments. Additionally, the increased credit shouldn't reduce the sum of money you've promised to save. Abusing your borrowing privileges can harm your personal budget and result in more costs. Adopt prudent borrowing habits to prevent spiraling into debt, and if you need assistance managing your credit issues, seek credit counseling.</p>
                          <h2 class="anchor_h2">Advertising Disclosures</h2>
                          <p>You will not be charged any fees by HolidayFunded to submit a loan request, connect with a lender, or receive loan offers or estimates. The lender with whom you may be connected is available to go through the conditions of any estimate or loan offer you get with you at no charge. Although the lender might charge a fee to process your formal loan application and/or credit report, you can shop with HolidayFunded for free up until you agree to pay the lender any fee(s) or sign any loan agreements.</p>
                          <p>The firms from which you might receive offers are those from which HolidayFunded is compensated. This compensation may have an effect on the offers you receive and the placement of products on this website (including, for example, the order in which they appear). Not all lenders in the market are available on HolidayFunded. If you receive an offer, carefully read the terms before accepting.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is HolidayFunded?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Get connected with lender</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Do I Qualify for a Personal Loan?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Responsible Lending</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Advertising Disclosures</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>