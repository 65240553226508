<template>
    <div class="th-back-top" v-show="flag" @click="clickHidden">
        <span class="bg-back2top2"></span>
    </div>
</template>
<script>
export default {
    name:"BackTop",
    data(){
        return{
            flag:false
        }
    },
    mounted(){
        //获取页面可视化高度

       window.addEventListener("scroll",()=>{
        //  console.log(document.documentElement.scrollTop);  
        //获取页面滚动的高度
           let scrollTop = document.documentElement.scrollTop;
           if(scrollTop > 300){
               this.flag = true;
           }else{
               this.flag = false;
           }

       }) 
    },
    methods:{
       clickHidden(){
           document.documentElement.scrollTop = 0;   //点击返回顶部
       }
    }
}
</script>
<style scoped>

.th-back-top{
    position:fixed;
    right:15px;
    bottom:104px;
    height: 39px;
    width: 80px;
    z-index:99;
    opacity:0.8;
    text-align:right;
}
.bg-back2top2 {
    display: inline-block;
    background: url(/imgs/home_content4_icon_top.png) no-repeat 0 0;
    -webkit-background-size: 100%;
    background-size: 100%;
    width: 57px;
    height: 57px;
}
</style>