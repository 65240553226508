// 管理整个项目的接口
import axios from 'axios';
import { apiUrl } from '../config/env';

export const LoginAxios = data => {
    return axios.post(`${apiUrl}users/v1/login/`, 'grant_type=&username=' + data.username + '&password=' + data.password + '&scope=&client_id=&client_secret=', { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 获取offer列表
export const GetOffers = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}offers/v1/`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 获取offer详情
export const GetOneOffers = data => {
    const params = data
    return axios.get(`${apiUrl}offers/v1/detail`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 添加offer列表
export const AddOffers = data => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.post(`${apiUrl}offers/v1/`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改offer
export const EditOffers = data => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}offers/v1/`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 删除offer
export const DeleteOffer = data => {
    return axios.delete(`${apiUrl}offers/v1/`, { data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改offer状态
export const EditOfferStatus = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}offers/v1/update_status`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改offer排名
export const EditOfferRanking = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}offers/v1/update_ranking`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//获取offer分类列表
export const GetOffersCate = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}offers/v1/offer_cate`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 添加offer分类
export const AddOfferCate = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.post(`${apiUrl}offers/v1/offer_cate`, {...data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改offer分类
export const EditOfferCate = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}offers/v1/offer_cate`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 删除offer分类
export const DeleteOffercate = data => {
    return axios.delete(`${apiUrl}offers/v1/offer_cate`, { data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//获取文章列表
export const GetPostlist = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}posts/v1/`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//获取文章详情
export const GetOnePostlist = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}posts/v1/detail`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//获取文章标签
export const GetPostTagslist = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}posts/v1/tag/`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 添加文章标签
export const AddPostTags = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.post(`${apiUrl}posts/v1/tag`, {...data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改文章标签
export const EditPostTags = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}posts/v1/tag`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 删除文章标签
export const DeletePostTags = data => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.delete(`${apiUrl}posts/v1/tag`, { data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 发布文章
export const AddPosts = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.post(`${apiUrl}posts/v1/`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 编辑文章
export const EditPosts = (data) => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}posts/v1/`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 删除文章
export const DeletePosts = data => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.delete(`${apiUrl}posts/v1/`, { data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//获取图片列表
export const GetImagelist = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}pic/v1/`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 删除图片
export const DeleteImage = data => {
    return axios.delete(`${apiUrl}pic/v1/`, { data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

//  获取用户信息
export const GetUsers = data => {
    const params = data
    params.website_id = window.sessionStorage.getItem('website')
    return axios.get(`${apiUrl}users/v1/`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 添加offer列表
export const AddUser = data => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.post(`${apiUrl}users/v1/`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改offer
export const EditUser = data => {
    data.website_id = window.sessionStorage.getItem('website')
    return axios.patch(`${apiUrl}users/v1/`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 删除用户
export const DeleteUser = data => {
    return axios.delete(`${apiUrl}users/v1/`, { data }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 获取user详情
export const GetOneUser = data => {
    const params = data
    return axios.get(`${apiUrl}users/v1/detail`, { params }).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}

// 修改密码
export const EditPassword = data => {
    return axios.patch(`${apiUrl}users/v1/password`, data).then(res => {
        if (res.status === 200) {
            return res.data;
        } else {
            Promise.reject();
        }
    }, error => {
        console.log(error);
        return Promise.reject();
    })
}