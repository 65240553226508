<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>How Refinancing Your Mortgage Could Work for You</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Sarah Pritzker</div>
                                <div>Last update: 19 October 2020</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/How Refinancing Your Mortgage Could Work for Youblog_1.jpg" alt="">
                            <p>When you were younger, you thought your student loan was the biggest decision you'd ever make. Fast forward a few years, and you've made a lot more substantial choices, including buying a house and taking out a mortgage. Faced with this decision, all those other choices pale in comparison. Yes, it's no exaggeration to say that taking out a mortgage may be the biggest decision anyone makes in their life, certainly their most prominent financial decision up to this point.</p>
                            <p>You're a safe investor, so you do your research. You find a reputable lender, read the T&Cs, and customize the terms exactly to your specific financial goals and requirements. Excellent. Except for one thing. For the vast majority of people, those all-important requirements and goals that existed when they first purchased their home have changed over the years. Suddenly, your mortgage loan isn't as picture-perfect as it was on signing day. From the interest rate you were given (which sounded reasonable at the time) to your financial standings and repayment terms, things have certainly changed over the years.</p>
                            <p>Out of luck? Hardly. This is where mortgage refinancing comes into play. Mortgage refinancing services help you get a better deal for your mortgage loan than the original one. Wondering if refinancing is the right move for you? Check out our guide to refinancing for everything you need to know, including the pros and cons of refinancing mortgages, what it takes to refinance, and where to go next.</p>
                            <h2>How Does a Refinance Mortgage Work?</h2>
                            <p>If you're new to the field, you might be wondering how a refinance mortgage works anyway? To someone who hasn't refinanced their home, it can be an intimidating concept. You don't want to mess with a good thing (i.e., your current loan), but you like the idea of upgrading and possibly even saving money (more on that below). Luckily, the entire experience can be quick, simple, and even pleasant if you go with the right mortgage refinancing services.</p>
                            <p>So, how does a refinance mortgage work? It's actually easier than you would think. Since you've already taken out a mortgage loan, you are already familiar with most of the steps involved because taking out a refinance mortgage is almost the same process as taking out a regular mortgage loan. The basic steps include:</p>
                            <ul class="text1"><li>
                            Start with a little comparison shopping
                            </li></ul>
                            <p>Make sure to look around for the best rates and loan terms possible. After all, you're doing this to make money, one way or another. So be sure you are getting the best deal for your mortgage. Opt for an industry leader like </p>
                            <ul class="text1"><li>
                            Apply for the mortgage refinancing loan
                            </li></ul>
                            <p>This step is already familiar to you, since it's pretty much the same as the first time around. You'll need to fill out a basic application form, provide some documentation, and have a credit pull done. The good news is that since many people improve their credit over the years of owning a home (by paying off their mortgages responsibly and in a timely manner each month), you are more likely to get approved for a better rate than you received previously.</p>
                            <p>Look for any prepayment penalties that the new (and old) loan might have. Also pay attention to </p>
                            <h2>What is Good & Bad Credit & Why it Matters</h2>
                            <p>None</p>
                            <p>Alright, you're an expert now. You can answer the most important question of how does a refinance mortgage work. But you're not quite ready to graduate. Let's take a look at one of the most important factors that determine your eligibility for refinancing approval: credit scores.</p>
                            <p>Your credit score is a grade you are given based on your credit history. It takes several things into consideration including timely payments, your debt to income ratio, the amount of credit you have, and more. Based on all these factors, you'll earn a score ranging from 300-850 (generally). There are different credit unions with slightly different grading systems, but the three major ones are TransUnion, Equifax, and Experian.</p>
                            <p>So </p>
                            <p>What does this have to do with the price of tea in China? Well, since your credit score can give them a good idea of your </p>
                            <h2> How Refinancing Can Save You Money</h2>
                            <p>Music to anyone's ears, it's always good to know how we can save some cash without putting in too much effort. And that's the beauty of refinancing. It can cut back on your monthly mortgage payments and doesn't require doing anything different on your part.</p>
                            <p>So how does a refinance mortgage work to save you money? There are actually a couple of ways it can help, including:</p>
                            <ul class="text1"><li>
                            Lowering your interest rate
                            </li></ul>
                            <p>Homeowners frequently take out a refinance mortgage to get a lower interest rate than the one they're currently paying. As mentioned, credit often improves over time. So you are more likely to get a lower interest rate on the new loan than you did on the original one. It is also possible that the market rate has dropped since you took out your first mortgage, enabling you to save money on interest payments and monthly payments</p>
                            <ul class="text1"><li>
                            Changing terms
                            </li></ul>
                            <p>Refinancing also allows you to change the repayment terms of your loan. How does this help? Well, the longer you spread out your loan, the more interest you end up paying over time. By shortening your repayment terms, you shorten the number of months you need to pay interest on, thereby lowering your overall payment amount.</p>
                            <h2>Another Reason to Refinance</h2>
                            <p>Saving money is one of the benefits of refinancing mortgages, but it's not the only one. Other homeowners will take out a refinance loan to tap into the equity of their home, pulling actual money out of the value of their homes. Here's how that works:</p>
                            <p>Your home has a specific dollar amount value on the market. Its equity is the amount it is worth minus the amount you still owe on your mortgage loan. So if, for example, your home is valued at $250k, and you still have $50k left on your mortgage loan, your home's equity is $200k. Now, if you wanted to get your hands on a large sum of money, you could pull it out of your home's equity. This is frequently done in order to pay for an upgrade on the home, finance an event or trip, or to pay outstanding bills.</p>
                            <p>You might also consider a refinance mortgage if you want to switch your mortgage from an adjustable-rate to a fixed-rate. This would be helpful if you've been unlucky enough to have gotten saddled with a mortgage during a particularly rough market increase (where the rates keep rising and you keep paying more). Switching to a fixed rate can lock in a lower rate and save you from paying more when rates rise.</p>
                            <p>But there are pros and cons of refinancing mortgages. If you aren't careful with your finances, you can end up losing your home because of poor financial decisions (that force you to miss payments on your refinance loan). So, only pull out equity if you are sure you can recoup your losses steadily.</p>
                            <h2>A More Pleasant Option: Going With the Best Mortgage Refinancing Services</h2>
                            <p>To sum up, we've learned how a refinance mortgage works in the real world and what it entails. You've discovered what a credit score is, why you want a good one, and how it affects your eligibility. You can even answer the question of how does a refinance mortgage work to save you money. If sorted out properly, refinancing mortgages can actually be spun to your financial benefit.</p>
                            <p>Refinancing is not for everyone. But for the right people, the benefits of refinancing mortgages can be a real game-changer in the way you manage your entire life finances, lifestyle, and peace of mind.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Sarah Pritzker</span><br />Last update: 19 October 2020</div>
                        </div>
                        <p>Sarah Pritzker loves researching - and then sharing - all the info she can get her hand on in the world of finance. Whether it's comparing credit cards, contrasting life insurance policies, staking out investment opportunities, or investigating fraud, Sarah's got her finger on the pulse of the finance moment. Sarah enjoys writing about other topics as well, including cybersecurity, artificial intelligence, machine learning, and other hi-tech interests.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
