<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Choice Home Warranty</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty' }">Home Warranty</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Choice Home Warranty</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Choice Home Warranty</h1>
                          <img src="../../../../public/imgs/reviews_list/choice_home_warranty_review_1.webp" alt="">
                          <h2 class="anchor_h2">Home Warranty of America plans</h2>
                          <p>Home warranties in America have been protecting homeowners, buyers and sellers for decades. The company has a variety of insurance options, allowing you to choose the one that best suits your needs. American Home Warranty offers warranty service in all 50 states and has a network of service professionals. For existing homeowners, American Home Warranty offers two levels of coverage.</p>
                          <p>Premier: The Premier plan is the most affordable plan for existing homeowners. It covers over 20 major household systems and appliances, and you can add coverage for individual items such as well pumps, freestanding refrigerators, and more.</p>
                          <p>Premier Plus: The Premier Plus plan covers all major systems and appliances, including air conditioning (Premier does not include air conditioning). It includes the company's premium insurance option, which adds 25 additional items, such as plumbing fixtures and toilet replacements.</p>
                          <p>Both plans come with a 13-month warranty. You can also add GreenPlus, the former allowing you to replace old appliances with energy-efficient models, and OrangePlus, which covers other components and services, including water softeners and rekeying services.</p>
                          <p>American Home Warranty also offers a home warranty program for those buying or selling homes. Coverage under these plans may vary from state to state. The company offers 13-month insurance contracts in its Gold, Platinum and Diamond plans, with optional seller and buyer insurance. These plans can only be purchased by realtors.</p>
                          <h2 class="anchor_h2">Excluded from American Home Warranty coverage</h2>
                          <p>In some cases, certain portions of other covered items will be excluded from coverage. It's important to read your contract to fully understand what each item really covers.</p>
                          <p>American Home Warranty does specify some coverage exceptions, which are typical of all home warranty service plans. More often than not, parts that are not critical to the project performing the core functionality may be left out. Dishwashers, for example, are all included in HWA's plans, but racks, baskets, tumblers and door seals are not. Likewise, coverage for electrical systems does not include items such as fixtures.</p>
                          <p>You can find a full list of exclusions on the US sample contract.</p>
                          <h2 class="anchor_h2">Methodology</h2>
                          <p>Consumer Affairs carefully collects in-depth, verified reviews to tell each customer about their experience with a company. We believe customer experience is a key metric by which to evaluate a company, so these reviews are an important factor in our selection of the best options.</p>
                          <p>To choose our top home warranty companies, we started with a list of 24 home warranty companies in this guide. To narrow it down, we first eliminated businesses with an overall satisfaction rating of less than 3.5 stars (as of October 13, 2021).</p>
                          <p>The Consumer Affairs research team compared the remaining 16 companies on a number of factors, including:</p>
                          <p>Plan Costs: We give preference to companies with average or below-average premiums and fees.</p>
                          <p>Availability: We give priority to companies that offer services nationwide.</p>
                          <p>Insurance Choices: We give priority to companies that offer a variety of insurance policies.</p>
                          <p>To dig deeper, the Consumer Affairs research team analyzed more than 36,000 reviews submitted by certified home warranty customers over the past year (October 13, 2020 - October 13, 2021).</p>
                          <p>We used this review data to finally select the top seven home warranty companies. Five-star review data also informs some of our top recommendations. For example, Cinch Home Services was our choice for "Easy Claims Process" because our research team observed a higher-than-average frequency of 5-star reviews mentioning this topic.</p>
                          <p>To make our final ranking, we scored companies based on the percentage of 5-star reviews and the difference between the number of 5-star and 1-star reviews.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Home Warranty of America plans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Excluded from American Home Warranty coverage</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Methodology</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>