<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>Will Refinancing Student Loans Save Me Money?</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Jessica Cotzin</div>
                                <div>Last update: 20 February 2019</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/Will Refinancing Student Loans Save Me Moneyblog_1.jpg" alt="">
                            <p>Refinancing your student loans is a financial strategy that can save you money in the long-term. The sooner you refinance, the better!</p>
                            <p>Student loan refinancing allows you to take out a new loan to pay off your other student loans with the purpose of paying less in interest or decreasing your monthly payments. This is a popular method for not only student loans but other loan types, like mortgages, auto loans, and personal loans.</p>
                            <p>If your credit score and financial history are in good shape and you want to save money on your student loans, student loan refinancing is for you. For everyone else, it may not be worth it. Keep reading to learn why.</p>
                            <h2>Does Student Loan Refinancing Cost Money?</h2>
                            <p>The great thing about </p>
                            <p>With that said, always read the fine print when comparing lenders to ensure there are no hidden costs.</p>
                            <h3>Hits to your credit</h3>
                            <p>While it shouldn't cost you anything to refinance your student loans, you should be aware that submitting applications for a loan will hurt your credit score, but only temporarily. So you should only apply for refinancing if you feel confident that you'll get approved.</p>
                            <h3>What to consider before refinancing your loans</h3>
                            <p>Some factors to consider when considering student loan refinancing:</p>
                            <ul class="text1"><li>Is your income stable? When applying for a loan, lenders want to see that you're employed and have steady income. This assures them that you'll make your payments on time.</li>
                            <li>Do you have good credit? While not all lenders will have a minimum credit score requirement, it might not be worth it to apply for student loan refinancing if you don't have good credit. Your chances of getting a better interest rate than what you already have will be greater if your credit score is 600 or higher.</li>
                            <li>Is the school you attend eligible? Most lenders will require your school to be eligible for federal aid dollars when refinancing your student loans.</li></ul>
                            <h2>Shop Around For the Best Rates</h2>
                            <p>Remember when we said that submitting applications for a loan will hurt your credit score? You can still compare rates from various lenders without affecting your credit score. This is known as a soft credit check.</p>
                            <p>Many—but not all— lenders, including banks, credit unions, and online lending institutions will only run a soft credit check when pre-approving you for a loan. This means you can compare and check with several lenders to find the best interest rates.</p>
                            <h3>Preliminary Application</h3>
                            <p>To get pre-approved for </p>
                            <p>This application (depending on the lender) will often only take a few minutes to fill out, is completely online, and will get you rates immediately.</p>
                            <p>Here is some basic information you may be asked in your preliminary application:</p>
                            <ul class="text1"><li>Citizenship status</li>
                            <li>Date of birth</li>
                            <li>Contact information, like home address and phone number</li>
                            <li>Living arrangement (renting or homeowner)</li>
                            <li>Highest degree completed</li>
                            <li>Employment status</li>
                            <li>Income</li>
                            <li>How much you'd like to borrow</li>
                            <li>Social Security number</li></ul>
                            <p>Once you move forward with your application, you'll need additional information and documents with your application, like a pay stub, tax form, and official statements for your private or federal student loans.</p>
                            <h2>How You Save Money</h2>
                            <p>So you may be wondering, how does knocking down the interest rate by a couple points save you money on your loan, and is student loan refinancing really worth it?</p>
                            <p>While getting a lower interest rate by just a point or two (or more) may not seem like much, it can save you thousands in the long-run, especially if you have a long loan term.</p>
                            <p>Let's say you have $30,000 of student loan debt (the average is </p>
                            <p>If you can get a loan with a lower interest rate, say 4%, so just two points less, you'd be paying $6,448.25 on your loan, saving you $3,519.13. There are online loan calculators you can use to see what you'll be saving by refinancing your student loans.</p>
                            <h3>Lower payments</h3>
                            <p>Aside from saving money on interest, another reason why many people decide to refinance their student loans is to get a lower monthly payment.</p>
                            <p>One thing to remember when making lower monthly payments is that you'll ultimately be paying more in the long term. This is because your loan is being stretched out over a longer period of time, making your monthly payments lower but also costing you more in interest.</p>
                            <p>If you do manage to score a lower interest rate as well as lower monthly payments, you have to be careful that you're not spending more by having longer terms on your loan (unless having lower payments is your primary goal).</p>
                            <p>If your main goal when refinancing your loans is to pay less on your loan overall, then it's best to pay it off in a reasonable amount of time.</p>
                            <h2>Risks To Be Aware of When Refinancing Student Loans</h2>
                            <p>As stated earlier, you always want to make sure your lender isn't charging you any kind of fees to refinance your student loans. Unlike mortgages and personal loans, student loan refinancing shouldn't cost anything.</p>
                            <p>Another risk to be aware of when refinancing is what you'll lose. If you decide to </p>
                            <ul class="text1"><li>Income-based repayment plans — There are a few repayment plans available to you that are based on your income. So if you're having difficulty paying off your federal student loans, you can see which one you qualify for.</li>
                            <li>Forgiveness options — Federal student loans can be forgiven with a few different options if you qualify, unlike loans from a private lender which typically don't offer forgiveness.</li></ul>
                            <p>Some other benefits you could stand to lose are deferment, forbearance, and death and disability discharge. Some lenders do offer these, so if it's important to you to have these options, do your research and pick the best lender.</p>
                            <h2>Final Thoughts</h2>
                            <p>It's no wonder why student loan refinancing has become such a popular financial strategy. If you have good credit (or a cosigner with good credit), you can qualify for low interest rates on your student loans, saving you thousands.</p>
                            <p>If your primary goal is to decrease your monthly payments, refinancing can score you longer loan terms so you aren't struggling to make your monthly payments.</p>
                            <p>Now that you've learned more about the process, the next step is to shop for the best lender to find rates that will save you the most money.</p>
                            <ul class="text1"><li>Filing for bankruptcy shall not exempt from the obligation to repay the loan.</li>
                            <li>You may be eligible to specific educational loan benefits from your educational institution or may be qualified for Federal student financial assistance you may receive additional information with your institution of higher education or at the website of the U.S. Department of Education.</li></ul>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Jessica Cotzin</span><br />Last update: 20 February 2019</div>
                        </div>
                        <p>Jessica Cotzin is a writer and the TopFinanceDirectory authority on small businesses and personal loans. She has been writing about personal finance and the loans industry for a number of years, and holds a bachelor's degree in journalism from Florida Atlantic University.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    .buttom_container {
                        text-align: center;
                        margin-top: 88px;
                        .buttom {
                            padding: 13px 18px;
                            background: #2ac8a2;
                            border-radius: 50px;
                            font-size: 18px;
                            color: #ffffff;
                            line-height: 18px;
                            font-weight: 700;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
