<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Select Home Warranty</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty' }">Home Warranty</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>HomeProjectPros</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>HomeProjectPros</h1>
                          <img src="../../../../public/imgs/reviews_list/select_home_warranty_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What are HomeProjectPros?</h2>
                          <p>HomeProjectPros Corporation, an American home warranty company based in Memphis, Tennessee, ranks No. 3 on our list of the Best Home Warranty Companies. Founded in 1971, HomeProjectPros has over 50 years of experience in the home warranty industry. Today, it serves three home warranty programs covering 23 major home systems and devices. HomeProjectPros does not currently offer plans for Alaska, New York City and certain zip codes in Hawaii.</p>
                          <h2 class="anchor_h2">HomeProjectPro warranty plan and pricing</h2>
                          <p>HomeProjectPros offers clients three plans: ShieldSilver, ShieldGold, and ShieldPlatinum. Its ShieldSilver is at the lowest level of coverage, but does cover some important systems such as plumbing. ShieldGold plans cover the systems in the Silver plan, as well as most everyday kitchen and laundry equipment. For the highest level of coverage, ShieldPlatinum plans cover 14 major systems, nine devices, and some additional benefits, such as roof leak coverage and HVAC adjustments, starting at about $69.99 a month.</p>
                          <p>To further customize your plan and pricing, you can choose from three trade service phone rates: $75, $100, or $125. The higher the fee, the lower the monthly cost. You can also add coverage for other items, such as electronics or swimming pools, but this will cost extra.</p>
                          <p>Screened silver</p>
                          <p>Hildsilver plans start at $29.99 a month and cover air conditioning, heating, electrical systems and fittings, and plumbing and fittings. Its complete coverage includes:</p>
                          <ul class="text1">
                            <li>air conditioning</li>
                            <li>heating device</li>
                            <li>pipeline system</li>
                            <li>built-in exhaust, air vents, and the attic fan</li>
                            <li>the main circuit breaker and fuse panel box</li>
                            <li>the doorbell and the doorbell</li>
                            <li>internal wiring</li>
                            <li>ceiling fans</li>
                            <li>garage door openers</li>
                            <li>water heater</li>
                            <li>internal water piping</li>
                            <li>the toilet</li>
                            <li>the faucet and the valve</li>
                            <li>whirlpool bathtub motor and components</li>
                          </ul>
                          <p>A key feature of the cladding is that there are no dollar limits on covering components for HVAC, plumbing and electrical systems. Some companies limit coverage to about $1,500, which can leave homeowners with out-of-pocket costs.</p>
                          <p>Heald gold</p>
                          <p>From $49.99 a month, ShieldGold covers all of ShieldSilver's products with the same limit, plus nine extra kitchen and laundry units:</p>
                          <ul class="text1">
                            <li>refrigerator</li>
                            <li>stove/oven</li>
                            <li>oven</li>
                            <li>the dishwasher</li>
                            <li>built-in microwave oven</li>
                            <li>garbage disposal</li>
                            <li>instant hot/cold water dispenser</li>
                            <li>the washer and dryer</li>
                          </ul>
                          <p>With that coverage, it's up to $3,000 per device, which leaves a lot of wiggle room along the way. Compared to some of the other companies we rate, $3,000 is a fairly average limit.</p>
                          <p>Shielded platinum</p>
                          <p>From $69.99 a month, ShieldPlatinum covers everything from ShieldSilver to ShieldGold at the same price, plus a few extra benefits:</p>
                          <ul class="text1">
                            <li>roof leakage repair</li>
                            <li>a free adjustment of HVAC</li>
                            <li>infinite air conditioning refrigerant</li>
                            <li>irregularities and licensing of coverage</li>
                          </ul>
                          <p>The device coverage cap has also been doubled, to $6,000 per device under the plan.</p>
                          <p>Each HomeProjectPros plan also includes a number of maintenance services, including $100 re-ignition, discounted heating or air-conditioning conditioning (ShieldPlatinum comes with one), discounted air filters and air-conditioning refrigerants. In addition, duplicate items, such as a second air conditioner, are available at no additional cost. Its process guarantee is only 30 days, and some of the other companies on our list offer longer warranties.</p>
                          <p>If you would like to learn more about what is and isn't covered under a home warranty, please read our What Is Covered under a Home Warranty? The guide.</p>
                          <h2 class="anchor_h2">What does HomeProjectPro guarantee cover?</h2>
                          <p>Depending on the plan you choose, the HomeProjectPro warranty can cover a variety of items. Some of the systems and equipment covered include:</p>
                          <ul class="text1">
                            <li>Air conditioning and heating units</li>
                            <li>Internal wire</li>
                            <li>Ceiling fan</li>
                            <li>Garage door opener</li>
                            <li>Water supply and drainage system</li>
                            <li>kitchenware</li>
                            <li>Laundry equipment</li>
                            <li>Plans can include, among other things, unlimited refrigerants for air conditioning, and coverage for violations and permits.</li>
                          </ul>
                          <h2 class="anchor_h2">What is not covered by HomeProjectPros?</h2>
                          <p>Some things HomeProjectPros didn't cover:</p>
                          <ul class="text1">
                            <li>Freestanding freezer</li>
                            <li>Flue, ventilation pipe, chimney and exhaust pipe</li>
                            <li>Solar energy systems and modules</li>
                            <li>Fire sprinkler system</li>
                          </ul>
                          <p>HomeProjectPro also does not include routine maintenance or system and device upgrades. This is not a complete list of everything that HomeProjectPro does not cover. To determine what is and what is not covered, read your home warranty policy carefully.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What are HomeProjectPros?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">HomeProjectPro warranty plan and pricing</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">What does HomeProjectPro guarantee cover?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What is not covered by HomeProjectPros?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>