<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Debt Consolidation Guide</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Ever wondered how Debt consolidation affects your credit score, How it can assist you with a wage garnishment, or what is the difference between Debt consolidation and a personal loan? Find all the answers in these guides</div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2 class="anchor_h2">How to Get a Debt Consolidation Loan in 7 Steps</h2>
                          <p>When borrowers accrue debt from multiple lenders, it can be difficult to track payments. Even if they have bad credit, debt consolidation loans are a way for borrowers to centralize what they owe by combining their debts into a single loan with a fixed payment plan. Read on to learn how to apply for a debt consolidation loan and compare offers.</p>
                          <h2>1. Know Your Credit Score</h2>
                          <p>A borrower’s first step as to how to get approved for a debt consolidation loan is to find out where their credit score stands. This may determine what kind of rates, terms, and amounts they’re eligible for or even which lenders will work with them.</p>
                          <p>A good credit score is considered a FICO score of 670 and above while a bad score is 579 or below. Many lenders may see bad credit scores as a sign that the individual would be a much greater risk to lend to than someone with a good credit score and, thus, may not approve them.</p>
                          <p>Consumers can check if they qualify with a lender without damaging their credit score if the lender offers soft credit checks. This allows consumers to prequalify for a loan without hurting their credit score. A hard check, on the other hand, could temporarily put a dent in a borrower’s score.</p>
                          <p>However, as frustrating as a bad credit score can be, it’s not the end of the world for many. In fact, there are plenty of ways consumers can improve their scores. If an individual has a credit score that could use some polishing, you can try to improve it by learning our tips for working on bad credit.</p>
                          <p>To check their credit score for free without hurting their credit, consumers can visit AnnualCreditReport.com.</p>
                          <h2>2. Calculate your Sum Total Debt and Payments</h2>
                          <p>To start calculating how much they may need for a debt consolidation loan, consumers can gather information on their current debts such as the amount still owed, interest rates, and the minimum monthly payment amount. Add together the total amount that is owed as well as the monthly repayments, then calculate the average interest rate across all the debts. Consumers can leave out secured debts and student loans.</p>
                          <p>Once the information is pulled together, consumers can compare those numbers to the interest rates, monthly payment amounts, and terms offered by lenders. This way, consumers can see which lender offerings may save them the most amount of money in the long run and find out how much funding they’ll need to cover their debts.</p>
                          <p>A common use for debt consolidation loans is to put it toward credit card debt. This is because credit cards often come with high interest and it can take months, even years, to pay it off. In the meantime, those credit cards are accruing interest. A debt consolidation loan can provide a light at the end of the tunnel with a clear repayment plan and potentially lower interest rates.</p>
                          <h2>3. Research Loan Options</h2>
                          <p>The steps for applying for a debt consolidation loan also include lots of research. It’s important to thoroughly review debt consolidation loan providers whether that’s a bank, credit union, or online lender. While banks are best for those with good credit, credit unions and online lenders tend to be more willing to work with those whose credit is poor.</p>
                          <p>While researching, consumers can note whether lenders report payments to credit bureaus and, if they do, which ones. Consistent, on-time payments could potentially increase credit scores so it’s important that your lender reports these. Consumers may also want to keep an eye out for lenders that simplify the process as this could be indicative of what it’s like to work with them.</p>
                          <p>If a consumer has a poor credit profile, they can look into consolidating debt with a bad credit score as some lenders are willing to work with credit scores that are less than perfect.</p>
                          <h2>4. Figure out a Payment Plan</h2>
                          <p>Before agreeing to a loan, consumers may want to create a financial budget to determine how much they can afford in monthly repayments. This way, potential borrowers can set themselves up to be prepared for this new loan plan and avoid missing payments. Consumers can plan ahead and avoid the same pitfalls that may have landed them in the position of needing a debt consolidation loan in the first place.</p>
                          <p>Within that budget, consumers can list out their projected monthly income as well as expenses. Consumers may also want to account for any unexpected expenses and provide themselves some financial cushion in case of any surprises down the road. A budget can give them an overview of how much they can afford in minimum monthly payments and even if they can afford to make extra payments. Consumers can compare this amount to how much they’re paying with their current debts.</p>
                          <h2>5. Apply and Compare Lenders for the Best Rates</h2>
                          <p>To find the right offer, consumers can apply to multiple lenders. If a lender offers a soft credit check, the consumer can check what the lender is willing to offer them without hurting their credit. When comparing quotes, consumers should pay particular attention to interest rates, loan terms, amounts, origination fees, and minimum monthly payments. Other considerations may be how quickly loans are funded and any other fees or penalties. While researching lenders, consumers may also want to continue educating themselves on debt consolidation principles to avoid any surprises down the road and decide if this type of loan is best for them.</p>
                          <h2>6. Read the Fine Print</h2>
                          <p>Some find that reading the fine print on an offer is among the most important steps for getting approved for a debt consolidation loan. The last thing a consumer may want is to face unexpected fees, penalties, or terms they weren’t aware of before they signed.</p>
                          <p>In particular, consumers may want to look over the fine print for the final APR rate, origination fees, late fees, and prepayment penalties. Another detail worth noting is whether the lender is willing to lower your interest rate if you sign up for automatic payments.</p>
                          <p>Find out whether your lender reports payments to the three credit bureaus—Equifax, Experian, and TransUnion. If they do report payments, consumers can find out to which bureaus if not all three. If a lender does not report payments, that may be a red flag. This can be an important factor to check on as on-time payments can lead to an increase in credit scores.</p>
                          <h2>7. Accept the Loan and Start Repayment</h2>
                          <p>Once a borrower has accepted the loan, they’ll need to start making repayments to their new lender. However, first, they need to make sure that their original creditors are paid off. While some lenders will directly pay the original creditors, some will fund the borrower the money and leave them to pay the original lenders. In this case, it’s important for borrowers to pay that debt off immediately. If the new lender is responsible for directly paying off the original creditors, the borrower may want to check with both the new and old lenders to verify that this is done.</p>
                          <p>Once the borrower starts making payments, they’ll want to keep up with the minimum monthly amount required. However, borrowers can save money in the long run by putting down extra payments on the debt consolidation loan. The longer the loan is dragged out, the lower the minimum payments but the higher the interest rate, and vice versa.</p>
                          <h2 class="anchor_h2">What Information And Documentation Will Loan Providers Ask For?</h2>
                          <p>During the application process, consumers will give their contact information, their income, how much funding they’re looking for, their credit score, and what they plan to use the money for. Once this has been submitted by the consumer, the lender will reach out and ask to verify the information. Depending on the type of lender, the consumer can do this either online or in person.</p>
                          <p>The documents consumers may need to provide can include:</p>
                          <ul class="text1">
                              <li><span class="text_bold">Proof of income:</span> Copy of pay stub/bank statement/tax return</li>
                              <li><span class="text_bold">Proof of identification:</span> Copy of driver’s license/passport/social security card</li>
                              <li><span class="text_bold">Address verification:</span> Proof of residence/utility bills/lease agreements</li>
                          </ul>
                          <h2 class="anchor_h2">Where Can the Best Debt Consolidation Loans Be Found?</h2>
                          <p>Because the hunt for a debt consolidation loan can be a complex and deeply personal decision, it’s important for consumers to take their time and do their due diligence in researching their options. Comparing rates, loan terms, loan amounts, fees, and the funding timeline can make a big difference in determining whether a particular lender is a good fit for them. It’s especially important that potential borrowers read the fine print on any loan offer to make sure they understand all the implications and avoid any unexpected terms.</p>
                          <p>Consumers may also want to put together a budget or financial plan for paying off their debt. This way consumers can estimate how much they can afford to pay and provide greater insight as to whether a debt consolidation loan is a right choice for them.</p>
                          <p>To learn more about the various options on the market, consumers can read our best debt consolidation loans available.</p>
                          <h2 class="anchor_h2">How A Debt Consolidation Loan Can Affect Your Credit Score</h2>
                          <p>Before applying for a loan, it’s recommended to understand how debt consolidation affects your credit score. While a debt consolidation loan may negatively impact a borrower’s credit score because they’ll need to go through a hard credit check, their score will eventually bounce back. Making consistent, on-time payments on a debt consolidation loan can have a positive effect on a person’s credit score if the lender reports the payments to the credit bureaus.</p>
                          <h2 class="anchor_h2">Debt Consolidation vs Personal Loans</h2>
                          <p>Personal loans can be used for just about any purpose, whether that be to pay for a wedding or pay medical bills. These loans are typically unsecured, meaning they don’t require collateral and have fixed interest rates. While a debt consolidation loan is a type of personal loan, there is a difference between debt consolidation and personal loans. Debt consolidation loans are specifically geared toward paying off existing debts by rolling them into one loan.</p>
                          <h2 class="anchor_h2">Debt Consolidation vs Personal Loans</h2>
                          <p>People can have their wages garnished if they’re unable to pay their debts, and learning how to deal with wage garnishment can be a confusing process. Wage garnishment is when an employer is legally required to hold out a certain portion of a person’s paycheck. That portion will then go toward paying off the delinquent debts. Paychecks can be garnished to pay off student loans, child support, credit cards, and loans.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">How to Get a Debt Consolidation Loan in 7 Steps</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">What Information And Documentation Will Loan Providers Ask For?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Where Can the Best Debt Consolidation Loans Be Found?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===3}">How A Debt Consolidation Loan Can Affect Your Credit Score</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===4}">Debt Consolidation vs Personal Loans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)" :class="{active: active===5}">Debt Consolidation & Wage Garnishment</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "debt_consolidation_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                width: 61%;
                margin: 11px auto 0;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        .article_list {
            width: 61%;
            margin: 0 auto 200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_list_left {
                width: 750px;
                .text {
                    margin-top: 40px;
                    font-size: 18px;
                    color: #666666;
                    line-height: 24px;
                    margin-bottom: 25px;
                    p {
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                        line-height: 36px;
                    }
                    table {
                        text-align: center;
                        margin-bottom: 35px;
                        th {
                            background-color: #2ac8a2;
                            color: #fff;
                        }
                    }
                }
                .article_content {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10);
                    border-radius: 12px;
                    padding: 0 15px;
                    .article_content_container {
                        .article {
                            display: flex;
                            justify-content: space-between;
                            color: #666666;
                            padding: 32px 0;
                            border-bottom: 1px solid #c5c5c5;
                            .left {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                .left_top {
                                    margin-bottom: 15px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                }
                            }
                            .center {
                                .center_top {
                                    font-size: 18px;
                                    color: #666666;
                                    span {
                                        font-size: 36px;
                                        font-weight: 700;
                                        color: #0062bd;
                                    }
                                    span:after {
                                        content: 'APR.';
                                        font-size: 14px;
                                        color: #666666;
                                        font-weight: 400;
                                        position: absolute;
                                    }
                                    ul {
                                        margin-top: 16px;
                                        li {
                                            font-size: 11px;
                                            color: #666666;
                                            line-height: 20px;
                                            background: url("/imgs/Snipaste_2022-03-21_18-33-23.png") no-repeat 0 5px;
                                            text-indent: 20px;
                                        }
                                    }
                                }
                            }
                            .right {
                                text-align: center;
                                .button {
                                    padding: 9px 35px;
                                    background-color: #092742;
                                    color: #fff;
                                    margin-bottom: 13px;
                                    font-size: 14px;
                                    font-weight: 700;
                                }
                                .remark {
                                    font-size: 11px;
                                    color: #666666;
                                }
                            }
                        }
                        .article:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
            .article_list_right {
                width: 325px;
                margin-top: 40px;
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #fff;
                            line-height: 20px;
                            background-color: #2ac8a2;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                            border-radius: 12px 12px 0px 0px;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 25px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px 45px;
                                padding-bottom: 15px;
                                div {
                                    font-size: 18px;
                                    line-height: 18px;
                                    color: #222831;
                                    text-align: left;
                                }
                                div:hover {
                                    color: #2ac8a2;
                                    cursor: default;
                                }
                                .active {
                                    font-weight: bold;
                                    color: #222831;
                                }
                            }
                            li:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }

        }
    }
    .question_container {
        width: 100%;
        background-color: #f5f8fb;
        .question_list {
            width: 61%;
            margin: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .question_list_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                h2 {
                    font-size: 36px;
                    margin: 80px 0 44px 0;
                    color: #222831;
                }
                p {
                    margin-bottom: 25px;
                }
                ul {
                    li {
                        margin-bottom: 80px;
                        span {
                            font-weight: Bold;
                        }
                    }
                    li:before {
                        content: " ";
                        background-color: #2ac8a2;
                        display: inline-block;
                        font-weight: bold;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                }
            }
            .question_list_right {
                width: 325px;
                .accordion {
                    margin-top: 80px;
                    padding: 20px 45px;
                    background-color: #fff;
                    border-radius: 12px;
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    font-size: 18px;
                    ul {
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5 ;
                            margin-bottom: 17px;
                            padding-bottom: 17px;
                        }
                        li:first-child {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
    .answer_container {
        width: 100%;
        .answer_list {
            width: 61%;
            margin: 50px auto 210px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .answer_list_right {
                width: 750px;
                .answer {
                    line-height: 36px;
                    margin-top: 50px;
                    padding-bottom: 30px;
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 44px;
                    }
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 23px;
                        text-align: justify;
                        margin-bottom: 30px;
                    }
                }
                .answer:not(:last-child) {
                    border-bottom: 1px solid #c5c5c5;
                }
            }
            .answer_list_left {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
                .head_subtitle {
                    width: 95%;
                }
            }
        }
        .article_container {
            .article_list {
                width: 90%;
                flex-direction: column-reverse;
                .article_list_left {
                    width: 96%;
                }
                .article_list_right {
                    width: 96%;
                    margin-bottom: 30px;
                    .text_right_bottom {
                        width: 96%;
                    }
                }
            }
        }
        .question_container {
            .question_list {
                width: 90%;
                flex-wrap: wrap;
                .question_list_right {
                    display: none;
                }
            }
        }
        .answer_container {
            .answer_list {
                width: 90%;
                flex-wrap: wrap;
            }
        }
    }
    @media (max-width: 760px) {
        .nav_img {
            height: 850px;
        }
        .article_container {
            .article_list {
                align-items: center;
                .article_list_left {
                    width: 90%;
                    .article_content {
                        .article_content_container {
                            .article {
                                flex-direction: column;
                                align-items: center;
                                div {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
                .article_list_right {
                    width: 100%;
                    .text_right_bottom {
                        width: 100%;
                    }
                }
            }
        }
        .question_container {
            .question_list {
                .question_list_left {
                    width: 100%;
                }
            }
        }
        .answer_container {
            .answer_list {
                .answer_list_right {
                    width: 100%;
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
    .text_bold {
        font-weight: bold;
    }
</style>

<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>