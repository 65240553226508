<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Home Warranty</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Home warranty may appear to be an unnecessary extra cost, but both during a sale of a home and later in home ownership, these tools can protect your investment and save you money. We develop the world's most comprehensive house warranty information, diving into the tiny variations between each home warranty business now on the market.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/home_warranty" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Home Warranty</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/home_warranty_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Home Warranty Reviews</span>
                          </router-link>
                      </div>
                      <!-- <div class="head_card">
                          <router-link to="/home_warranty_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Home Warranty Guide</span>
                          </router-link>
                      </div> -->
                      <div class="head_card">
                          <router-link to="/home_warranty" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Home Warranty Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Home Warranty</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.pic_img" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="item.star"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.name}}</span>
                                          <ul>
                                              <li v-for="(subitem, index) of item.features" :key="index">{{ subitem }}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What is Home Warranty?</h2>
                          <p>A home warranty is a service contract with a warranty company that covers the cost of maintaining and repairing all whole-house systems (such as electrical, plumbing, and HVAC) and appliances listed in the contract, and may also cover major appliances like washers and dryers, refrigerators, and swimming pools. A yearly service fee, similar to an insurance deductible or a medical copay, is required.</p>
                          <h2 class="anchor_h2">What’s the Benefits of Home Warranty?</h2>
                          <ul class="text1">
                              <li>A home warranty covers many of your home's major systems and appliances, which can be costly to fix or replace. </li>
                              <li>Help you budget for unexpected expenses. Instead of guessing how much system breakdowns will cost you all the time, you'll have a clearer understanding of what costs to expect, which will help you manage your household budget.</li>
                              <li>Be used in conjunction with regular homeowner's insurance. Unlike homeowner's insurance, home Warranty covers important appliances and home system components when they break down due to regular wear and tear, which most homeowner's insurance policies do not cover.</li>
                              <li>Having a house warranty can save your valuable time. Breakdowns of home systems and appliances often seem to occur at the most inconvenient times, leaving you scurrying to find available aid from a reputable contractor. If the item is covered by a home warranty plan, you have a ready resource to call instead of scrambling for repair help.</li>
                              <li>Save even more money. The Discount Program provides unique discounted pricing on premium name brand appliances to home warranty customers, customers also obtain useful home management information that might assist them in running their houses more efficiently. </li>
                          </ul>
                          <h2 class="anchor_h2">How to Buy Home Warranty?</h2>
                          <ul class="text1">
                              <li>Gather all of the home's systems and appliances' maintenance records.If you fail to maintain track of the maintenance records for your home systems and appliances, it could cost you later. Your claim could be refused if you don't have enough documentation for the appliance's upkeep. Because most home warranty providers do not cover known pre-existing issues, possessing these maintenance records can show an absence of them.</li>
                              <li>Identify any appliances or other objects that may need to be repaired in the future.Check which items are still covered by the manufacturer's warranty and which ones don't. Based on your maintenance data, you can also identify items that need to be covered. A home warranty is a sensible investment if you have objects in your home that are likely to fail or wear out over time. A home warranty can help you save money on frequent repairs for household items that break down.</li>
                              <li>Look into Home Warranty Providers<br />Before purchasing a home warranty, you should research online for those companies in your area,comparing their service quality,pricing and coverage,reputation and any other specific or customizable plans. And contact the warranty providers directly to learn more about their plans.</li>
                              <li>Gather at least three quotes from reputable home warranty providers. You can compare providers, service contracts, limitations, and costs in this manner.</li>
                          </ul>
                          <h2 class="anchor_h2">How Much Does Home Warranty Cost?</h2>
                          <p>For the most comprehensive coverage, the home warranty plans in our rating range in price from $546 to $840 per year. The cost of a service visit ranges from $60 to $125. The plan you choose, however, will determine what services you have and how much you pay. The prices and services provided by the plans vary depending on a variety of factors.</p>
                          <p>The cost of your home warranty may be affected by the following factors:</p>
                          <ul class="text1">
                              <li>The location of your house</li>
                              <li>The insurance policy you purchase</li>
                              <li>Any additional coverages you may choose to include</li>
                              <li>Count the number of service calls you make (additional to the cost of your plan)</li>
                          </ul>
                          <h2 class="anchor_h2">The Difference of Home Warranty and Home Insurance</h2>
                          <p>"But I already have homeowners insurance!" you may say. "Why would I need home warranty insurance?" The answer is that insurance and warranties are similar in concept but very different in terms of what they cover.</p>
                          <p>Homeowners insurance is an important part of owning a home and is usually required by mortgage lenders. It protects a home from both internal and external damage and, in some cases, offers the homeowner liability protection if an accident occurs on the property. It is specifically limited to damage caused by fires, property crime, water, weather, and other external forces, and it expressly excludes failures caused by normal aging or wear and tear.</p>
                          <p>That is the purpose of a home warranty: Many of the costly and unexpected failures that homeowners insurance excludes are covered by a warranty. A strong home protection plan is formed when homeowners insurance and a home warranty are combined. Accidental and damage coverage, as well as age-related failure, will be provided for the home, and in some cases, both types of protection are required. If a hot water pipe leaks into the ceiling, the home warranty will cover the pipe's repair or replacement, and homeowners insurance may cover the damage to the walls, ceiling, carpeting, and any possessions damaged as a result of the leak.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is Home Warranty?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">What’s the Benefits of Home Warranty?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">How to Buy Home Warranty?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">How Much Does Home Warranty Cost?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">The Difference of Home Warranty and Home Insurance</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { GetOffers } from '../../api/cindex';
import { imgsapiUrl } from '../../config/env';
export default {
    name: "home_warranty",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: [],
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    created() {
        this.GetOffersList();
        console.log(imgsapiUrl);
    },
    methods: {
        // 获取offer数据
        async GetOffersList() {
            const res = await GetOffers( { category_id: '162', page: '1', page_size: '4',  } )
            res.data.forEach(i => {
                i.pic_img = imgsapiUrl + i.img.split('.')[0] + '/' + i.img
                i.star = 5
            })
            this.offers_list = res.data
        },
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/navimgcrad.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>

<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>