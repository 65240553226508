<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Smarter security features for your smart money moves</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Rest easy knowing that we’ve done our nerdy research and partnered with industry experts to keep your data secure.</span>
                    </div>
                </div>
            </div>
            <div class="from_container">
                <div class="from">
                    <div class="from_left">
                        <h2 style="margin-bottom: 35px;">Frequently asked questions</h2>
                        <el-collapse v-model="activeName" accordion>
                            <el-collapse-item title="Why do you need my bank’s username and password?" name="1">
                                <div>Linking your bank accounts to TopFinanceDirectory’s dashboard not only allows you to see all your accounts in one place and transfer money between them, but it also helps us provide the best, most personalized recommendations for you. We link your accounts using the Yodlee platform, who are just as committed to your security as we are. Learn more about Yodlee privacy policies.</div>
                            </el-collapse-item>
                            <el-collapse-item title="How does TopFinanceDirectory protect my personal information?" name="2">
                                <div>We use industry-standard security controls, such as cryptography, to protect your personally identifying information. And our partners Yodlee, TransUnion, and Plaid do the same.</div>
                            </el-collapse-item>
                            <el-collapse-item title="How does TopFinanceDirectory get my credit score?" name="3">
                                <div>TopFinanceDirectory partners with TransUnion® to provide your VantageScore® 3.0, based on information in your TransUnion® credit report. Your score and credit report information are updated weekly.</div>
                            </el-collapse-item>
                            <el-collapse-item title="Is my credit score really free?" name="4">
                                <div>Yup, it’s 100% free. You can sign in to TopFinanceDirectory anytime to see your free credit score, your free credit report info and more. TopFinanceDirectory only pulls your credit score with your permission.</div>
                            </el-collapse-item>
                            <el-collapse-item title="How can I do my part to keep my account information safe on TopFinanceDirectory? " name="5">
                                <div>Here are a few things we recommend for keeping your info safe — with us, and in general. For starters, make your password tough to guess. Mix up numbers, letters and special characters when you can. Make sure you never share it with anybody. And never reuse your TopFinanceDirectory account password on any other site. We also recommend using virus protection, firewalls, and password managers. Make sure you never install programs from unfamiliar people or companies, or from suspicious emails. For any programs you install, apply all security updates. These steps will help protect you against identity theft and phishing attempts.</div>
                            </el-collapse-item>
                            
                            <el-collapse-item title="Can I unlink accounts from TopFinanceDirectory?" name="6">
                                <div>Yes, you can unlink your accounts at any time. And even though we’d be sad to see you go, you can also deactivate your TopFinanceDirectory account here.</div>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                    <div class="from_right">
                        <div class="text_right_top">
                            <h2>Connect with us</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/phone.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>+1 8049932944</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/email.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>demand@samsonads.com</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/address.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>6 Jason Drive,East Brunswick,New Jersey 08816</span>
                                    </div>
                                </li>
                                <!-- <li>
                                    <div class="left">
                                        <img src="/imgs/contact_us_fb.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>Find us on Facebook</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/contact_us_fb.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>Join the Bankrate Insiders Facebook group</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/contact_us_tw.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>Tweet us @Bankrate</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/contact_us_in.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>Connect with us on LinkedIn</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/contact_us_inw.png" alt="">
                                    </div>
                                    <div class="center text_a">
                                        <span>Follow us on Instagram</span>
                                    </div>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="map_container">
                <div class="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.1140766177855!2d-74.43116478435135!3d40.40632356419576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c56cace82d7d%3A0xc637f13e91d478ff!2s6%20Jason%20Dr%2C%20East%20Brunswick%2C%20NJ%2008816!5e0!3m2!1sen!2sus!4v1650592588905!5m2!1sen!2sus" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EmailSystem } from '../../api/cindex';
export default {
    name: "faqs",
    data() {
        return {
            map: null,
            activeName: 0,
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                width: 61%;
                margin: 11px auto 0;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
        }
    }
    .from_container {
        width: 100%;
        .from {
            width: 61%;
            margin: 50px auto 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .from_left {
                width: 750px;
            }
            .from_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    h2 {
                        font-size: 24px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 24px;
                        background-color: #f5f8fb;
                        padding: 18px 0px;
                        text-align: center;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 19px 5px;
                            .left {
                                margin: 0 15px 0 30px;
                            }
                            .center {
                                display: flex;
                                flex-direction: column;
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 14px;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
            }
        }
    }
    .map_container {
        width: 100%;
        .map {
            width: 61%;
            height: 426px;
            margin: 40px auto 220px;
        }
    }
    @media (max-width: 1280px) {
        .from_container {
            .from {
                width: 90%;
            }
        }
        .map_container {
            .map {
                width: 90%;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .from_container {
            margin-top: 50px;
            .from {
                width: 90%;
                flex-direction: column-reverse;
                .from_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                    h2 {
                        font-size: 38px;
                    }
                }
                .from_right {
                    width: 100%;
                }
            }
        }
        .map_container {
            .map {
                width: 90%;
            }
        }
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
.text_a {
    color: #2684ff;
}
</style>
<style>
.el-button{
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 700 !important;
    background-color: #2ac8a2 !important;
    padding: 13px 70px !important;
    border-radius: 50px !important;
}
.el-select {
    width: 100%;
}
.el-form-item__label {
    font-family: Roboto, Roboto-Bold;
    font-weight: 700;
    color: #222831;
}
.el-collapse-item__header {
    font-size: 16px !important;
    font-weight: 700 !important;
}
</style>
