<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>What is Life Insurance?</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Jessica Cotzin</div>
                                <div>Last update: 27 October 2020</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/What is Life Insuranceblog_1.jpg" alt="">
                            <p>Whether it's payments on our car, mortgage, or student loans—I think we can all agree: bills aren't fun, but they're certainly worth it, right?</p>
                            <p>After all, there's nothing quite like driving a brand new vehicle off the lot or stepping into your first home with your family. Sure, making payments on some of these things can feel burdensome, but they're a fact of life for the majority of Americans.</p>
                            <p>Think of life insurance in the same way. It may be adding another payment to your already growing list of monthly payments, but it also offers you something that makes it worth it: peace of mind.</p>
                            <p>While it's not something most people like thinking about—in fact, roughly </p>
                            <p>It's a safety net that ensures your loved ones are taken care of financially if you die, which is especially important if you're a parent of young children or someone else who relies on your income.</p>
                            <p> </p>
                            <h2>Life Insurance: What it is and How it Works</h2>
                            <p>If you're considering purchasing a life insurance policy, it's important to understand not only what it is, but how it works. Let's break it down.</p>
                            <h2>#1 Terminology</h2>
                            <p>Let's start off with some of the terminology you'll encounter when shopping around for life insurance. Understanding these common terms will be helpful when you're ready to compare quotes and choose an insurer.</p>
                            <ul class="text1"><li>Policy: This is the contract you have with the life insurance company.</li>
                            <li>Premiums: The monthly payments you'll be making to the policy.</li>
                            <li>Policyholder: Whoever takes out the policy. If you buy life insurance for someone else, you would still be the policyholder since you are the owner of the policy.</li>
                            <li>Death benefit: The lump sum of money provided if you die.</li>
                            <li>Beneficiary: The person (or people) chosen to receive the death benefit if you, the owner of the policy) passes away. This is most often children or a spouse, but it could be anyone.</li>
                            <li>Riders: These are additional options you can add to a life insurance policy, like extra coverage. These added benefits offer more flexibility to your life insurance coverage but will come at a higher cost.</li></ul>
                            <p> </p>
                            <h2>#2 What is life insurance?</h2>
                            <p>Life insurance is a contract between you (the policy owner) and the insurance company. As long as you make your monthly payments on your policy, the insurer will pay the agreed upon amount to your loved ones.</p>
                            <p>This is important because when you die, so does your income. If you want your family to maintain their current standard of living and to not feel the burden of that loss of income, life having life insurance is key.</p>
                            <p>Here are some quick highlights:</p>
                            <ul class="text1"><li>Similar to other types of insurance (like auto or health insurance), life insurance is a legally binding contract.</li>
                            <li>You'll often need to disclose your health condition (former and current) as well as your high-risk activities. For example, if you're a smoker who also enjoys mountain climbing, you'll need to disclose this kind information to your insurer since they're factors that will affect your policy rate.</li>
                            <li>You must continue to pay your monthly (or yearly) policy premium for your life insurance to remain active.</li>
                            <li>When the insured dies, their beneficiaries will receive the death benefit.</li>
                            <li>Term life insurance policies expire after the agreed upon term. For example, most insurers offer life insurance coverage for 10, 15, and 20 years.</li>
                            <li>Whole life insurance is a permanent policy that doesn't expire, but it's much more expensive. It also comes with a cash value (a sort of investment component).</li></ul>
                            <p> </p>
                            <h2>#3 Types of life insurance</h2>
                            <p>There are several types of life insurance, but the most popular options are term life insurance and whole life insurance.</p>
                            <h3>Term life insurance</h3>
                            <p>This is the most popular and affordable type of life insurance. Term life insurance offers coverage until the policy expires. When you shop around for this type of policy, you choose how long you'd like coverage, whether it's 10-year, 20-year, or longer.</p>
                            <p>Every insurer is different but most will offer terms ranging between 10-30 years. </p>
                            <p>How it works with term life insurance is if you die within the selected term, your beneficiary will receive the death benefit. If you don't die during the term and it expires, no death benefit is paid out.</p>
                            <p>Keep in mind that this death benefit amount is something you select when applying for a life insurance policy, just like the life insurance term length. The higher the amount, the higher your premiums will be.</p>
                            <p>None</p>
                            <p> </p>
                            <h3>Whole life insurance</h3>
                            <p>Whole life insurance coverage is exactly how it sounds. It's a type of life insurance that lasts your whole life, and as a result, premiums are significantly higher than term policies.</p>
                            <p>This type of policy also differs from term life insurance in that it comes with a cash value component, which some view as a kind of investment. The longer you pay into your whole life insurance coverage policy, the more cash value you'll build up.</p>
                            <p>You can also borrow money from this type of life insurance policy, but it'll be borrowing against the death benefit.</p>
                            <p>In short, don't look at whole life insurance as a way to make an easy investment while getting life insurance. You're better off paying into traditional retirement accounts like a Roth IRA or 529 plan. And if you need some quick cash, go with a </p>
                            <p> </p>
                            <h2>#4 Factors that will affect your life insurance premiums</h2>
                            <p>If you're shopping around for life insurance terms or whole life insurance coverage, you'll have to fill out a preliminary application that will ask you several questions to generate a personalized quote.</p>
                            <p>This is because there are many factors that can affect your monthly premiums.</p>
                            <p>Here are some of those factors:</p>
                            <ul class="text1"><li>Age: Since younger people are generally healthier, they'll generally see much lower rates than older individuals.</li>
                            <li>Health history: Most insurers (but not all), will ask questions about your current and past health conditions, and many will require a medical exam to complete the application for life insurance terms or whole life insurance.</li>
                            <li>Hobbies: High-risk hobbies like mountain climbing or scuba diving, for example, could result in higher premiums.</li>
                            <li>Gender: Since women tend to live longer than men, they may get lower rates on their life insurance policy.</li>
                            <li>Policy type: The type of life insurance policy you choose will also play a big role in how much you pay on your premiums. As mentioned, whole life insurance is much higher than life insurance term policies.</li></ul>
                            <p> </p>
                            <h2>#5 Application process</h2>
                            <p>Most life insurance companies will allow you to fill out a preliminary application to get a quick quote.</p>
                            <p>Remember the factors we reviewed in the previous section? This is the kind of information insurers will want to know that will help them come up with a personalized rate. You can expect to encounter questions related to your health history, activities and hobbies, in addition to other personal information, like your age and gender.</p>
                            <p>You'll also need to select the life insurance terms, like the length of the policy and the death benefit amount.</p>
                            <p>Once you decide to move forward with a life insurance policy, you'll need to provide additional information, like who your beneficiaries will be, and you may also need to take a medical exam.</p>
                            <p> </p>
                            <h2>Conclusion</h2>
                            <p>Shopping around for the best life insurance terms or whole life insurance coverage is really not all that different from buying other types of insurance. You'll need to provide some details about yourself, choose terms that best suit your needs, and compare quotes from various insurers.</p>
                            <p>Choosing the right life insurance policy is important. Be sure to reach out to an insurance company's customer service if you need further help understanding their policies and which one works best for your family.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Jessica Cotzin</span><br />Last update: 27 October 2020</div>
                        </div>
                        <p>Jessica Cotzin is a writer and the TopFinanceDirectory authority on small businesses and personal loans. She has been writing about personal finance and the loans industry for a number of years, and holds a bachelor's degree in journalism from Florida Atlantic University.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    .buttom_container {
                        text-align: center;
                        margin-top: 88px;
                        .buttom {
                            padding: 13px 18px;
                            background: #2ac8a2;
                            border-radius: 50px;
                            font-size: 18px;
                            color: #ffffff;
                            line-height: 18px;
                            font-weight: 700;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
