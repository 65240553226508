<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Quicken Compare</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
                <div class="Bread_crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                        <el-breadcrumb-item>Quicken Compare</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Quicken Compare</h1>
                          <img src="../../../../public/imgs/reviews_list/rocket_mortgage_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Quicken Compare Overview</h2>
                          <p><a href="https://www.rocketmortgage.com/">Quicken Compare</a> is one of the most well-known online mortgage lenders in the United States. The lender offers a variety of purchase and refinance alternatives, including a flexible-term product dubbed "YOURgage," which allows borrowers to choose between eight and 29 years for the term of their loan, as well as cash-out refinancing. Quicken Loans, the parent company of Quicken Compare, was formed in 1985 by Dan Gilbert, who still serves as chairman. Quicken Loans rose to prominence in recent years as a result of its adoption of technology that allows customers to apply for loans entirely online through Quicken Compare, which was officially renamed Quicken Loans in 2021.</p>
                          <h2 class="anchor_h2">Quicken Compare loan types</h2>
                          <p>Quicken Compare has a lending portfolio that is similar to that of most nonbank lenders. Fixed and adjustable rates are available on conventional, FHA, and VA loans. In addition to the usual 15- or 30-year mortgage, a customised fixed-rate conventional loan with a period ranging from eight to 29 years is also available.</p>
                          <p>Quicken was the second-largest FHA lender in the US in 2020, with more than double the FHA loan volume of the next lender, despite FHA loans accounting for only 7% of its entire business.</p>
                          <p>There are no home equity loans or home equity lines of credit (HELOCs) available. Those looking to use their home equity with Rocket should consider a cash-out refinance.</p>
                          <h2 class="anchor_h2">Not sure if Quicken Compare is right for you? Consider these alternatives</h2>
                          <p>Review of LoanDepot: An online lender that will reimburse your appraisal fee if you refinance your home a second time.</p>
                          <p>Another high-volume lender, Freedom Mortgage, offers first-time homebuyer programs and other loans.</p>
                          <p>Review of Mr. Cooper: There is also an app where you may check the status of your loan application and make payments.</p>
                          <p>Review of United Wholesale Mortgage: This wholesale lender is a frontrunner in the market if you're working with a mortgage broker.</p>
                          <p>Review of the Costco Mortgage Program: Through relationships with several mortgage lenders, a marketplace that gives Costco members cheaper fees.</p>
                          <h2 class="anchor_h2">How to Apply with Quicken Compare?</h2>
                          <img src="../../../../public/imgs/reviews_list/rocket_mortgage_review_2.jpg" alt="">
                          <p><a href="https://www.rocketmortgage.com/">Quicken Compare</a> allows you to complete your mortgage application entirely online, using shortcuts to speed up the process. You may apply via the Quicken Compare app or on the company's website, which, given Rocket's online-first background, is mobile-friendly. The size of your screen is the most significant difference between applying on your phone versus your computer.</p>
                          <p>You can contact a Quicken Compare-affiliated mortgage broker if you'd prefer to work with an actual person face to face (or at least screen to screen).</p>
                          <p>According to Quicken Compare, a normal online application takes around half an hour, but you can work at your own pace. You'll begin by making an account and completing a few basic questions, such as the ZIP code where you want to buy a house. Many financial institutions in the United States can instantly transfer most of your financial information, such as bank and investment accounts, to Quicken Compare. Many working Americans' income and job information can also be imported by Rocket.</p>
                          <p>Then you'll go on to the "Look for solutions" stage. Quicken Compare collects your credit information and shows you your loan options as well as how much house you can afford. You can then modify your options by changing the period, the amount owed at closing, or your interest rate.</p>
                          <p><a href="https://www.rocketmortgage.com/">Quicken Compare</a> verifies your eligibility and submits your application to an automated underwriting system once you've decided on a loan. You can lock your loan rate and receive a preapproval letter once you've been approved. You can generate as many preapproval letters as you need, adjusting the amount to match your offers so sellers don't know how much you can spend.</p>
                          <p>If you choose Rocket for your home loan and advance to full approval, you'll complete your application over the phone with a loan officer.</p>
                          <p>This quick procedure eliminates the need to initially submit personal information in order to receive a callback from a loan officer. In fact, <a href="https://www.rocketmortgage.com/">Quicken Compare</a> (then known as Quicken Loans) was one of the first organizations to recognize the benefits of the internet for mortgage applications in 1998. Since 2002, the company has provided electronic mortgage document signing, a provision that some other lenders still do not provide.</p>
                          <h2 class="anchor_h2">What Are the Rocket Mortgage’s Terms, Fees and Conditions?</h2>
                          <img src="../../../../public/imgs/reviews_list/rocket_mortgage_review_3.jpg" alt="">
                          <p>The terms, fees, and conditions of the lender vary depending on the type of loan and other considerations. 15- and 30-year conventional loans, 30-year VA and FHA fixed-rate loans, and YOURgage loans with fixed-rate periods ranging from eight to 29 years are all available through Quicken Compare.</p>
                          <p>Note that quoted prices assume that discount points will be paid at closing, that you have a credit score of at least 720, and that your debt-to-income ratio is less than 30%.</p>
                          <p>You may lock in the rate once you've found the correct loan by creating an account and answering a few questions about your income and assets. According to <a href="https://www.rocketmortgage.com/">Quicken Compare</a>, most lock periods range from 15 to 60 days.</p>
                          <h2 class="anchor_h2">What to Do If You Get Turned Down?</h2>
                          <p>While the organization has a lengthy history of technology innovation, it mostly focuses on funding for small businesses. If your application is declined, you may need to take efforts to improve your credit score, reduce your debt, or boost your income in order to qualify with Quicken Compare. The more you achieve in these areas, the more likely you are to not only be authorized, but also to receive a higher rate. Remember to look into the Fresh Start Program as well.</p>
                          <p>If you're in a hurry, you might be able to be authorized with a different lender who has more lenient requirements. If you're ready to pay a higher interest rate, a lender that offers non-QM loans could be able to satisfy your needs.</p>
                          <p>Getting a co-signer or co-borrower may be a possibility, but asking someone to co-sign or co-borrow a mortgage with you is a big commitment, especially if they don't live in the house. Use our mortgage calculator to figure out how much you can afford on your own.</p>
                          <p>It's a good idea to apply with various mortgage providers to evaluate where you stand before deciding what to do next. Don't be concerned about your credit score being harmed: According to the Consumer Financial Protection Bureau, filing numerous applications within 45 days has the same impact on your credit score as submitting a single application (CFPB).</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Quicken Compare Overview</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Quicken Compare loan types</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Not sure if Quicken Compare is right for you? Consider these alternatives</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">How to Apply with Quicken Compare?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">What Are the Rocket Mortgage’s Terms, Fees and Conditions?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">What to Do If You Get Turned Down?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>