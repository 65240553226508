<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Cinch Home Services</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty' }">Home Warranty</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>HQ Home Warranty</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>HQ Home Warranty</h1>
                          <img src="../../../../public/imgs/reviews_list/cinch_home_warranty_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is HQ Home Warranty</h2>
                          <p>HQ Home Warranty tied for #1 on our 2023 Best Home Warranty Companies list. Founded in 2008, the New Jersey-based company offers home warranty plans in every U.S. state except Washington. The company offers two insurance options for homebuyers and sellers, with some pay-as-you-go add-ons. With a monthly fee, customers can avoid being completely locked in to repairing and replacing eligible home parts and take advantage of a network of related service providers.</p>
                          <h2 class="anchor_h2">HQ Home Warranty Plans</h2>
                          <p>HQ Home Warranty's two plans cover a wide variety of household items. Unlike some home warranty companies that organize their plans as built-in systems or just appliances, HQ uses a tiered portfolio structure with two levels of coverage. As a common home warranty company, headquarters pricing depends on location, home size, and building type. To compare the rates of the companies in our 2023 Best Home Warranty Companies rating, we used a sample of a 2,345-square-foot townhouse in Austin, Texas.</p>
                          <p>HQ's Basic plan, the company's least comprehensive home warranty option, starts at $46.67 per month ($560.00 per year). The basic plan includes only the home essentials: heating, electricity, and plumbing; built-in kitchen appliances (except the refrigerator); and ductwork, fans, and garage door switches. It also includes a whirlpool.</p>
                          <p>Total plans start at $55.00 per month ($660.00 per year). It covers everything included in the base plan, plus several other key coverage areas: washers, dryers, refrigerators, and air conditioning systems. For even greater coverage, homeowners with both base plans can buy any of the company's individual add-ons. These include pool equipment, well pumps, and septic systems, just to name a few.</p>
                          <p>These prices put HQ on the low end of cost among our top home warranty companies, with only Cinch Home Service and First American Home Warranty offering lower monthly prices.</p>
                          <p>One cost that is consistent regardless of location or coverage plan is an $85 service charge to headquarters (referred to by headquarters as a "trade service call charge"). This is paid directly to the technician and covers the cost of home visits in addition to the results. As with the HQ Home Warranty, the only change to this fee is if a specific discount or promotion is applicable.</p>
                          <h2 class="anchor_h2">HQ Home Warranty Plans reviews</h2>
                          <p>Pros:</p>
                          <p>simple program structure</p>
                          <p>Flat Rate Service Fee</p>
                          <p>Response time for service requests is 4 hours</p>
                          <p>Cons：</p>
                          <p>Unable to select service provider</p>
                          <p>Small list of optional add-in overrides</p>
                          <p>Not available in the Washington area</p>
                          <p>HQ Home Warranty offers customers two plans, Basic and Total, that provide coverage for a wide variety of appliances and home systems. Customers can't choose their own contractors, but the company has a network of more than 25,000 affiliated contractors and gets in touch with customers within four hours of requesting service. Like many of its competitors, the head office accepts claims 24 hours a day.</p>
                          <p>HQ has a longer warranty on repaired home parts than many other home warranty companies, including a 60-day workmanship warranty and a 90-day parts warranty.</p>
                          <p>Headquarters does not require home inspections or maintenance records before purchasing insurance. Finally, it charges $85 per service rather than variable rates like other companies in our rating.</p>
                          <h2 class="anchor_h2">The Bottom Line: </h2>
                          <p>HQ Home Warranty offers nationwide availability (except Washington) and only two plans for homeowners to choose from. Its Max insurance plan is comparable in price to other plans in our ratings and protects the home's major appliances and systems. However, additional functionality is limited.</p>
                          <h2 class="anchor_h2">HQ Home Warranty vs. American Home Shield</h2>
                          <p>National Shield's full coverage plan is the most expensive in our rating at $69.99. Its ShieldSilver and ShieldGold plans are more affordable, though, at $29.99 and $49.99, respectively. The company is comparable to HQ Home Warranty in many ways, including its tiered plan structure.</p>
                          <p>American Airlines' cheapest and most basic plan covers only plumbing, electrical, heating and cooling systems, while the mid-size plan covers general kitchen and laundry equipment. The company's most comprehensive plan adds more coverage, such as roof leaks, HVAC adjustments and permits.</p>
                          <p>American offers six major optional coverages, less than HQ and many of the other home warranty companies on our list. However, boutique hotels also offer optional insurance for electronics and guest equipment, which is not available in boutique hotels.</p>
                          <p>HQ has a more generous workmanship guarantee, and the warranty period of Home Shield in the United States is only 30 days. American Airlines' service fee system is more flexible than the $85 flat rate at headquarters. American Airlines lets customers choose between several price points ($75, $100 and $125), with higher fees resulting in lower monthly fees. Both US and HQ serve most of the US states (US Home Shield excludes Alaska) and both have similar response times.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is HQ Home Warranty</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">HQ Home Warranty Plans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">HQ Home Warranty Plans reviews</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">The Bottom Line: </div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">HQ Home Warranty vs. American Home Shield</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>