<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Compare your homeowners insurance options</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">We found some companies we think could save you money</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/auto_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Auto Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/health_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Health Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/homeowners_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Home Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/life_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Life Insurance</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.img_url" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.APR}}</span>
                                          <ul>
                                              <li>{{item.Features1}}</li>
                                              <li>{{item.Features2}}</li>
                                              <li>{{item.Features3}}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.offers_url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.offers_url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What is home insurance?</h2>
                          <p>Home insurance, also commonly called homeowner's insurance (often abbreviated in the US real estate industry as HOI), is a type of property insurance that covers a private residence. It is an insurance policy that combines various personal insurance protections, which can include losses occurring to one's home, its contents, loss of use (additional living expenses), or loss of other personal possessions of the homeowner, as well as liability insurance for accidents that may happen at the home or at the hands of the homeowner within the policy territory.</p>
                          <h2 class="anchor_h2">Types of home insurance policies</h2>
                          <p>According to a 2018 National Association of Insurance Commissioners (NAIC) report on data from 2016, 73.8% of homes were covered by owner-occupied homeo' policies. Of these, 79.52% had an HO-3 Special policy, and 13.35% had the more expensive HO-5 Comprehensive. Both of these policies are "all risks" or "open perils", meaning that they cover all perils except those specifically excluded. Homes covered by an HO-2 Broad policy accounted for 5.15%, which covers only specific named perils. The remaining 2% includes the HO-1 Basic and the HO-8 Modified policies, which are the most limited in the coverage offered. HO-8, also known as older home insurance, is likely to pay only actual cash value for damages rather than replacement.</p>
                          <p>The remaining 21.3% of home insurance policies were covered by renter's or condominium insurance. 14.8% of these had the HO-4 Contents Broad form, also known as renters' insurance, which covers the contents of an apartment not specifically covered in the blanket policy written for the complex. This policy can also cover liability arising from injury to guests as well as negligence of the renter within the coverage territory. Common coverage areas are events such as lightning, riot, aircraft, explosion, vandalism, smoke, theft, windstorm or hail, falling objects, volcanic eruption, snow, sleet, and weight of ice. The remainder had the HO-6 Unit-Owners policy, also known as a condominium insurance, which is designed for the owners of condos and includes coverage for the part of the building owned by the insured and for the property housed therein. Designed to span the gap between the coverage provided by the blanket policy written for the entire neighborhood or building and the personal property inside the home. The condominium association's by-laws may determine the total amount of insurance necessary. E.g., in Florida, the scope of coverage is prescribed by statute – 718.111(11)(f).[14]</p>
                          <h2>Collateral protection insurance</h2>
                          <p>If a home can't be insured, obtaining a mortgage on it is difficult or impossible. If the homeowner's insurance is canceled after a mortgage agreement is in force, and the home judged to be uninsurable, a standard mortgage contract that compels homeowner's insurance allows the lender to purchase collateral protection insurance, (sometimes called "force-placed insurance") and charge the premiums to the homeowner via escrow. CPI's pay off the balance owed on the mortgage if the homeowner defaults on mortgage payments, and some will cover damage to the home that impacts the resale value. This repair coverage can benefit the homeowner, but by design the contractual benefits flow to the mortgagee. If the structure is deemed uninsurable, the homeowner also benefits from not having the mortgage called in by the lender - without CPI the homeowner would be in serious breach of his contractual obligations.</p>
                          <h2 class="anchor_h2">Causes of loss</h2>
                          <p>According to the 2008 Insurance Information Institute factbook, for every $100 of premium, in 2005 on average $16 went to fire and lightning, $30 to wind and hail, $11 to water damage and freezing, $4 for other causes, and $2 for theft. An additional $3 went to liability and medical payments and $9 for claims settlement expenses, and the remaining $25 was allocated to insurer expenses. One study of fires found that most were caused by heating incidents, although smoking was a risk factor for fatal fires.</p>

                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">What is home insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">Types of home insurance policies</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Causes of loss</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "life_insurance",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: [{
                    id: 1,
                    name: 'American Home Shield',
                    img_url: '/imgs/offers_imgs/American.svg',
                    offers_url: 'https://www.ahs.com/',
                    APR: '$74.99/Month',
                    Features1: '23 appliances and systems',
                    Features2: ' if we can’t repair your covered item, we’ll replace it',
                }, {
                    id: 2,
                    name: 'Choice Home Warranty',
                    img_url: '/imgs/offers_imgs/chw.png',
                    offers_url: 'http://www.ChoiceHomeWarranty.com',
                    APR: '$55/Month',
                    Features1: 'Were Here for you 24/7',
                    Features2: '',
                    Features3: '',
                }, {
                    id: 3,
                    name: 'Liberty Home Guard',
                    img_url: '/imgs/offers_imgs/Liberty.png',
                    offers_url: 'https://www.libertyhomeguard.com/',
                    APR: '69.99/Month',
                    Features1: 'U.S. News Offer: $200 off + 2 Free Bonus Months + Free Roof Leak Coverage!',
                    Features2: '',
                    Features3: '',
                }, {
                    id: 4,
                    name: 'Cinch Home Services',
                    img_url: '/imgs/offers_imgs/Cinch.png',
                    offers_url: 'https://www.cinchhomeservices.com/',
                    APR: '$61.99/Month',
                    Features1: '40 years of proven experience',
                    Features2: 'We surveyed over 1000 homeowners',
                    Features3: '',
                }, {
                    id: 5,
                    name: "America's First Choice Home Club",
                    img_url: '/imgs/offers_imgs/AFC.png',
                    offers_url: 'https://afchomeclub.com/',
                    APR: '$67.5/Month',
                    Features1: 'Compare Prices from Top Gutter Cover Companies',
                    Features2: '',
                    Features3: '',
                }]
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgcrad.scss';
@import '../../assets/scss/textnavigation.scss';


.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>