<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Compare Student Loan Refinance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_subtitle">If you’re considering options to refinance your student loan, you’re in the right place.Compare our recommended student loan refinancing companies below to get started.</div>
              </div>
          </div>
          <div class="list_container">
              <div class="list_content">
                  <div class="list_content_search">
                      <div class="condition">
                          <div class="condition_title">
                              <p>Which mortgage lender is right for you?</p>
                          </div>
                          <div class="condition_list_container">
                              <div class="condition_list">
                                  <div class="condition_list_condition">
                                      <span>Loan Purpose <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <el-radio-group v-model="radio1" size="mini">
                                            <el-radio-button border label="Other"></el-radio-button>
                                            <el-radio-button border label="Debt Consolidation Loan"></el-radio-button>
                                            <el-radio-button border label="Home Improvement"></el-radio-button>
                                            <el-radio-button border label="Student Loans"></el-radio-button>
                                            <el-radio-button border label="Medical Expenses"></el-radio-button>
                                            <el-radio-button border label="Large Purchases"></el-radio-button>
                                            <el-radio-button border label="Special Occasion"></el-radio-button>
                                          </el-radio-group>
                                      </div>
                                  </div>
                                  <div class="condition_list_condition">
                                      <span>My Credit Score <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <el-radio-group v-model="radio2" size="mini">
                                            <el-radio-button border label="Other"></el-radio-button>
                                            <el-radio-button border label="Debt Consolidation Loan"></el-radio-button>
                                            <el-radio-button border label="Home Improvement"></el-radio-button>
                                            <el-radio-button border label="Student Loans"></el-radio-button>
                                            <el-radio-button border label="Medical Expenses"></el-radio-button>
                                            <el-radio-button border label="Large Purchases"></el-radio-button>
                                            <el-radio-button border label="Special Occasion"></el-radio-button>
                                          </el-radio-group>
                                      </div>
                                  </div>
                                  <div class="condition_list_condition">
                                      <span>Loan Amount <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <el-radio-group v-model="radio3" size="mini">
                                            <el-radio-button border label="Other"></el-radio-button>
                                            <el-radio-button border label="Debt Consolidation Loan"></el-radio-button>
                                            <el-radio-button border label="Home Improvement"></el-radio-button>
                                            <el-radio-button border label="Student Loans"></el-radio-button>
                                            <el-radio-button border label="Medical Expenses"></el-radio-button>
                                            <el-radio-button border label="Large Purchases"></el-radio-button>
                                            <el-radio-button border label="Special Occasion"></el-radio-button>
                                          </el-radio-group>
                                      </div>
                                  </div>
                                  <div class="condition_list_condition">
                                      <span>Closing soon? <img src="/imgs/search bar_pickers_icon2.png" alt=""></span>
                                      <div class="search_option">
                                          <el-radio-group v-model="radio4" size="mini">
                                            <el-radio-button border label="Other"></el-radio-button>
                                            <el-radio-button border label="Debt Consolidation Loan"></el-radio-button>
                                            <el-radio-button border label="Home Improvement"></el-radio-button>
                                            <el-radio-button border label="Student Loans"></el-radio-button>
                                            <el-radio-button border label="Medical Expenses"></el-radio-button>
                                            <el-radio-button border label="Large Purchases"></el-radio-button>
                                            <el-radio-button border label="Special Occasion"></el-radio-button>
                                          </el-radio-group>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.img_url" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.APR}}</span>
                                          <ul>
                                              <li>{{item.Features1}}</li>
                                              <li>{{item.Features2}}</li>
                                              <li>{{item.Features3}}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.offers_url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.offers_url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="article_list_user_right">
                      <div class="article_list_right_top">
                          <span>1,523 uesrs</span>
                          <img src="/imgs/content3_icon.png" alt="">
                      </div>
                      <div class="article_list_right_bottom">
                          <span>Picked a lender via<br />BestMoney this week</span>
                      </div>
                  </div>
              </div>
          </div>
          <div class="question_container">
              <div class="question_list">
                  <div class="question_list_left">
                      <h2 class="anchor_h2">Getting started: Student loan refinancing</h2>
                      <p>When you refinance student loans, you get a new loan to pay off the balance on one or multiple loans. Most people are looking to reduce their monthly payments or pay less interest when they research student loan refinance rates.</p>
                      <p>It’s possible to refinance both federal and private student loans, so you’ll make only one payment each month. Lowering your monthly payment by refinancing student loans is a smart way to free up money in your budget. Student loan consolidation, which combines multiple loans, offers the opportunity to pay extra toward the principal balance of the debt, further reducing interest costs.</p>
                      <h2>What happens when you refinance your student loans?</h2>
                      <p>When you decide to refinance your student loans, you’ll apply to private lenders. They’ll check into your credit history and collect information about your job and income. If they approve your application and the terms are agreeable to you, the private lender will pay off your student loan balances, and you’ll begin making monthly payments to your new lender.</p>
                      <h2>When is it a good idea to refinance student loans?</h2>
                      <p>When you research how to refinance student loans, pay attention to lender qualifications. Refinancing may not be an option if you can’t meet the lender’s standards for credit and income.</p>
                      <p>If your student loan payments are negatively affecting your quality of life, but you can’t afford to pay more toward the loan’s principal, refinancing could offer some financial relief. You’ll have to qualify, which usually requires a steady income, good credit, and good payment history. Some lenders have more relaxed standards, so don’t give up if you are credit challenged or aren’t employed.</p>
                      <p>Refinancing higher-interest student loans could translate to significant savings over the life of your loans. Choosing the shortest term you can afford will reduce the total amount of interest you pay, but it may make your monthly payments larger. A longer term will lower your payments, but you’ll also pay more interest over time.</p>
                      <p>If you’ve built up good credit, are finished with school, and have a stable income, you are likely to qualify for the best student loan refinance rates.</p>
                      <h2 class="anchor_h2">Student loan refinancing vs. loan consolidation</h2>
                      <p>If you want to consolidate your federal student loans, so you have just one payment and one interest rate, you’ll go through the process of getting a Direct Consolidation Loan, which is different than student loan refinancing.</p>
                      <p>You won’t save money on interest if you consolidate student loans. If you want to combine several private loans so you make only one payment, you’ll need to proceed with student loan consolidation.</p>
                      <p>Here’s the difference between refinancing student loans and consolidating student loans:</p>
                      <h2>Student loan refinance:</h2>
                      <ul class="text1">
                          <li>Can add federal student loans and private student loans to a private student loan refinance</li>
                          <li>Can refinance a single student loan to get a lower interest rate and more favorable terms</li>
                          <li>Student loans refinance interest rate based on borrower qualifications</li>
                          <li>Must qualify with satisfactory credit and income</li>
                          <li>The lender sets qualification criteria</li>
                          <li>Refinancing federal student loans with a private lender means you’ll lose eligibility for Public Service Loan Forgiveness, Teacher Loan Forgiveness, Income-Driven Repayment (IDP), forbearance, and deferment</li>
                      </ul>
                      <h2>Federal student loan consolidation:</h2>
                      <ul class="text1">
                          <li>Federal student loans only; cannot add private student loans</li>
                          <li>Must have at least two eligible federal student loans to be eligible for federal student loan consolidation</li>
                          <li>Student loan consolidation rates are a weighted average of the rates across all your loans, rounded up 1/8% of 1%</li>
                          <li>No need to qualify with a credit check</li>
                          <li>Qualification criteria set by the federal government and is the same for every student loan borrower</li>
                          <li>Maintain eligibility for Public Service Loan Forgiveness, Teacher Loan Forgiveness, Income-Driven Repayment (IDP), forbearance, or deferment offered with federal student loans</li>
                          <li>Visit StudentLoans.gov for more information about how to consolidate federal student loans</li>
                      </ul>
                      <h2>Private student loan consolidation:</h2>
                      <ul class="text1">
                          <li>Can add federal student loans and private student loans to a private student loan consolidation</li>
                          <li>The process combines multiple loans into one loan with one monthly payment</li>
                          <li>Interest rates based on borrower creditworthiness and ability to repay the loan</li>
                          <li>Must qualify; can be turned down for a private loan</li>
                          <li>Qualification criteria set by individual lenders</li>
                          <li>Consolidating federal student loans with a private lender means you’ll lose eligibility for Public Service Loan Forgiveness, Teacher Loan Forgiveness, Income-Driven Repayment (IDP), forbearance, and deferment</li>
                      </ul>
                      <h2>Recommended student loan refinance lenders</h2>
                      <ul class="text1">
                          <li>Splash Financial</li>
                          <li>Credible</li>
                          <li>PenFed</li>
                      </ul>
                      <h2 class="anchor_h2">How do I get started?</h2>
                      <p>First, carefully research lenders by reading online reviews from verified borrowers and those who have completed the student loan refinance process.</p>
                      <p>Applying online is a quick and easy process. You’ll need income verification like tax returns and/or pay stubs, identity verification like a driver’s license or passport, and the contact information for your current student loan lenders.</p>
                      <p>While you are waiting for approval and finalizing your new loan, be sure to continue making all your student loan payments on time. Your new lender will let you know when the balances are paid in full and when your new payments start.</p>
                      <p>Tip: Check your credit files for mistakes before applying for student loan refinancing. Correcting mistakes could help boost your credit scores, which can help you get the best student loan refinance rates.</p>
                      <h2>What credit score do I need to refinance my student loans?</h2>
                      <p>While there are private student loan refinance options for nearly any credit score, the best student loan interest rates go to applicants with credit scores above 700. You can get approved with a credit score in the 600s, but you may not get the best interest rate.</p>
                      <p>Every lender has its own qualification requirements for student loan refinancing. It’s essential to shop around and compare various lenders before moving forward with your plan to refinance your student loans.</p>
                      <p>If you have bad credit, which means your credit scores are in the 500 – 600 range, you’ll have a tough time getting a better interest rate when you try to qualify with private lenders. Consolidating student loans could be a better option.</p>
                      <p>It may help to get someone with good credit to cosign the loan, but that presents significant financial risks for your cosigner. They’ll be equally legally responsible for the debt, and if you make late payments, it will hurt their credit score. If you stop making payments, the lender will come after your cosigner to recover the balance of the loan. Borrowers who need a cosigner should look for lenders who offer a cosigner release. When your credit improves, and you’ve made a series of on-time payments, the lender may allow the cosigner to drop off the loan, leaving you solely responsible for the debt.</p>
                      <h2>When to reconsider student loan financing?</h2>
                      <p>There’s more to refinancing student loans than merely getting a lower monthly payment. If you take out a private loan, you’ll no longer have the advantages of federal student loans. Here’s what you’ll give up when you go through a student loan refinance and pay off your federal student loans with a private loan:</p>
                      <p><span class="text_bold">Forbearance and deferment:</span>If you experience financial hardship, you can put off making monthly federal student loan payments through forbearance or deferment. Depending on the lender, your private student loan may not offer these two benefits.</p>
                      <p><span class="text_bold">Income-driven repayment (IDR):</span>When you refinance student loans, you won’t be able to enroll in IDR plans that allow you to make a monthly payment that’s equivalent to a portion of your discretionary income.</p>
                      <p><span class="text_bold">Loan forgiveness:</span>The Teacher Loan Forgiveness and Public Service Loan Forgiveness programs allow you to have your student loan debt forgiven after you make a set number of on-time payments. With a private loan, you won’t have this option.</p>
                      <p>If you complete the student loan refinance process and pay off your outstanding student loans with a single private loan, you won’t be able to reverse the process. If you can’t qualify for a significantly lower interest rate, consider working on improving your credit and income before you apply for a private student loan refinance again.</p>
                      <p>While you may gain important financial benefits when you refinance student loans, it’s crucial to be sure the pros outweigh the cons before proceeding with student loan refinancing.</p>
                      <h2 class="anchor_h2">Final thoughts</h2>
                      <p>While student loan refinancing isn’t a viable option for everyone, consolidation may provide the advantages you need. Asking a cosigner for help or improving your credit are also great ways to make refinancing student loans work. If you decide to move forward, researching student loan refinance companies will help you get the lowest possible interest rates and best loan terms.</p>
                  </div>
                  <div class="question_list_right">
                      <div class="accordion">
                          <ul>
                              <li>
                                  <div @click="scrollTo(0)" :class="{active: active===0}">Getting started: Student loan refinancing</div>
                              </li>
                              <li>
                                  <div @click="scrollTo(1)" :class="{active: active===1}">Student loan refinancing vs. loan consolidation</div>
                              </li>
                              <li>
                                  <div @click="scrollTo(2)" :class="{active: active===2}">How do I get started?</div>
                              </li>
                              <li>
                                  <div @click="scrollTo(3)" :class="{active: active===3}">Final thoughts</div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "student_loans",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: [{
                    id: 1,
                    name: 'Splash Financial',
                    img_url: '/imgs/offers_imgs/splashfinancial_l.svg',
                    offers_url: 'https://www.splashfinancial.com/partner/lendstart/?',
                    APR: '1.74% – 7.49%',
                    Features1: '4.8/5 Rating on Trustpilot',
                    Features2: 'Easy application process',
                    Features3: 'Quick & Easy to Refinance'
                }, {
                    id: 2,
                    name: 'PenFed',
                    img_url: '/imgs/offers_imgs/PenFed_L.svg',
                    offers_url: 'https://www.credible.com/a/dd-penfed/?',
                    APR: '2.17%-5.15%',
                    Features1: 'Competitive interest rates',
                    Features2: 'Long-standing lender',
                    Features3: '85+ Years of Experience',
                }, {
                    id: 3,
                    name: 'Credible',
                    img_url: '/imgs/offers_imgs/credible_l.svg',
                    offers_url: 'https://www.credible.com/refinance-student-loans',
                    APR: '1.83%-8.73%',
                    Features1: 'Jargon-free explanations',
                    Features2: 'Apply online with 1 simple form',
                    Features3: 'Honest Source to Compare Rates',
                }, {
                    id: 4,
                    name: 'Earnest',
                    img_url: '/imgs/offers_imgs/earnest_l.svg',
                    offers_url: 'https://www.earnest.com/refinance-student-loans?',
                    APR: '1.99%-5.89%',
                    Features1: 'Check eligibility in 2 min',
                    Features2: 'No origination/prepayment fees',
                    Features3: '5-Star Rating on Trustpilot',
                }, {
                    id: 5,
                    name: 'Citizens Bank',
                    img_url: '/imgs/offers_imgs/209548.svg',
                    offers_url: 'https://www.credible.com/a/citizens-slr/?',
                    APR: '2.24% – 9.15%',
                    Features1: 'Facilitated over $183 billion in assets.',
                    Features2: 'Offers a variety of financial',
                    Features3: 'Personalize Your Banking Experience',
                }, {
                    id: 6,
                    name: 'MEFA',
                    img_url: '/imgs/offers_imgs/mefa_l.svg',
                    offers_url: 'https://www.credible.com/a/dd-mefa-slr/?',
                    APR: '3.05% – 5.55%',
                    Features1: 'Multiple repayment options',
                    Features2: 'No maximum amount',
                    Features3: 'Student Loans Built for Success',
                }, {
                    id: 7,
                    name: 'CommonBond',
                    img_url: '/imgs/offers_imgs/commonbond.svg',
                    offers_url: 'https://www.credible.com/a/slr-commonbond?',
                    APR: '2.56%-6.87%',
                    Features1: 'Competitive fee structure',
                    Features2: 'Good forbearance period',
                    Features3: 'Your Friend in Finance',
                }, {
                    id: 8,
                    name: 'SoFi',
                    img_url: '/imgs/offers_imgs/sofi_l.svg',
                    offers_url: 'https://partners.sofi.com/slr-affiliate-rmg-sz/?',
                    APR: '2.25%-6.74%',
                    Features1: '$30 billion in loans facilitated',
                    Features2: '900,000 member community',
                    Features3: 'Loan Amount: $5K+',
            }],
            radio1: 'Other',
            radio2: 'Other',
            radio3: 'Other',
            radio4: 'Other',
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.question_list_left .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.question_list_left .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .list_container {
        width: 100%;
        .list_content {
            width: 61%;
            margin: 60px auto 50px;
            .list_content_search {
                margin-top: 20px;
                .condition {
                    margin-top: 20px;
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10);
                    padding-bottom: 35px;
                    border-radius: 12px;
                    .condition_title {
                        background: #f5f8fb;
                        p {
                            padding: 11px 0 11px 60px;
                            font-weight: 700;
                            font-size: 20px;
                            color: #222831;
                        }
                    }
                    .condition_list_container {
                        .condition_list {
                            padding: 0px 30px;
                            margin-top: 20px;
                            .condition_list_condition {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: flex-start;
                                margin-bottom: 30px;
                                span {
                                    font-weight: 600;
                                    color: #0062bd;
                                    line-height: 14px;
                                    font-size: 14px;
                                    margin-right: 15px;
                                    img {
                                        margin-left: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .question_container {
        width: 100%;
        background-color: #f5f8fb;
        .question_list {
            width: 61%;
            margin: 0 auto 150px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .question_list_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                margin-top: 80px;
                h2 {
                    font-size: 36px;
                    font-weight: 700;
                    color: #222831;
                    margin-bottom: 35px;
                    line-height: 36px;
                }
                p {
                    margin-bottom: 25px;
                }
            }
            .question_list_right {
                width: 325px;
                .accordion {
                    margin-top: 80px;
                    padding: 20px 45px;
                    background-color: #fff;
                    border-radius: 12px;
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    font-size: 18px;
                    ul {
                        li {
                            div:hover {
                                color: #2ac8a2;
                                cursor: default;
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5 ;
                            margin-bottom: 17px;
                            padding-bottom: 17px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .list_container {
            .list_content {
                width: 90%;
                .list_content_search {
                    .condition {
                        .condition_list_container {
                            .condition_list {
                                .condition_list_condition {
                                    flex-wrap: wrap;
                                    span {
                                        font-size: 20px;
                                        margin-bottom: 15px;
                                    }
                                    .search_option {
                                        flex-wrap: wrap;
                                        justify-content: center;
                                        div {
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .question_container {
            .question_list {
                width: 80%;
                flex-wrap: wrap;
                .question_list_left {
                    line-height: 30px;
                }
                .question_list_right {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .nav_img {
            .nav_img_content {
                .head_subtitle {
                    font-size: 19px;
                }
            }
        }
        .list_container {
            .list_content {
                .list_content_search {
                    .condition {
                        .condition_title {
                            p {
                                padding: 11px 0 11px 15px;
                            }
                        }
                    }
                }
            }
        }
        .question_container {
            .question_list {
                margin: 0 auto 200px;
                .question_list_left {
                    width: 100%;
                    h2 {
                        font-size: 29px;
                    }
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 17px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
    .text_bold {
        font-weight: bold;
    }
</style>
<style scoped>
    .search_option >>> .el-radio-button__inner {
        margin-right: 15px;
        margin-bottom: 15px;
        border-left: 1px solid #DCDFE6;
    }
</style>