<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Tax Relief Guide</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_subtitle">Have you ever wondered how you get ready for the tax deadline? Or are you curious about what you’re paying for when you hire a tax relief company? You can find out the answers to these questions and more with our tax relief explained articles. If a person owes a hefty sum in back taxes, there are programs that provide tax relief including payment plans and even debt relief. Because overdue taxes can accumulate interest, fees, and even tax liens, it's recommended for taxpayers to address their owed taxes as soon as possible before they find themselves even more impacted. Read on to learn how tax relief loans are approved.</div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2 class="anchor_h2">Getting Tax Relief When It Matters</h2>
                          <p>As the quote goes, “In this world, there is nothing certain but death and taxes.” Taxes, for better or worse, are a part of life; however, the process for filing and understanding taxes can be confusing for many individuals. Some even go as far as to hire a tax expert to walk them through the process in order to avoid making mistakes.  While in some instances a person may receive a tax return, in many cases, people owe. As a result, the accumulation of unpaid taxes can occur. This is referred to as “back taxes.” Unfortunately, in the event that they don’t pay on time, the amount is considered delinquent and they may be faced with fees, interest rates, and tax liens.   If an individual is unable to pay their taxes, it is recommended they seek out alternative means of support. This can include payment plans and, in some cases, debt relief or forgiveness.</p>
                          <h2 class="anchor_h2">Understanding Tax Relief</h2>
                          <p>Tax relief can be a way for taxpayers, whether they be an individual or a business, to reduce the amount of taxes they owe to the federal government when they file. Tax relief basics can include tax benefits, credits, and deductions. For those who have already filed their taxes and can’t afford to pay off their income tax right away, the IRS also offers payment plans, offers in compromise, or “currently not collectible” statuses.</p>
                          <h2 class="anchor_h2">IRS Payment Plans</h2>
                          <p>If someone files their taxes and can’t afford the amount upfront, there are ways they can still pay their taxes without being considered delinquent. The IRS offers payment plans to those who qualify under the criteria. Keep in mind, interest rates, penalties, and fees may apply with a payment plan.  There are two types of payment plans. <span class="text_bold">Short-Term Payment Plan:</span> A short-term payment plan is for taxpayers who are able to pay off their owed income tax in 180 days or less. To qualify, the taxpayer cannot have an income tax debt of over $100,000. There are no set-up fees and individuals can pay by check, money order, debit or credit card, or connect their checking or savings account to their payment plan account.  <span class="text_bold">Long-Term Payment Plan:</span> With a long-term payment plan, set-up fees can range from $31 to $225 depending on how taxpayers sign up and what method of payment they choose. Long-term payment plans can stretch out anywhere from three years to 72 months depending on how much is owed.  Low-income applicants, or taxpayers that are “at or below 250% of the applicable federal poverty level,” can have their set-up fees reimbursed if they meet the IRS’s criteria. Here’s how to qualify for tax relief via a payment plan:</p>
                          <div style="overflow:auto;width:100%">
                              <table border="1" rules="all" width="90%">
                                <tr>
                                    <th>Payment Plan Type</th>
                                    <th>Minimum Debt to Qualify</th>
                                    <th>Maximum Debt to Qualify</th>
                                    <th>Setup Fees</th>
                                    <th>Accepted Payment Methods</th>
                                </tr>
                                <tr>
                                    <th>Short Term Payment Plan</th>
                                    <td>None</td>
                                    <td>$100,000</td>
                                    <td>None</td>
                                    <td>Check, money order, debit/credit card, or checking or savings account.</td>
                                </tr>
                                <tr>
                                    <th>Long Term Payment Plan</th>
                                    <td>None</td>
                                    <td>$50,000</td>
                                    <td>$31 – $225</td>
                                    <td>Check, money order, debit/credit card, or checking or savings account.</td>
                                </tr>
                            </table>
                          </div>
                          <h2 class="anchor_h2">Offers in Compromise</h2>
                          <p>In some cases, the IRS allows taxpayers to settle their tax income debt for less than what is owed. Taxpayers can apply if they’re unable to afford their income tax or if paying it would create financial hardship for them. To determine whether a taxpayer is eligible, the IRS will look at these factors:</p>
                          <ul class="text1">
                              <li>Ability to pay</li>
                              <li>Income</li>
                              <li>Expenses</li>
                              <li>Asset equity</li>
                          </ul>
                          <p>To qualify, taxpayers must be current on their tax filings and required estimated tax payments, among other criteria. Qualifying for an offer in compromise can be challenging and requires a hefty fee, so it’s best to explore other options first.</p>
                          <h2 class="anchor_h2">How to Apply for an Offer in Compromise</h2>
                          <p>When applying for an offer in compromise, applicants must pay a $205 non-refundable fee, though low-income applicants can have this waived. While they’re not required to, applicants can hire a tax expert to help them through the process as it can be complex.  Offer in compromise applicants must be up to date on all their tax returns and cannot be in bankruptcy proceedings. Taxpayers may also need to be prepared to make an initial payment which can be either 20% of the amount they’re willing to pay or their first monthly installment.   Before filling out an application, taxpayers may want to keep a few things in mind. During the proceedings, the IRS can still put down a tax lien until they either approve the application or the taxpayer has satisfied their part of the agreement. Secondly, offers in compromise can become public records that include the person’s name, location, the amount owed, and the terms of the deal.</p>
                          <h2 class="anchor_h2">‘Currently Not Collectible’ Status</h2>
                          <p>If a person is not able to balance paying off their income tax debt while also being able to cover their day-to-day needs, they may be able to apply for a “currently not collectible” status. This form of tax relief is temporary and while a taxpayer has this status, the IRS will periodically check the individual’s income to see if they’re eligible to pay. When applying for a “currently not collectible” status, applicants will need to provide information on their income and the expenses they have each month. This will demonstrate to the IRS that the individual is experiencing financial hardship and they are unable to pay for the time being.   If a taxpayer is approved for a “currently not collectible” status, the IRS will not try to collect from that individual. During the time that the debt is considered “currently not collectible,” though, it can still build up interest and penalties. Any tax returns the individual receives during this period will be put toward the outstanding tax debt. Keep in mind, this does not make tax income debt disappear. It’s more like hitting the pause button on them until the government determines that the individual is able to pay once more.</p>
                          <h2 class="anchor_h2">Hiring a Tax Relief Company</h2>
                          <p>If an individual is overwhelmed by their income tax debt, they can work with a tax relief company. A tax relief company is an organization that works as a mediator between the taxpayer and the IRS. Some tax relief companies promise to significantly lower people’s tax bills by helping them find tax relief programs. However, before agreeing to anything, taxpayers may want to consider: Are tax relief companies worth it? By hiring a tax relief company, taxpayers are utilizing the help of tax professionals—attorneys and other licensed experts—to help them navigate the often confusing landscape of tax relief. These professionals help taxpayers find out whether they are eligible for certain tax relief programs and assist them in applying for them. While this may sound like great news all around, tax relief companies can be expensive. In many cases, tax relief company fees can cost thousands of dollars—generally between $3,000 to $4,500—so people will want to compare pricing from trustworthy companies and determine if a tax relief company is a right choice for them, especially if they’re already experiencing financial hardship.  Unfortunately, in this market, there are plenty of scammers and companies looking to take advantage of taxpayers in tough financial positions, which brings us to our next point. </p>
                          <h2 class="anchor_h2">Be Weary of ‘Too Good to be True’ Promises</h2>
                          <p>While tax relief companies often promise they can lower tax debt, the Federal Trade Commission advises that taxpayers be wary of businesses overpromising, stating, “The truth is that most taxpayers don’t qualify for the programs these fraudsters hawk, their companies don’t settle the tax debt, and in many cases don’t even send the necessary paperwork to the IRS requesting participation in the programs that were mentioned.” In some cases, these companies don’t offer refunds to customers, which can leave them in a deeper financial hole than before.  In short, taxpayers may want to do a fair share of research and read over any tax relief company reviews.</p>
                          <h2 class="anchor_h2">What Can Be Done Today?</h2>
                          <p>Understanding the IRS landscape can be intimidating, especially if a person owes, but it’s highly recommended to stay on top of any debt that is unsettled as the ramifications of not paying taxes can be steep. Taxpayers can begin to take control of their tax situation and prepare for what’s ahead by collecting necessary documents and filing for tax relief as soon as possible. In some cases, that may mean seeking a tax professional.  To start, taxpayers can determine the existing debt, collect tax records and any other necessary documentation, and sign up for a payment plan with the IRS to avoid being considered delinquent.</p>
                          <h2 class="anchor_h2">Choose Wisely</h2>
                          <p>Figuring out how to file taxes can be both confusing and frustrating for some taxpayers, particularly if they end up owing. If a taxpayer ends up having to pay and can’t afford the amount upfront, there are several programs they can take advantage of, including payment plans, offers in compromise, and “currently not collectible” statuses.  For those that are looking for professional help on discovering things like tax benefits and credit, tax relief companies are available. However, taxpayers will want to thoroughly research the best tax relief companies as some are considered to be scammers and come with steep fees.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">Getting Tax Relief When It Matters</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">IRS Payment Plans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Offers in Compromise</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===3}">How to Apply for an Offer in Compromise</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===4}">‘Currently Not Collectible’ Status</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)" :class="{active: active===5}">Hiring a Tax Relief Company</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)" :class="{active: active===6}">Be Weary of ‘Too Good to be True’ Promises</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)" :class="{active: active===7}">What Can Be Done Today?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(8)" :class="{active: active===8}">Choose Wisely</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "tax_relief_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';
@import '../../assets/scss/textnavigation.scss';


.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
        .nav_img {
            height: 971px;
            background-size: cover;
        }
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>