<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                    <div class="head_button">
                        <span>Visit Site</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>4 Lucrative Ways to Use Your Personal Loan</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Bradley Schnitzer</div>
                                <div>Last update: 25 October 2020</div>
                            </div>
                        </div>
                        <div class="text">
                            <p>LendingTree lets borrowers take advantage of the competition between personal loan lenders to access better terms, rates, and loan amounts, even if you have poor credit. It gives customers the ability to compare offers simultaneously, empowering them to find a solution based on their unique circumstances. </p>
                            <p>Borrowers have the freedom to use a personal loan in a number of ways—from making home improvements or repairs to paying off medical expenses or consolidating debt. To begin comparing rates from lenders and seeing what you can get approved for, 5K Funds has a quick, simple, and secure online form.</p>
                            <p> APR can range anywhere from 5.99% to 35.99%and will vary depending on a variety of factors, such as a borrower’s credit score, credit history, and overall financial profile. Since 5K Funds doesn’t issue loans directly, fees and rates will vary according to the specific lender, so be sure to read through the terms upon approval.</p>
                            <img src="/imgs/content2_img.png" alt="">
                            <div class="text_list">
                                <div class="text_list_left">
                                    <div>PROS</div>
                                    <ul class="text1">
                                        <li>Large network of reliable lenders</li>
                                        <li>No fees for connecting with loan providers</li>
                                    </ul>
                                </div>
                                <div class="text_list_right">
                                    <div>PROS</div>
                                    <ul class="text2">
                                        <li>Large network of reliable lenders</li>
                                        <li>No fees for connecting with loan providers</li>
                                    </ul>
                                </div>
                            </div>
                            <h2>Loan Features</h2>
                            <p>The most obvious feature that stands out is the swift process for finding the right loan. Normally, researching loans and finding the right provider could take weeks or even months. LendingTree has effectively streamlined the process, and takes just a few minutes to show you potential lenders that match your needs and creditworthiness by using a single pre-qualification application.</p>
                            <p>LendingTree offers personal loans for a variety of reasons, including medical expenses, major spending, and debt consolidation. It can generally offer good interest rates thanks to its competitive marketplace, where lenders will compete for a borrower’s business, and not the other way around. Moreover, if you need to see the real impact of a loan, or your expected repayments, you can use one of LendingTree’s great calculators to understand a potential loan’s financial impact. </p>
                            <h2>LendingTree’s highlights:</h2>
                            <ul class="text1">
                                <li>A collection of articles and guides</li>
                                <li>A competitive online loan marketplace</li>
                                <li>A single application to prequalify for most lenders</li>
                                <li>Use your loan for any purpose</li>
                                <li>Access a variety of different loan products</li>
                                <li>Find unsecured personal loans for up to $50,000</li>
                                <li>See the implications of your loan with repayment calculators</li>
                                <li>Responsive customer support</li>
                            </ul>
                            <h2>The Application Process</h2>
                            <p>To get started, fill out a single application that pre-qualifies you for a range of different loans. This form does not ask for any sensitive personal details, instead focusing on your contact information and some financial specifics like your income level, housing status, and similar data.</p>
                            <p>LendingTree’s initial application does not include a hard credit pull, so your scores will remain unaffected. Once you have been approved, which takes a few minutes in most cases, you will be shown at least 5 options that match your creditworthiness and loan amount requirements. After choosing an option, you will finish applying directly with the lender selected.</p>
                            <div class="buttom_container">
                                <span class="buttom">Find Your Best Loan</span>
                            </div>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Bradley Schnitzer</span><br />Last update: 25 October 2020</div>
                        </div>
                        <p>Bradley Schnitzer is a copywriter and content writer that helps businesses gain loyal customers through informative content pieces and persuasive copy. He is passionate about personal finance and helping budding freelancers further their careers. He spends his free time reading, hitting the gym, pursuing several intellectual interests, and drinking coffee.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    .buttom_container {
                        text-align: center;
                        margin-top: 88px;
                        .buttom {
                            padding: 13px 18px;
                            background: #2ac8a2;
                            border-radius: 50px;
                            font-size: 18px;
                            color: #ffffff;
                            line-height: 18px;
                            font-weight: 700;
                            margin: 0 auto;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
