<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">SmartFinancial Auto Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance' }">Auto Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>SmartFinancial Auto Insurance</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>SmartFinancial Auto Insurance</h1>
                          <img src="../../../../public/imgs/reviews_list/smart_financial_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Who is SmartFinancial?</h2>
                          <p><a href="https://smartfinancial.com/">SmartFinancial</a> (also known as Contactability.com) is an online insurance quote comparison tool, founded by Lev Barinskiy in Costa Mesa, California, providing real-time insurance rates and insurance services in all 50 states through partnerships with various insurance carriers and agencies. The company's search engine allows you to look for almost any type of coverage, such as home insurance, auto insurance, life insurance, health insurance, Medicare Supplement Insurance, rental insurance, and etc. </p>
                          <p>The site then searches over 200 insurance companies, such as Progressive, 21st Century Insurance, Nationalwide and Liberty Mutual, to find real-time options that match your ideal coverage. The website also looks for all available discounts. This entire procedure can be completed in as little as three or four minutes.</p>
                          <p>Smart Financial's goal is to assist you in locating the best insurance provider for your needs. You'll begin the policy application process by answering some basic questions about the type of insurance you want.</p>
                          <h2 class="anchor_h2">How Does SmartFinancial Work?</h2>
                          <p>If you go to SmartFinancia auto insurance website, like similar quote-comparison tools, they ask you to start your search for a cheaper auto policy by entering your ZIP code. You then create a driver profile by answering a series of questions about your current insurance company, whether you own your home, and whether you've served in the military.</p>
                          <img src="../../../../public/imgs/blog_imgs/smart_financial_auto_insurance_review_1.png" alt="">
                          <h2 class="anchor_h2">Information Including:</h2>
                          <ul class="text1">
                              <li>Zip Code</li>
                              <li>Vehicle Year</li>
                              <li>Vehicle Model</li>
                              <li>Car Conditions</li>
                              <li>Home Owner Or Not</li>
                              <li>Current Insurance Carrier</li>
                              <li>Insurance Coverage Length </li>
                              <li>DUIs</li>
                              <li>Past Claims, Tickets or Accidents</li>
                              <li>Personal Info</li>
                              <li>Marital Status</li>
                          </ul>
                          <img src="../../../../public/imgs/blog_imgs/smart_financial_auto_insurance_review_2.png" alt="">
                          <p>After filing out this quick form, <a href="https://smartfinancial.com/">SmartFinancial</a> will provide you with several recommendations based on the information submitted including your area, personal status and etc to find the best local auto insurance carrier for you.</p>
                          <h2 class="anchor_h2">Is SmartFinancial Compatible with Mobile Devices? Is there a Mobile App?</h2>
                          <p>To compete in today's market, businesses should be mobile-friendly in order to . The website of SmartFinancial is mobile friendly. They make it simple to use your mobile device. You can quickly obtain a quote, call the company, and even send an email if necessary.</p>
                          <p>There is no mobile app for <a href="https://smartfinancial.com/">SmartFinancial</a>. If you are looking for a quick way to access services like you can with many other companies, SmartFinancial is not for you. While their official website is mobile-friendly, they have not gone so far as to develop an app for smartphones or tablets to facilitate auto quoting.</p>
                          <h2 class="anchor_h2">Does SmartFiancial Have a Good Reputation on the Market?</h2>
                          <img src="../../../../public/imgs/reviews_list/smart_financial_review_2.jpg" alt="">
                          <p>Smart Financial is an excellent place to start when comparing car insurance quotes. While other comparison sites offer actual quotes, Smart Financial does provide a link to a comprehensive list of insurers. The site will even look for current specials and deals. As a result, you may discover that you have access to offers that are not available elsewhere.</p>
                          <p>The best part is that the entire procedure only takes a few minutes. As a result, you won't have to waste much time.</p>
                          <p>Furthermore, the Smart Financial website is jam-packed with resources to assist you in making informed insurance decisions.</p>
                          <h2 class="anchor_h2">What Customers Think of SmartFinancial?</h2>
                          <p>Most reviews of <a href="https://smartfinancial.com/">SmartFinancial</a> online are positive, and it is 4.7 out 5 on Facebook ratings. There are some complaints about spam message and calls from different insurance carriers as well since as well.</p>
                          <img src="../../../../public/imgs/blog_imgs/smart_financial_auto_insurance_review_3.png" alt="">
                          <img src="../../../../public/imgs/blog_imgs/smart_financial_auto_insurance_review_4.png" alt="">
                          <div class="FAQ_container">
                              <div class="FAQ_list">
                                  <div class="FAQ_list_left">
                                      <div class="FAQ_onecontailner">
                                          <h2 class="anchor_h2">FAQ</h2>
                                          <el-collapse accordion class="FAQ">
                                              <el-collapse-item title="Is Your Information Sold by SmartFinancial?" name="1">
                                                  <div>Smart Financial is a platform for insurance comparison shopping, not an insurer. This means it earns a commission by generating leads for its insurer partners.</div>
                                                  <div>This means that if you shop for a quote on the site, you may receive calls from several insurers. There have been some complaints about spam calls and messages, but if you file a complaint, Smart Financial will address these issues.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="Is SmartFinancial legitimate and secure?" name="2">
                                                  <div>SmartFinancial is a legitimate and secure website, but it does not provide actual car insurance quotes. Despite not being accredited by the Better Business Bureau (BBB), the company has an A+ rating. California residents can opt out of the sale of their personal information by filling out a form on the website.</div>
                                              </el-collapse-item>
                                          </el-collapse>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Who is SmartFinancial?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">How Does SmartFinancial Work?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Information Including</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Is SmartFinancial Compatible with Mobile Devices? Is there a Mobile App?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Does SmartFiancial Have a Good Reputation on the Market?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">What Customers Think of SmartFinancial?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">FAQ</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
    
    .FAQ >>> .el-collapse-item__header {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    .FAQ >>> .is-active {
        color: #2ac8a2;
    }
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 35px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 100px;
            text-align: left;
        }
    }
</style>