<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Health Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Health insurance for every body</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/auto_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Auto Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/health_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Health Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/homeowners_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Home Insurance</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/life_insurance" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Life Insurance</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h2>What is Health Insurance?</h2>
                          <p>A medical insurance policy, also called as health insurance, covers medical expenses for illnesses or injuries. It reimburses your bills or pays the medical care provider directly on your behalf. A comprehensive medical insurance covers the cost of hospitalisation, daycare procedures, medical care at home (domiciliary hospitalisation), ambulance charges, amongst others.</p>
                          <p>A health insurance plan helps you stay covered against various diseases. Additionally, it helps you boost tax savings. Under section 80D of the Income Tax Act, 1961, you can claim tax benefits against your health insurance premium.</p>
                          <h2>What are the benefits of buying a health insurance policy?</h2>
                          <ul class="text1">
                              <li>One of the key benefits of a health insurance policy is that it covers your medical expenses incurred just before and after hospitalisation.</li>
                              <li>A health plan covers you for a pre-existing disease once you have completed the waiting period mentioned in the policy certificate.</li>
                              <li>At the time of hospitalisation, you don’t have to worry about arranging cash to pay your hospital bill. Your health insurance company will settle it directly with the hospital if it is in their network.</li>
                              <li>You can claim a tax deduction on health insurance insurance premium under section 80D of the Income Tax Act, 1961.</li>
                              <li>Keeping healthy has its own benefits. You can enjoy additional sum insured for every claim-free year as per the policy certificate of your health plan.</li>
                          </ul>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">Life Insurance Explained</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">Types of Life Insurance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Types of Life Insurance</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "life_insurance",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgcrad.scss';
@import '../../assets/scss/textnavigation.scss';


.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>