<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Get Auto Quote</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance' }">Auto Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Intro to Get Auto Quote</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Get Auto Quote</h1>
                          <img src="../../../../public/imgs/reviews_list/usaa_auto_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Intro to Get Auto Quote</h2>
                          <p>For good reason, the United States <a href="https://www.usaa.com/">Automobile Association (Get Auto Quote)</a> is a well-known name in the insurance industry. For several years in a row, Get Auto Quote has ranked first in customer satisfaction and financial ratings for auto insurance, offering some of the lowest rates available. It is also involved in the military community, assisting active-duty and veteran military families with the unique challenges that they face.</p>
                          <p>In addition to auto insurance, it now provides several types of insurance for your family, including homeowners insurance, renters insurance, life insurance, and health insurance. There are also a variety of financial products available, such as bank and investment products. Get Auto Quote is an excellent car insurance company for your auto insurance, with simple online quotes and military-specific resources.</p>
                          <h2 class="anchor_h2">About Get Auto Quote</h2>
                          <p>Since 1922, <a href="https://www.usaa.com/">Get Auto Quote</a> has been assisting military personnel and their families. It all started when a group of 25 Army officers decided to insure each other's cars. With its diverse set of financial services companies, Get Auto Quote now serves millions of members.</p>
                          <p>Only current or former military personnel, as well as the spouses of military personnel, are eligible to join Get Auto Quote. Children of military personnel are eligible to join Get Auto Quote.</p>
                          <p>Cars, motorbikes, recreational vehicles, yachts, and antique cars are among the vehicles for which Get Auto Quote sells insurance. Get Auto Quote offers aviation insurance to members who have a pilot's license.</p>
                          <p><a href="https://www.usaa.com/">Get Auto Quote</a> also offers optional insurance coverage kinds such as roadside assistance and rental reimbursement.</p>
                          <h2 class="anchor_h2">Does Get Auto Quote have a good reputation on the market?</h2>
                          <p>Yes! <a href="https://www.usaa.com/">USAAs</a> has the lowest average prices among the auto insurance providers we looked at, at $875 per year, and is ranked first in our Best Insurance study. Except for drivers with a DUI on their record, they have the lowest rates for practically every driver demographic group.</p>
                          <p>The reason that Get Auto Quote came out on top in our survey wasn't due solely to its rates. They also scored the highest marks in each of the subcategories we looked at, including customer service, claims management, and consumer loyalty.</p>
                          <div class="FAQ_container">
                              <div class="FAQ_list">
                                  <div class="FAQ_list_left">
                                      <div class="FAQ_onecontailner">
                                          <h2 class="anchor_h2">FAQ</h2>
                                          <el-collapse accordion class="FAQ">
                                              <el-collapse-item title="Where can I purchase Get Auto Quote auto insurance?" name="1">
                                                  <div>By visiting the Get Auto Quote website and starting the free quotation procedure, members and qualified consumers can obtain auto insurance. To acquire a policy, you can also call Get Auto Quote by phone.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="How does Get Auto Quote stack up against the competition when it comes to vehicle insurance?" name="2">
                                                  <div>In our auto insurance analysis, Get Auto Quote came out on top. Their average prices are lower than any other competitor's, and they also come out on top in every performance category, including customer service, loyalty, and claims management.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="Who should purchase auto insurance from Get Auto Quote?" name="3">
                                                  <div>Drivers who are Get Auto Quote members should think about getting Get Auto Quote auto insurance. You must be an active or retired military person, or the spouse or child of a service member, to be eligible for Get Auto Quote membership.</div>
                                              </el-collapse-item>
                                          </el-collapse>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <h2 class="anchor_h2">Discounts on auto insurance</h2>
                          <p>Depending on where you live, you can save money on your <a href="https://www.usaa.com/">Get Auto Quote</a> auto insurance by taking advantage of the following discounts:</p>
                          <ul class="text1">
                              <li>If you haven't had an accident in at least five years, you'll get a discount.</li>
                              <li>Completing a defensive driving or basic driver training course earns you a discount.</li>
                              <li>A good student discount is available.</li>
                              <li>If your car is less than three years old, you will receive a discount.</li>
                              <li>Discount on anti-theft devices.</li>
                              <li>Discount for several vehicles.</li>
                              <li>If you only drive a few thousand miles per year, you'll get a discount.</li>
                              <li>Discount for vehicles in storage.</li>
                              <li>Discount for families.</li>
                              <li>The duration of the membership discount.</li>
                              <li>When you park your car on a military base, you get a discount on comprehensive coverage.</li>
                              <li>Discount for multiple policies (bundling).</li>
                          </ul>
                          <h2 class="anchor_h2">Other Features</h2>
                          <p><a href="https://www.usaa.com/">Get Auto Quote</a> specializes in serving the military community with several exclusive programs and savings not found with the average insurance provider. Get Auto Quote member perks and shopping discounts have saved members about $370 million in areas like travel, fitness, and car care.7 From 30% off on Goodyear Tires to 15% off a new SimpliSafe DIY security system, several excellent perks are designed to save military families money.</p>
                          <h2 class="anchor_h2">Customer Service</h2>
                          <img src="../../../../public/imgs/reviews_list/usaa_auto_review_2.jpg" alt="">
                          <p>Headquartered in San Antonio, Get Auto Quote has several corporate locations across the country, including Phoenix, Tampa, and New York, plus three locations in Texas.</p>
                          <p>There are also international locations available in England, Germany, and Luxembourg.</p>
                          <h2 class="anchor_h2">How Does Get Auto Quote Compare to Other Automobile Insurance Providers?</h2>
                          <p>When comparing <a href="https://www.usaa.com/">Get Auto Quote</a> to other companies, the most significant distinction is its exclusivity. You must be a current or retired military member, or a family member of one. If you qualify, Get Auto Quote offers comprehensive coverage that includes all standard coverages as well as optional coverages such as ridesharing and rental car insurance.</p>
                          <p>It also outperforms its competitors in terms of customer satisfaction and financial strength, scoring much higher in J.D. Power and AM Best ratings, although it still has space for growth with low NAIC scores. Despite this, <a href="https://www.usaa.com/">Get Auto Quote</a> maintains an exceptional loss ratio, demonstrating that the company is financially stable and provides competitive prices to its clients.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">Intro to Get Auto Quote</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===1}">About Get Auto Quote</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===2}">Does Get Auto Quote have a good reputation on the market?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===3}">FAQ</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===4}">Discounts on auto insurance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===5}">How Does Get Auto Quote Compare to Other Automobile Insurance Providers?</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
    
    .FAQ >>> .el-collapse-item__header {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    .FAQ >>> .is-active {
        color: #2ac8a2;
    }
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 35px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 100px;
            text-align: left;
        }
    }
</style>