<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Tax Relief</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">Income tax relief either is directed to reducing your tax liability before you file your return or negotiating a settlement for back taxes. When you owe back taxes to the IRS, you should obtain help from a professional income tax relief company. Doing so will help you get back on track financially and avoid serious legal repercussions.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/tax_relief" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Tax Relief Companies</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/tax_relief_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Tax Relief <br /> Reviews</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/tax_relief_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Tax Relief Guide</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/tax_relief" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Tax Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.img_url" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="score"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.APR}}</span>
                                          <ul>
                                              <li>{{item.Features1}}</li>
                                              <li>{{item.Features2}}</li>
                                              <li>{{item.Features3}}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.offers_url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.offers_url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">What Is Tax Relief?</h2>
                          <p>Tax Relief is a program designed to reduce the amount of taxes paid by individuals or businesses. It involves a universal cut by the IRS that benefits specific cohorts of taxpayers and involves the deduction of expenses, tax credits, and other benefits associated with your taxable income. </p>
                          <p>Tax relief may take one of the various forms when it comes to filing taxes. You can reduce your tax liability by:</p>
                          <ul class="text1">
                              <li>Tax Deductions – This is a legal reduction in tax relief that offers federal tax reductions on your taxable income as a result of expenses such as mortgage interest rates. It reduces the taxable income of an individual or a business. For example, if your single filer’s taxable income for 2020 was $75,000 you would owe $12,500 in federal income tax. Nonetheless, if you qualify for a $4,000 tax deduction, the tax amount will be computed as $75,000 – $4,000 = $71,000 in taxable income. Your federal taxable income will be reduced to around $11,125.</li>
                              <li>Tax Credits (also called Tax Incentives) – This is another category of tax relief and provides more savings compared to tax deductions. It is directly subtracted from the total tax amount after all deductions have been calculated. Tax credits are also known as tax incentive as it compensates for expenditures that the government finds significant. For instance, the Lifetime Learning Credit programs permit credits for people who indulge in postsecondary education programs. </li>
                              <li>Tax Benefits (such as enrolling in a tax-free IRA or Individual Retirement Account) – These are also referred to as exclusions and they categorize specific types of income as tax relief which lessens the amount of tax you pay. The most common exclusion is the employer-based health insurance payment program. If an employer receives healthcare benefits, the taxable income is lowered at the end of the pay period, which reduces the tax owed. </li>
                          </ul>
                          <p>Regardless of the form of tax relief you need, it will normally reduce your tax debt and help prevent any payments that are related to a tax underpayment or tax evasion penalties. The next section will give you a brief overview of tax penalties and how they may be imposed.</p>
                          <h2 class="anchor_h2">Compare Best Tax Relief Loans</h2>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">Common Causes of Tax Stress: Penalties</h2>
                          <p>Some people find that the easiest way to avoid tax evasion penalties, or an infraction that results in a tax underpayment penalty, is to seek advice from an income tax relief professional. The 2 main tax penalties usually imposed on taxpayers include –</p>
                          <ul class="text1">
                            <li><span class="text_bold">Failure-to-file penalties </span>, imposed for not filing a tax return by the due date. These penalties levy a rate of 5% on the tax still owed for a month or the part of a month the return remains late. You may have to pay as much as 25% of the owed amount.</li>
                            <li><span class="text_bold">failure-to-pay penalties </span>amount to 0.5% of the unpaid tax if a return is filed but the tax goes unpaid by the tax due date. The rate jumps to 1% if the tax has not yet been paid after 10 days, or after the IRS gives the taxpayer a Notice of Intent to Levy Property.</li>
                          </ul>
                          <p>Some people who signed an installment agreement with the IRS reduced their assessed penalty.</p>
                          <h2>Interest on Unpaid Taxes</h2>
                          <p>The interest on unpaid taxes is assessed from the due date of the tax return until the amount is paid in full. The interest is calculated quarterly, at the current federal short-term rate plus 3%. This amount accumulates or accrues daily.</p>
                          <p>Payments are applied to the tax owed, then to the penalties and interest.</p>
                          <h2>Preventing Tax Penalties</h2>
                          <p>To prevent penalties, take advantage of income tax relief options, such as deductions, credits, and exclusions. If you are a business, you need to make estimated tax payments. If you don’t make the required quarterly estimated payments by the due date, the IRS can penalize you.</p>
                          <h2 class="anchor_h2">Tax Relief in 2021</h2>
                          <p>To prevent penalties, take advantage of income tax relief options, such as deductions, credits, and exclusions. If you are a business, you need to make estimated tax payments. If you don’t make the required quarterly estimated payments by the due date, the IRS could penalize you.</p>
                          <p>Other options that are available for tax relief include</p>
                          <h2>Fresh Start Program</h2>
                          <p>Which is stipulated for taxpayers behind on their tax bills. This is an IRS program that allows you to compromise and clear your debt for a lower amount than you owe. The IRS scrutinizes this offer based on your assets, income, and household expenses. </p>
                          <h2>Installment Agreements</h2>
                          <p>It is also referred to as a payment plan and allows you to repay your taxes for a longer period. Its objective is to make the management of taxes easier. </p>
                          <h2>Penalty Relief and Interest Abatement</h2>
                          <p>This allows you to plead for high-interest rates, penalties, and administrative fees. However, you must provide a valid reason to the IRS for why your taxes were unpaid. </p>
                          <table border="1" rules="all" width="100%">
                                <tr>
                                    <th>Relief</th>
                                    <th>How it Helps</th>
                                    <th>Who Can Help</th>
                                </tr>
                                <tr>
                                    <td>Fresh Start Program</td>
                                    <td>Settling your tax debt for less than you owe</td>
                                    <td>IRS or tax relief company</td>
                                </tr>
                                <tr>
                                    <td>Instalments Agreements</td>
                                    <td>Paying your tax bills over time</td>
                                    <td>IRS or tax relief company</td>
                                </tr>
                                <tr>
                                    <td>Penalty Relief and Interest Abatement</td>
                                    <td>Waives fees and interest costs</td>
                                    <td>IRS or tax relief company</td>
                                </tr>
                            </table>
                            <h2>Additional Tax Relief During Covid-19</h2>
                            <p>These days, many people find tax relief in the USA in 2021 is especially significant because of the COVID-19 pandemic – an unexpected event that disrupted business operations and led to problems with tax filings.</p>
                            <p>The IRS extended the filing deadline to cope with the Covid pandemic. Due to the financial complications that came about as a result of the global pandemic, there have been increased unemployment benefits to help taxpayers resolve their bills.</p>
                            <h2>Small Employer Tax Credits</h2>
                            <p>One of the big measures imposed for 2020 that affects tax relief in 2021 is the Small Employer Tax Credits program, covering required COVID-19-associated paid leave for employees. This measure and tax credit plan was implemented by the Families First Coronavirus Response Act (FFCRA), which passed in March 2020.</p>
                            <p>While the FFCRA Act expired on December 31, 2020, the Small Employer Credits program has been extended to cover leave payments made in 2021 between January 1 and March 31.</p>
                            <h2>The Employee Retention Tax Credit</h2>
                            <p>An employee retention tax credit, which was signed into law through the CARES Act on March 27, 2020, allows a credit equal to 50% of eligible employee wages paid by a qualified employer. The new law for 2021 allows an extension of the employee retention tax credit (up to $10,000) to cover the first 2 quarters of 2021, up to June 30, 2021.</p>
                            <p>The new law or extension for the 2020 law, allows a credit up to $14,000 over the 50% ($5,000) permitted for $10,000 in 2020. Therefore, 70% is taken from the cap of $10,000 x 2 quarters (or $7,000 each).</p>
                            <h2>Payroll Tax Deferral Income Tax Relief</h2>
                            <p>Through the CARES Act, you can defer a 6.2% employer portion of the Social Security tax owed for the initial amount of $137,700 of an employee’s 2020 wages from March 27, 2020, to December 31, 2020.</p>
                            <p>Businesses must pay the deferred amounts in 2 installments, each half due by – December 31, 2022</p>
                            <p>If you are self-employed, you can defer half the liability for self-employment tax and pay your deferral amounts at end-of-year in 2021 and 2022.</p>
                            <h2 class="anchor_h2">Getting Tax Relief and Making a Plan: Installment Agreements</h2>
                            <p>If you are not sure about your tax debt, you can check to see if you have an outstanding balance with the IRS by going to IRS.gov/account. You can sign up in about 15 minutes and you only need to provide your identity once.</p>
                            <p>If you already know your tax debt, you can either make payments or learn more about seeking tax forgiveness in the form of a tax compromise. Many find it important to do research and use a tax relief guide at each step to be thorough.</p>
                            <h2>Making Payments to the IRS</h2>
                            <p>The IRS features 2 types of payment plans – short-term and long-term.</p>
                            <h2>Short-term Payments</h2>
                            <p>A short-term payment arrangement covers a period of up to 180 days. Automatic withdrawals can be made from your checking account or you can pay with a debit/credit card, money order, or check. The maximum you can owe in taxes, penalties, and interest is $100,000.</p>
                            <h2>Long-term Payments</h2>
                            <p>If you owe $50,000 for taxes, penalties, and interest, you can make long-term payment arrangements of 120 days or more. Because of COVID-19, the IRS extended the period from 120 days in November 2020.</p>
                            <p>If you choose to pay by automatic withdrawal, you will be charged a fee of $31 to apply online and $107 if you elect to apply in-person, by mail, or by phone. These fees are waived for low-income applicants.</p>
                            <p>If you choose another payment method, other than automatic withdrawals, you have to pay $149 to apply online or $225 to apply in-person, by mail, or over the phone. The fees are lowered to $43 for low-income payers and may be refunded in some cases.</p>
                            <p>The form of payment used for installment accounts is called a Direct Debit Installment Agreement (DDIA).</p>
                            <h2 class="anchor_h2">What to Keep in Mind when Paying the IRS</h2>
                            <p>Keep the following in mind if you choose to make payments to the IRS:</p>
                            <p>You still have to pay the interest and penalties and interest you owe if you make payments to the IRS. Arranging a payment plan does not lead to tax forgiveness in this respect.</p>
                            <p>If you owe more than $25,000 in taxes, payments must be made by automatic withdrawal from</p>
                            <p>your checking account.</p>
                            <p>You may also be eligible for an offer in compromise or may be able to arrange a settlement that is lower than your current tax. An offer in compromise is helpful if you claim financial hardship or simply cannot pay the entire tax amount.</p>
                            <h2>Applying for an Offer In Compromise</h2>
                            <p>To apply for an offer in compromise with the IRS, the following needs to take place.</p>
                            <p>You need to submit an offer in compromise using IRS Form 656-B.</p>
                            <p>You will be assessed a non-refundable $205 fee unless you are low-income. In this case, you can get a waiver.</p>
                            <p>You will need to make a non-refundable initial payment.</p>
                            <p>You should be current on all your tax returns. If you have not filed tax returns for some time, you may not be eligible.</p>
                            <p>The IRS has the right to maintain current tax liens until your offer is accepted or you have fulfilled the agreement and paid the owed tax.</p>
                            <p>You cannot apply for tax forgiveness and a tax compromise if you are currently involved in a bankruptcy proceeding.</p>
                            <p>Once you file an application for an offer in compromise, all collection activities by the IRS will stop.</p>
                            <p>If your application is accepted, some information for your offer in compromise will be made public, including your name and residential or business location. Your tax liability and offer terms will also be made part of the public record.</p>
                            <p>Rejections of your offer can be appealed after 30 days.</p>
                            <h2 class="anchor_h2">What are the ramifications of not repaying tax debts?</h2>
                            <p>The IRS has several mechanisms for dealing with tax evasion. They can decide whether to use any or all of the discussed options below:</p>
                            <ul class="text1">
                                <li>The IRS will continue to pursue payment</li>
                                <li>Activation of the IRS Automated Collection system</li>
                                <li>The IRS will charge you interest on your tax bill</li>
                                <li>Penalties imposed will increase the size of your debt</li>
                                <li>Acquisition of any funds that the IRS gets</li>
                                <li>Extreme cases may lead to loss of passport privileges </li>
                                <li>The IRS will continue to pursue the taxes they are owed</li>
                            </ul>
                            <p>While the ramifications of not paying your back taxes are serious, tax relief companies can show you why it is to your benefit to avoid this. Be sure to thoroughly review tax relief companies to get great insights.</p>
                            <h2 class="anchor_h2">How do unpaid taxes affect my Credit?</h2>
                            <p>Nowadays, unpaid taxes do not have an impact on your credit. This is because tax liens do not show up on credit reports which do not directly have an influence on your credit status. In addition, credit scores are computed based on your performance in credit reports that are compiled by the national credit bureaus such as Equifax or TransUnion. </p>
                            <h2 class="anchor_h2">What can the IRS do if you owe back taxes?</h2>
                            <p>Back taxes are funds that you owe the IRS and have not been paid on the due dates. They are subject to penalties and interest and serious legal actions can be deployed if they remain unpaid. Some of the common actions include tax liens, wage garnishments, or even prison time. Such actions depend on the circumstance you are in. In other cases, the IRS may seize property, assets or press charges. </p>
                            <h2 class="anchor_h2">Is it better to work directly with the IRS or hire a professional tax relief company?</h2>
                            <p>The IRS offers free tax help by computer, television, or in person. They also help you get forms and publications and provide answers to a wide range of tax questions. However, an IRS interview might run your nerves with numerous questions regarding your situation. That’s why it’s recommended to always have a tax attorney to help you.  </p>
                            <p>Professional tax relief companies have free consultations which provide answers to your questions. They also send their agents to talk to the IRS on your behalf. However, they have additional costs and may take longer to come up with a solution. </p>
                            <h2 class="anchor_h2">Choosing a Tax Relief Company</h2>
                            <p>Needless to say, the IRS tax law is both complicated and complex. That is why you may want to consider getting assistance from a professional income tax relief service provider. The best tax relief companies assisted millions of clients to navigate through the tax system easily, mainly supporting their efforts in finding the right tax solutions for them.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">What Is Tax Relief?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">Compare Best Tax Relief Loans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)" :class="{active: active===2}">Common Causes of Tax Stress: Penalties</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)" :class="{active: active===3}">Tax Relief in 2021</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)" :class="{active: active===4}">Getting Tax Relief and Making a Plan: Installment Agreements</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)" :class="{active: active===5}">What to Keep in Mind when Paying the IRS</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)" :class="{active: active===6}">What are the ramifications of not repaying tax debts?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)" :class="{active: active===7}">How do unpaid taxes affect my Credit?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(8)" :class="{active: active===8}">What can the IRS do if you owe back taxes?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(9)" :class="{active: active===9}">Is it better to work directly with the IRS or hire a professional tax relief company?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(10)" :class="{active: active===10}">Choosing a Tax Relief Company</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "tax_relief",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: [ {
                    id: 1,
                    name: 'Anthem Tax Services',
                    img_url: '/imgs/offers_imgs/Anthem_Logo_Color.svg',
                    offers_url: 'https://anthemtaxservices.com/',
                    APR: '$8,000',
                    Features1: '100% money-back guarantee',
                    Features2: 'Save you up to 99% on unpaid taxes',
                    Features3: 'Fresh Start Initiative assistance',
                }, {
                    id: 2,
                    name: 'Tax Hardship Center',
                    img_url: '/imgs/offers_imgs/taxhardshipcenter_l_bottom.svg',
                    offers_url: 'https://www.taxhardshipcenter.com/',
                    APR: '$12,000',
                    Features1: 'Customers with $12k+ get relief in back taxes',
                    Features2: 'A+ BBB rated, for individuals & businesses',
                    Features3: '100% money-back guarantee',
                }, {
                    id: 3,
                    name: 'Tax Resolvers',
                    img_url: '/imgs/offers_imgs/taxresolvers.svg',
                    offers_url: 'https://www.thetaxresolvers.com/tax-help/',
                    APR: '$7,500',
                    Features1: 'BBB A+ Accredited Business',
                    Features2: 'Flexible payments',
                    Features3: 'Skilled team of professionals',
                }, {
                    id: 4,
                    name: ' Larson Tax Relief',
                    img_url: '/imgs/offers_imgs/larson_l.svg',
                    offers_url: 'https://larsontaxrelief.com/tax-relief-form-tp22-1/?oid=6',
                    APR: '$20,000',
                    Features1: 'Award-Winning Customer Service',
                    Features2: '17 Years in Business',
                    Features3: 'Family Owned and Operated',
                }, {
                    id: 5,
                    name: 'Community Tax',
                    img_url: '/imgs/offers_imgs/community_tax_logo_bottom.svg',
                    offers_url: 'https://www.community.tax/tax-debt-assistance/',
                    APR: '$10,000',
                    Features1: 'A+ rating from BBB',
                    Features2: '100% money-back guarantee',
                    Features3: 'Fresh Start Initiative assistance',
                }, {
                    id: 6,
                    name: 'Jackson Hewitt',
                    img_url: '/imgs/offers_imgs/jackson_hewitt_logo.svg',
                    offers_url: 'https://www.jacksonhewitt.com/',
                    APR: '$10,000',
                    Features1: '35+ years of experience',
                    Features2: 'Covers most forms of tax relief',
                    Features3: 'Competitive fees & free consultation',
                }, {
                    id: 7,
                    name: 'Tax Defense Network',
                    img_url: '/imgs/offers_imgs/TDN-with-MS-horz-4Color_02-2.svg',
                    offers_url: 'https://solutions.taxdefensenetwork.com/lendstart-variant-c',
                    APR: '$11,997',
                    Features1: 'Free Consultation & Case Review',
                    Features2: 'Consulted on Over $23 Billion in Tax Debt',
                    Features3: 'Customized Tax Resolution Plans',
                }, {
                    id: 8,
                    name: 'Optima Tax Relief',
                    img_url: '/imgs/offers_imgs/optima_tax_relief_l.svg',
                    offers_url: 'https://app.optimataxrelief.com/tax-problems',
                    APR: '$10,000',
                    Features1: 'Full-service, direct provider',
                    Features2: 'Certified agents on staff',
                    Features3: 'Wide range of services',
                }, {
                    id: 9,
                    name: 'Victory Tax Lawyers',
                    img_url: '/imgs/offers_imgs/victorytaxlaw_l_bottom_2.svg',
                    offers_url: 'https://victorytaxlaw.com/trafficpoint',
                    APR: '$25,000',
                    Features1: 'Free consultation with licensed attorney',
                    Features2: 'Flat fees – no surprises',
                    Features3: 'Transparent & trustworthy service',
                }]
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/navimgcrad.scss';
.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>