<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Allstate</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance' }">Auto Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>AllState Auto</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>AllState Auto</h1>
                          <img src="../../../../public/imgs/reviews_list/allstate_auto_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Intro to Allstate</h2>
                          <p><a href="https://www.allstate.com/auto-insurance.aspx">Allstate</a> was founded in the year of 1931, with official website at www.allstate.com. </p>
                          <p>Until today, Allstate is the largest publicly traded personal insurer in the United States, with over 113 million proprietary policies. It is based in Northbrook, Illinois, and serves all 50 states. It also works with a number of insurance companies to underwrite its policies, including Allstate New Jersey Property and Casualty Insurance Company and Castle Key Insurance Company in Florida.</p>
                          <p>Allstate claims to care about its customers, but the company is currently embroiled in a consumer class action lawsuit in Florida over spam texting using personal numbers allegedly obtained from data aggregation companies.It also resolved a national domestic class action suit on behalf of policyholders from 38 different states. </p>
                          <p>These policyholders had underpaid property damage claims, despite the fact that these cases primarily involved Allstate's home insurance claims.</p>
                          <p>If you need to file a car insurance claim or change your insurance coverage, <a href="https://www.allstate.com/auto-insurance.aspx">Allstate</a> provides nationwide availability and a solid financial foundation with you, while also operating local agencies from coast to coast. Allstate's comprehensive insurance portfolio allows customers to shop for auto, business, home, and life insurance all in one place. Its digital tools make it simple for policyholders to shop for, purchase, and manage auto insurance policies.</p>
                          <h2 class="anchor_h2">What is the Cost of Allstate Auto Insurance?</h2>
                          <p>With <a href="https://www.allstate.com/auto-insurance.aspx">Allstate</a>, the average yearly car insurance price for minimal coverage is $1,422. The average rate for full coverage, which provides greater financial protection, is $2,243.</p>
                          <p>When calculating your insurance rates, insurance companies consider a variety of factors such as your car type, driving history, and personal characteristics, thus premiums can change significantly from driver to driver.</p>
                          <p>When it comes to determining premiums, insurers consider age as a major issue. According to statistics, teen drivers are nearly three times as likely as drivers over 20 to be involved in an accident, and this risk means they pay far higher insurance premiums than the ordinary driver.</p>
                          <p>Remember that auto insurance premiums are determined by a variety of factors, including your vehicle, zip code, age, and the type of coverage you require. Get a sense of where premium prices start for your demographic and compare many rate quotes before buying a policy.</p>
                          <div class="FAQ_container">
                              <div class="FAQ_list">
                                  <div class="FAQ_list_left">
                                      <div class="FAQ_onecontailner">
                                          <h2 class="anchor_h2">FAQ</h2>
                                          <el-collapse accordion class="FAQ">
                                              <el-collapse-item title="Where Can I Get Allstate Auto Insurance?" name="1">
                                                  <div>Allstate car insurance can be purchased online, over the phone, or in person with an Allstate agent in your local city.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="How Does Allstate Stack up Against the Competition?" name="2">
                                                  <div>Allstate is ranked ninth out of the nine companies we looked at. They typically have one of the highest average insurance rates for all types of drivers, including those with bad credit, good drivers, and senior drivers. According to our survey, Allstate has the lowest customer loyalty and recommendation scores.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="Who Should Purchase Allstate Auto Insurance?" name="3">
                                                  <div>Most drivers will not be able to find the best rates with Allstate. Drivers in certain demographics, however, may find Allstate to be slightly more competitive than other insurers. This is especially true of teen drivers. Allstate is ranked seventh in that driver demographic, trailing Farmers and Progressive by 16 percent and 21 percent, respectively.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="Is Allstate a reputable insurance provider?" name="4">
                                                  <div>Allstate had good service and coverage alternatives, but its rates were far higher than those of other big American insurers such as Geico and State Farm.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="What kind of discounts does Allstate provide?" name="5">
                                                  <div>Allstate offers a bunch of discounts, but the Drivewise app discount, defensive driving discount, and pay-in-full discount are the most popular.</div><br />
                                                  <div>By joining in the Drivewise program, you can save more than 40% on your regular insurance costs. It's a form of telematics-based system that rewards safe driving with lower rates and incentives. You can earn cashback up to twice a year by using the Allstate mobile app.</div><br />
                                                  <div>The app also has free crash detection, which will automatically offer assistance if a significant collision is detected.</div><br />
                                              </el-collapse-item>
                                          </el-collapse>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <h2 class="anchor_h2">Customer’s Feedback</h2>
                          <img src="../../../../public/imgs/reviews_list/allstate_auto_review_2.jpg" alt="">
                          <p>The website and online tools provided by <a href="https://www.allstate.com/auto-insurance.aspx">Allstate</a> are straightforward and simple to use. Drivers can contact Allstate directly for assistance with policy questions, billing, or claims filing, or they can reach the company online or via the Allstate mobile app.</p>
                          <p>However, you may not need to contact an <a href="https://www.allstate.com/auto-insurance.aspx">Allstates</a> representative because the website provides helpful breakdowns of coverage options as well as a library of information about car insurance terms and options.</p>
                          <h2 class="anchor_h2">Several Complaints about the Company</h2>
                          <p>Complaints about rates, customer service, and claims handling are common at any insurance company. Many of the typical complaints were made by Allstate customers.</p>
                          <p>Respondents who were dissatisfied with their <a href="https://www.allstate.com/auto-insurance.aspx">Allstate</a> experience frequently complained about slow response times and difficulty contacting their agent.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Intro to Allstate</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">What is the Cost of Allstate Auto Insurance?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">FAQ</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Customer’s Feedback</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Several Complaints about the Company</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
    
    .FAQ >>> .el-collapse-item__header {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    .FAQ >>> .is-active {
        color: #2ac8a2;
    }
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 35px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 100px;
            text-align: left;
        }
    }
</style>