export default {
    namespaced: true,
    state: {
        parent_url: null,
        parent_name: null,
        post_id: null,
        sonUrl: null,
    },
    getters: {
        parent_name: state => {
            if (!state.parent_name) {
                try {
                    state.parent_name = localStorage.getItem('pname')
                } catch (e) {
                    console.error(e)
                }
            }
            return state.parent_name
        },
        parent_url: state => {
            if (!state.parent_url) {
                try {
                    state.parent_url = localStorage.getItem('purl')
                } catch (e) {
                    console.error(e)
                }
            }
            return state.parent_url
        },
        post_id: state => {
            if (!state.post_id) {
                try {
                    state.post_id = localStorage.getItem('pid')
                } catch (e) {
                    console.error(e)
                }
            }
            return state.post_id
        },
        sonUrl: state => {
            if (!state.sonUrl) {
                try {
                    state.sonUrl = localStorage.getItem('sonUrl')
                } catch (e) {
                    console.error(e)
                }
            }
            return state.sonUrl
        },
    },
    mutations: {
        setPurl(state, url) {
            state.parent_url = url
            localStorage.setItem('purl', url)
        },
        setPname(state, name) {
            state.parent_name = name
            localStorage.setItem('pname', name)
        },
        setPid(state, id) {
            state.post_id = id
            localStorage.setItem('pid', id)
        },
        setsonUrl(state, url) {
            state.sonUrl = url
            localStorage.setItem('sonUrl', url)
        },
    }
}