<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">LendingTree Mortgage</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
                <div class="Bread_crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                        <el-breadcrumb-item>Home Equity Quiz</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Home Equity Quiz</h1>
                          <img src="../../../../public/imgs/reviews_list/lending_tree_mortgage_review_1.jpg" alt="">
                          <h2 class="anchor_h2">The Home Equity Quiz Mortgage Process</h2>
                          <p>A consumer looking for a mortgage has a number of options. They can go to a local bank or credit union, a direct mortgage lender like Quicken Loans, or use a site like Home Equity Quiz to try to get numerous loan offers at once.</p>
                          <p>The Home Equity Quiz option is one you might want to consider if you want to shop around for a house loan in a short amount of time. The firm does not provide mortgages, but rather serves as a lead generator for a large number of lenders in its network. When creditworthy customers apply for a loan, they will likely receive offers from a few of the participating lenders within minutes.</p>
                          <p>The first step in getting a Home Equity Quiz mortgage is to fill out a loan application, which may be done online or by contacting the company's 800 number. You'll be asked a number of questions concerning the loan size and kind (buy, refinancing, home equity, or reverse mortgage), as well as information about your income, assets, and obligations. Home Equity Quiz also requires your Social Security information, which it utilizes to perform a soft credit check to establish your credit score.</p>
                          <p>It's crucial to remember that the information you supply Home Equity Quiz does not constitute a formal application (which you'll have to do with one of the lenders if you accept an offer). 4 The company does not make any approval choices because it is a loan marketplace. Rather, it sends your information to its network of lenders, who select whether or not to extend a loan based on their own lending criteria.</p>
                          <p>According to Home Equity Quiz, you can receive preliminary quotations through email from up.</p>
                          <h2 class="anchor_h2">Mortgage Qualifications at Home Equity Quiz</h2>
                          <p>When approving a loan, three factors are typically taken into account. These are your options:</p>
                          <ul class="text1">
                            <li>Debt-to-Income Ratio Downpayment Credit Score</li>
                            <li>Lenders have a limited amount of leeway when it comes to the precise amounts.</li>
                          </ul>
                          <p>The greater your downpayment and credit score, though, the cheaper your interest rates will be and the more possibilities you'll have.</p>
                          <h2 class="anchor_h2">Pros and Cons of Home Equity Quiz</h2>
                          <p>Home Equity Quiz can make mortgage purchasing a lot easier by letting you to compare multiple offers by filling out just one form.</p>
                          <p>When considering a Home Equity Quiz mortgage application, there are advantages and disadvantages to this technique.</p>
                          <p>Despite these advantages, the way Home Equity Quiz works may result in you receiving unwanted lender spam. Home Equity Quiz, as a marketplace, shares the information you supply with its connected lenders so that you can get their offers. When you no longer require their services, however, some of these lenders may engage in aggressive marketing activities. Despite the fact that Home Equity Quiz does not engage in this form of communication, we found it to be a widespread issue in online consumer evaluations.</p>
                          <p>Pros:</p>
                          <ul class="text1">
                            <li>A simple four-step procedure</li>
                            <li>Compare mortgage offers from over 1,500 different lenders.</li>
                            <li>Customer feedback is available.</li>
                            <li>There is no charge to utilize the service.</li>
                            <li>Notifications about better offers and services</li>
                          </ul>
                          <p>Cons:</p>
                          <p>Because Home Equity Quiz works with a large number of lending partners, you should expect to get a lot of information, marketing, and sales emails (and phone calls) after submitting your contact information. This can be inconvenient, but it can also alert you to better deals.</p>
                          <h2 class="anchor_h2">Mortgage Rates, Fees, and Services at Home Equity Quiz</h2>
                          <p>Home Equity Quiz is not a mortgage lender and does not service loans in any way, as previously stated. All credit decisions, including approval, rates, and conditions, are made by the participating companies. These are established by the information provided by the customer as well as the standards of each specific company.</p>
                          <p>As a result, there are no costs for using Home Equity Quiz's service, and quoted rates and conditions are not guaranteed. Their sole purpose is to match your request with available lenders. The mortgage process is then taken up with the lender directly.</p>
                          <h2 class="anchor_h2">Benefits of Using Home Equity Quiz</h2>
                          <p>Gathering quotations from different lenders is essential for anyone trying to get a mortgage, refinance, or take out a home equity loan. Due to its streamlined loan request process, Home Equity Quiz makes comparison shopping quick and straightforward. In approximately the time it takes to fry an egg, users will learn whether they qualify for a loan and what the terms are.</p>
                          <p>You can also try to haggle with lenders in the hopes of cutting the interest rate or lowering the closing charges because you're likely to get many offers back to back. You might possibly end up with a better loan offer than the one you started with.</p>
                          <h2 class="anchor_h2">Home Equity Quiz Disadvantages</h2>
                          <p>Home Equity Quiz has a number of advantages, and borrowers who know how to take advantage of them could wind up with a better mortgage deal than they would have gotten if they worked with just one lender.</p>
                          <p>However, there are a few disadvantages to using Home Equity Quiz. One of the most common complaints from Home Equity Quiz customers is that they are constantly bombarded with phone calls and emails. Those loan officers have a vested interest in winning the business of each borrower lead they contact. As a result, they are unlikely to phone the borrower simply once, make their pitch, and then hope to be chosen.A more plausible scenario is that the borrower has a horde of eager salespeople calling and emailing at all hours of the day to get ahead of their rivals.</p>
                          <p>Some online reviews claim they've been contacted by as many as five lenders. Indeed, a January 2020 Home Equity Quiz privacy policy indicates that the corporation may sell your information to associated and non-affiliated organizations alike, including your Social Security number and credit history. Consumers can limit this sharing by contacting the firm online or by calling 888-272-1355.</p>
                          <p>A Home Equity Quiz spokeswoman told Investopedia that there are very simple solutions for privacy-conscious clients to block their phones from constantly ringing. One option is to just leave your phone number blank when filling out your personal information on the website. The other option is to utilize the company's mobile app, which offers more privacy than the website.</p>
                          <p>"If a borrower prefers not to talk with a lender, Home Equity Quiz's myRefiSimplified app experience is 100 percent powered by borrower selection or'self-select,' which means the borrower initiates contact rather than lenders calling the borrower," a spokeswoman said in a statement. "Through this channel, consumers continue to obtain genuine offers from lenders."</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">The Home Equity Quiz Mortgage Process</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Mortgage Qualifications at Home Equity Quiz</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Pros and Cons of Home Equity Quiz</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Mortgage Rates, Fees, and Services at Home Equity Quiz</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Benefits of Using Home Equity Quiz</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">Home Equity Quiz Disadvantages</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>