<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">eMortgage</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>eMortgage</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>eMortgage</h1>
                          <img src="../../../../public/imgs/reviews_list/emortgage_review_1.jpg" alt="">
                          <p><a href="https://emortgage.com/">eMortgage</a> Capital is provided by eMortgage Capital Inc (NMLS #1416824), a direct lender situated in Irvine, California, that was created in 2015. eMortgage Capital operates in 23 states across the United States.</p>
                          <h2 class="anchor_h2">What is the Key Takeaways?</h2>
                          <p>There are four distinct mortgage programs available. FHA, USDA, VA, jumbo, conforming, and conventional mortgages are all available with eMortgage Capital.</p>
                          <p>Financing options for a wide range of property kinds. Home loans are available for manufactured, single-family, and multi-family homes.</p>
                          <p>Interest rate types that are common. <a href="https://emortgage.com/">eMortgage</a> Capital provides both adjustable and fixed-rate mortgages.</p>
                          <p>Takes into account both personal and investment properties. Primary residences, vacation homes, and even investment assets, such as rental properties, are all eligible for financing.</p>
                          <p>There is a cost for prepayment. This means that if you pay off your mortgage early, you will be assessed a penalty.</p>
                          <p>Applications for cosigner and coborrower are accepted. When applying for eMortgage Capital, a cosigner or coborrower can help you qualify for better rates and conditions.</p>
                          <p>Acceptance of bad credit is possible. You can apply even if your credit is terrible or poor. Borrowers with a credit score of 500 or less are taken into consideration.</p>
                          <h2 class="anchor_h2">Pros and Cons for Emortgage Capital</h2>
                          <p class="text_bold">Pros:</p>
                          <ul class="text1">
                              <li>Considers applicants who have a good credit history.</li>
                              <li>Offers single-family, multi-family, and manufactured-home finance.</li>
                          </ul>
                          <p class="text_bold">Cons:</p>
                          <ul class="text1">
                              <li>An origination fee is charged.</li>
                              <li>Prepayment fees are charged.</li>
                              <li>Only 23 states have this option.</li>
                          </ul>
                          <h2 class="anchor_h2">What is the longest loan term offered by eMortgage Capital?</h2>
                          <p>Mortgages from <a href="https://emortgage.com/">eMortgage</a> Capital are available with periods ranging from 8 to 30 years. Longer periods allow borrowers to take out larger loans while keeping monthly payments lower. The longer the term of your loan, though, the more interest you'll have to pay.</p>
                          <h2 class="anchor_h2">What are the mortgage rates offered by eMortgage Capital Inc?</h2>
                          <p>Mortgage rates fluctuate on a daily basis based on market conditions, and they differ greatly depending on the loan type and term duration. Fixed-rate mortgages, for example, will often have a higher interest rate than variable-rate mortgages, and jumbo loans will typically have a higher interest rate than conforming conventional loans. Before you choose a house loan, acquire quotations from three or more mortgage companies to find the best interest rate.</p>
                          <h2 class="anchor_h2">Is there a fee for getting a mortgage?</h2>
                          <p>A loan origination fee may be charged by eMortgage Capital.</p>
                          <h2 class="anchor_h2">What types of mortgages are available?</h2>
                          <p><a href="https://emortgage.com/">eMortgage</a> Capital also offers the following mortgage kinds in addition to traditional mortgages:</p>
                          <p>FHA loans: are mortgages that are insured by the Federal Housing Administration. They have lower eligibility standards and offer competitive mortgage rates. Borrowers must, however, pay a mortgage insurance payment. You can apply for an FHA loan with a credit score as low as 580, for example. Co-applicants are permitted, and you may be eligible for a down payment as low as 3%.</p>
                          <p>Jambo loans:Any mortgage with a loan amount more than the Federal Housing Finance Agency's conforming loan restrictions ($548,250 as of 2021).</p>
                          <p>USDA loans: For homebuyers in the suburbs and rural areas, a zero-down payment mortgage loan with cheap mortgage rates is available. Although the USDA does not need a minimum credit score, most mortgage lenders do. A minimum credit score of 640 is required by most mortgage lenders.</p>
                          <p>VA loans:For American service personnel, veterans, and their spouses, VA loans provide a zero-down payment mortgage or a refinance of up to 120 percent of the home's value. VA loans have low interest rates and don't require a minimum credit score. Mortgage lenders who offer them, on the other hand, usually require a credit score of at least 640.</p>
                          <h2 class="anchor_h2">What types of property can eMortgage Capital be used for?</h2>
                          <p>Some mortgage lenders impose restrictions on the kind of uses that borrowers can make of their mortgaged properties. The following types of property utilization are permitted by eMortgage Capital:</p>
                          <ul class="text1">
                              <li>Primary residences, secondary residences, and holiday homes</li>
                              <li>House flipping projects and investment properties</li>
                          </ul>
                          <h2 class="anchor_h2">What is the phone number for customer service?</h2>
                          <img src="../../../../public/imgs/reviews_list/emortgage_review_2.jpg" alt="">
                          <p><a href="https://emortgage.com/">eMortgage</a> Capital's customer support number is (855) 569-3700.</p>
                          <h2 class="anchor_h2">What is the email address for contacting support?</h2>
                          <p>Customercare@emortgagecapital.com is the email address for this business credit card's customer service.</p>
                          <h2 class="anchor_h2">Some of the comments for Emortage Capital</h2>
                          <p class="text_bold">1. Barbara Chase</p>
                          <p>This mortgage firm is one of the best I've ever worked with in my 20-year career. They executed two challenging refinances, but their staff stayed on task every day and provided good communication and results. They are incredibly devoted, honest, and have the ability to swiftly fix problems in order to make the process run well! The best aspect is that they had a good game plan from the start to save me money while still achieving our goal in record time!</p>
                          <p class="text_bold">2. Megan F</p>
                          <p>It's a pleasure to work with Phil, Lauren, and their staff! This was my first time getting a loan, and they were patient, accommodating, and got me the greatest prices! Thanks to Phil, Lauren, and Nicole, I had an amazing experience and would absolutely deal with this team again in the future.</p>
                          <p class="text_bold">3. Beth Lawson</p>
                          <p>Julia, our loan officer, was fantastic! She made the process of refinancing our home very straightforward. She was knowledgable and forthright, which I appreciated. The company is well-organized and has made everything quite simple. They came to us when we were available, and we signed when we were available! Julia and her staff at eMortgage Capital are highly recommended for your home finance needs. The entire team was fantastic!</p>
                          <p class="text_bold">4. Claudia C</p>
                          <p>My entire refinance experience has been excellent. I recently completed a refinance and wanted to express my gratitude to my loan officer, Kevin Nguyen. I know how vital communication is in these situations, and Kevin was outstanding. His responses were timely and kept me informed. I didn't feel rushed because he made it so stress-free. He provided me the best deal, was honest, and did an excellent job of getting everything I wanted. Kevin Nguyen deserves a big thank you, and I'll be promoting him and eMortgage Capital to my relatives and friends!</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is the Key Takeaways?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Pros and Cons for Emortgage Capital</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">What is the longest loan term offered by eMortgage Capital?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What are the mortgage rates offered by eMortgage Capital Inc?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Is there a fee for getting a mortgage?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">What types of mortgages are available?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">What types of property can eMortgage Capital be used for?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)">What is the phone number for customer service?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(8)">What is the email address for contacting support?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(9)">Some of the comments for Emortage Capital</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>