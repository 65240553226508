<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">LoanDepot</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
                <div class="Bread_crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                        <el-breadcrumb-item>HomeLight Simple Sale</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>HomeLight Simple Sale</h1>
                          <img src="../../../../public/imgs/reviews_list/loandepot_review_1.jpg" alt="">
                          <h2 class="anchor_h2">HomeLight Real Estate Mortgage Rates Overview</h2>
                          <p>Founded in 2012, HomeLight is a real estate technology company dedicated to making home buying, selling, and financing simple, certain and satisfying. The company offers a wide range of services including real estate agent matching, real estate investor matching, and mortgage products through its affiliated company HomeLight Home Loans. The company is privately held and located in San Francisco, California.</p>
                          <p>HomeLight offers software and services to homebuyers, sellers, and realtors including HomeLight home Loans™, HomeLight Closing Services™, HomeLight Trade-in™, and HomeLight Cash Offers™. When you find the home you want and are ready to buy, they also Mortgage loans will be provided. HomeLight holds lending licenses in 8 states.</p>
                          <p>For homebuyers, HomeLight offers fast prequalification: Just five minutes, according to the company's website. Mortgage prequalification is an important step to take as you start shopping and budgeting for a new home. Knowing how much you're likely to be able to borrow can help you focus your search on homes that you'll actually get a loan on. If you decide to apply for a loan, HomeLight can get you pre-approved within 24 hours.</p>
                          <p>For sellers, HomeLight provides a true, near-instant home value assessment. The company also offers a buyer-seller matching service for sellers who prefer to sell their homes without hiring a real estate agent.</p>
                          <p>All in all, HomeLight has a strong value proposition that will likely appeal to many buyers and sellers.</p>
                          <h2 class="anchor_h2">How HomeLight works</h2>
                          <p>As a home buyer or seller, you visit HomeLight, answer a series of questions, and enter your contact information. HomeLight will then call you with more questions to provide its agency advice.</p>
                          <p>Agents are ranked based on their stats. After you make a purchase or sale with one of the agents they recommend, the agent pays HomeLight a referral fee of 25% of their commission. So with a 6% commission, HomeLight will get 0.75% of the sale price of your home (from the listing agent), just tell you who the local agent is and their basic stats, you can find these on Zillow and other sources data. Might be a bit pricey for most.</p>
                          <p>Homebuyers and sellers get no discounts here compared to UpNest, owned by parent company Realtor.com, where users can get competitive offers from agents in advance and enjoy the same full range of services while doing so in the process. Save thousands of dollars. In this report on HomeLight's competitors, UpNest home sellers saw their homes sell an average of 10 days faster, while buyers saved an average of 5 percent more on home purchase costs.</p>
                          <h2 class="anchor_h2">HomeLight Simple Sale</h2>
                          <p>In 2019, HomeLight announced what it called "the first instant buyer marketplace." Essentially, it's a referral service to home buying companies willing to pay cash for a property. Effectively a replica of earlier sites and tools like Zillow and Opendoor.</p>
                          <p>Simple Sale is now reportedly introducing those leads to 100 such buyers in 35 U.S. markets. Assuming HomeLight earns a similar commission, home sellers are better off heading directly to those buyer sites.</p>
                          <h2 class="anchor_h2">HomeLight Service for Buyers and Sellers</h2>
                          <p>Some of the products HomeLight offers are specifically for only Sellers or only Buyers. However, there are a number of services that can help both.</p>
                          <p>Home Light Trade-In</p>
                          <p>One somewhat unique thing that HomeLight offers is something called “HomeLight Trade-In”. This is a new type of service that is supposed to take the stress out of selling your home before buying a new one.</p>
                          <p>Home buyers are able to move into their new home now and get full market value for their old home.</p>
                          <p>This allows you to make an offer on a house without a lending or home sale continency. Once your offer is accepted on a new home, you finish accepting the offer on your current home and can close very quickly.</p>
                          <p>This can really be a great option for a lot of home sellers, especially if they are in a situation where they have found their dream home and their current one has no offers.</p>
                          <h2 class="anchor_h2">Pros and cons</h2>
                          <p>Pros</p>
                          <ul class="text1">
                            <li>No matter where you live, HomeLight usually has a local agent for you. With over 28,000 agents nationwide, HomeLight's network is much larger than its closest competitor.</li>
                            <li>HomeLight's customer dashboard is streamlined and easy to use. Each real estate agent and their key performance data are displayed on one page, making it simple to compare and contact different agent matches.</li>
                            <li>HomeLight's algorithm instantly matches you with up to three local agents. You can request two additional games for a total of up to five games. That's more than most other proxy matching services offer.</li>
                          </ul>
                          <p>Cons</p>
                          <ul class="text1">
                            <li>Unlike some of its competitors, HomeLight doesn’t provide built-in savings for sellers or home buyers. Its agents charge the standard market rate, which is typically 2.5-3%.</li>
                            <li>HomeLight’s agent matching process relies 100% on an algorithm. While a concierge will contact you immediately afterward to review those matches — and potentially suggest different ones — the fact that the concierge doesn’t verify whether those matches are a good fit first means your initial agent matches may disappoint.</li>
                            <li>HomeLight does very little vetting of its agents and has almost no requirements that agents must meet. Any real estate agent with a license can sign up, regardless of how well they perform in their local markets.</li>
                            <li>We got a lot of phone calls, texts, and emails after signing up, both from HomeLight itself and from the agents that HomeLight recommends. While some contact is normal, the volume of communication from HomeLight was beyond what we got from other agent matching services.</li>
                          </ul>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">HomeLight Real Estate Mortgage Rates Overview</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">How HomeLight works</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">HomeLight Simple Sale</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">HomeLight Service for Buyers and Sellers</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Pros and cons</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>