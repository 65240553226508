import Vue from 'vue';
import Router from 'vue-router';

// 导入自己的组件
import Home from './pages/home'
import Debt from './pages/debt'
import Loans from './pages/loans'
import Privacy_policy from './pages/introduction_page/privacy_policy'
import Learning_center from './pages/learning_center'
import Contact_us from './pages/introduction_page/contact_us'
import Faqs from './pages/financial_help_page/faqs'
import About_us from './pages/introduction_page/about_us'
import How_we_rate from './pages/introduction_page/how_we_rate'
import Privacy_rights_policy from './pages/introduction_page/privacy_rights_policy'
import Terms_of_use from './pages/introduction_page/terms_of_use'
import California_privacy_policy from './pages/introduction_page/California_privacy_policy'
import Personal_information from './pages/introduction_page/Personal_information'
import Faq from './pages/introduction_page/FAQ'
import Calculator from './pages/calculator'
import Tax_relief from './pages/financial_help_page/tax_relief'
import Tax_relief_guide from './pages/financial_help_page/tax_relief_guide'
import Tax_relief_reviews from './pages/financial_help_page/tax_relief_reviews'
import Debt_relief from './pages/financial_help_page/debt_relief'
import Credit_repair from './pages/financial_help_page/credit_repair'
//Personal页面
import ZippyLoan from './pages/loans_page/reviews_more/ZippyLoan'
import LendingForBadCredit from './pages/loans_page/reviews_more/LendingForBadCredit'
import Credible_review from './pages/loans_page/reviews_more/credible_review'
import SearchKeyLoans from './pages/loans_page/reviews_more/SearchKeyLoans'
import Personal_loans from './pages/loans_page/personal_loans'
import Personal_loans_guide from './pages/loans_page/personal_loans_guide'
import Personal_loans_reviews from './pages/loans_page/personal_loans_reviews'
import Amone from './pages/loans_page/reviews_more/Amone'
import HolidayFunded from './pages/loans_page/reviews_more/HolidayFunded'
import LoanPioneer from './pages/loans_page/reviews_more/LoanPioneer'
import GetCashReliefNow from './pages/loans_page/reviews_more/GetCashReliefNow'
//Home_warranty页面
import Home_warranty from './pages/home_services/home_warranty'
import Home_warranty_guide from './pages/home_services/home_warranty_guide'
import Home_warranty_reviews from './pages/home_services/home_warranty_reviews'
import AHS_review from './pages/home_services/reviews_more/AHS_review'
import GovHomeProgram from './pages/home_services/reviews_more/GovHomeProgram'
import ChoiceHomeWarranty from './pages/home_services/reviews_more/ChoiceHomeWarranty'
import HomeProjectPros from './pages/home_services/reviews_more/HomeProjectPros'
import HQHomeWarranty from './pages/home_services/reviews_more/HQHomeWarranty'

import Student_loans from './pages/student_loans_page/student_loans'
import Debt_consolidation from './pages/debt_consolidation_page/debt_consolidation'
import Debt_consolidation_guide from './pages/debt_consolidation_page/debt_consolidation_guide'
import Debt_consolidation_reviews from './pages/debt_consolidation_page/debt_consolidation_reviews'
import Business_loans from './pages/business_loans_page/business_loans'
import Business_loans_guide from './pages/business_loans_page/business_loans_guide'
import Business_loans_reviews from './pages/business_loans_page/business_loans_reviews'
// Mortgage页面
import Mortgage_rates from './pages/mortgages_page/mortgage_rates'
import Mortgage_rates_reviews from './pages/mortgages_page/mortgage_rates_reviews'
import Mortgage_rates_guide from './pages/mortgages_page/mortgage_rates_guide'
import Veterans_united_review from './pages/mortgages_page/reviews_more/veterans_united_review'
import HomeLightSimpleSale from './pages/mortgages_page/reviews_more/HomeLightSimpleSale'
import QuickenCompare from './pages/mortgages_page/reviews_more/QuickenCompare'
import FHARateSimplified from './pages/mortgages_page/reviews_more/FHARateSimplified'
import HarpRateQuiz from './pages/mortgages_page/reviews_more/HarpRateQuiz'
import Emortgage_review from './pages/mortgages_page/reviews_more/emortgage_review'
import HomeEquityQuiz from './pages/mortgages_page/reviews_more/HomeEquityQuiz'
//insurance页面
import Life_insurance from './pages/insurance_page/life_insurance'
import Insurance from './pages/insurance_page/insurance'
import Auto_insurance from './pages/insurance_page/auto_insurance'
import Health_insurance from './pages/insurance_page/health_insurance'
import Life_insurance_reviews from './pages/insurance_page/life_insurance_reviews'
import Auto_insurance_reviews from './pages/insurance_page/auto_insurance_reviews'
import Statefarm_auto_review from './pages/insurance_page/reviews_more/statefarm_auto_review'
import Allstate_auto_review from './pages/insurance_page/reviews_more/allstate_auto_review'
import CoverageProfessor from './pages/insurance_page/reviews_more/CoverageProfessor'
import SelectMyPolicy from './pages/insurance_page/reviews_more/SelectMyPolicy'
import Nationwide_life_insurance_review from './pages/insurance_page/reviews_more/nationwide_life_insurance_review'
import GreatLife from './pages/insurance_page/reviews_more/GreatLife'
import ProtectingFamiliesEveryday from './pages/insurance_page/reviews_more/ProtectingFamiliesEveryday'
import EasyAutoPolicy from './pages/insurance_page/reviews_more/EasyAutoPolicy'
import Smart_financial_auto_insurance_review from './pages/insurance_page/reviews_more/smart_financial_auto_insurance_review'
import GetAutoQuote from './pages/insurance_page/reviews_more/GetAutoQuote'
import EverydayLifeInsurance from './pages/insurance_page/reviews_more/EverydayLifeInsurance'
//博客文章
import Homeowners_insurance from './pages/insurance_page/homeowners_insurance'
import Can_You_Refinance_Student_Loans_More_than_Once from './pages/blog_page/blogs/Can You Refinance Student Loans More than Once'
import Personal_Loan_Mistakes_to_Avoid from './pages/blog_page/blogs/5 Personal Loan Mistakes to Avoid'
import Do_I_Qualify_for_a_Business_Loan_6_Questions_to_Ask from './pages/blog_page/blogs/Do I Qualify for a Business Loan 6 Questions to Ask'
import What_is_Debt_to_Income_Ratio_and_Why_Its_Important from './pages/blog_page/blogs/What is Debt-to-Income Ratio and Why It’s Important'
import How_Refinancing_Your_Mortgage_Could_Work_for_You from './pages/blog_page/blogs/How Refinancing Your Mortgage Could Work for You'
import Choosing_the_Right_Life_Insurance_Company_For_You from './pages/blog_page/blogs/Choosing the Right Life Insurance Company For You'
import What_is_the_Difference_Between_Term_and_Whole_Life_Insurance from './pages/blog_page/blogs/What is the Difference Between Term and Whole Life Insurance'
import Why_A_Partial_Student_Loan_Refinance_Could_Be_Perfect_For_You from './pages/blog_page/blogs/Why A Partial Student Loan Refinance Could Be Perfect For You'
import Will_Refinancing_Student_Loans_Save_Me_Money from './pages/blog_page/blogs/Will Refinancing Student Loans Save Me Money'
import What_is_Life_Insurance from './pages/blog_page/blogs/What is Life Insurance'
import Article from './pages/blog_page/article'
import Blog from './pages/blog_page/blog'
import Blog_list from './pages/blog_page/blog_list'

//Reviews详情页面
import ReviewsDetails from './pages/reviewsdetails'


Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [{
        path: '/',
        name: 'home',
        component: Home,
        redirect: '/',
        children: [{
            path: '/',
            name: 'debt',
            component: Debt
        }, {
            path: '/loans',
            name: 'loans',
            component: Loans
        }, {
            path: '/reviewsdetails',
            name: 'reviewsdetails',
            component: ReviewsDetails
        }, {
            path: '/article',
            name: 'article',
            component: Article
        }, {
            path: '/blog',
            name: 'blog',
            component: Blog
        }, {
            path: '/blog_list',
            name: 'blog_list',
            component: Blog_list
        }, {
            path: '/privacy_policy',
            name: 'privacy_policy',
            component: Privacy_policy
        }, {
            path: '/learning_center',
            name: 'learning_center',
            component: Learning_center
        }, {
            path: '/contact_us',
            name: 'contact_us',
            component: Contact_us
        }, {
            path: '/faqs',
            name: 'faqs',
            component: Faqs
        }, {
            path: '/about_us',
            name: 'about_us',
            component: About_us
        }, {
            path: '/privacy_rights_policy',
            name: 'privacy_rights_policy',
            component: Privacy_rights_policy
        }, {
            path: '/how_we_rate',
            name: 'how_we_rate',
            component: How_we_rate
        }, {
            path: '/terms_of_use',
            name: 'terms_of_use',
            component: Terms_of_use
        }, {
            path: '/California_privacy_policy',
            name: 'California_privacy_policy',
            component: California_privacy_policy
        }, {
            path: '/Personal_information',
            name: 'Personal_information',
            component: Personal_information
        }, {
            path: '/Faq',
            name: 'Faq',
            component: Faq
        }, {
            path: '/calculator',
            name: 'calculator',
            component: Calculator
        }, {
            path: '/tax_relief',
            name: 'tax_relief',
            component: Tax_relief
        }, {
            path: '/tax_relief_guide',
            name: 'tax_relief_guide',
            component: Tax_relief_guide
        }, {
            path: '/tax_relief_reviews',
            name: 'tax_relief_reviews',
            component: Tax_relief_reviews
        }, {
            path: '/personal_loans',
            name: 'personal_loans',
            component: Personal_loans
        }, {
            path: '/ZippyLoan',
            name: 'ZippyLoan',
            component: ZippyLoan
        }, {
            path: '/LendingForBadCredit',
            name: 'LendingForBadCredit',
            component: LendingForBadCredit
        }, {
            path: '/credible_review',
            name: 'credible_review',
            component: Credible_review
        }, {
            path: '/SearchKeyLoans',
            name: 'SearchKeyLoans',
            component: SearchKeyLoans
        }, {
            path: '/personal_loans_guide',
            name: 'personal_loans_guide',
            component: Personal_loans_guide
        }, {
            path: '/personal_loans_reviews',
            name: 'personal_loans_reviews',
            component: Personal_loans_reviews
        }, {
            path: '/debt_consolidation',
            name: 'debt_consolidation',
            component: Debt_consolidation
        }, {
            path: '/debt_consolidation_guide',
            name: 'debt_consolidation_guide',
            component: Debt_consolidation_guide
        }, {
            path: '/debt_consolidation_reviews',
            name: 'debt_consolidation_reviews',
            component: Debt_consolidation_reviews
        }, {
            path: '/business_loans',
            name: 'business_loans',
            component: Business_loans
        }, {
            path: '/business_loans_guide',
            name: 'business_loans_guide',
            component: Business_loans_guide
        }, {
            path: '/business_loans_reviews',
            name: 'business_loans_reviews',
            component: Business_loans_reviews
        }, {
            path: '/student_loans',
            name: 'student_loans',
            component: Student_loans
        }, {
            path: '/debt_relief',
            name: 'debt_relief',
            component: Debt_relief
        }, {
            path: '/credit_repair',
            name: 'credit_repair',
            component: Credit_repair
        }, {
            path: '/mortgage_rates',
            name: 'mortgage_rates',
            component: Mortgage_rates
        }, {
            path: '/mortgage_rates_reviews',
            name: 'mortgage_rates_reviews',
            component: Mortgage_rates_reviews
        }, {
            path: '/mortgage_rates_guide',
            name: 'mortgage_rates_guide',
            component: Mortgage_rates_guide
        }, {
            path: '/veterans_united_home_loans_review',
            name: 'veterans_united_review',
            component: Veterans_united_review
        }, {
            path: '/QuickenCompare',
            name: 'QuickenCompare',
            component: QuickenCompare
        }, {
            path: '/HomeLightSimpleSale',
            name: 'HomeLightSimpleSale',
            component: HomeLightSimpleSale
        }, {
            path: '/FHARateSimplified',
            name: 'FHARateSimplified',
            component: FHARateSimplified
        }, {
            path: '/life_insurance',
            name: 'life_insurance',
            component: Life_insurance
        }, {
            path: '/insurance',
            name: 'insurance',
            component: Insurance
        }, {
            path: '/auto_insurance',
            name: 'auto_insurance',
            component: Auto_insurance
        }, {
            path: '/health_insurance',
            name: 'health_insurance',
            component: Health_insurance
        }, {
            path: '/life_insurance_reviews',
            name: 'life_insurance_reviews',
            component: Life_insurance_reviews
        }, {
            path: '/auto_insurance_reviews',
            name: 'auto_insurance_reviews',
            component: Auto_insurance_reviews
        }, {
            path: '/statefarm_auto_review',
            name: 'statefarm_auto_review',
            component: Statefarm_auto_review
        }, {
            path: '/allstate_auto_review',
            name: 'allstate_auto_review',
            component: Allstate_auto_review
        }, {
            path: '/CoverageProfessor',
            name: 'CoverageProfessor',
            component: CoverageProfessor
        }, {
            path: '/SelectMyPolicy',
            name: 'SelectMyPolicy',
            component: SelectMyPolicy
        }, {
            path: '/nationwide_life_insurance_review',
            name: 'nationwide_life_insurance_review',
            component: Nationwide_life_insurance_review
        }, {
            path: '/GreatLife',
            name: 'GreatLife',
            component: GreatLife
        }, {
            path: '/ProtectingFamiliesEveryday',
            name: 'ProtectingFamiliesEveryday',
            component: ProtectingFamiliesEveryday
        }, {
            path: '/homeowners_insurance',
            name: 'homeowners_insurance',
            component: Homeowners_insurance
        }, {
            path: '/Can_You_Refinance_Student_Loans_More_than_Once',
            name: 'Can_You_Refinance_Student_Loans_More_than_Once',
            component: Can_You_Refinance_Student_Loans_More_than_Once
        }, {
            path: '/Personal_Loan_Mistakes_to_Avoid',
            name: 'Personal_Loan_Mistakes_to_Avoid',
            component: Personal_Loan_Mistakes_to_Avoid
        }, {
            path: '/Do_I_Qualify_for_a_Business_Loan_6_Questions_to_Ask',
            name: 'Do_I_Qualify_for_a_Business_Loan_6_Questions_to_Ask',
            component: Do_I_Qualify_for_a_Business_Loan_6_Questions_to_Ask
        }, {
            path: '/What_is_Debt_to_Income_Ratio_and_Why_Its_Important',
            name: 'What_is_Debt_to_Income_Ratio_and_Why_Its_Important',
            component: What_is_Debt_to_Income_Ratio_and_Why_Its_Important
        }, {
            path: '/How_Refinancing_Your_Mortgage_Could_Work_for_You',
            name: 'How_Refinancing_Your_Mortgage_Could_Work_for_You',
            component: How_Refinancing_Your_Mortgage_Could_Work_for_You
        }, {
            path: '/Choosing_the_Right_Life_Insurance_Company_For_You',
            name: 'Choosing_the_Right_Life_Insurance_Company_For_You',
            component: Choosing_the_Right_Life_Insurance_Company_For_You
        }, {
            path: '/What_is_the_Difference_Between_Term_and_Whole_Life_Insurance',
            name: 'What_is_the_Difference_Between_Term_and_Whole_Life_Insurance',
            component: What_is_the_Difference_Between_Term_and_Whole_Life_Insurance
        }, {
            path: '/Why_A_Partial_Student_Loan_Refinance_Could_Be_Perfect_For_You',
            name: 'Why_A_Partial_Student_Loan_Refinance_Could_Be_Perfect_For_You',
            component: Why_A_Partial_Student_Loan_Refinance_Could_Be_Perfect_For_You
        }, {
            path: '/Will_Refinancing_Student_Loans_Save_Me_Money',
            name: 'Will_Refinancing_Student_Loans_Save_Me_Money',
            component: Will_Refinancing_Student_Loans_Save_Me_Money
        }, {
            path: '/What_is_Life_Insurance',
            name: 'What_is_Life_Insurance',
            component: What_is_Life_Insurance
        }, {
            path: '/home_warranty',
            name: 'home_warranty',
            component: Home_warranty
        }, {
            path: '/home_warranty_guide',
            name: 'home_warranty_guide',
            component: Home_warranty_guide
        }, {
            path: '/home_warranty_reviews',
            name: 'home_warranty_reviews',
            component: Home_warranty_reviews
        }, {
            path: '/american_home_shield_review',
            name: 'AHS_review',
            component: AHS_review
        }, {
            path: '/ChoiceHomeWarranty',
            name: 'ChoiceHomeWarranty',
            component: ChoiceHomeWarranty
        }, {
            path: '/GovHomeProgram',
            name: 'GovHomeProgram',
            component: GovHomeProgram
        }, {
            path: '/HomeProjectPros',
            name: 'HomeProjectPros',
            component: HomeProjectPros
        }, {
            path: '/EverydayLifeInsurance',
            name: 'EverydayLifeInsurance',
            component: EverydayLifeInsurance
        }, {
            path: '/Amone',
            name: 'Amone',
            component: Amone
        }, {
            path: '/HolidayFunded',
            name: 'HolidayFunded',
            component: HolidayFunded
        }, {
            path: '/LoanPioneer',
            name: 'LoanPioneer',
            component: LoanPioneer
        }, {
            path: '/GetCashReliefNow',
            name: 'GetCashReliefNow',
            component: GetCashReliefNow
        }, {
            path: '/EasyAutoPolicy',
            name: 'EasyAutoPolicy',
            component: EasyAutoPolicy
        }, {
            path: '/smart_financial_review',
            name: 'smart_financial_auto_insurance_review',
            component: Smart_financial_auto_insurance_review
        }, {
            path: '/GetAutoQuote',
            name: 'GetAutoQuote',
            component: GetAutoQuote
        }, {
            path: '/HarpRateQuiz',
            name: 'HarpRateQuiz',
            component: HarpRateQuiz
        }, {
            path: '/emortgage_review',
            name: 'emortgage_review',
            component: Emortgage_review
        }, {
            path: '/HQHomeWarranty',
            name: 'HQHomeWarranty',
            component: HQHomeWarranty
        }, {
            path: '/HomeEquityQuiz',
            name: 'HomeEquityQuiz',
            component: HomeEquityQuiz
        }]
    }, ]
})