<template>
  <div>
    <div class="container">
      <div class="nav_img">
        <div class="nav_img_content">
          <div class="title">
            <h1>Personal Finance Satisfied</h1>
            <div class="line"></div>
            <span>At TopFinanceDirectory, we provide various finance options,comprisons and<br /> honest reviews to satisfy your personal finance needs.</span>
          </div>
          <div class="head_card_list">
            <div class="head_card">
                <router-link to="/personal_loans" class="head_card_content">
                    <img src="/imgs/home_content_icon1.png" alt="">
                    <span>Personal loans</span>
                </router-link>
            </div>
            <div class="head_card">
                <router-link to="/life_insurance" class="head_card_content">
                    <img src="/imgs/home_content_icon2.png" alt="">
                    <span>Life Insurance</span>
                </router-link>
            </div>
            <div class="head_card">
                <router-link to="/mortgage_rates" class="head_card_content">
                    <img src="/imgs/home_content_icon3.png" alt="">
                    <span>Mortgage Rates</span>
                </router-link>
            </div>
            <div class="head_card">
                <router-link to="/auto_insurance" class="head_card_content">
                    <img src="/imgs/home_content_icon4.png" alt="">
                    <span>Auto Insurance</span>
                </router-link>
            </div>
            <div class="head_card">
                <router-link to="/home_warranty" class="head_card_content">
                    <img src="/imgs/home_content_icon5.png" alt="">
                    <span>Home warranty</span>
                </router-link>
            </div>
            <div class="head_card">
                <router-link to="/calculator" class="head_card_content">
                    <img src="/imgs/home_content_icon6.png" alt="">
                    <span>Tax Relief</span>
                </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="best-choice-box">
        <div class="best-choice-son-box">
          <div class="best-choice-box-left">
            <img class="choice-box-left" src="/imgs/choice-box-left.jpg">
            <img class="home-content-bg" src="/imgs/home_content_bg.png">
          </div>
          <div class="best-choice-box-right">
            <div class="best-choic-box-right-sonleft">
              <img src="/imgs/home_content2_icon1.png">
              <div class="best-choic-box-right-imgs-line"></div>
              <img src="/imgs/home_content2_icon2.png">
              <div class="best-choic-box-right-imgs-line"></div>
              <img src="/imgs/home_content2_icon3.png">
            </div>
            <div class="best-choic-box-right-sonright">
              <h4>How TopFinanceDirectory works</h4>
              <div class="best-choic-box-right-sontext">
                <h5>Expand financial knowledge</h5>
                <p>Enable to access to a broad range of financial products, services, and educational resources.</p>
              </div>
              <div class="best-choic-box-right-sontext">
                <h5>One marketplace for every major provider</h5>
                <p>No need to shop around. Review every major provider side-by-side in one place.</p>
              </div>
              <div class="best-choic-box-right-sontext">
                <h5>Find a match and save hundreds every year</h5>
                <p>Whether you're looking to save on a financial program, we find the best value for you.</p>
              </div>
              <!-- <div class="best-choic-box-right-button">
                <p>Find Your Financial Trip</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="video-choice-box">
        <!-- <div class="bg"></div> -->
        <span class="content">Read More About TopFinanceDirectory</span>
        <!-- <img src="/imgs/player_icon.png"> -->
      </div>

      <div class="knowledge-center-box">
        <span class="knowledge-title">Recommended Reading</span>
        <div class="line"></div>
        <span class="knowledge-content"></span>
        <div class="card-list">
          <!-- 轮播图和左侧的导航菜单 -->
          <div class="swiper-box">
            <!-- 轮播图 -->
            <swiper :options="swiperOption">
              <swiper-slide v-for="(item, index) in slideList" :key="index">
                <!--<a :href="'/#/product/' + item.id">-->
                <a :href="item.url">
                  <img :src="item.img" alt="">
                </a>
                <p class="card-title">{{item.title}}</p>
                <p class="card-text">{{item.text}}</p>
              </swiper-slide>
              <!--<div class="swiper-pagination" slot="pagination"></div>-->
              <!-- <div class="swiper-button-prev" slot="button-prev">
                <img src="/imgs/icon_left.png" alt="">
              </div>
              <div class="swiper-button-next" slot="button-next">
                <img src="/imgs/icon_right.png" alt="">
              </div> -->
            </swiper>
            <div class="swiper-prev" slot="button-prev">
              <img src="/imgs/icon_left.png" alt="">
            </div>
            <div class="swiper-next" slot="button-next">
              <img src="/imgs/icon_right.png" alt="">
            </div>
            <!-- <a href="/blog_list" class="button-read">Read More</a> -->
          </div>
        </div>
      </div>

      <div class="about_us_box">
        <div class="about_us_content">
          <h2 class="title">About US</h2>
          <div class="line"></div>
          <div class="title_content">Financial decisions that work for you</div>
          <div class="bottom_content">
            <div class="bottom_left_content">
              <p>TopFinanceDirectory is an online finance marketplace for various financial knowledge including kinds of Loans and Insurance,like life,auto,health and home, Mortgage and Home services. We collect information from thousands of products and services to help you make the right choice and find the best. Our goal is to help each and every customer navigate their personal finances and achieve their version of financial stability.</p>
              <p>Confident financial decisions are attributed to your extensive financial knowledge. Our purpose is to equip people with the tools, information, and insight they need to make sound financial decisions throughout their lives. So they can quickly locate what they require and resume their lives.</p>
              <p>Everything we do is shaped by our values. They aren't just words on a page; they are lived and breathed every day.</p>
            </div>
            <div class="bottom_right_content"></div>
            <div class="m_bottom_right_content"><img src="/imgs/about_us_img.jpg" alt=""></div>
          </div>
          <div class="bottom_right_content2">
            <img src="/imgs/about_us_img2.png" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: "debt",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // 债务重整对象
      debtConsolidation: {
        id: 0,
        img_name: '',
        introduce: '',
        is_delete: false,
        minimum_debt: 0,
        pros: '',
        qualifications: '',
        require: '',
        url: ''
      },

      debtConsolidationList: [],

      // 轮播图设置
      swiperOption: {
        // autoplay: true,
        // loop: true,
        simulateTouch: true,
        allowTouchMove: true,
        // 轮播图动画
        // effect: 'cube',
        slidesPerView: 'auto',
        spaceBetween: 20,
        // 轮播图分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        // 导航
        navigation: {
          nextEl: '.swiper-prev',
          prevEl: '.swiper-next'
        }
      },

      // 轮播图列表
      slideList: [
        {
          id: '42',
          img: '/imgs/Snipaste_2022-04-20_18-17-34.png',
          title: 'Veterans United',
          text: "In addition to VA loans, Veterans United offers a wide range of lending products to service members, veterans, and qualifying reservists. Potential borrowers can quickly obtain current pricing information, contact customer support at any time of the day or night, and apply online.",
          url: "/veterans_united_home_loans_review"
        },
        {
          id: '45',
          img: '/imgs/bouquet-universal-match-v-1.jpg',
          title: 'Lending Tree',
          text: "LendingTree dismantled the Tree.com umbrella in 2015 to focus on its core brand, LendingTree, which offered business loans, personal loans, debt consolidation, free credit scores, and student loans in addition to its core mortgage offerings of house loans, mortgage refinance, ...",
          url: "/lending_tree_review"
        },
        {
          id: '46',
          img: '/imgs/ahs-hero-desktop-flipped.jpeg',
          title: 'American Home Shield',
          text: "Founded in 1971, American Home Shield (AHS) is the first home warranty provider in the U.S with more than 2 million members. Frontdoor, its parent company, offers home service plans across the country, with AHS serving as its flagship brand. Frontdoor also owns Landmark, a ...",
          url: "/american_home_shield_review"
        },
      ]
    }
  },
  methods: {
    /**
     * 获取债务合并列表
     */
    getDebtConsolidation(debt_amount) {
      this.axios.get('/debtconsolidation', {
        params: {
          debt_amount
        }
      }).then((res) => {
        console.log(res.data.result)
        this.debtConsolidationList = res.data.result
      }).catch((err) => {
        console.log(err)
      })
    },

    handleUrl(url) {
      window.location.href = url
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/base.scss';
@import '../assets/scss/config.scss';

.container {
  @include flex();

  flex-wrap: wrap;

  .nav_img {
    width: 100%;
    height: 763px;
    color: #222831;
    font-size: 18px;
    background: url(/imgs/index_bg.jpg) no-repeat;
    .nav_img_content {
      width: 61%;
      margin: 0 auto 0;
      padding: 104px 0 0;
      .title {
        h1 {
          font-size: 48px;
          color: #222831;
          line-height: 48px;
          margin-bottom: 13px;
        }
        .line {
          border: 0.5px solid #d7d7d7;
          width: 227px;
          position: relative;
          margin-left: 15px;
          margin-bottom: 35px;
        }
        .line:before {
              width: 7px;
              height: 7px;
              background: #2ac8a2;
              border-radius: 50%;
              content: '';
              display: block;
              position: absolute;
              top: -3px;
              left: -15px;
        }
        span {
          font-size: 18px;
          color: #666666;
          line-height: 24px;
        }
      }
      .head_card_list {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 85px;
          width: 675px;
          .head_card {
              margin-right: 50px;
              margin-bottom: 36px;
              .head_card_content {
                  width: 175px;
                  height: 175px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 12px;
                  font-size: 18px;
                  font-weight: 700;
                  color: #222831;
                  img {
                      width: 60px;
                      height: 60px;
                      margin-bottom: 15px;
                  }
              }
          }
          .head_card:hover {
              position: relative;
              bottom: 15px;
              box-shadow: 0px 15px 20px 5px rgba(0,0,0,0.10); 
          }
      }
    }
  }

  .best-choice-box {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 220px;
    .best-choice-son-box{
      width: 61%;
      margin: auto;
      display: flex;
      .best-choice-box-left{ 
        margin-right: 137px;
        position: relative;
        .choice-box-left {
          width: 415px;
          position:absolute;
          top: 0px;
          left:0px;
          z-index: 2;
          border-radius: 5%;
        }
        .home-content-bg {
          width: 453px;
          height: 534px;
          position: relative;
          top: 132px;
          left: -65px;
        }
      }
      .best-choice-box-right {
        display: flex;
        .best-choic-box-right-sonleft {
          width: 40px;
          height: 310px;
          margin-top: 120px;
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          img {
            width: 40px;
          }
          .best-choic-box-right-imgs-line {
            width: 1px;
            height: 40px;
            border-left: 1px solid #c4c4c4;
          }
        }
        .best-choic-box-right-sonright {
          width: 572px;
          h4 {
            font-size: 34px;
            font-family: Roboto, Roboto-Bold;
            text-align: left;
            color: #353449;
            line-height: 48px;
            position:relative;
            margin-bottom: 95px;
          }
          h4:before {
            width: 54px;
            height: 7px;
            background: #2ac8a2;
            border-radius: 12px;
            content: '';
            display: block;
            position: absolute;
            top: 60px;
          }
          .best-choic-box-right-sontext {
            margin: 55px 15px 0;
            h5 {
              font-size: 18px;
              font-family: Roboto, Roboto-Bold;
              font-weight: 700;
              text-align: left;
              color: #353449;
              line-height: 18px;
              margin-bottom: 12px;
              padding-left: 20px;
              position:relative;
            }
            h5:before {
              width: 5px;
              height: 5px;
              background: #2ac8a2;
              border-radius: 50%;
              content: '';
              display: block;
              position: absolute;
              top: 3px;
              left: -1px;
            }
            h5:after {
              width: 5px;
              height: 5px;
              background: #c4c4c4;
              border-radius: 50%;
              content: '';
              display: block;
              position: absolute;
              top: 13px;
              left: -1px;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #666666;
              line-height: 14px;
            }
          }
          .best-choic-box-right-button {
            width: 225px;
            height: 47px;
            background: #2ac8a2;
            border-radius: 50px;
            font-family: Roboto, Roboto-Bold;
            font-weight: 700;
            text-align: center;
            line-height: 47px;
            color: #fff;
            font-size: 18px;;
            margin-top: 50px;
          }
        }
      }
    }
  }

  .video-choice-box {
    width: 100%;
    height: 378px;
    background-image: url('/imgs/home_content3_bg.png');
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .content {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 36px;
      width: 100%;
      text-align: center;
      font-family: Roboto, Roboto-Regular, San Francisco Display-Bold, San Francisco Display;
      font-weight: bold;
      color: #FFFFFF;
    }

    img {
      position: absolute;
      width: 76px;
      height: 76px;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }

  }
  .knowledge-center-box {
    margin-top: 100px;
    margin-bottom: 100px;
    width: 100%;
    height: 800px;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;
    //align-items: center;
    text-align: center;

    .knowledge-title {
      display: inline-block;
      margin-bottom: 10px;
      font-size: 47px;
      font-family: Roboto, Roboto-Regular, San Francisco Display-Bold, San Francisco Display;
      font-weight: bold;
      color: #353449;
      line-height: 56px;
    }

    .line {
      width: 54px;
      height: 7px;
      margin: auto auto;
      background: #2AC8A2;
      border-radius: 12px 12px 12px 12px;
      opacity: 1;
    }

    .knowledge-content {
      display: inline-block;
      margin-top: 30px;
      margin-bottom: 233px;
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #666666;
    }

    .card-list {
      background: #F6F6F6;
      position: relative;
      height: 500px;
      
      .swiper-box {

        //--swiper-theme-color: #ff6600;/* 设置Swiper风格 */
        //--swiper-navigation-color: #00ff33;/* 单独设置按钮颜色 */
        //--swiper-navigation-size: 30px;/* 设置按钮大小 */
        position: absolute;
        top: -163px;
        left: 50%;
        transform: translateX(-50%);
        width: 1140px;
        margin: auto auto;
        .swiper-slide {
          width: 360px;
        }
        img {
          display: inline-block;
          width: 359px;
          height: 359px;
          object-fit: cover;
          border-radius: 12px 12px 12px 12px;
        }

        .card-title {
          font-size: 18px;
          font-weight: bold;
          color: #353449;
          margin-top: 15px;
          margin-bottom: 15px;
          text-align: left;
          padding-left: 20px;
          position:relative;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .card-title:before {
          width: 5px;
          height: 5px;
          background: #2ac8a2;
          border-radius: 50%;
          content: '';
          display: block;
          position: absolute;
          top: 3px;
          left: -1px;
        }
        .card-title:after {
          width: 5px;
          height: 5px;
          background: #c4c4c4;
          border-radius: 50%;
          content: '';
          display: block;
          position: absolute;
          top: 13px;
          left: -1px;
        }
        
        .card-text{
          font-size: 14px;
          width: 359px;
          text-align: justify;
        }
        .swiper-button-prev img {
          padding-left: 15px;
          width: 76px;
          height: 76px;
        }

        .swiper-button-next img {
          width: 76px;
          height: 76px;
        }

        // 消除左侧的导航按钮
        .swiper-button-prev:after, .swiper-container-rtl, .swiper-button-next:after {
          content: ''
        }
      }

      .swiper-prev {
        position: relative;
        img{
          position: absolute;
          top: -271px;
          left: -110px;
          padding-left: 15px;
          width: 76px;
          height: 76px;
        }
      }

      .swiper-next {
        position: relative;
        img {
          position: absolute;
          top: -271px;
          left: 1155px;
          width: 76px;
          height: 76px;
        }
      }

      .button-read {
        display: inline-block;
        margin-top: 40px;
        margin-bottom: 59px;
        // width: 178px;
        // height: 37px;
        padding:3px 30px;
        background: #2AC8A2;
        border-radius: 50px 50px 50px 50px;

        font-size: 18px;
        line-height: 37px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
  }
  .about_us_box {
    width: 100%;
    margin-bottom: 240px;
    .about_us_content {
      width: 61%;
      margin: 0 auto 0;
      .title {
        font-size: 48px;
        font-weight: 700;
        color: #353449;
        line-height: 48px;
        text-align: center;
        margin-bottom: 8px;
      }
      .line {
        width: 54px;
        height: 7px;
        margin: auto auto;
        background: #2ac8a2;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
      }
      .title_content {
        text-align: center;
        color: #666666;
        margin: 23px 0 70px;
      }
      .bottom_content {
        display: flex;
        .bottom_left_content {
          width: 49%;
          p {
            font-size: 14px;
            color: #000000;
            line-height: 21px;
            text-align: justify;
            margin-bottom: 25px;
          }
        }
        .bottom_right_content {
          width: 511px;
          height: 267px;
          border-radius: 12px;
          box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.20); 
          position: relative;
          top: 45px;
          left: 60px;
          z-index: 9;
        }
        .bottom_right_content:after {
          width: 511px;
          height: 267px;
          background-image: url(/imgs/about_us_img.jpg);
          background-position-y: center;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 12px;
          content: "";
          display: block;
          position: absolute;
          top: -42px;
          left: -30px;
        }
        .m_bottom_right_content {
          display: none;
        }
      }
      .bottom_right_content2 {
        position: absolute;
        right: 0;
        bottom: 100px;
        width: 239px;
        height: 651px;
      }
    }
  }
@media (max-width: 1440px) {
  .best-choice-box {
    .best-choice-son-box {
      width: 80%;
      .best-choice-box-left {
        margin-right: 0px;
      }
      .best-choice-box-right {
        .best-choic-box-right-sonright {
          width: 100%;
        }
      }
    }
  }
  .knowledge-center-box {
    .card-list {
      .swiper-box {
        .swiper-prev {
          img {
            left: -90px;
          }
        }
        .swiper-next {
          img {
            left: 1130px;
          }
        }
      }
    }
  }
}

  @media (max-width: 1199px) {
    .nav_img {
      background-size: cover;
      height: 860px;
      .nav_img_content {
        width: 90%;
      }
    }
    .best-choice-box {
      margin-bottom: 60px;
      .best-choice-son-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .best-choice-box-left {
          margin-right: 0;
        }
        .best-choice-box-right {
          padding-top: 170px;
          width: 90%;
          .best-choic-box-right-sonleft {
            margin-right: 5px;
            margin-top: 90px;
            justify-content: space-around;
          }
          .best-choic-box-right-sonright {
            h4 {
              font-size: 30px;
              margin-bottom: 0;
            }
            h4::before {
              top: 60px;
            }
            .best-choic-box-right-sontext {
              h5 {
                font-size: 20px;
                line-height: 22px;
              }
              p {
                font-size: 18px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
    .knowledge-center-box {
      .card-list {
        .swiper-box {
          width: 760px;
          left: 50%;
          transform: translateX(-50%);
          .swiper-prev {
            display: none;
          }
          .swiper-next {
            display: none;
          }
        }
      }
    }
    .about_us_box {
      margin-bottom: 200px;
      .about_us_content {
        width: 90%;
        .title_content {
          font-size: 14px;
        }
        .bottom_content {
          flex-direction: column;
          align-items: center;
          .bottom_left_content {
            width: 90%;
          }
          .bottom_right_content {
            left: 0;
            top: 60px;
          }
        }
        .bottom_right_content2 {
          display: none;
        }
      }
    }
  }
  @media (max-width: 760px) {
    .nav_img {
      height: 1000px;
      background-size: cover;
      .nav_img_content {
          .title {
            h1 {
              font-size: 30px;
            }
          }
        .head_card_list {
          width: 100%;
          justify-content: space-around;
          .head_card {
            margin: 0px 0px 25px 0;
            .head_card_content {
              width: 125px;
              height: 125px;
              img {
                width: 50px;
                height: 50px;
              }
              span {
                text-align: center;
              }
            }
          }
        }
      }
    }
    .best-choice-box {
      margin-top: 60px;
      margin-bottom: 50px;
      .best-choice-son-box {
        width: 100%;
        flex-direction: column;
        .best-choice-box-left {
          left: 30px;
          .choice-box-left {
            width: 300px;
            left: -25px;
          }
          .home-content-bg {
            width: 300px;
            height: 450px;
            top: 45px;
            left: -56px;
          }
        }
        .best-choice-box-right {
          width: 340px;
          padding-top: 55px;
          display: block;
          .best-choic-box-right-sonleft {
            display: none;
          }
          .best-choic-box-right-sonright {
            width: 100%;
            h4 {
              font-size: 23px;
            }
            .best-choic-box-right-sontext {
              p {
                line-height: 30px;
              }
            }
          }
        }
      }
    }
    .video-choice-box {
      height: 240px;
      .content {
        top: 40%;
        font-size: 25px;
      }
      img {
        top: 45%;
      }
    }
    .knowledge-center-box {
      margin-top: 35px;
      .card-list {
        .swiper-box {
          width: 360px;
          left: 50%;
          transform: translateX(-50%);
          .swiper-slide {
            width: 359px;
            .card-title:after {
              left: 7px;
            }
            .card-title:before {
              left: 7px;
            }
            .card-text {
              width: 330px;
              margin: auto;
            }
          }
          .swiper-prev {
            display: none;
          }
          .swiper-next {
            display: none;
          }
        }
      }
    }
    .about_us_box {
      margin-bottom: 285px;
      margin-top: 25px;
      .about_us_content {
        .bottom_content {
          .bottom_left_content {
            p {
              line-height: 30px;
            }
          }
          .bottom_right_content {
            display: none;
            
          }
          .m_bottom_right_content {
            display: block;
            width: 350px;
            height: 211px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}


</style>
