<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">American Home Shield</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty' }">Home Warranty</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/home_warranty_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>American Home Shield</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>American Home Shield</h1>
                          <img src="../../../../public/imgs/reviews_list/american_home_shield_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is American Home Shield?</h2>
                          <p>Founded in 1971, <a href="https://www.ahs.com/">American Home Shield (AHS)</a> is the first home warranty provider in the U.S with more than 2 million members. Frontdoor, its parent company, offers home service plans across the country, with AHS serving as its flagship brand. Frontdoor also owns Landmark, a top-rated home warranty company, as well as HSA and OneGuard. According to Frontdoor, AHS employs over 17,000 contractors across the United States.</p>
                          <p>Thie plans include parts for up to 23 appliances and systems, and if they are unable to repair your covered item, they will replace it.</p>
                          <h2 class="anchor_h2">American Home Shield Plans & Coverage</h2>
                          <p>American Home Shield provides three coverage plans to homeowners who want to protect themselves from unexpected repair or replacement costs.Here's an in-depth look at American Home Shield's coverage options:</p>
                          <ul class="text1">
                              <li><span class="text_bold">ShieldSilver Plan: T</span>he ShieldSilver Plan protects your home's 14 major systems. This is an excellent safeguard for critical systems such as air conditioning, hot water heaters, electrical systems, and plumbing.</li>
                              <li><span class="text_bold">ShieldGold Plan: </span>The ShieldGold Plan adds 9 major home appliances to the ShieldSilver Plan, including refrigerators, dishwashers, ovens, garbage disposals, and clothes washers and dryers.</li>
                              <li><span class="text_bold">ShieldPlatinum Plan: </span>The ShieldPlatinum Plan adds $1,500 of roof-leak coverage, $1,000 of coverage for code violations and permits, and unlimited coverage to the ShieldGold Plan.</li>
                          </ul>
                          <p>American Home Shield allows customers to purchase the following optional add-ons for any selected plan:</p>
                          <ul class="text1">
                              <li>Electronic extended warranty</li>
                              <li>Guest unit under 750 square feet</li>
                              <li>Pool and inground spa</li>
                              <li>Septic pump</li>
                              <li>Well pump</li>
                          </ul>
                          <h2 class="anchor_h2">American Home Shield Reviews</h2>
                          <img src="../../../../public/imgs/reviews_list/american_home_shield_review_2.jpg" alt="">
                          <p><a href="https://www.ahs.com/">American Home Shield</a> is accredited by the BBB and holds a B rating from the organization. The reviews for American Home Shield, like any other top-rated home warranty provider, are a good mix of positive and negative consumer feedback. Positive ratings emphasize AHS personnel' professionalism, while unfavorable reviews emphasize a lack of communication between AHS and authorized contractors.</p>
                          <p>Here are a few examples of American Home Shield customer reviews:</p>
                          <h3>JACK - ATLANTA, GA</h3>
                          <p>Apr 12, 2022</p>
                          <p>The appliance repair company used was excellent. Prompt and knowledgeable and very pleasant. Got needed part very quickly, repaired and explained very well. Hope you'll continue to use them.</p>
                          <h3>JAFFAR0215</h3>
                          <p>Apr 11, 2022</p>
                          <p>American home shield is the best insurance for your home warranty, the will take care of the repairs. I have been using them for many years in 2 different house and they never fail me. I had different insurance before and the fail me every time. I highly recommend AHS you will be in good hands.</p>
                          <h3>CINDY - BALTIMORE, MD</h3>
                          <p>Apr 11, 2022</p>
                          <p>I have used <a href="https://www.ahs.com/">American Home Shield</a> twice this year and both times the companies that they provided were both excellent and resolved my issue right away. American Home Shield is professional and reliable. They also answer my questions right away. I have recommended them to my friends & family and I will continue to do so! Thanks for providing great customer service!</p>
                          <h3>DOLORES - DESERT HOT SPRINGS, CA</h3>
                          <p>Apr 10, 2022</p>
                          <p>Every time that they’ve come to my house they’re on time if it doesn’t work they come back again which sometimes it happens they’re all very pleasant the last gentleman they came had to get a Part he went down to Home Depot got the part came right back with it I can’t say enough good about American shield I’ve only been with them six months they have replaced my dishwasher they fixed my refrigerator and now they fixed my swamp cooler as far as I’m concerned it doesn’t get any better than that I am a single ladies by myself I recommend all single ladies get American shield.</p>
                          <h2 class="anchor_h2">Conclusion</h2>
                          <p>American Home Shield is a seasoned home warranty provider with a number of flexible plan options that cover major appliance and system repairs and replacements. Our best home warranty business features substantial coverage limits, numerous service fee options, and a no-cost cancellation period. One disadvantage is that the provider is not available in Alaska, and service fixes are only guaranteed for 30 days.</p>
                          <p>It also has a solid reputation in the market, as seen by the numerous favorable American Home Shield home warranty reviews and years of experience.</p>
                          <p>Fill out this simple online form or call 844-529-9298 to get your unique estimate from <a href="https://www.ahs.com/">American Home Shield</a>.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is American Home Shield?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">American Home Shield Plans & Coverage</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">American Home Shield Reviews</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Conclusion</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>