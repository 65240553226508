<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Lending Tree</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Zippy Loan</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Zippy Loan</h1>
                          <img src="../../../../public/imgs/reviews_list/lending_tree_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is Zippy Loan？</h2>
                          <p>Zippy Loan means any written loan, loan agreement, note or borrowing arrangement (including leases, credit enhancements, guarantees and interest bearing assets) to which United Bank or United Bank, Inc. is party as a creditor.</p>
                          <h2 class="anchor_h2">Why Choose Zippy Loan？</h2>
                          <p>Local Faces. Expert Advice.Our knowledge of the local market, ability to offer custom solutions, and commitment to personal service are just a few reasons why our clients choose United Home Loans.We continue to grow by exceeding expectations every day.</p>
                          <p>The mortgage process can be complicated. We make it better by knowing more than other lenders and sharing that knowledge with clients. </p>
                          <h2 class="anchor_h2">How does Zippy Loan？</h2>
                          <ul class="text3">
                            <li>1. We Find The Best People. You need to have the right people to consult clients on how to best structure their mortgage. We find them and show them the UHL way.</li>
                            <li>2. Along With Tools To Make The Process Easier. In-house underwriting, local appraisers, digital applications. All of these things help give our clients a quick closing and a seamless loan process.</li>
                            <li>3. And Give Them The Best Resources. We educate. We empower people to make decisions. We also have fun. Needless to say, our employees tend to stay awhile.</li>
                            <li>4. To Create Responsible Homeowners For Life. With the best loan program and rate, our clients can focus on making their house a home knowing we have always have their backs.</li>
                          </ul>
                          <h2 class="anchor_h2">What is the Pros and Cons?</h2>
                          <p>Pros：</p>
                          <ul class="text3">
                            <li>Multipurpose</li>
                            <li>Adequate loan quantum</li>
                            <li>Quick processing</li>
                            <li>No security requirements</li>
                            <li>Simple documentation</li>
                            <li>Flexible term</li>
                          </ul>
                          <p>Cons：</p>
                          <ul class="text3">
                            <li>High processing fee</li>
                            <li>High interest rate</li>
                            <li>Strict eligibility criteria</li>
                            <li>Repayment rigidity</li>
                          </ul>
                          <h2 class="anchor_h2">Frequently Asked Questions：</h2>
                          <p>What will my mortgage payments include？</p>
                          <p>For most borrowers, each monthly mortgage payment includes:</p>
                          <ul class="text3">
                            <li>Principal, which is the total outstanding balance of the loan</li>
                            <li>Interest, which is the cost of borrowing money</li>
                            <li>Taxes, which are levied on the property by the local government</li>
                            <li>Insurance, which protects the owner and the lender from losses caused by fire and natural hazards.</li>
                          </ul>
                          <p>What is a mortgage check-up?</p>
                          <ul class="text3">
                            <li>Similar to a health check-up, a mortgage check-up reveals the</li>
                            <li>overall financial health of your current mortgage and other</li>
                          </ul>
                          <p>household debts. Like most families, your mortgage is probably your largest debt and your home equity is possibly your largest investment. Therefore, it makes sense to evaluate your mortgage annually. Call us for a no obligation mortgage check-up and see if you can save.</p>
                          <p>What will a lender look at when I apply for a mortgage？</p>
                          <p>Lenders consider many factors in evaluating your loan application, but they usually focus on four areas:</p>
                          <p>Income and debt: How much money you make and what other bills you have to pay helps the lender determine whether you can afford to make mortgage payments.</p>
                          <p>Assets: The lender needs to make sure you have enough money to cover the costs of buying a home.</p>
                          <p>Credit: Whether you've met other financial obligations helps the lender predict whether you will repay your mortgage.</p>
                          <p>Property: The home you want to buy has to be worth enough to act as collateral for the mortgage.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is Zippy Loan？</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Why Choose Zippy Loan？</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">How does Zippy Loan？</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">What is the Pros and Cons?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Frequently Asked Questions：</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>