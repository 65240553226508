<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Reading Center</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_list_container">
                <div class="article_list">
                    <h2>More high-yield savings options:</h2>
                    <div class="article">
                        <router-link to="/Personal_Loan_Mistakes_to_Avoid" class="one_article">
                            <img src="/imgs/blog_imgs/5 Personal Loan Mistakes to Avoidblog_1.jpg" alt="">
                            <h2>5 Personal Loan Mistakes to Avoid</h2>
                            <p>Getting approved for a personal loan isn't always easy, and there are some pitfalls you'll want to avoid in the process. Let's take a look at five of the most common ones so you can stay financially responsible while putting money in your pocket.</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/Can_You_Refinance_Student_Loans_More_than_Once" class="one_article">
                            <img src="/imgs/blog_imgs/Can You Refinance Student Loans More than Onceblog_1.jpg" alt="">
                            <h2>Can You Refinance Student Loans More than Once</h2>
                            <p>Whether it's your student loans, a mortgage, or a personal loan, refinancing can be a smart move that has the potential to save you money on your existing loans by lowering payments or securing a lower interest.</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/Choosing_the_Right_Life_Insurance_Company_For_You" class="one_article">
                            <img src="/imgs/blog_imgs/Choosing the Right Life Insurance Company For Youblog_1.jpg" alt="">
                            <h2>Choosing the Right Life Insurance Company For You</h2>
                            <p>Choosing any kind of insurance, whether it's health insurance, car insurance, life insurance, or any other type, really, can be a challenger to navigate if you've never done it before!</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/Do_I_Qualify_for_a_Business_Loan_6_Questions_to_Ask" class="one_article">
                            <img src="/imgs/blog_imgs/Do I Qualify for a Business Loan 6 Questions to Askblog_1.jpg" alt="">
                            <h2>Do I Qualify for a Business Loan 6 Questions to Ask</h2>
                            <p>There are 28 million small businesses in the United States alone. Many of these businesses will require a loan at some point, be it to get started, purchase equipment, or to help keep their doors open. Whether it is a brick and mortar business or completely online, it does not matter. Almost every successful business has taken out some sort of</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/How_Refinancing_Your_Mortgage_Could_Work_for_You" class="one_article">
                            <img src="/imgs/blog_imgs/How Refinancing Your Mortgage Could Work for Youblog_1.jpg" alt="">
                            <h2>How Refinancing Your Mortgage Could Work for You</h2>
                            <p>When you were younger, you thought your student loan was the biggest decision you'd ever make. Fast forward a few years, and you've made a lot more substantial choices, including buying a house and taking out a mortgage. Faced with this decision, all those other choices pale in comparison. Yes, it's no exaggeration to say that taking out a mortgage may be the biggest decision anyone makes in their life, certainly their most prominent financial decision up to this point.</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/What_is_Debt_to_Income_Ratio_and_Why_Its_Important" class="one_article">
                            <img src="/imgs/blog_imgs/what-is-debt-to-income-ratio-and-why-its-important_blog_1.jpg" alt="">
                            <h2>What is Debt-to-Income Ratio and Why It’s Important</h2>
                            <p>Keeping your debt at reasonable levels is a cornerstone of financial strength. Reducing your debt allows you to enjoy a larger percentage of your income and wastes less of your money on interest.</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/What_is_Life_Insurance" class="one_article">
                            <img src="/imgs/blog_imgs/What is Life Insuranceblog_1.jpg" alt="">
                            <h2>What is Life Insurance</h2>
                            <p>Whether it's payments on our car, mortgage, or student loans—I think we can all agree: bills aren't fun, but they're certainly worth it, right?</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="/What_is_the_Difference_Between_Term_and_Whole_Life_Insurance" class="one_article">
                            <img src="/imgs/blog_imgs/What is the Difference Between Term and Whole Life Insuranceblog_1.jpg" alt="">
                            <h2>What is the Difference Between Term and Whole Life Insurance</h2>
                            <p>Shopping for life insurance can feel like a daunting task, with all the providers and all the coverage options to think through, but it's actually more straightforward than you think once you have some of the basic terminologies down.</p>
                            <span>Read more »</span>
                        </router-link>
                        <router-link to="Will_Refinancing_Student_Loans_Save_Me_Money" class="one_article">
                            <img src="/imgs/blog_imgs/Will Refinancing Student Loans Save Me Moneyblog_1.jpg" alt="">
                            <h2>Will Refinancing Student Loans Save Me Money</h2>
                            <p>Refinancing your student loans is a financial strategy that can save you money in the long-term. The sooner you refinance, the better!</p>
                            <span>Read more »</span>
                        </router-link>
                    </div>
                    <!-- <div class="pagination">
                        <el-pagination
                            small
                            background
                            layout="prev, pager, next"
                            :total="1000">
                        </el-pagination>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog_list",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
        }
    }
    .article_list_container {
        width: 100%;
        .article_list {
            width: 61%;
            margin: 50px auto 220px;
            h2 {
                font-weight: 700;
                font-size: 48px;
                line-height: 48px;
                color: #222831;
                margin-bottom: 60px;
            }
            .article {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                .one_article {
                    width: 31%;
                    margin-bottom: 80px;
                    img {
                        width: 100%;
                        height: 146px;
                    }
                    h2 {
                        font-size: 18px;
                        font-weight: 700;
                        color: #353449;
                        line-height: 18px;
                        margin: 40px 0 15px 0;
                        padding-left: 20px;
                        position: relative;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    h2:before {
                        width: 5px;
                        height: 5px;
                        background: #2ac8a2;
                        border-radius: 50%;
                        content: '';
                        display: block;
                        position: absolute;
                        top: 3px;
                        left: -1px;
                    }
                    h2:after {
                        width: 5px;
                        height: 5px;
                        background: #c4c4c4;
                        border-radius: 50%;
                        content: '';
                        display: block;
                        position: absolute;
                        top: 13px;
                        left: -1px;
                    }
                    p {
                        text-align: justify;
                        color: #666666;
                        line-height: 14px;
                        font-size: 14px;
                        margin-bottom: 10px;
                        height: 56px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    span {
                        color: #2684ff;
                        line-height: 12px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_list_container {
            .article_list {
                width: 95%;
                margin: 0px auto 220px;
                h2 {
                    text-align: left;
                    padding-left: 40px;
                    font-size: 40px;
                }
                .article {
                    justify-content: space-around;
                    .one_article {
                        width: 340px;
                    }
                }
            }
        }
    }
    @media (max-width: 760px) {
    }
}
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
    .pagination >>> .el-pagination {
        text-align: center;
    }
</style>