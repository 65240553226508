<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Select Home Warranty</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans' }">Personal Loans</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/personal_loans_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Select Home Warranty</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Select Home Warranty</h1>
                          <p>Select House Warranty, founded in 2012, provides a variety of home warranty programs for homeowners, realtors, and real estate investors. All 50 states and the District of Columbia have policies available. Home warranties are available for single-family houses, mobile homes, rental properties, condos, and multi-unit properties.</p>
                          <p>One of the most attractive benefits to choosing a Select Home Warranty plan is its discount option – you may receive $150 off a plan if you pay annually, an additional two months for free, and free roof coverage.</p>
                          <h2>Select Home Warranty Plans</h2>
                          <p>Select Home Warranty has three distinct plans to choose from. It features three plans: one for home systems, one for appliances, and one for both. We've broken out what each of these plans offers in more detail below.</p>
                          <ul class="text1">
                              <li><span class="text_bold">Bronze Care</span><br />The Select Bronze Care home warranty plan covers your clothes washer and dryer, refrigerator, garbage disposal, dishwasher, built-in microwave, oven, stove, and cooktop. This plan doesn't cover systems like heating, cooling, electrical, water heater, plumbing and stoppages, garage door opener, ductwork, and ceiling fans.</li>
                              <li><span class="text_bold">Gold Care</span><br />Select's Gold Care home warranty plan covers water heaters, ductwork, plumbing, electrical, and cooling and heating systems. The Gold Care package doesn’t include garage door openers, clothes washers and dryers, ceiling fans, garbage disposals, plumbing stoppages, refrigerators, cooktops, built-in microwaves, ovens, or stoves.</li>
                              <li><span class="text_bold">Platinum Care</span><br />Select’s Platinum Care home warranty plan includes systems and appliances from both of the previous plans. This includes air conditioning and cooling, heating systems, plumbing, plumbing stoppages, electrical, water heater, ductwork, clothes washer and dryer, garbage disposal, refrigerator, built-in microwave, garage door opener, ceiling fan, and stove, oven, and cooktop coverage.</li>
                          </ul>
                          <p>Plumbing fixtures, lighting fixtures, restricted roof leaks for single-family homes only, pool/spa equipment, well pumps, sump pumps, central vacuums, second refrigerators, stand-alone freezers, lawn sprinkler systems, septic systems, and refrigerator ice makers are all subject to an additional charge. You can call the provider for a free quote to get an idea of the cost for your home.</p>
                          <h2>Select Home Warranty Costs</h2>
                          <p>Each add-on item has a charge associated with it. Add-on pricing for Select Home Warranty is $3.33-$8.33 per month, or $39.96-$99.96 per year. Many home warranty companies charge $180 or more per year for coverage on more expensive items, such as pool coverage, while Select Home Warranty prioritizes policies that are affordable to everyone. Select Home Warranty considers its free roof leak coverage an add-on, so you'll have to add it to a plan when you sign up, but the website says it's $0 per month.</p>
                          <h2>Select Home Warranty Reviews</h2>
                          <div style="overflow:auto;width:100%">
                            <table border="1" rules="all" width="100%">
                                    <tr>
                                        <th>Feature</th>
                                        <th>Bronze Care Plan</th>
                                        <th>Gold Care Plan</th>
                                        <th>Platinum Care Plan</th>
                                    </tr>
                                    <tr>
                                        <td>Plan Price</td>
                                        <td>$60.42/month or $629.99/year</td>
                                        <td>$60.42/month or $629.99/year</td>
                                        <td>$60.42/month or $629.99/year</td>
                                    </tr>
                                    <tr>
                                        <td>Plumbing System</td>
                                        <td></td>
                                        <td>√</td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Electrical System</td>
                                        <td></td>
                                        <td>√</td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Heating System</td>
                                        <td></td>
                                        <td>√</td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Ductwork</td>
                                        <td></td>
                                        <td>√</td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Air Conditioning</td>
                                        <td></td>
                                        <td>√</td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Water Heater</td>
                                        <td></td>
                                        <td>√</td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Refrigerator</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Clothes Washer</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Clothes Dryer</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Oven/Stove</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Cooktop</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Dishwasher</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Built-in Microwave</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Garbage Disposal</td>
                                        <td>√</td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Plumbing Stoppages</td>
                                        <td></td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Garage Door Opener</td>
                                        <td></td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                                    <tr>
                                        <td>Ceiling Fan</td>
                                        <td></td>
                                        <td></td>
                                        <td>√</td>
                                    </tr>
                            </table>
                          </div>
                          <p>Existing customer reviews for Select Home Warranty are largely balanced, with a mix of good and negative feedback. On its BBB webpage, the company has a 1.1 average customer review rating based on over 800 customer reviews. The company has a higher rating on Trustpilot, with a 3.5 average rating based on over 600 reviews.</p>
                          <p>Positive Select Home Warranty reviews generally highlight the company's reasonable costs and competent customer service, while negative reviews highlight communication issues and claim denials. Consider the following examples of positive and bad reviews.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)" :class="{active: active===0}">American Home Shield Plans & Coverage</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)" :class="{active: active===1}">Conclusion</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>