<template>
    <div>
        <div class="container">
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>Privacy Policy</h2>
                        </div>
                        <div class="text_avatar">
                            <div class="avatar_information">
                                <div>Effective date: January 1, 2023</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/privacy_policy_text_img.png" alt="">
                            <p>Effective date: January 1, 2023</p>
                            <h2>Introduction and overview</h2>
                            <p>At TopFinanceDirectory, our mission is to provide you with clarity for all of your life’s financial decisions. We do so by empowering you with financial information and guidance you can trust and by providing you with tools to help you make the best decisions for your financial needs.</p>
                            <p>When you input your personal financial information into our websites and other applications, we have an obligation to provide you with clarity regarding how your data is accessed and used by TopFinanceDirectory, its affiliates, vendors, partners and other third party entities within the value chain.</p>
                            <p>To that end, this Privacy Policy describes the information we collect from you, how we use your information, whom we share your information with, and the steps we take to protect your information.</p>
                            <h2>Scope of this Privacy Policy</h2>
                            <p>This Policy applies to your access and use of all products and services that are made available by TopFinanceDirectory, Inc. or its affiliates, including Fundera, Inc. (the “TopFinanceDirectory Services” or “Services”) through our websites, including but not limited to www.TopFinanceDirectory.com and our downloadable mobile applications (collectively, the “Site”).</p>
                            <p>By using the Services, you consent to the privacy practices described in this Policy.</p>
                            <p>Most of our data collection, use, and sharing practices are governed by the Gramm-Leach-Bliley Act. Please see our Gramm-Leach-Bliley Privacy Notice for a summary of privacy options related to most of our Services. Capitalized terms that are not defined in the Privacy Policy have the meaning given to them in the <a href="/terms_of_use">TopFinanceDirectory Terms of Use</a>.</p>
                            <h2>I. Information We Collect</h2>
                            <p><strong>A. INFORMATION WE COLLECT DIRECTLY FROM YOU</strong></p>
                            <p>We may collect information directly from you when you use our Services — for example, when you register to become a member, when you request to prequalify for a third-party credit card or loan product, when you request your TransUnion credit report and/or score, when you link your third-party financial accounts, or when you fill out calculators, forms or other fields on our Site. In such cases, we may ask you to provide us with one or more of the following pieces of information:</p>
                            <ul>
                            <li>Name.</li>
                            <li>Email address.</li>
                            <li>Mailing address.</li>
                            <li>Phone number.</li>
                            <li>Date of birth.</li>
                            <li>Social Security number.</li>
                            <li>Your registration information, such as a username and password.</li>
                            <li>Your third-party financial account information, such as an ID and password.</li>
                            <li>Your financial information, such as investments, income, expenses, investable assets, investing risk tolerance or other similar types of information.</li>
                            <li>Information about your job or business.</li>
                            <li>Age, gender, or other similar demographic information when such information is relevant to a particular financial product or service you have requested (for example, when requesting auto insurance rates or using retirement planning calculators).</li>
                            <li>When you communicate with us, either by email, phone, or other means of communication, we may also collect the content of these communications, including but not limited to recordings of phone calls where permitted by law.</li>
                            </ul>
                            <p>Please note: We do not store your third-party financial account ID or passwords. Instead, we securely pass this information to our third-party partners when you request Services that require us to confirm your identity, when you request to prequalify for their products, and/or to provide you with access to your financial information on our Site.</p>
                            <p><strong>B. INFORMATION AUTOMATICALLY COLLECTED&nbsp;</strong></p>
                            <p><strong>1. Information Collected</strong></p>
                            <p>When you visit the Site, use the Services, or open an email from TopFinanceDirectory, we may automatically receive and record certain information from your computer, web browser and/or mobile device, including without limitation:</p>
                            <ul>
                            <li>IP address or other device address or ID.</li>
                            <li>Web browser and/or device type.</li>
                            <li>Geolocation data, including if you use the Site on a mobile device.</li>
                            <li>Hardware and software settings and configurations.</li>
                            <li>Web pages or sites that you visit just before or just after visiting the Site.</li>
                            <li>Pages you view on the Site.</li>
                            <li>Your actions on the Site, including the electronic path you take to our Site, through our Site and when exiting our Site, as well as your usage and activity on our Site, such as the links and objects you view, click or otherwise interact with (also known as “Clickstream Data”).</li>
                            <li>Dates and times that you visit, access, or use the Services.</li>
                            </ul>
                            <p><strong>2. Cookie Information&nbsp;</strong></p>
                            <p>When you use the Services, we may send one or more Cookies (which are small text files containing a string of alphanumeric characters) to your computer or mobile device.&nbsp; We may use both Session Cookies and Persistent Cookies.&nbsp; A Session Cookie disappears after you close your browser. A Persistent Cookie remains after you close your browser and may be used by your browser on subsequent visits to the Services. Persistent Cookies can be removed.&nbsp; Please review your web browser “Help” file to learn the proper way to modify your Cookie settings. If you delete, or choose not to accept, Cookies from the Services, you may not be able to utilize the features of the Services to their fullest potential.</p>
                            <p><strong>3. Third-Party Web Beacons and Third-Party Buttons</strong></p>
                            <p>We may implement third-party advertising or other content on the Site that may use clear GIFs or other forms of web beacons, which allow the third-party content provider to read and write Cookies to your web browser in connection with your viewing of the third-party content on the Site. Additionally, we may implement third-party buttons (such as Facebook “like” or “share” buttons) that may allow a third party to collect information about you through their browser Cookies, even when you do not interact with the button.</p>
                            <p>We also engage third-party partners and vendors like Google Analytics, Hotjar and others, who provide analytics and advertising services, to assist us in providing you the Site and our Services. We allow these third parties, including nonaffiliated business partners, advertising networks, analytics providers, and other advertising service providers, to automatically collect Clickstream Data and other information about your online activities on the Site and across your browsers and devices using cookies, web beacons, mobile advertising identifiers and other technologies. For example, we may use third-party analytics tools to help us understand and record the electronic path you take to our Site, through our Site and when exiting our Site, as well as your usage and activity on our Site, such as the links and objects you view, click or otherwise interact with. We use this data for our own analytics and advertising purposes, as well as to facilitate partner relationships and your interactions with other third parties. In addition, certain of our third-party partners may use this information to display online advertisements tailored to your interests and preferences across your browsers and devices, to conduct ad campaign measurement and website analytics, to detect, prevent and report fraud, or to carry out their own business and commercial purposes.</p>
                            <p>Where information collected through web beacons, buttons, and similar technologies is collected directly by third parties, their collection and use of information is subject to that third party’s own privacy policies. Some of these third parties are members of the Network Advertising Initiative or the Digital Advertising Alliance and participate in the industry’s opt out, available at <a href="http://optout.aboutads.info/#!/">http://optout.aboutads.info/#!/</a> or at <a href="http://optout.networkadvertising.org/#!/">http://optout.networkadvertising.org/#!/</a>. Additionally, you may opt out of the use of Google Analytics Cookies here: <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.</p>
                            <p>Due to differences between using websites and apps on mobile devices, you may need to take additional steps to opt out on both platforms.&nbsp; Many mobile devices allow you to opt out of interest-based advertising for mobile apps using the settings within the mobile app or your mobile device. For more information, please check your mobile settings. You also may uninstall our app using the standard uninstall process available on your mobile device or app marketplace. Please note that your opt-out choice will apply only to the browser and device you are using when you opt out.</p>
                            <p><strong>C. INFORMATION FROM OTHER SOURCES&nbsp;</strong></p>
                            <p>We may also obtain information about you from third-party sources, such as our business partners, referral partners or advertisers. We may also, at your direction, receive credit reports or scores from consumer reporting agencies such as TransUnion, Experian, or Equifax, or third-party services that provide a mechanism to share information you have provided to the third party through the use of an application program interface (API), such as Facebook Connect or the Twitter API. If we combine or associate information from other sources with your information that we collect through the Services, we will treat the combined information in accordance with this Privacy Policy, the Terms, and applicable law.</p>
                            <p><strong>1.&nbsp;Link My Account</strong></p>
                            <p>Link My Account is a feature that allows you to track all of your third-party financial accounts on TopFinanceDirectory’s website. By using the Link My Account Service, you grant TopFinanceDirectory and its third-party service provider, Plaid, Inc., permission to access your third-party financial accounts designated by you. We may use your account information to evaluate your eligibility for and recommend other products or services and to modify or develop our products and services to better serve you. By using the Link My Account Service, you agree to the Plaid End User Privacy Policy.</p>
                            <h2>II. How We Use Your Information</h2>
                            <p>Your information is an integral part of our operations, and we use it in a variety of ways in providing the Services and operating our business. We use the information you provide, for example:</p>
                            <p><strong>A.&nbsp;</strong>To provide you the Services you request and enable non-affiliated third parties to provide additional services to you.</p>
                            <p><strong>B.</strong> To operate, maintain and improve the Services and create new features and functionality.</p>
                            <p><strong>C.</strong> To understand and analyze usage trends and preferences of our users across different devices.</p>
                            <p><strong>D.</strong> For fraud detection and information security.</p>
                            <p><strong>E.</strong> We may use your e-mail address or other personal information (a) to contact you for administrative purposes such as customer service, (b) to address intellectual property, right of privacy or defamation issues related to content you have posted on the Services, and/or (c) to send you promotional materials, offers, and/or messages related to the Services and the activities of third parties we work with. Generally, you can opt out of receiving promotional communications, through links provided in the messages, by updating your account preferences through the Services, or by contacting us directly at&nbsp;<a href="mailto:demand@samsonads.com">demand@samsonads.com</a>.</p>
                            <p><strong>F.</strong> We may use Cookie Information and Automatically Collected Information to: (a) personalize our services, such as remembering your information so that you will not have to re-enter it during your visit or the next time you visit the Services; (b) pre-fill forms; (c) provide customized advertisements, content, and information across your devices; (d) monitor and analyze the effectiveness of Services and third-party marketing activities; (e) monitor aggregate site usage metrics such as total number of visitors and pages viewed; and (f) track your entries, submissions, and status in any promotions or other activities.</p>
                            <p><strong>G.</strong> If you request access to your consumer credit reports, we may use it (1) to provide you with access to your free credit score, including periodic updates to your credit score, and (2) to make recommendations regarding financial products and services that may be of interest to you, including credit cards, personal loans, home loans and other financial products and services offered by TopFinanceDirectory partners.</p>
                            <h2>III. How We Share Your Information</h2>
                            <p><strong>A.</strong>&nbsp;We may disclose your information in connection with providing the Services and the operation of our business. For example, we may share personal information, such as name, email, address, Social Security number and other identifying information, with nonaffiliated third-party service providers to verify your identity in connection with your use of certain aspects of the Services. For example, we may ask for your Social Security number, or a portion of it, to verify your identity in connection with services that enable you to see your current credit score or other financial information.</p>
                            <p><strong>B.</strong>&nbsp;We may share information collected directly from you with nonaffiliated third-party lending partners or third-party service providers in connection with your use of specific aspects of the Services. Some examples include but are not limited to:</p>
                            <p><strong>1.</strong> When you request to prequalify for a third-party loan or credit card product on our Site.</p>
                            <p><strong>2.</strong> When you request additional or personalized information regarding financial product offers from third-party financial service aggregators we partner with.</p>
                            <p><strong>3.</strong> When you request access to your credit report or score.</p>
                            <p><strong>4.</strong> When you request access to your third-party banking, credit card, retirement or investment products on our Site.</p>
                            <p><strong>5.</strong> When you report a complaint about a product or a third-party lending partner featured on our Site; we may forward your complaint to that lending partner.</p>
                            <p><strong>6.</strong> When you request answers or advice through our Services, TopFinanceDirectory may provide certain of your information to third-party experts, advocates, and advisors.</p>
                            <p><strong>C.</strong> We may share automatically collected information such as IP address and Clickstream Data as well as de-identified information with nonaffiliated third-party partners when you leave our Site (for example, from a rate table or review) to go to a third-party site to request information or apply for financial products.</p>
                            <p><strong>D.</strong> We may share your credit report and other personal information with and/or among our wholly owned TopFinanceDirectory subsidiaries in order to provide you with recommendations on financial products and services that might be of interest to you.</p>
                            <p><strong>E.</strong> We may share your information with third-party service providers to support our internal and business operations. In such cases, service providers may use the information only as necessary to provide the services to us and are contractually required to keep your information confidential and secure. For example:</p>
                            <p><strong>1.</strong> For website, application development, hosting, data storage, maintenance, and other services for us.</p>
                            <p><strong>2.</strong> For internal marketing, delivery of emails, including promotional, marketing and transactional materials, delivery of other promotional materials, including via postal mailers, SMS and text messaging, and online and social media promotions and advertising.</p>
                            <p><strong>3.</strong> For fraud prevention and security purposes.</p>
                            <p><strong>F.</strong> When you choose to share your own information on a publicly accessible area of the Site, such as a public profile page or any section where you can publicly submit questions to us or our third-party partners, that information will be available to anyone who is able to access that content, including other Site users. Additionally, questions you submit and answers provided by third parties may be posted on our Site and viewable by other users and the public.</p>
                            <p><strong>G.</strong> We may share de-identified and/or aggregate analytics with third-party partners about how users interact with our Services, including usage patterns for certain programs, content, services, advertisements, promotions, and/or functionality available through the Service.</p>
                            <p><strong>H.</strong> We may disclose your information if required to do so by law or in the good-faith belief that such action is necessary to comply with state and federal laws (such as copyright law), in response to a court order, judicial or other government subpoena or warrant, or to otherwise cooperate with law enforcement activity.</p>
                            <p><strong>I.</strong>&nbsp;By submitting a request through our Services, you consent to the disclosure of your information to third parties in order for us to carry out your request. The collection and use of your information by third parties is subject to each third party’s specific privacy policy and terms of use. TopFinanceDirectory has no control over the means by which third parties further use or disclose your information.</p>
                            <p><strong>J.</strong> If you submit a request through our Services, a third-party service provider may directly contact you with quotes or information via telephone, auto-dialed prerecorded calls, SMS, fax, and/or email. You may also receive telemarketing calls as a result of submitting the request, even if you are on the National Do Not Call Registry or any other similar registry.</p>
                            <p><strong>K.</strong> Do not submit a request for information or services if you do not want your personal information shared as described above. Once you have submitted a request through the Services and we have shared your information as described above, the terms of the third party’s privacy policy will apply to that party’s use of your information.</p>
                            <p><strong>L.</strong> You may, of course, decline to share certain information with us, in which case we may not be able to provide to you some of the features and functionality of the Services.</p>
                            <p><strong>M.</strong>&nbsp;You have the option to refuse any free consultations or offers for services provided after you submit a request to a third party through the Services.</p>
                            <p><strong>N.</strong> We may take part in or be involved with a corporate business transaction, such as a merger, acquisition, joint venture, or financing or sale of company assets. We may disclose personal information to a third party during negotiations of, in connection with or as an asset in such a corporate business transaction. Personal information may also be disclosed in the event of insolvency, bankruptcy, or receivership.</p>
                            <p><strong>O.</strong> Please see our Gramm-Leach-Bliley Privacy Policy for more information about how you can limit your sharing options.<a name="s4"></a></p>
                            <h2>IV. Exercising Your Rights, Updating Your Membership Information, Canceling Your Membership, and Deactivating Your Account</h2>
                            <p><strong>A.</strong> <strong>EXERCISING YOUR RIGHTS</strong></p>
                            <p><strong>1. Your rights</strong></p>
                            <p>We believe that you have rights to your personal information even if you do not have an account with us.<br>
                            However, because some states provide their residents with specific rights in relation to their personal information, the scope of your rights with respect to the data we collect and process may vary depending on where you live. To find out more about how to exercise your rights, please visit our <a href="https://privacyportal.onetrust.com/webform/694a76f6-1f82-455e-b46e-ffeed902c3fa/db85134f-a11a-451c-a462-3f2f39a662bc">Privacy Preferences</a> page. For Fundera, please visit our <a href="https://privacyportal.onetrust.com/webform/694a76f6-1f82-455e-b46e-ffeed902c3fa/6505b804-0b41-462a-9417-9d71960b39ac">Fundera Privacy Preferences</a> page.</p>
                            <p><strong>2. Verification</strong></p>
                            <p>To help protect your privacy and secure your personal information, you may be asked to provide additional information to verify your identity and/or ownership rights prior to us exercising your data rights. If we are unable to verify your identity or ownership rights to the data, we may not be able to provide you with data rights until you are able to provide us with proper documents.</p>
                            <p><strong>3. Information retention</strong></p>
                            <p>Unless you specifically ask us to delete your personal information, we will retain your personal information as long as it is necessary for us to comply with our data retention requirements and provide you with services and the benefits of our Services and successfully run our business. However, even if you request a deletion, we may be required to maintain your information for as long as necessary to:</p>
                            <ul>
                            <li>comply with our legal or regulatory compliance needs (e.g. maintaining records of transactions you have made with us);</li>
                            <li>to exercise, establish or defend legal claims; and/or</li>
                            <li>to protect against fraudulent or abusive activity on our service.</li>
                            </ul>
                            <p>This means we may keep different information for different periods. If your account is canceled because you’ve not used it in a long time, we may delete this information at any time.</p>
                            <p>There may be occasions where we are unable to fully delete, anonymize, or de-identify your information due to technical, legal, regulatory compliance or other operational reasons. Where this is the case, we will take reasonable measures to securely isolate your personal information from any further processing until such time as we are able to delete, anonymize, or de-identify it.</p>
                            <p><strong>B. UPDATING YOUR INFORMATION</strong></p>
                            <p><strong>1. How to Update your TopFinanceDirectory Information</strong></p>
                            <p>Once you have registered for a TopFinanceDirectory account, you may update or correct your profile information and preferences at any time by accessing your&nbsp;account settings page&nbsp;through the Site.</p>
                            <p><strong>2. How to Update your Fundera Information</strong></p>
                            <p>If you have applied for a loan through Fundera and wish to access personal information that you have submitted to us or to request the correction of any inaccurate information you have submitted to us, you may correct certain information via your user account. Alternatively, you can contact us at support@fundera.com to request any corrections to your personal information. You may also email us if you wish to deregister, but even after you deregister, we may retain archived copies of information about you consistent with applicable law.</p>
                            <p><strong>C. DEACTIVATING YOUR ACCOUNT</strong></p>
                            <p>We do not delete information about you upon deactivation of your account. Although your deactivated status is reflected promptly in our user databases, we may retain the information you submit for a variety of purposes, including legal compliance, backups and archiving, prevention of fraud and abuse, and analytics. Upon deactivation, you will no longer receive emails from us and links to third-party financial accounts, and Services will automatically terminate.</p>
                            <p><strong>D. UNSUBSCRIBING&nbsp;</strong></p>
                            <p><strong>1. How to Unsubscribe From Email Communications</strong></p>
                            <p>If you do not wish to receive email offers or newsletters from us, you can opt out of receiving emails from us (except for emails related to the completion of your registration, correction of user data, change of password, and other similar communications essential to your transactions through the Services) by using the unsubscribe process at the bottom of any marketing email from us. Although your changes are reflected promptly in active user databases, we may retain all information you submit for a variety of purposes, including backups and archiving, prevention of fraud and abuse, and analytics.</p>
                            <p><strong>2. How to Unsubscribe from SMS Communications</strong></p>
                            <p>We may provide our registered Fundera customers with SMS alerts regarding their activity on the Service. Registered customers have the ability to opt out of receiving these SMS alerts by texting the word STOP to 646-760-3048. You may also reply STOP&nbsp;to any message you receive from us. Upon receipt of your STOP&nbsp;message, we will send you a SMS message to confirm that you have been unsubscribed. At that point, you will no longer receive any further SMS messages from Fundera. If you need any assistance, you can always text the word HELP&nbsp;to 646-760-3048 or reply HELP to any message you receive from us. Upon receipt of your HELP&nbsp;message, we will respond with STOP instructions and a link to relevant Terms of Service and Privacy Policy.</p>
                            <h2>V.&nbsp;Our Commitment to Data Security</h2>
                            <p>We use certain physical, managerial, and technical safeguards that are designed to protect the integrity and security of your information. We cannot, however, ensure or warrant the security of any information you transmit to us through the Services or store on the Site, and you do so at your own risk.</p>
                            <h2>VI. California Privacy Rights</h2>
                            <p>This section contains disclosures required by the California Consumer Privacy Act (“CCPA”) and applies only to “personal information” that is subject to that law.&nbsp; Most of our data collection, use, and sharing practices are governed by our Gramm-Leach-Bliley Privacy Policy. However, if you are a California resident, the CCPA may apply to our Services as they relate to business products featured on our site (for example, business credit cards and small business loans). Please review our California Privacy Policy for our California privacy disclosures and information on how to exercise your CCPA rights.</p>
                            <h2>VII. International Visitors</h2>
                            <p>The Service is hosted in the United States and is intended solely for visitors located within the United States. The information we provide and the third-party products and services featured are tailored for the United States market only.&nbsp; You agree that if you choose to use the Services from outside the United States, you will be transferring your information outside of those regions to the United States for storage and processing.</p>
                            <h2>VIII. In the Event of Merger or Sale</h2>
                            <p>TopFinanceDirectory reserves the right to transfer or assign the information that we have collected from users in connection with a corporate transaction, such as a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy.</p>
                            <h2>IX. Changes and Updates to This Privacy Policy</h2>
                            <p>We reserve the right to make changes to this Privacy Policy at any time. We will notify you about material changes in the way we treat your information, including by placing a prominent notice on the Site or by sending you an email so that you can choose whether to continue using the Services. Material modifications are effective 30&nbsp;calendar days after our initial notification or upon your acceptance of the modified Terms. Immaterial modifications are effective upon posting of the updated Privacy Policy or Terms of Service on the Site. Please revisit this page periodically to stay aware of any changes to this Privacy Policy. For the avoidance of doubt, disputes arising hereunder will be resolved in accordance with the Privacy Policy in effect at the time the dispute arose.</p>
                            <p>Thank you for reviewing our Privacy Policy.</p>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "privacy_policy",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            is_content_anchor: true
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 200px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 15px 0 14px 0;
                    display: flex;
                    line-height: 21px;
                    font-size: 14px;
                    color: #666666;
                }
                .text {
                    margin-top: 40px;
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                    table {
                        text-align: center;
                        th {
                            background-color: #2ac8a2;
                            color: #fff;
                            padding: 31px 114px;
                        }
                        td {
                            padding: 30px 25px;
                        }
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #fff;
                            line-height: 20px;
                            background-color: #2ac8a2;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                            border-radius: 12px 12px 0px 0px;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 25px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px 45px;
                                padding-bottom: 15px;
                                div {
                                    font-size: 18px;
                                    line-height: 18px;
                                    color: #222831;
                                    text-align: left;
                                }
                                .active {
                                    font-weight: bold;
                                    color: #222831;
                                }
                            }
                            li:last-child {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .article_bottom {
        background-color: #f5f8fb;
        .article_text {
            .article_text_left {
                .text {
                    margin: 30px 0 220px 0
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .article_container {
            margin-top: 0px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                    .text {
                        h2 {
                            text-align: left;
                        }
                    }
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        text-align: justify;
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                        table {
                            th {
                                padding: 25px 0;
                            }
                            td {
                                padding: 25px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text_a {
        color: #2684FF;
        border-bottom: 1px solid #2684FF;
    }
    .text_bold {
        font-weight: bold;
    }
</style>

<style scoped>
    .pagination >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2ac8a2 !important;
        border-radius: 50%;
    }
    .pagination >>> button,
    .pagination >>> .el-pager li {
        background-color: transparent !important;
    }
</style>