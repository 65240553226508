<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">QuickenLoans</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
                <div class="Bread_crumbs">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates' }">Mortgage</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: '/mortgage_rates_reviews' }">Reviews</el-breadcrumb-item>
                        <el-breadcrumb-item>FHARateSimplified</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>FHARateSimplified</h1>
                          <img src="../../../../public/imgs/reviews_list/quickenloans_review_1.jpg" alt="">
                          <h2 class="anchor_h2">What is the FHA?</h2>
                          <p>The FHA was created in 1934 to give renters in the U.S. better lending options for buying a home. Back then, a 50% down payment was common, which means you needed to earn enough income to pay off a mortgage in three to five years.</p>
                          <p>An FHA loan is a mortgage guaranteed by the Federal Housing Administration (FHA).</p>
                          <p>FHA insurance protects mortgage lenders, allowing them to offer loans with low interest rates, easier credit requirements, and low down payments (starting rates are as low as 3.5%).</p>
                          <p>These FHA loan requirements are much looser than other types of mortgages.</p>
                          <p>For example, FHA allows borrowers with credit scores as low as 500, while most other loan types require a minimum credit score of 620 or higher.</p>
                          <p>In some cases, the FHA allows debt-to-income ratios of up to 50%, compared with the maximum of 43% for traditional loans. That means you're more likely to get an FHA home mortgage if you have a lot of current debt.</p>
                          <p>Collectively, these guidelines allow you to buy a home through the Federal Housing Administration, even if you don't have a super credit score or have saved a ton of money.</p>
                          <p>Because of their flexibility and low interest rates, FHA loans are especially popular with first-time homebuyers, low- or moderate-income homebuyers, and/or low-credit homebuyers.</p>
                          <p>But FHA financing is not limited to a specific type of buyer, anyone can apply.</p>
                          <p>FHA Easy Refinancing allows homeowners to switch from their current FHA loan to a new loan, whether it is a fixed-rate loan or an ARM loan. This refinancing is the most straightforward, with no cash-out option.</p>
                          <p>With a simple FHA refinance, the lender will need credit eligibility, which is the process of analyzing credit, income, and assets to ensure that the borrower meets the requirements of the new loan terms. There is also a home appraisal to see how much the home has increased in value. This loan can include all closing costs and prepaid items in the loan amount as long as the amount is within the appraised value; loan-to-value ratio up to 97.75%.</p>
                          <h2 class="anchor_h2">What are the types of FHA loans?</h2>
                          <h3>Basic Home MortgageLoan 203(b)</h3>
                          <p>Fixed or adjustable rates.</p>
                          <p>Several types of homes are eligible.</p>
                          <h3>203(k) Rehab Mortgage</h3>
                          <p>Finances purchase and renovation withone loan.</p>
                          <p>Purchase and refi options available.</p>
                          <h3>Construction to Permanent(CP) Loan</h3>
                          <p>Finances the construction of a home aswell as the land on which it will be built.</p>
                          <h3>TitleI PropertyImprovement Loan</h3>
                          <p>Used to purchase a manufactured homeor repair an existing home.</p>
                          <h3>Energy Efficient Mortgage(EEM)</h3>
                          <p>Finances energy-efficient upgradesduring a home purchase or refinance.</p>
                          <p>Available for new or existing homes.</p>
                          <h2 class="anchor_h2">Why Use FHA Loans?</h2>
                          <p>FHA loans have been making homeownership more accessible for decades. The FHA is tailored for borrowers with lower credit, enabling them to purchase a home with a credit score of just 580 and a drop of only 3.5%.</p>
                          <p>But homebuyers aren't the only ones standing to benefit. For current homeowners, FHA refinancing may get you low interest rates and home equity even without great credit.</p>
                          <p>Not sure if you qualify for a mortgage? Check out the FHA program. You might be surprised.</p>
                          <h2 class="anchor_h2">FHA home loan benefits</h2>
                          <p>There’s a lot to love about the FHA home loan program. Here are some of the biggest benefits:</p>
                          <ul class="text1">
                            <li>Low down payments</li>
                            <li>Gifted funds permitted</li>
                            <li>Higher DTIs allowed</li>
                            <li>Lower credit scores allowed</li>
                            <li>No credit scores eligible</li>
                            <li>Sizable loan amounts</li>
                            <li>Loan limits can be extended</li>
                            <li>Refinancing available</li>
                          </ul>
                          <h2 class="anchor_h2">Cons of FHA</h2>
                          <p>Closing Costs Are Required</p>
                          <p>Limited Cash Back Is Offered</p>
                          <p>You Have To Pay Mortgage Insurance</p>
                          <h2 class="anchor_h2">How to Get an FHA Loan</h2>
                          <p>You need to meet several requirements to get an FHA loan. It's important to note that these are FHA minimum requirements, and lenders may have additional regulations. To make sure you get the best FHA mortgage rate and loan terms, shop multiple FHA-approved lenders and compare quotes.</p>
                          <p>credit score</p>
                          <p>The minimum credit score for an FHA loan is 500. If your score is between 500 and 579, you'll still qualify for an FHA loan, but you'll need to make a higher down payment.</p>
                          <p>Although the FHA has not changed its requirements, the combined effects of the COVID-19 pandemic and the recession have caused many FHA lenders to increase their minimum credit scores for FHA loans. Many lenders currently require a credit score of at least 620.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">What is the FHA?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">What are the types of FHA loans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Why Use FHA Loans?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">FHA home loan benefits</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Cons of FHA</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">How to Get an FHA Loan</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>