<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">How can we help you?</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Type a word or phrase below to search our help topics.</span>
                    </div>
                    <div class="head_input">
                        <el-input v-model="faq" placeholder="Search">
                            <i slot="prefix" class="el-input__icon el-icon-search" style="font-size: 23px;"></i>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="looking_container">
                <div class="looking">
                    <h2>Which service are you looking for?</h2>
                    <div class="box_list">
                        <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/faqs_ico_list1.png" alt="">
                              <span>My NerdWallet account</span>
                          </div>
                      </div>
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/faqs_ico_list2.png" alt="">
                              <span>All things money</span>
                          </div>
                      </div>
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/faqs_ico_list3.png" alt="">
                              <span>Still have questions?</span>
                          </div>
                      </div>
                      <div class="head_card">
                          <div class="head_card_content">
                              <img src="/imgs/faqs_ico_list4.png" alt="">
                              <span>About TopFinanceDirectory, Inc.</span>
                          </div>
                      </div>    
                    </div>
                </div>
            </div>
            <div class="FAQ_container">
                <div class="FAQ_list">
                    <div class="FAQ_list_left">
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">Overview</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="How can Credible help me refinance my student loans?" name="1">
                                    <div>Credible enables you to compare the student loan refinancing products from multiple lenders you qualify for side-by-side, based on all of the criteria that are important to you, like total repayment amount, APR, repayment options, and monthly payment. It takes just two minutes to find out if you are eligible for prequalified rates with our partner lenders!</div>
                                </el-collapse-item>
                                <el-collapse-item title="How do I know if I am eligible?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Can I use Credible to refinance my student loans if I didn't graduate?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="What types of loans can I refinance? Federal and private loans? Parent PLUS loans?" name="4">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="My loans are in deferment or forbearance. Will this affect my eligibility?" name="5">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How much does it cost to use Credible?" name="6">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">Requesting prequalified rates</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="Should I include my spouse or partner’s income?" name="1">
                                    <div>All information that you provide (including your income and housing expenses) should pertain only to you as an individual, even if you are married. For example, if you pay half of your monthly housing payment, only include your share, not the total amount of the payment paid by both you and your spouse/partner. If you include your spouse/partner as a co-signer on your request, they will list their income/expense information separately, as part of their Credible co-signer profile.</div>
                                </el-collapse-item>
                                <el-collapse-item title="When and how will I find out about the prequalified options I’ve received after I submit my profile?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Am I guaranteed to receive prequalified rates from every lender?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Am I accepting an offer by submitting my Credible profile?" name="4">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Can you tell me more about the lenders?" name="5">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">Receiving and choosing a prequalified rate</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="Am I guaranteed to receive an offer from a lender after I have been prequalified?" name="1">
                                    <div>No. When you receive prequalified rates, you’re getting an estimate of the rates you may be able to receive from our partner lenders. However, there is no guarantee that the rates you prequalify for are the rates you will ultimately receive, or that the lender will be able to extend you an offer of credit.</div> <br />
                                    <div> After you get and choose a prequalified rate, you will fill out some additional information with the lender of your choice. Once you provide additional information (which takes about 10 minutes to complete, on average), the lender will conduct a hard credit inquiry, and request additional documentation from you, if necessary.</div><br />
                                    <div>Lenders will decide to extend you credit based on whether you still meet the criteria used to prequalify you, your ability or inability to provide the documentation requested, or based on any other applicable criteria. Lenders also reserve the right to change or withdraw prequalified rates at any time.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How long are my prequalified rates good for?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="I received a prequalified rate I’d like to pursue. What’s next?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Do I have to choose an option if I receive prequalified rates from lenders?" name="4">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Will I lose any of the benefits that federal loans give me if I refinance my loans?" name="5">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="From the time that I choose a prequalified rate on my Credible Dashboard, how long before the lender responds with an offer of credit?" name="6">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">Co-signer Questions</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="Do I need a co-signer?" name="1">
                                    <div>No. When you receive prequalified rates, you’re getting an estimate of the rates you may be able to receive from our partner lenders. However, there is no guarantee that the rates you prequalify for are the rates you will ultimately receive, or that the lender will be able to extend you an offer of credit.</div> <br />
                                    <div> After you get and choose a prequalified rate, you will fill out some additional information with the lender of your choice. Once you provide additional information (which takes about 10 minutes to complete, on average), the lender will conduct a hard credit inquiry, and request additional documentation from you, if necessary.</div><br />
                                    <div>Lenders will decide to extend you credit based on whether you still meet the criteria used to prequalify you, your ability or inability to provide the documentation requested, or based on any other applicable criteria. Lenders also reserve the right to change or withdraw prequalified rates at any time.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Who can serve as my co-signer?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Can a co-signer later be released from their loan obligation?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="Will my co-signer see my information?" name="4">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="I submitted my profile and have received prequalified rates, but now I’d like to add a co-signer to improve my options. What do I do?" name="5">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">About TopFinanceDirectory</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="What is TopFinanceDirectory?" name="1">
                                    <div>No. When you receive prequalified rates, you’re getting an estimate of the rates you may be able to receive from our partner lenders. However, there is no guarantee that the rates you prequalify for are the rates you will ultimately receive, or that the lender will be able to extend you an offer of credit.</div> <br />
                                    <div> After you get and choose a prequalified rate, you will fill out some additional information with the lender of your choice. Once you provide additional information (which takes about 10 minutes to complete, on average), the lender will conduct a hard credit inquiry, and request additional documentation from you, if necessary.</div><br />
                                    <div>Lenders will decide to extend you credit based on whether you still meet the criteria used to prequalify you, your ability or inability to provide the documentation requested, or based on any other applicable criteria. Lenders also reserve the right to change or withdraw prequalified rates at any time.</div>
                                </el-collapse-item>
                                <el-collapse-item title="What makes Credible different from other comparison sites?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How does Credible get paid?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">Credit considerations</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="Does Credible pull my credit?" name="1">
                                    <div>No. When you receive prequalified rates, you’re getting an estimate of the rates you may be able to receive from our partner lenders. However, there is no guarantee that the rates you prequalify for are the rates you will ultimately receive, or that the lender will be able to extend you an offer of credit.</div> <br />
                                    <div> After you get and choose a prequalified rate, you will fill out some additional information with the lender of your choice. Once you provide additional information (which takes about 10 minutes to complete, on average), the lender will conduct a hard credit inquiry, and request additional documentation from you, if necessary.</div><br />
                                    <div>Lenders will decide to extend you credit based on whether you still meet the criteria used to prequalify you, your ability or inability to provide the documentation requested, or based on any other applicable criteria. Lenders also reserve the right to change or withdraw prequalified rates at any time.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How can multiple inquiries affect my credit score?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How strong of a credit score do I need to get a loan through your site?" name="3">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                        <div class="FAQ_onecontailner">
                            <h2 class="anchor_h2">Contact us</h2>
                            <el-collapse v-model="activeName" accordion class="FAQ">
                                <el-collapse-item title="I can’t find an answer to my question here. Where can I find out more about Credible’s services?" name="1">
                                    <div>No. When you receive prequalified rates, you’re getting an estimate of the rates you may be able to receive from our partner lenders. However, there is no guarantee that the rates you prequalify for are the rates you will ultimately receive, or that the lender will be able to extend you an offer of credit.</div> <br />
                                    <div> After you get and choose a prequalified rate, you will fill out some additional information with the lender of your choice. Once you provide additional information (which takes about 10 minutes to complete, on average), the lender will conduct a hard credit inquiry, and request additional documentation from you, if necessary.</div><br />
                                    <div>Lenders will decide to extend you credit based on whether you still meet the criteria used to prequalify you, your ability or inability to provide the documentation requested, or based on any other applicable criteria. Lenders also reserve the right to change or withdraw prequalified rates at any time.</div>
                                </el-collapse-item>
                                <el-collapse-item title="How can I contact Credible directly?" name="2">
                                    <div>None.</div>
                                </el-collapse-item>
                            </el-collapse>
                        </div>
                    </div>
                    <div class="FAQ_list_right">
                        <div class="text_right_top">
                            <h2>Skip to:</h2>
                            <ul>
                                <li @click="scrollTo(0)">
                                    Overview
                                </li>
                                <li @click="scrollTo(1)">
                                    Requesting prequalified rates
                                </li>
                                <li @click="scrollTo(2)">
                                    Receiving and choosing a prequalified rate
                                </li>
                                <li @click="scrollTo(3)">
                                    Co-signer Questions
                                </li>
                                <li @click="scrollTo(4)">
                                    About TopFinanceDirectory
                                </li>
                                <li @click="scrollTo(5)">
                                    Credit considerations
                                </li>
                                <li @click="scrollTo(6)">
                                    Contact us
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "faqs",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            faq: '',
            activeName: '1'
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.FAQ_onecontailner .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.FAQ_onecontailner .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/navimgnotcrad.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        .nav_img_content {
            .head_input {
                width: 42%;
                margin: 40px auto 0;
            }
        }
    }
    .looking_container {
        width: 100%;
        margin-top: 60px;
        .looking {
            width: 61%;
            margin: 0 auto 50px;
            h2 {
                font-size: 48px;
                line-height: 48px;
                color: #222831;
                font-weight: 700;
            }
            .box_list {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin-top: 45px;
                .head_card {
                    margin-right: 55px;
                    .head_card_content {
                        width: 212px;
                        height: 212px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12px;
                        font-size: 24px;
                        color: #222831;
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10);
                        text-align: center;
                        line-height: 24px;
                        img {
                            width: 55px;
                            margin-bottom: 15px;
                        }
                    }
                }
                .head_card:hover {
                    position: relative;
                    bottom: 15px;
                    box-shadow: 0px 15px 20px 5px rgba(0,0,0,0.10); 
                }
            }
        }
    }
    .FAQ_container {
        width: 100%;
        .FAQ_list {
            width: 61%;
            margin: 50px auto 220px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .FAQ_list_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .FAQ_onecontailner {
                    margin-bottom: 60px;
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 24px;
                        margin-bottom: 30px;
                    }
                    .FAQ {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        padding: 30px 40px 20px;
                    }
                }
            }
            .FAQ_list_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    h2 {
                        font-size: 24px;
                        font-weight: 700;
                        color: #fff;
                        line-height: 24px;
                        background-color: #2ac8a2;
                        padding: 18px 45px;
                        text-align: left;
                        border-radius: 12px 12px 0px 0px;
                    }
                    ul {
                        li {
                            padding: 19px 5px;
                            font-size: 18px;
                            color: #222831;
                            line-height: 18px;
                            margin: 0 45px;
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                        li:hover {
                            color: #2ac8a2;
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            .nav_img_content {
                .head_input {
                    width: 75%;
                }
            }
        }
        .looking_container {
            .looking {
                width: 90%;
                .box_list {
                    flex-wrap: wrap;
                    justify-content: center;
                    .head_card {
                        margin-bottom: 40px;
                    }
                }
            }
        }
        .FAQ_container {
            margin-top: 50px;
            .FAQ_list {
                width: 90%;
                flex-direction: column-reverse;
                .FAQ_list_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .FAQ_list_right {
                    width: 100%;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .looking_container {
            .looking {
                h2 {
                    font-size: 36px;
                }
                .box_list {
                    .head_card {
                        margin-right: 24px;
                        .head_card_content {
                            width: 140px;
                            height: 140px;
                            span {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
        .FAQ_container {
            margin-top: 0;
            .FAQ_list {
                .FAQ_list_left {
                    .FAQ_onecontailner {
                        .anchor_h2 {
                            font-size: 35px;
                            line-height: 35px;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style scoped>
    .head_input >>> .el-input__inner {
        border-radius: 12px;
        height: 60px;
        font-size: 18px;
        padding-left: 45px;
    }
    .FAQ >>> .el-collapse-item__header {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    .FAQ >>> .is-active {
        color: #2ac8a2;
    }
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 35px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 65px;
            text-align: left;
        }
    }
</style>