<template>
  <div class="maincontainer">
    <nav-header></nav-header>
    <router-view></router-view>
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import NavHeader from "@/components/NavHeader";
import NavFooter from "@/components/NavFooter";
import ClientHeight from "@/components/ClientHeight";

export default {
  name: "home",
  components: { NavFooter, NavHeader, ClientHeight}
}
</script>


<style scoped>
.maincontainer{
  width: 100%;
}
</style>
