<template>
    <div>
        <div class="container">
            <div class="nav_img">
                <div class="nav_img_content">
                    <div class="head_title">Mortgage Article Library</div>
                    <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                    <div class="head_subtitle">
                        <span>Learn the mortgage process, rates, credit scores and loan programs that may apply to you. Happy reading, easy choosing!</span>
                    </div>
                </div>
            </div>
            <div class="article_container">
                <div class="article_text">
                    <div class="article_text_left">
                        <div class="text_title">
                            <h2>Can You Refinance Student Loans More than Once?</h2>
                        </div>
                        <div class="text_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div class="avatar_information">
                                <div>Jessica Cotzin</div>
                                <div>Last update: 8 June 2020</div>
                            </div>
                        </div>
                        <div class="text">
                            <img src="/imgs/blog_imgs/Can You Refinance Student Loans More than Onceblog_1.jpg" alt="">
                            <p>Whether it's your student loans, a mortgage, or a personal loan, refinancing can be a smart move that has the potential to save you money on your existing loans by lowering payments or securing a lower interest.</p>
                            <p>Refinancing is the process of replacing an existing loan, like your student loan, with a new loan. The idea is to replace your old loan with a new loan with better terms.</p>
                            <p>But can you refinance student loans more than once, and what are the downsides? We'll explore the topic of multiple refinancing below.</p>
                            <h2>How it works</h2>
                            <p>The good news is, yes, you can refinance your student loans multiple times, since these types of loans normally don't have prepayment penalties or other fees.</p>
                            <p>So, how does student loan refinancing work, anyway?</p>
                            <p>When you initially take out a federal student loan, you're conceding to their terms and conditions with little say. This includes things like your monthly payments, the interest rate on the loan, and the length of the loan.</p>
                            <p>While interest rates on private student loans and other loans types depend on your credit score and history, interest rates for federal student loans are dependent on outside factors like the economy and not your credit score.</p>
                            <p>Student loan refinancing is an opportunity to get better terms on your loan, like a lower interest rate. You pay less on the loan in the end by replacing your student loan with a new, private loan. If your credit score and income continue to improve, you can refinance again to get even better terms.</p>
                            <h3>Benefits of Refinancing</h3>
                            <p>As mentioned, one of the key benefits of refinancing your student loans is to pay less on your loan by securing a lower interest rate than the one you currently have. Even if it's just by a few points, having a lower interest rate can save you a lot of money in the long run.</p>
                            <p>Suppose you have $50,000 worth of student loan debt at a 7% interest rate. You'd be paying $19,665 in interest over the course of 10 years. Knock that interest down to 5%, and you're now paying $13,639, saving you close to $6,026. You can play with the numbers using this </p>
                            <p>You can also apply for student loan refinancing with a cosigner to help get you a better interest rate. A cosigner is useful if they have a good credit history or a better credit score than you.</p>
                            <p>Another benefit to refinancing (if you have multiple student loans) is the simplicity of managing one large loan rather than several loans with different terms. This will help you stay on top of your loan payments.</p>
                            <h3>Downsides to Refinancing</h3>
                            <p>Just as there are upsides to refinancing, there are also downsides where student loan refinancing isn't always the right move.</p>
                            <p>When refinancing, you often need to meet stricter requirements than when you initially took out your student loan. Lenders require borrowers to be </p>
                            <p>Additionally, when refinancing a student loan, you'll be locked into your new repayment plan. Your initial student loan allowed you the ability to modify your repayment plan, like changing it from a 10-year plan to 20-year plan, for example. But with your new refinanced loan, there isn't that kind of flexibility.</p>
                            <p>Another downside is that you lose your federal repayment protections when refinancing. While some lenders will offer some kind of deferment or forbearance option in the case that you lose your job or suffer a financial hardship, it's not quite the same as the options available through the Department of Education.</p>
                            <h2>Who Should Refinance Student Loans</h2>
                            <p>Student loan refinancing isn't for everyone, so how do you know when it's right for you and your financial situation?</p>
                            <p>Student loan refinancing is potentially a good option if:</p>
                            <ul class="text1"><li>Your income is stable and you have good credit. The sooner you start refinancing your loans the better so that you can save money with a lower interest rate. Just remember that income and good credit are important factors when finding the best rates.</li>
                            <li>Your current student loans have high interest rates. You can save thousands on your student loan through refinancing if you're able to secure a low-interest rate loan.</li>
                            <li>You have several student loans. Consolidating and refinancing your student loans will not only save you money, but it will also save you the hassle of having to manage multiple loans.</li>
                            <li>The grace period on your student loan has ended. Federal student loans have a six-month grace period after you graduate where you don't have to start paying the loan back just yet. This gives graduates some time to find a job so they can afford making their monthly payments. When it ends, it's the perfect time to consider refinancing your student loans.</li></ul>
                            <p>Student loan refinancing might not be the right option if:</p>
                            <ul class="text1"><li>You plan on applying for federal loan forgiveness. These are programs that help you pay down your student loan debt, so you shouldn't refinance your student loans if this is something you want to do.</li>
                            <li>You want an income-based repayment plan. Similar to loan forgiveness programs, if you're not financially stable, you won't be able to qualify for income-based repayment plans or low payments if you refinance your student loans.</li>
                            <li>You have poor credit. If you don't have good credit, you'll find it challenging to get a better interest rate on your loan. It's still worth researching, especially since many lenders will allow you to get pre-approved for a loan so you can compare rates. One way around having bad credit is to add a co-signer, such as a family member or trusted friend, who has a high credit score.</li></ul>
                            <h2>How to Refinance Your Student Loans</h2>
                            <p>Now that you have a better idea of the pros and cons revolving around student loan refinancing, you can move forward.</p>
                            <h3>Compare lenders</h3>
                            <p>The first step when refinancing your student loans is to check with multiple lenders to find the best rates. This includes not only traditional financial institutions like banks and credit unions, but also online lenders.</p>
                            <p>Online lenders like SoFi and Earnest offer student loan refinancing and are known for their low interest rates, but you do need a minimum credit score of 650 to get approved. You can get pre-approved in a matter of minutes which will help give you an idea of what interest rates you qualify for given your financial profile.</p>
                            <p>Some information you'll need when getting pre-approved is your contact info, degree and university, income, and total student debts. Submitting this preliminary application will only conduct a soft credit check, meaning it won't affect your credit score. It's only when you submit your full application that a hard credit check will be conducted.</p>
                            <h3>Choose a lender</h3>
                            <p>When choosing a lender, the interest rate isn't the only thing you should base your choice off of. You also want to consider what fees are involved in the loan, such as origination fees, late fees, and prepayment penalties. Some less-reputable lenders will lure you in with low interest rates just to charge fees, so always read the terms and conditions carefully when doing your research.</p>
                            <p>Customer service is also important when taking out a loan, so chose a reputable lender with good reviews and good customer support.</p>
                            <h3>Have your documents ready to go</h3>
                            <p>Once you're ready to submit your full application, you'll need a few documents, which you'll either have to upload to your application or send via mail, depending on the lender.</p>
                            <p>Here are a few important documents to have on hand:</p>
                            <ul class="text1"><li>Proof of identity, like your driver's license or social security card</li>
                            <li>Proof of income, like a pay stub or tax form</li>
                            <li>Statements for your private or federal student loans</li>
                            <li>If using a cosigner, you'll need their information too</li></ul>
                            <p>Even if you decide to refinance your student loans, make sure to keep making your monthly payments while you wait to see if you're </p>
                            <h2>Final Thoughts</h2>
                            <p>Refinancing students loans is a popular and completely valid option when handling your student loan debt, and you can do it as many times as you want. If you have a good credit score and stable income, refinancing your high-interest student loans can save you thousands.</p>
                            <p>Get started today by comparing lenders to see what kind of rates you qualify for. Read the find print, choose the best terms for you, and enjoy being one step closer to student debt freedom.</p>
                            <ul class="text1"><li>Filing for bankruptcy shall not exempt from the obligation to repay the loan.</li>
                            <li>You may be eligible to specific educational loan benefits from your educational institution or may be qualified for Federal student financial assistance you may receive additional information with your institution of higher education or at the website of the U.S. Department of Education.</li></ul>
                        </div>
                    </div>
                    <div class="article_text_right">
                        <div class="text_right_top">
                            <h2>Top 5 Personal Loans</h2>
                            <ul>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="left">
                                        <img src="/imgs/content2_top5_1_logo.png" alt="">
                                    </div>
                                    <div class="center">
                                        <span>Credible</span>
                                        <span>Read more »</span>
                                    </div>
                                    <div class="right">
                                        <span>Visit Site</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Must Reads</h2>
                                <ul>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <li>
                                        <div>How Loans Under 36 Can Help You Find the Right Personal Loans</div>
                                        <div>Read more »</div>
                                    </li>
                                    <span class="buttom">Read More →</span>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <img class="right_img" src="/imgs/about_us_img2.png" alt="">
                </div>
            </div>
            <div class="user_information_container">
                <div class="user_information">
                    <div class="user_information_left">
                        <div class="user_avatar">
                            <img src="/imgs/Slice_57.png" alt="">
                            <div><span>Jessica Cotzin</span><br />Last update: 8 June 2020</div>
                        </div>
                        <p>Jessica Cotzin is a writer and the TopFinanceDirectory authority on small businesses and personal loans. She has been writing about personal finance and the loans industry for a number of years, and holds a bachelor's degree in journalism from Florida Atlantic University.</p>
                    </div>
                    <div class="user_information_rgiht"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "blog",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'}
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    .nav_img {
        width: 100%;
        height: 763px;
        color: #222831;
        font-size: 18px;
        background: url(/imgs/index_bg.jpg) no-repeat;
        .nav_img_content {
            text-align: center;
            margin-top: 270px;
            .head_title {
                color: #222831;
                font-size: 48px;
                font-weight: 700;
                line-height: 48px;
                margin-top: 7px;
            }
            .head_subtitle {
                margin-top: 11px;
                span {
                    font-size: 14px;
                    color: #222831;
                    margin-left: 12px;
                }
            }
            .head_line {
                margin: 12px 0 19px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .title_underline_left{
                    margin-right: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
                .title_origin{
                    width: 7px;
                    height: 7px;
                    background: #2ac8a2;
                    border-radius: 50%;
                }
                .title_underline_right{
                    margin-left: 20px;
                    width: 227px;
                    height: 1px;
                    border-bottom: 0.5px solid #898c8d;
                }
            }
            .head_button {
                margin-top: 130px;
                span {
                    background: #2ac8a2;
                    padding: 13px 64px;
                    border-radius: 50px;
                    color: #fff;
                    font-size: 18px;
                }
            }
        }
    }
    .article_container {
        width: 100%;
        margin-top: 90px;
        .article_text {
            width: 61%;
            margin: 0 auto 170px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .article_text_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .text_title {
                    h2 {
                        font-size: 48px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 72px;
                        text-align: left;
                    }
                }
                .text_avatar {
                    margin: 40px 0 14px 0;
                    display: flex;
                    line-height: 24px;
                    font-size: 14px;
                    img {
                        margin-right: 15px;
                    }
                    .avatar_information {
                        div:first-child {
                            font-size: 18px;
                            font-weight: 700;
                            color: #222831;
                        }
                        div:last-child {
                            color: #c5c5c5;
                        }
                    }
                }
                .text {
                    p {
                        font-size: 18px;
                        color: #666666;
                        line-height: 24px;
                        margin-bottom: 25px;
                    }
                    img {
                        width: 100%;
                        margin-bottom: 25px;
                    }
                    .text_list {
                        margin: 60px 0;
                        display: flex;
                        justify-content: space-between;
                        .text_list_left {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #2ac8a2;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                        }
                        .text_list_right {
                            div{
                                margin-bottom: 27px;
                                font-size: 24px;
                                font-weight: 700;
                                color: #222831;
                                position: relative;
                            }
                            div:before {
                                content: " ";
                                background-color: #c5c5c5;
                                display: inline-block;
                                width: 54px;
                                height: 7px;
                                border-radius: 12px;
                                position: absolute;
                                bottom: -7px;
                            }
                            
                        }
                        
                    }
                    h2 {
                        font-size: 36px;
                        font-weight: 700;
                        color: #222831;
                        margin-bottom: 25px;
                    }
                }
            }
            .article_text_right {
                width: 325px;
                .text_right_top {
                    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                    border-radius: 12px;
                    margin-bottom: 50px;
                    h2 {
                        font-size: 20px;
                        font-weight: 700;
                        color: #222831;
                        line-height: 20px;
                        background-color: #f5f8fb;
                        padding: 18px 0px 18px 30px;
                    }
                    ul {
                        li {
                            display: flex;
                            justify-content: space-evenly;
                            align-items: center;
                            padding: 19px 5px;
                            .center {
                                display: flex;
                                flex-direction: column;
                                span {
                                    color: #2684ff;
                                }
                                span:first-child {
                                    margin-bottom: 3px;
                                    font-size: 18px;
                                    font-weight: 700;
                                    color: #222831;
                                }
                            }
                            .right {
                                span {
                                    padding: 9px 22px;
                                    background: #2ac8a2;
                                    border-radius: 50px;
                                    color: #fff;
                                }
                            }
                        }
                        li:not(:last-child) {
                            border-bottom: 1px solid #c5c5c5;
                        }
                    }
                }
                .text_right_bottom {
                    width: 325px;
                    .text_bottom_top {
                        box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.10); 
                        border-radius: 12px;
                        margin-bottom: 50px;
                        h2 {
                            font-size: 20px;
                            font-weight: 700;
                            color: #222831;
                            line-height: 20px;
                            background-color: #f5f8fb;
                            padding: 18px 0px 18px 30px;
                            text-align: center;
                        }
                        ul {
                            text-align: center;
                            padding-bottom: 50px;
                            li {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                border-bottom: 1px solid #d0d0dc;
                                margin: 27px;
                                padding-bottom: 15px;
                                div {
                                    padding: 0 30px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    color: #222831;
                                    line-height: 14px;
                                    text-align: left;
                                }
                                div:last-child {
                                    font-size: 12px;
                                    line-height: 12px;
                                    color: #2684ff;
                                    font-weight: 400;
                                    margin-top: 5px;
                                }
                            }
                            li:last-child {
                                margin-bottom: 30px;
                            }
                            .buttom {
                                color: #fff;
                                padding: 8px 25px;
                                background: #2ac8a2;
                                border-radius: 50px;
                            }
                        }
                    }
                }
            }
            .right_img {
                position: absolute;
                right: 0px;
                top: 1954px;
            }
        }
    }
    .user_information_container {
        width: 100%;
        background-color: #f5f8fb;
        .user_information {
            width: 61%;
            margin: 50px auto 230px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .user_information_left {
                width: 750px;
                color: #666666;
                font-size: 18px;
                text-align: justify;
                .user_avatar {
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    img {
                        margin-right: 25px;
                    }
                    div {
                        font-size: 14px;
                        color: #c5c5c5;
                        line-height: 24px;
                        span {
                            font-size: 18px;
                            color: #222831;
                        }
                    }
                }
            }
            .user_information_rgiht {
                width: 325px;
            }
        }
    }
    @media (max-width: 1199px) {
        .nav_img {
            height: 470px;
            .nav_img_content {
                margin-top: 70px;
            }
        }
        .article_container {
            margin-top: 50px;
            .article_text {
                width: 90%;
                flex-direction: column-reverse;
                .article_text_left {
                    width: 100%;
                    margin-top: 40px;
                    z-index: 9;
                }
                .article_text_right {
                    width: 100%;
                    .text_right_bottom {
                        display: none;
                    }
                }
            }
        }
        .trustpilot_container {
            .trustpilot_reviews {
                width: 90%;
                .reviews_left {
                    width: 100%;
                }
                .reviews_right {
                    display: none;
                }
            }
        }
        .user_information_container {
            .user_information {
                width: 90%;
                .user_information_left {
                    width: 100%;
                }
                .user_information_rgiht {
                    display: none;
                }
            }
        }
    }
    @media (max-width: 760px) {
        .article_container {
            .article_text {
                .article_text_left {
                    .text_title {
                        h2 {
                            line-height: 50px;
                        }
                    }
                    .text {
                        li {
                            text-align: left;
                        }
                        h2 {
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    .text1 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #2ac8a2;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
    .text2 {
        li {
            margin-bottom: 27px;
        }
        li:before {
            content: " ";
            background-color: #c5c5c5;
            display: inline-block;
            font-weight: bold;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            margin-right: 10px;
        }
    }
</style>
