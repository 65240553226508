<template>
  <div>
      <div class="container">
          <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Mortgage Rates</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
                  <div class="head_content">A mortgage is a contract between you and a lender that gives the lender the right to repossess your home if you don't repay the loan plus interest.</div>
                  <div class="head_card_list">
                      <div class="head_card">
                          <router-link to="/mortgage_rates" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img1.png" alt="">
                              <span>Best Mortgage Purchase Rates</span>
                          </router-link>
                      </div>
                      <div class="head_card">
                          <router-link to="/mortgage_rates_reviews" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img2.png" alt="">
                              <span>Mortgage Reviews</span>
                          </router-link>
                      </div>
                      <!-- <div class="head_card">
                          <router-link to="/mortgage_rates_guide" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img3.png" alt="">
                              <span>Mortgage Guide</span>
                          </router-link>
                      </div> -->
                      <div class="head_card">
                          <router-link to="/calculator" class="head_card_content">
                              <img src="/imgs/tax_relief_list_img4.png" alt="">
                              <span>Mortgage Calculator</span>
                          </router-link>
                      </div>
                  </div>
              </div>
          </div>
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item>Mortgage</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="article_content">
                          <div class="article_content_container">
                              <div class="article" v-for="item of offers_list" :key="item.id">
                                  <div class="left">
                                      <div class="left_top">
                                          <img :src="item.pic_img" alt="">
                                          <!-- <img src="/imgs/content2_icon.png" alt=""> -->
                                      </div>
                                      <div>
                                          <el-rate
                                          disabled
                                          :colors="colors"
                                          v-model="item.star"></el-rate></div>
                                  </div>
                                  <div class="center">
                                      <div class="center_top">
                                          <span>{{item.name}}</span>
                                          <ul>
                                              <li v-for="(subitem, index) of item.features" :key="index">{{ subitem }}</li>
                                          </ul>
                                      </div>
                                      <div class="center_bottom"></div>
                                  </div>
                                  <div class="right">
                                      <a class="button" :href="item.url" target="_blank">View Rates</a>
                                      <!-- <a class="remark" :href="item.url" target="_blank">Read Review</a> -->
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="text">
                          <h2 class="anchor_h2">Mortgage Definition</h2>
                          <p>A mortgage is a loan used to purchase or maintain a home, land, or other piece of real estate. The borrower agrees to pay the lender over time, usually in a series of periodic payments that are split between principal and interest. The loan will be secured by collateral.</p>
                          <p>A borrower must apply for a mortgage with their preferred lender and meet a number of criteria, including minimum credit scores and down payments. Before they reach the closing stage, mortgage applications go through a thorough underwriting process. Conventional and fixed-rate loans are two types of mortgages that differ depending on the borrower's demands.</p>
                          <h2 class="anchor_h2">How They Work</h2>
                          <p>Mortgages allow individuals and corporations to purchase real estate without paying the whole purchase price up front. The borrower pays back the loan plus interest over a set period of time until they acquire the property outright. Liens against property or claims on property are other terms for mortgages. If the borrower defaults on the loan, the lender has the option to foreclose on the property.</p>
                          <p>A residential homebuyer, for example, promises his or her home to their lender, who then has a claim on the property. If the buyer defaults on their financial obligations, this protects the lender's interest in the property. In the event of a foreclosure, the lender may evict the occupants, sell the property, and use the proceeds to repay the borrower.</p>
                          <h2 class="anchor_h2">Mortgage Application Process</h2>
                          <p>Interested borrowers start the process by submitting an application to one or more mortgage lenders. The lender will demand proof of the borrower's ability to repay the loan. Bank and investment statements, recent tax returns, and proof of current employment are all examples of this. In most cases, the lender will also do a credit check.</p>
                          <p>If the application is approved, the lender will make the borrower an offer for a loan up to a given amount at a certain interest rate. Pre-approval is a method that allows homebuyers to apply for a mortgage after they have decided on a property to purchase or while they are still looking for one. Pre-approval for a mortgage might provide buyers an advantage in a competitive housing market.</p>
                          <p>When a buyer and seller have reached an agreement on the terms of their transaction, they or their agents will meet for a closing. This is when the borrower pays the lender a down payment. The seller will give the buyer possession of the property and receive the agreed-upon amount of money, and the buyer will sign any remaining mortgage agreements.</p>
                          <h2 class="anchor_h2">Types of Mortgages</h2>
                          <p>Mortgages come in a variety of shapes and sizes. Fixed-rate mortgages of 30 and 15 years are the most frequent. Some mortgages have periods as little as five years, while others might last up to 40 years. Spreading payments out over a longer period of time may lower the monthly payment, but it also raises the total amount of interest the borrower pays during the loan's life.</p>
                          <p>Numerous types of home loans are available for specific populations that may not have the income, credit scores, or down payments required to qualify for conventional mortgages, including Federal Housing Administration (FHA) loans, United States Department of Agriculture (USDA) loans, and United States Department of Veterans Affairs (VA) loans.</p>
                          <p>The following are just a few examples of the most common forms of mortgage loans that are offered to borrowers.</p>
                          <h2 class="anchor_h2">Fixed-Rate Mortgages</h2>
                          <p>The interest rate on a fixed-rate mortgage remains constant throughout the loan's term, as do the borrower's monthly mortgage payments. A typical mortgage is sometimes known as a fixed-rate mortgage.</p>
                          <h2 class="anchor_h2">Adjustable-Rate Mortgage (ARM)</h2>
                          <p>An adjustable-rate mortgage (ARM) has a fixed interest rate for a set period of time, after which it can alter based on current interest rates. The initial interest rate is frequently below market, making the mortgage more affordable in the near term but potentially less so in the long run if the rate climbs significantly.</p>
                          <p>ARMs usually contain ceilings on how much the interest rate can grow each time it adjusts and throughout the life of the loan.</p>
                          <h2 class="anchor_h2">Interest-Only Loans</h2>
                          <p>Interest-only mortgages and payment-option ARMs are two less popular types of mortgages that might have complicated repayment schedules and are best employed by sophisticated borrowers.</p>
                          <p>During the early 2000s housing bubble, many homeowners ran into financial problems because of these types of mortgages.</p>
                          <h2 class="anchor_h2">Reverse Mortgages</h2>
                          <p>Reverse mortgages, as their name implies, are a unique financial product. They are intended for homeowners aged 62 and up who desire to turn a portion of their home's value into cash.</p>
                          <p>These homeowners can take out a loan against the value of their house and get the funds in the form of a lump amount, a fixed monthly payment, or a line of credit. When a borrower dies, moves out permanently, or sells their house, the entire loan debt becomes payable.</p>
                          <h2 class="anchor_h2">Average Mortgage Rates for 2022</h2>
                          <p>The amount you'll pay for a mortgage is determined by the kind of loan (fixed or adjustable), the period (such as 20 or 30 years), any discount points paid, and current interest rates. Interest rates fluctuate from week to week and from lender to loan, so shopping around is a good idea.</p>
                          <p>In 2020, mortgage rates were near-record lows, with an average of 2.66 percent on a 30-year fixed-rate mortgage for the week of December 24, 2020. 5 Rates remained stable during 2021 and have begun to rise steadily starting December 3, 2021. According to the Federal Home Loan Mortgage Corporation, average interest rates in February 2022 were as follows:</p>
                          <ul class="text1">
                              <li>3.92 percent for a 30-year fixed-rate mortgage (0.8 point)</li>
                              <li>3.15 percent fixed-rate mortgage for 15 years (0.8 point)</li>
                              <li>2.98 percent on a 5/1 adjustable-rate mortgage (0.8 point)</li>
                          </ul>
                          <p>A 5/1 adjustable-rate mortgage has a fixed interest rate for the first five years and then increases every year after that.</p>
                          <h2 class="anchor_h2">How to Compare Mortgages</h2>
                          <p>At one time, banks, savings and loan associations, and credit unions were the only places to get a mortgage. Nonbank lenders such as Better, loanDepot, Rocket Mortgage, and SoFi now account for a growing portion of the mortgage market.If you're looking for a mortgage, an online mortgage calculator can help you compare expected monthly payments based on the type of loan, the interest rate, and the amount of money you want to put down. It might also assist you in determining how much property you can afford.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Mortgage Definition</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">How They Work</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Mortgage Application Process</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Types of Mortgages</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Fixed-Rate Mortgages</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">Adjustable-Rate Mortgage (ARM)</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">Interest-Only Loans</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(7)">Reverse Mortgages</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(8)">Average Mortgage Rates for 2022</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(9)">How to Compare Mortgages</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import { GetOffers } from '../../api/cindex';
import { imgsapiUrl } from '../../config/env';
export default {
    name: "mortgage_rates",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
            offers_list: []
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    created() {
        this.GetOffersList();
    },
    methods: {
        // 获取offer数据
        async GetOffersList() {
            const res = await GetOffers( { category_id: '156', page: '1', page_size: '4'  } )
            res.data.forEach(i => {
                i.pic_img = imgsapiUrl + i.img.split('.')[0] + '/' + i.img
                i.star = 5
            })
            this.offers_list = res.data
        },
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/base.scss';
@import '../../assets/scss/config.scss';
@import '../../assets/scss/textnavigation.scss';
@import '../../assets/scss/navimgcrad.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
        .nav-img {
            height: 980px;
            background-size: cover;
            .nav-img-content {
                margin-top: 60px;
                .head_content {
                    width: 80%;
                    font-size: 20px;
                }
                .head_card_list {
                    flex-wrap: wrap;
                    .head_card {
                        margin: 20px 50px;
                    }
                }
            }
        }
        .article_container {
            .article_list {
                width: 90%;
                flex-direction: column-reverse;
                .article_list_left {
                    width: 100%;
                }
                .article_list_right {
                    width: 100%;
                    margin-bottom: 30px;
                    .text_right_bottom {
                        width: 100%;
                    }
                }
            }
        }
        .question_container {
            .question_list {
                width: 90%;
                flex-wrap: wrap;
                .question_list_right {
                    display: none;
                }
            }
        }
        .answer_container {
            .answer_list {
                width: 90%;
                flex-wrap: wrap;
            }
        }
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>