<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">Everyday Life Insurance</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance' }">Life Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/life_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>Everyday Life Insurance</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>Everyday Life Insurance</h1>
                          <img src="../../../../public/imgs/reviews_list/national_familiy_life_review_1.webp" alt="">
                          <h2 class="anchor_h2">Pros:</h2>
                          <ul class="text1">
                              <li><span class="text_bold">30-day money-back guarantee: </span>If you are unhappy with your life insurance policy or simply change your mind, you can cancel your coverage for a full refund within the first 30 days.</li>
                              <li><span class="text_bold">Agents are available anytime: </span>National Family guides and agents work from the company’s platform anywhere and anytime. This means that you can get answers, receive a quote, and start coverage day or night… even in the midst of a worldwide pandemic! That’s right: even coronavirus shutdowns won’t impact your ability to shop around for life insurance through National Family.</li>
                              <li><span class="text_bold">Everyone gets the personalized touch: </span>To ensure that everyone is matched with the right policy at the right price, National Family’s guides will contact you to verify and, if needed, adjust your coverage options.</li>
                          </ul>
                          <h2 class="anchor_h2">Cons:</h2>
                          <ul class="text1">
                              <li><span class="text_bold">Your information may be shared with third-parties: </span>National Family will use the information that you provide to offer personalized quotes from their partner issuers. With your permission, your info may also be shared with third-party companies who may provide services that could be valuable to you, or to better personalize your insurance experience.</li>
                              <li><span class="text_bold">Partner companies are limited: </span>Currently, National Family only connects you with quotes from up to five different providers (Lumico/Assurance, John Hancock, Transamerica, Prudential, and HII). While this list is likely to grow in the future, it’s always wise to shop around as much as you can before committing to a policy.</li>
                          </ul>
                          <h2 class="anchor_h2">Review：</h2>
                          <p>Headquartered in Bellevue, Washington, National Life Insurance is part of the National Life Insurance Group. The larger company is an online marketplace for life, home, health and auto insurance. It offers the most competitive services offered by major providers such as GEICO, Allstate, Esurance, Progressive, Liberty Mutual and Nationwide.</p>
                          <p>National Family Assurance is attracting people buying their first life insurance who also appreciate being able to do things over the internet.</p>
                          <h2 class="anchor_h2">Features of National Family Life Insurance</h2>
                          <p>National Family Assurance is designed to sell various types of life insurance for a range of carriers, so its features vary. However, the website states that some features in the service are fixed. These measures include:</p>
                          <ul class="text3">
                              <li>1. Up to 70% life insurance savings</li>
                              <li>2. Sponsorship approval before age 85</li>
                              <li>3. Insurance up to $500,000</li>
                              <li>4. No medical examination required</li>
                          </ul>
                          <p>The goal behind National Family is to provide the best insurance for those who prefer to apply and purchase insurance over the Internet. It also applies to individuals who prefer to speak with an agent over the phone. However, there are differences between the two services. The online version only offers one policy quote, while calling will give you more options.</p>
                          <h2 class="anchor_h2">National Family Brand Rated Highly for Financial Strength.</h2>
                          <img src="../../../../public/imgs/reviews_list/national_familiy_life_review_2.jpg" alt="">
                          <p>The financial strength of the brand is highly rated by various assessment agencies for every insurance company offered through National Family. For example, Allstate has A1 from Moody's, A+ from S&P, and A+ from AM Best. However, each institution is graded differently, so an A+ at one institution may mean something different than an A+ at another institution.</p>
                          <p>To see how it works, A+ is the second best rating for AM best, but only in the top five by S&P. You can find more information about the ratings and the basis for each rating from the Insurance Information Institute. This can help you make sure you choose the right life insurance policy for your needs.</p>
                          <p>As an insurance customer, it is most important to understand the financial health of the underwriting insurance company. It's important to know that when you do need to claim, the supplier will be able to pay all associated costs if you do. You can rest assured that the companies that work with National Family are well-positioned in this regard.</p>
                          <p>As of 2019, National Life Insurance is rated A+ by the Better Business Bureau. Since the agency works with a variety of different insurance companies, the best way to check how well these companies are doing with customer service is to check with the BBB individually about your options for more information.</p>
                          <h2 class="anchor_h2">No Complaints Received for Life Insurance Products</h2>
                          <img src="../../../../public/imgs/reviews_list/national_familiy_life_review_3.webp" alt="">
                          <p>The company has received only about 25 complaints since 2016. Most of the time these are related to sales or advertising rather than individual life insurance products or claims issues. </p>
                          <p>National family provides services to everyone in the United States and provides life insurance from various partners. You can use free online tools or call to find strategies. The service can get quotations from insurance companies in real time according to your needs and lifestyle. The company is legal and has been highly rated by users in the past. However, in some cases, people may want to avoid life insurance without a medical examination, or at least compare the price with the company that needs a medical examination. They may find that through the complete examination and underwriting process, they can save more money. Otherwise, if people want to buy life insurance, national family is a good insurance institution.</p>
                          <h2 class="anchor_h2">National Family has High Authority.</h2>
                          <p>National Family Assurance Group is a licensed insurance agency and online marketplace that works with highly rated carriers such as New York Life, AIG Direct, Allstate, Liberty Mutual, Omaha Mutual and National.</p>
                          <p>In addition to offering regular, overall, generic and final charge policies, National Life Insurance Group provides free information, insurance quotes, tools, links and advice to help customers with all their insurance-related needs.</p>
                          <p>We rate National Life Insurance Group highly for its extremely flexible options.</p>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Pros</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">Cons</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">Review</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">Features of National Family Life Insurance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">National Family Brand Rated Highly for Financial Strength.</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">No Complaints Received for Life Insurance Products</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">National Family has High Authority.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
</style>