<template>
  <div>
      <div class="container">
          <!-- <div class="nav_img">
              <div class="nav_img_content">
                  <div class="head_title">StateFarm Auto</div>
                  <div class="head_line">
                      <div class="title_underline_left"></div>
                      <div class="title_origin"></div>
                      <div class="title_underline_right"></div>
                  </div>
              </div>
          </div> -->
          <div class="article_container">
              <div class="Bread_crumbs">
                  <el-breadcrumb separator-class="el-icon-arrow-right">
                      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance' }">Auto Insurance</el-breadcrumb-item>
                      <el-breadcrumb-item :to="{ path: '/auto_insurance_reviews' }">Reviews</el-breadcrumb-item>
                      <el-breadcrumb-item>StateFarm Auto</el-breadcrumb-item>
                  </el-breadcrumb>
              </div>
              <div class="article_list">
                  <div class="article_list_left">
                      <div class="text">
                          <h1>StateFarm Auto</h1>
                          <img src="../../../../public/imgs/reviews_list/statefarm_auto_review_1.jpg" alt="">
                          <h2 class="anchor_h2">Introduction to State Farm</h2>
                          <p>Since 1922, the largest auto insurance in the United States has been in operation. State Farm wrote $41.6 billion in private passenger auto insurance premiums in 2021, according to the National Association of Insurance Commissioners (NAIC), accounting for 15.9% of the market. That's about $4 billion more than Geico, the second-largest insurer.</p>
                          <p><a href="https://www.statefarm.com/insurance/auto">State Farm</a> began as a single-line vehicle insurance firm, but now offers more than 100 products and services across five business lines, including home, life, and health insurance. In addition, the Illinois-based insurance firm offers a variety of financial services, including loans, estate planning, and investment accounts.</p>
                          <p>Many consumers prefer to work with local insurance agents, and <a href="https://www.statefarm.com/insurance/auto">State Farms</a> has more than 19,000 agents nationwide, with the exception of Massachusetts and Rhode Island. That means there's a strong chance you'll be able to locate a State Farm agent near you.</p>
                          <h2 class="anchor_h2">What Does State Farm Car Insurance Cover for You?</h2>
                          <p>State Farm provides all of the basic auto insurance coverages, as well as rideshare, car rental, and travel expenses coverage. Although the corporation does not offer gap insurance, it does offer a similar service known as Payoff Protector to anyone who takes out a car loan with a State Farm bank (in alliance with U.S. Bank).</p>
                          <ul class="text1">
                              <li>bank (in alliance with U.S. Bank).</li>
                              <li>Liability insurance (bodily injury and property damage)</li>
                              <li>Collision and comprehensive coverage</li>
                              <li>Medical payments and personal injury protection</li>
                              <li>Coverage for uninsured and underinsured motorists</li>
                              <li>Coverage for car rentals and travel expenditures</li>
                              <li>Roadside assistance in an emergency</li>
                              <li>Insurance for rideshare drivers</li>
                          </ul>
                          <h2 class="anchor_h2">What Does State Farm Car Insurance Cost?</h2>
                          <p>According to our annual survey of the Cheapest Car Insurance Companies, most drivers can save money with State Farm. State Farm had an average auto insurance rate of $1,268, according to our research. After USAA and Geico, this is the third-lowest average rate in our survey. This pricing also indicates a savings when compared to our study's national average rate of $1,350.</p>
                          <p>Keep in mind that your auto insurance premiums will vary depending on your unique circumstances. Despite the fact that State Farm has some of the best rates, our research discovered that there are a few outliers for certain demographic groups.</p>
                          <h2 class="anchor_h2">State Farm Auto Insurance Discounts </h2>
                          <img src="../../../../public/imgs/reviews_list/statefarm_auto_review_2.jpg" alt="">
                          <p>Even though State Farm's vehicle insurance prices are generally reasonable, discounts might help you save even more money.</p>
                          <p>Bundling, multiple vehicle, safe driving, anti-theft system, defensive driving, and airbag discounts are some of the most common auto insurance discounts offered by State Farm. However, several of the most popular discounts, such as pay-in-full and paperless, are not available.</p>
                          <p><a href="https://www.statefarm.com/insurance/auto">State Farm</a> is ideal for young drivers because it gives a discount for finishing a driver's education course before the age of 21 as well as a significant student discount. A rebate on medical bills is possible for drivers who own an automobile manufactured after 1994.</p>
                          <h2 class="anchor_h2">Customer Feedback and Satisfaction</h2>
                          <p>In both claims satisfaction and purchasing experience, the company was ranked marginally higher than its competitors. The little number of complaints received in contrast to the company's size is a positive indicator of its customer happiness.</p>
                          <p>AM Best has given the company an A++ rating for financial strength, indicating that it is financially secure enough to pay its clients' insurance claims. This is the highest possible rating for an insurance firm. While they have the cash to pay for a claim, some consumers have expressed dissatisfaction with the company's claim procedure and payment.</p>
                          <h2 class="anchor_h2">How to Obtain State Farm Auto Insurance</h2>
                          <p><a href="https://www.statefarm.com/insurance/auto">State Farm</a> can be signed up for online or over the phone, as well as by visiting a local State Farm office and acquiring a quote in person.</p>
                          <p>State Farm's application, like other insurance quotations, demands basic information as well as your driver's licence number or social security number in order to obtain a quote. The candidate must also include an email address or a phone number in order to complete the application.</p>
                          <p>If you prefer face-to-face communication, <a href="https://www.statefarm.com/insurance/auto">State Farm</a> has a nationwide network of offices and agents who can assist you with the application process.</p>
                          <div class="FAQ_container">
                              <div class="FAQ_list">
                                  <div class="FAQ_list_left">
                                      <div class="FAQ_onecontailner">
                                          <h2 class="anchor_h2">FAQ</h2>
                                          <el-collapse accordion class="FAQ">
                                              <el-collapse-item title="What is the Contact Number for State Farm's Accident Claim?" name="1">
                                                  <div>To generate a claim, call 800-732-5246 (800-SF-CLAIM) directly if you are a State Farm client or were in an accident with one of State Farm's insured drivers. You can also submit an insurance claim using State Farm's mobile app or online.</div>
                                              </el-collapse-item>
                                              <el-collapse-item title="Is Towing Covered by State Farm?" name="2">
                                                  <div>The answer is Yes! With its roadside assistance add-on, State Farm covers towing. In the event of an accident, State Farm's collision or comprehensive insurance may also cover towing. If you simply have liability coverage for personal injury and property damage, you will be responsible for towing until the other driver's insurance covers it.</div>
                                              </el-collapse-item>
                                          </el-collapse>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="article_list_right">
                        <div class="text_right_bottom">
                            <div class="text_bottom_top">
                                <h2>Table of Contents</h2>
                                <ul>
                                    <li>
                                        <div @click="scrollTo(0)">Introduction to State Farm</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(1)">What Does State Farm Car Insurance Cover for You?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(2)">What Does State Farm Car Insurance Cost?</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(3)">State Farm Auto Insurance Discounts</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(4)">Customer Feedback and Satisfaction</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(5)">How to Obtain State Farm Auto Insurance</div>
                                    </li>
                                    <li>
                                        <div @click="scrollTo(6)">FAQ</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: "personal_loans_guide",
    data() {
        return {
            search_input: '',
            score: 4,
            colors: {5: '#2ac8a2'},
            active: 0,
        }
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },
    destroy() {
        window.removeEventListener('scroll', this.onScroll)
    },
    methods: {
        onScroll() {
            const navContents = document.querySelectorAll('.text .anchor_h2')
            const offsetTopArr = []
            navContents.forEach(item => {
                offsetTopArr.push(item.offsetTop)
            })
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
             let navIndex = 0
             if (scrollTop >= 5293) {
                this.is_content_anchor = 1
             } else {
                 this.is_content_anchor = 0
             }
             for (let n = 0; n < offsetTopArr.length; n++) {
                // 如果 scrollTop 大于等于第 n 个元素的 offsetTop 则说明 n-1 的内容已经完全不可见
                // 那么此时导航索引就应该是 n 了
                if (scrollTop >= offsetTopArr[n]) {
                navIndex = n
                }
            }
            this.active = navIndex
        },
        scrollTo(index) {
            const targetOffsetTop = document.querySelectorAll(`.text .anchor_h2`)[index].offsetTop
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const STEP = 50
            if (scrollTop > targetOffsetTop) {
                // 往上滑
                smoothUp()
            } else {
                // 往下滑
                smoothDown()
            }
            function smoothDown() {
                if (scrollTop < targetOffsetTop) {
                // 如果和目标相差距离大于等于 STEP 就跳 STEP
                // 否则直接跳到目标点，目标是为了防止跳过了。
                if (targetOffsetTop - scrollTop >= STEP) {
                    scrollTop += STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
                // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
                requestAnimationFrame(smoothDown)
                }
            }
            function smoothUp() {
                if (scrollTop > targetOffsetTop) {
                if (scrollTop - targetOffsetTop >= STEP) {
                    scrollTop -= STEP
                } else {
                    scrollTop = targetOffsetTop
                }
                document.body.scrollTop = scrollTop
                document.documentElement.scrollTop = scrollTop
                requestAnimationFrame(smoothUp)
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/base.scss';
@import '../../../assets/scss/config.scss';
@import '../../../assets/scss/navimgnotcrad.scss';
@import '../../../assets/scss/textnavigation.scss';

.container {
    @include flex();
    flex-wrap: wrap;
    @media (max-width: 1199px) {
    }
    @media (max-width: 760px) {
    }
}
</style>
<style scoped>
    .el-input__inner {
        height: 60px;
        border: 1px solid #c5c5c5;
        border-radius: 12px;
    }
    
    .FAQ >>> .el-collapse-item__header {
        font-size: 16px;
        font-weight: 700;
        line-height: 18px;
    }
    .FAQ >>> .is-active {
        color: #2ac8a2;
    }
     @media (max-width: 760px) {
        .head_input >>> .el-input__inner {
            padding-left: 35px;
        }
        .FAQ >>> .el-collapse-item__header {
            height: 100px;
            text-align: left;
        }
    }
</style>